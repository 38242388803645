import React, { useContext } from 'react';
import Parceiro from '../Parceiro';
import Utils from '../../Utils';
import { MainContext } from '../../helpers/MainContext';

export default ({parceiros, hideText, hideFilter}) => {

    const {user} = useContext(MainContext);

    return (
      user && parceiros?.length > 0 ? (
        <div className='mt-3 mb-1'>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>     
          {hideText ? (null) : (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <ion-icon name="diamond-outline" style={{color: "black"}}></ion-icon>
                <h3 style={{color: "black"}}>&nbsp;Suas assinaturas</h3> 
              </div>  
          )}
          {hideFilter ? (null) : (
            <div>
              <ion-icon name="filter-outline" style={{fontSize: '15pt'}}></ion-icon>
            </div>
          )}
          </div>
          <div className='mt-1' style={{display: 'grid', gridTemplateColumns: Utils.mobileCheck() ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)', justifyContent: 'center', gap: '10px'}}>
              {parceiros?.map((parceiro, index) => (
                  <Parceiro className={`mt-1`} key={index} parceiro={parceiro}/>
              ))}
          </div>    
        </div>
      ) : (null)
    )
}
