import React, { useEffect, useState } from 'react';
import './style.css';
import Card from '../Card';
import Button from '../Button';
import ProductItem from '../ProductItem';
import { useNavigate } from 'react-router-dom';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';
import Utils from '../../Utils';
import Environment from '../../Environment';

const startSwipperLogic = (className) => {

    let elementsArray = [
        document.querySelector('.'+className)
    ];

    let isDown = false;
    let startX;
    let scrollLeft;

    const end = (s) => {
        isDown = false;
        s.classList.remove('active');
    }

    const start = (e, s) => {
        isDown = true;
        s.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - s.offsetLeft;
        scrollLeft = s.scrollLeft;	
    }
    
    const move = (e, s) => {
        if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - s.offsetLeft;
        const dist = (x - startX);
        s.scrollLeft = scrollLeft - dist;
    }

    elementsArray.forEach(function(slider) {

        if(slider){
            slider.addEventListener('mousedown', function (e) { start(e, slider) });
            slider.addEventListener('touchstart', function (e) { start(e, slider) });
            slider.addEventListener('mousemove', function (e) { move(e, slider) });
            slider.addEventListener('touchmove', function (e) { move(e, slider) });
            slider.addEventListener('mouseleave', function () {end(slider) });
            slider.addEventListener('mouseup', function () { end(slider) });
            slider.addEventListener('touchend', function () { end(slider) });
        }

    });

}

export default ({parceiros, produtos, titleComponent, useFilter, ignoreMarginTop, useMarginBottomLarge}) => {

    const navigate = useNavigate();

    const [coords, setCoords] = useState(null);

    useEffect(() => {
        CoordsProviderHelper(async (coords) => {
            setCoords({
                latitude: coords?.latitude,
                longitude: coords?.longitude,
            })
        })
    }, [])

    useEffect(() => {
        parceiros?.map((item, index) => {
            startSwipperLogic(`items-product-${index}`);
        })
    }, [])

    function getProcuctsByParceiro (id_parceiro) {
        return produtos?.filter(p => {return p?.id_parceiro == id_parceiro});
    }

    const handleShowMore = async (id_parceiro) => {
        navigate(`/parceiro/${id_parceiro}`);
    }

    const handleProductClick = async (id_parceiro, id_produto) => {
        localStorage.setItem("tk_beauty_product_clicked", id_produto);
        navigate(`/parceiro/${id_parceiro}`);
    }

    return (
        produtos?.length > 0 ? (
            <div className={`${!ignoreMarginTop ? '' : 'mt-3'} mb-1`}>
                <div className={`${useMarginBottomLarge ? 'mb-2' : 'mb-1'}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {titleComponent}
                    {useFilter ? (
                        <div>
                            <ion-icon name="filter-outline" style={{fontSize: '15pt'}}></ion-icon>
                        </div>
                    ) : (null)}
                </div>
                {parceiros?.map((parceiro, index) => (
                    getProcuctsByParceiro(parceiro?.id_parceiro)?.length > 0 ? (
                        <Card style={{marginBottom: '8px'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}} onClick={() => {handleShowMore(parceiro?.id_parceiro)}}>
                                    <img 
                                        src={Environment.API_IMAGE + "/imagem/" + parceiro?.logo_parceiro || "../placeholder-image.png"}
                                        style={{width: '20px', height: '20px', objectFit: 'cover', borderRadius: '50%'}} 
                                        loading="lazy"
                                    />
                                    <b style={{fontSize: '0.90rem'}}>&nbsp;{parceiro?.nome_parceiro}</b>&nbsp;•&nbsp; 
                                    <span style={{fontSize: '0.90rem'}}>{
                                        Utils.calculateDistance(
                                            Number(parceiro?.latitude),
                                            Number(parceiro?.logintude),
                                            coords?.latitude,
                                            coords?.longitude,
                                        ).toFixed(1)
                                    }km</span>
                                </div>
                                <Button onClick={() => {handleShowMore(parceiro?.id_parceiro)}} style={{height: '30px', fontSize: '0.85rem'}}>Ver Mais</Button>
                            </div>
                            <div className="wrapper mb-2 mt-1">
                                <ul className={`items-product items-product-${index}`} style={{padding: '4px'}}>
                                    {getProcuctsByParceiro(parceiro?.id_parceiro)?.map((produto, index) => (
                                        <ProductItem onClick={() => {handleProductClick(parceiro?.id_parceiro, produto?.id_produto)}} produto={produto} />
                                    ))}
                                </ul>
                            </div>
                        </Card>
                    ):(null)
                ))}
            </div>
        ) : (null)
    )
}
