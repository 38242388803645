import axios from "axios";
import Environment from "./Environment";

const API_BASE = Environment.API_BASE;
const RAIO_KM = 40;
const LIMIT_PARCEIROS = 35;

const Api = {
    geral: {
        banners: async ({lat, lon}) => {
            return await axios.get(`${API_BASE}/banner/banners/raio/${lat}/${lon}/${RAIO_KM}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        categoria: async (id) => {
            return await axios.get(`${API_BASE}/categoria/categorias/${id}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        categorias: async () => {
            return await axios.get(`${API_BASE}/categoria/categorias`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        parceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/parceiro/parceiros/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        parceiros: async ({lat, lon}) => {
            return await axios.get(`${API_BASE}/parceiro/parceiros/raio/${lat}/${lon}/${RAIO_KM}/${LIMIT_PARCEIROS}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        parceirosSearch: async ({lat, lon, filtro}) => {
            return await axios.get(`${API_BASE}/parceiro/parceiros/search/raio/${lat}/${lon}/${filtro}/${RAIO_KM}/${LIMIT_PARCEIROS}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        produtos: async ({ids_parceiros}) => {
            return await axios.post(`${API_BASE}/produto/produtos/parceiros/`, {ids_parceiros: ids_parceiros.join("-")}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        produtosByParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/produto/produtos/parceiro/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        assinaturasByParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/assinatura/assinaturas/parceiro/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        servicosByParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/servico/servicos/parceiro/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        colaboradoresByParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/colaborador/colaboradores/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        agendamentosParceiroByColaborador: async ({id_parceiro, id_colaborador}) => {
            return await axios.get(`${API_BASE}/agendamento/agendamentos/parceiro/${id_parceiro}/colaborador/${id_colaborador}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        parceiroExpedientes: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/parceiro/parceiros/expedientes/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        postsByParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/post/posts/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        avaliacaoParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/avaliacao/parceiro/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        avaliacaoCompletaParceiro: async ({id_parceiro}) => {
            return await axios.get(`${API_BASE}/avaliacao/completa/parceiro/${id_parceiro}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        viaCep: async ({cep}) => {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                if (response.data.erro) {
                    return null;
                } else {
                    return response.data;
                }
            } catch (error) {
                return null;
            }
        },
        bancos: async () => {
            return await axios.get(`${API_BASE}/banco/lista`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        receitaAwsCnpj: async ({cnpj}) => {
            return await axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            }); 
        },
        ufs: async () => {
            return await axios.get(`${API_BASE}/uf/lista`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        municipio: async ({ufid}) => {
            return await axios.get(`${API_BASE}/uf/municipio/${ufid}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        servico: async ({id_servico}) => {
            return await axios.get(`${API_BASE}/servico/servicos/${id_servico}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        solicitacaoPaymentLink: async ({id_payment_link_solicitacao}) => {
            return await axios.get(`${API_BASE}/agendamento/agendamentos/solicitacao/${id_payment_link_solicitacao}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        verificarAgendamentoSolicitacao: async ({id_payment_link_solicitacao}) => {
            return await axios.get(`${API_BASE}/agendamento/agendamentos/verificar/${id_payment_link_solicitacao}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        agendarHorarioPaymentLink: async ({data}) => {
            return await axios.post(`${API_BASE}/agendamento/agendamentos/register-with-payment-link`, {data: data}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        }
    },
    cliente: {
        auth: async () => {
            return await axios.get(`${API_BASE}/cliente/auth`, Environment.HEADERS_CLIENTE).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        get: async (forceToken) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/cliente/get`, headers).then(async (response) => {
                localStorage.setItem("tk_beauty_id", response?.data?.data?.id_cliente);
                return await response;
            }).catch(err => {
                return err;
            });
        },
        login: async ({device_token, phone, password}) => {
            return await axios.post(`${API_BASE}/cliente/login`, {device_token, phone, password}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        register: async ({device_token, nome, email, cpf, phone, password, precadastro}) => {
            return await axios.post(`${API_BASE}/cliente/register`, {device_token, nome, email, cpf, phone, password, precadastro}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        addAddress: async ({id_cliente, cep, logradouro, uf, municipio, numero, complemento, latitude, logintude}) => {
            return await axios.post(`${API_BASE}/cliente/address/add`, {id_cliente, cep, logradouro, uf, municipio, numero, complemento, latitude, logintude}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        updateAddress: async ({id_cliente, id_endereco, cep, logradouro, uf, municipio, numero, complemento, latitude, logintude}) => {
            return await axios.post(`${API_BASE}/cliente/address/update`, {id_cliente, id_endereco, cep, logradouro, uf, municipio, numero, complemento, latitude, logintude}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        removeAddress: async ({id_cliente, id_endereco}) => {
            return await axios.post(`${API_BASE}/cliente/address/remove`, {id_cliente, id_endereco}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        updateAddressPrincipal: async ({id_cliente, id_endereco}) => {
            return await axios.post(`${API_BASE}/cliente/address/update-principal`, {id_cliente, id_endereco}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        verifyUserExists: async ({phone, cpf}) => {
            return await axios.get(`${API_BASE}/cliente/verify-exist-user/${phone}/${cpf}`).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        editPassword: async ({forceToken, oldPassword, newPassword}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.put(`${API_BASE}/cliente/editpassword`, {oldPassword, newPassword}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        favoritar: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.post(`${API_BASE}/favorito/favoritos`, {id_parceiro}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        desfavoritar: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.delete(`${API_BASE}/favorito/favoritos/${id_parceiro}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        favoritoByParceiro: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/favorito/favoritos/${id_parceiro}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        favoritosByCliente: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/favorito/favoritos/cliente`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        parceirosFavoritosByCliente: async ({forceToken, ids_parceiros}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/parceiro/parceiros/favoritos/${ids_parceiros.join("-")}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        agendarHorario: async ({forceToken, data}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.post(`${API_BASE}/agendamento/agendamentos`, {data: data}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        remarcarHorario: async ({forceToken, data}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.put(`${API_BASE}/agendamento/remarcar`, {data: data}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        obterAgendamentos: async (forceToken) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/agendamento/cliente`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        update: async ({data, forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.put(`${API_BASE}/cliente/clientes`, {data}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        cancelarAgendamento: async ({id_agendamento, forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.put(`${API_BASE}/agendamento/cancelar`, {id_agendamento}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        obterSaldoBonus: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/bonus/saldo`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        obterHistoricoSaldoBonus: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/bonus/history`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        assinaturasByCliente: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/assinatura/cliente`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        assinaturasByClienteTodos: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/assinatura/cliente/todos`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        assinaturaByClienteCheck: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/assinatura/cliente/check/${id_parceiro}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        verifyCode: async ({forceToken, code}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.post(`${API_BASE}/cliente/verify-code`, {code}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        resendCode: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.post(`${API_BASE}/cliente/resend-code`, {}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        avaliacoesDisponiveis: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/avaliacao/disponiveis`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        avaliar: async ({forceToken, id_avaliacao, estrelas, comentario, ignorado, mostrado}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.put(`${API_BASE}/avaliacao/avaliar`, {id_avaliacao, estrelas, comentario, ignorado, mostrado}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        historico: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/cliente/history`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getAddresses: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/cliente/address`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        notifications: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_CLIENTE_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_CLIENTE;
            return await axios.get(`${API_BASE}/notificacao/notificacoes`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        calcularFrete: async ({id_parceiro, id_endereco, id_cliente, id_produto}) => {
            return await axios.post(`${API_BASE}/frete/calcular-preco`, {id_parceiro, id_endereco, id_cliente, id_produto}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        sendCodeRecoveryPassword: async ({phone}) => {
            return await axios.post(`${API_BASE}/cliente/send-code-recovery-password`, {phone}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        verifyCodeRecoveryPassword: async ({phone, code}) => {
            return await axios.post(`${API_BASE}/cliente/verify-code-recovery-password`, {phone, code}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        recoveryChangePassword: async ({phone, code, senha}) => {
            return await axios.post(`${API_BASE}/cliente/change-password-recovery`, {phone, code, senha}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
    },
    parceiro: {
        register: async ({data}) => {
            try {
                const formData = new FormData();
                formData.append('data', JSON.stringify(data));
                formData.append('foto_frente_documento_identificacao', data.detalhes.foto_frente_documento_identificacao);
                formData.append('foto_verso_documento_identificacao', data.detalhes.foto_verso_documento_identificacao);
                formData.append('selfie_documento_identificacao', data.detalhes.selfie_documento_identificacao);
                formData.append('foto_certificacao_servico', data.detalhes.foto_certificacao_servico);
                const response = await axios.post(`${API_BASE}/parceiro/parceiros`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                return response.data;
            } catch (err) {
                return err;
            }
        },
        saldo: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/parceiro/parceiros/${id_parceiro}/saldo`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        historico: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/parceiro/parceiros/${id_parceiro}/history`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        contasPix: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/parceiro/parceiros/${id_parceiro}/contas-pix`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        saque: async ({forceToken, id_parceiro, id_conta_pix}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.post(`${API_BASE}/payment/saque/${id_parceiro}`, {id_conta_pix}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        addContaPix: async ({forceToken, id_parceiro, nome, tipo, chave, proprietario}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.post(`${API_BASE}/parceiro/add-conta-pix`, {id_parceiro, nome, tipo, chave, proprietario}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        pontoEntregas: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/parceiro/parceiros/${id_parceiro}/ponto-entregas`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        pontoEntregasUpdate: async ({forceToken, id_parceiro, flg_delivery, json}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/parceiro/parceiros/ponto-entregas-update`, {id_parceiro, flg_delivery, json}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        clientes: async ({forceToken, id_parceiro, nome}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.post(`${API_BASE}/parceiro/parceiros/clientes`, {id_parceiro, nome}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        pedidos: async ({forceToken, id_parceiro,}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/parceiro/pedidos/${id_parceiro}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        updatePedidoStaus: async ({forceToken, id_pedido, status, notify}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/parceiro/pedidos/update-status`, {id_pedido, status, notify}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getPedidosIndicadores: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/produto/adm/${id_parceiro}/pedidos/indicadores`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        }
    },
    colaborador: {
        auth: async () => {
            return await axios.get(`${API_BASE}/colaborador/auth`, Environment.HEADERS_PARCEIRO).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        get: async (forceToken) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/colaborador/get`, headers).then(async (response) => {
                localStorage.setItem("tk_beauty_id", response?.data?.data?.id_colaborador);
                return await response;
            }).catch(err => {
                return err;
            });
        },
        login: async ({device_token, role, phone, password}) => {
            return await axios.post(`${API_BASE}/colaborador/login`, {device_token, role, phone, password}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        resendDocumentation: async ({forceToken, data}) => {
            try {
                let headers = (forceToken) ? { headers: { TKBEAUTY_PARCEIRO_ACCESS_TOKEN: forceToken } } : Environment.HEADERS_PARCEIRO;

                const formData = new FormData();

                console.log(data)
                formData.append('foto_frente_documento_identificacao', data.foto_frente_documento_identificacao);
                formData.append('foto_verso_documento_identificacao', data.foto_verso_documento_identificacao);
                formData.append('selfie_documento_identificacao', data.selfie_documento_identificacao);

                headers.headers['Content-Type'] = 'multipart/form-data';
                
                try {
                    const response = await axios.post(`${API_BASE}/parceiro/parceiros/resend-documentation`, formData, headers);
                    return response;
                } catch (error) {
                    return error;
                }
            } catch (err) {
                console.log(err);
                return err;
            }
        },
        sendCodeRecoveryPassword: async ({phone}) => {
            return await axios.post(`${API_BASE}/colaborador/send-code-recovery-password`, {phone}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        verifyCodeRecoveryPassword: async ({phone, code}) => {
            return await axios.post(`${API_BASE}/colaborador/verify-code-recovery-password`, {phone, code}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        recoveryChangePassword: async ({phone, code, senha}) => {
            return await axios.post(`${API_BASE}/colaborador/change-password-recovery`, {phone, code, senha}).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        getAgendamentos: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/agendamento/adm/agendamentos/now-next`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        finishAgendamentoByCode: async ({forceToken, code, id_agendamento}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/agendamento/adm/agendamentos/finish-code`, {code, id_agendamento}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        finishAgendamentoByCpf: async ({forceToken, cpf, id_agendamento}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/agendamento/adm/agendamentos/finish-cpf`, {cpf, id_agendamento}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        addServico: async ({forceToken, id_parceiro, ativo, nome_servico, descricao_servico, preco_servico, tempo_servico, incluso_na_assinatura, atende_domicilio}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.post(`${API_BASE}/servico/servicos`, {id_parceiro, ativo, nome_servico, descricao_servico, preco_servico, tempo_servico, incluso_na_assinatura, atende_domicilio}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        updateServico: async ({forceToken, id_servico, ativo, nome_servico, descricao_servico, preco_servico, tempo_servico, incluso_na_assinatura, atende_domicilio}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/servico/servicos/editar`, {id_servico, ativo, nome_servico, descricao_servico, preco_servico, tempo_servico, incluso_na_assinatura, atende_domicilio}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        servicosByParceiro: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/servico/servicos/parceiro/${id_parceiro}/inativos`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        deleteServico: async ({forceToken, id_servico}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.delete(`${API_BASE}/servico/servicos/${id_servico}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        produtos: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/produto/produtos/parceiros/${id_parceiro}/inativos`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        addColaborador: async ({ forceToken, id_parceiro, ativo, nome, imagem, email, celular, senha, disponivel }) => {
            let headers = (forceToken) ? { headers: { TKBEAUTY_PARCEIRO_ACCESS_TOKEN: forceToken } } : Environment.HEADERS_PARCEIRO;
        
            let formData = new FormData();
            formData.append('id_parceiro', id_parceiro);
            formData.append('ativo', ativo);
            formData.append('nome', nome);
            formData.append('imagem', imagem);
            formData.append('email', email);
            formData.append('celular', celular);
            formData.append('senha', senha);
            formData.append('disponivel', disponivel);
        
            headers.headers['Content-Type'] = 'multipart/form-data';
        
            try {
                const response = await axios.post(`${API_BASE}/colaborador/add`, formData, headers);
                return response;
            } catch (error) {
                return error;
            }
        },
        updateColaborador: async ({ forceToken, id_parceiro, id_colaborador, ativo, nome, imagem, email, celular, disponivel }) => {
            let headers = (forceToken) ? { headers: { TKBEAUTY_PARCEIRO_ACCESS_TOKEN: forceToken } } : Environment.HEADERS_PARCEIRO;
        
            let formData = new FormData();
            formData.append('id_parceiro', id_parceiro);
            formData.append('id_colaborador', id_colaborador);
            formData.append('ativo', ativo);
            formData.append('nome', nome);
            formData.append('imagem', imagem);
            formData.append('email', email);
            formData.append('celular', celular);
            formData.append('disponivel', disponivel);
        
            headers.headers['Content-Type'] = 'multipart/form-data';
        
            try {
                const response = await axios.put(`${API_BASE}/colaborador/update`, formData, headers);
                return response;
            } catch (error) {
                return error;
            }
        },
        deleteColaborador: async ({forceToken, id_parceiro, id_colaborador}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.delete(`${API_BASE}/colaborador/delete/${id_parceiro}/${id_colaborador}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        colaboradoresByParceiro: async ({forceToken, id_parceiro,}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/colaborador/colaboradores/${id_parceiro}/inativos`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        updateDisponibilidade: async ({forceToken, id_parceiro, disponivel, id_colaborador}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/colaborador/update/disponibilidade`, {id_parceiro, disponivel, id_colaborador}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        receitaDiaria: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/agendamento/adm/agendamentos/receita-diaria`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        ticketDiario: async ({forceToken}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/agendamento/adm/agendamentos/ticket-diario`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        addProduto: async ({forceToken, id_parceiro, ativo, nome, imagem, preco, descricao, estado, altura, largura, comprimento, peso, estoque}) => {
            let headers = (forceToken) ? { headers: { TKBEAUTY_PARCEIRO_ACCESS_TOKEN: forceToken } } : Environment.HEADERS_PARCEIRO;
            let formData = new FormData();
            formData.append('id_parceiro', id_parceiro);
            formData.append('ativo', ativo);
            formData.append('nome', nome);
            formData.append('imagem', imagem);
            formData.append('preco', preco);
            formData.append('descricao', descricao);
            formData.append('estado', estado);
            formData.append('altura', altura);
            formData.append('largura', largura);
            formData.append('comprimento', comprimento);
            formData.append('peso', peso);
            formData.append('estoque', estoque);
            headers.headers['Content-Type'] = 'multipart/form-data';
            try {
                const response = await axios.post(`${API_BASE}/produto/add`, formData, headers);
                return response;
            } catch (error) {
                return error;
            }
        },
        updateProduto: async ({forceToken, id_parceiro, id_produto, ativo, nome, imagem, preco, descricao, estado, altura, largura, comprimento, peso, estoque}) => {
            let headers = (forceToken) ? { headers: { TKBEAUTY_PARCEIRO_ACCESS_TOKEN: forceToken } } : Environment.HEADERS_PARCEIRO;
            let formData = new FormData();
            formData.append('id_parceiro', id_parceiro);
            formData.append('id_produto', id_produto);
            formData.append('ativo', ativo);
            formData.append('nome', nome);
            formData.append('imagem', imagem);
            formData.append('preco', preco);
            formData.append('descricao', descricao);
            formData.append('estado', estado);
            formData.append('altura', altura);
            formData.append('largura', largura);
            formData.append('comprimento', comprimento);
            formData.append('peso', peso);
            formData.append('estoque', estoque);
            headers.headers['Content-Type'] = 'multipart/form-data';
            try {
                const response = await axios.put(`${API_BASE}/produto/update`, formData, headers);
                return response;
            } catch (error) {
                return error;
            }
        },
        deleteProduto: async ({forceToken, id_parceiro, id_produto}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.delete(`${API_BASE}/produto/delete/${id_parceiro}/${id_produto}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        assinantes: async ({forceToken, id_parceiro}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.get(`${API_BASE}/assinatura/assinantes/${id_parceiro}`, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        solicitacaoAgendamentoPaymentLink: async ({forceToken, data}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.post(`${API_BASE}/agendamento/agendamentos/solicitacao`, {data}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        },
        agendamentoPaymentLinkUpdate: async ({forceToken, phone, name, id_payment_link_solicitacao, payment_link}) => {
            let headers = (forceToken) ?  {headers:{TKBEAUTY_PARCEIRO_ACCESS_TOKEN : forceToken}} : Environment.HEADERS_PARCEIRO;
            return await axios.put(`${API_BASE}/agendamento/agendamentos/solicitacao-update`, {phone, name, id_payment_link_solicitacao, payment_link}, headers).then(async (response) => {
                return await response;
            }).catch(err => {
                return err;
            });
        }
    },
}

export default Api;