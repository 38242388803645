import React, { useContext, useState } from 'react';
import './style.css';
import Card from '../Card';
import Switch from '../Switch';
import Utils from '../../Utils';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import Environment from '../../Environment';

export default ({ index, colaborador, className, openModalEdit, handleDeleteColaborador }) => {

    const {user} = useContext(MainContext);

    const [disponivel, setDisponivel] = useState(colaborador?.disponivel === 1);

    const handleToggleDisponivel = async () => {
        setDisponivel(!disponivel);
        let request = await Api.colaborador.updateDisponibilidade({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro,
            id_colaborador: colaborador?.id_colaborador,
            disponivel: !disponivel
        })
        Utils.processRequest(request, (data) => {}, () => {}, true);
    };
    
    return (
        <>
            <Card className={className} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={Environment.API_IMAGE + "/imagem/" + colaborador?.foto || "../placeholder-image.png"} style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '4px' }} />&nbsp;
                    <div>
                        {user?.id_colaborador == colaborador?.id_colaborador ? (
                            <>
                                <b>{colaborador?.nome} (você)</b><br />
                            </>
                        ) : (
                            <>
                                <b>{colaborador?.nome}</b><br />
                            </>
                        )}
                        <span style={{ fontSize: '10pt' }}>{Utils.formatarParaCelular(colaborador?.celular)}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                            index={index}
                            isOn={disponivel}
                            onChange={handleToggleDisponivel}
                            colorOne="#000"
                            colorTwo="#ddd"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div onClick={() => {openModalEdit(colaborador)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                            <ion-icon name="create-outline" size="large"></ion-icon>
                        </div>
                        {user?.id_colaborador == colaborador?.id_colaborador ? (null) : (
                            <>
                                &nbsp;&nbsp;
                                <div onClick={() => {handleDeleteColaborador(colaborador)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                    <ion-icon style={{color: 'red'}} name="trash-outline" size="large"></ion-icon>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Card>
        </>
    );
}
