import React from 'react';
import Utils from '../../Utils';
import './style.css';
import Card from '../Card';

export default () => {
    return (
        <>
            {Utils.isWebView() ? (null) : (
                <Card style={{padding: '16px'}} className='mt-2 mb-2'>
                    <span>Para melhor <b>experiência</b>, baixe nosso <b>APP</b></span>
                    <div className='mt-1 mb-1' style={{overflow: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%'}}>
                        <img onClick={() => {}} src="../app_google.webp" style={{width: '140px', cursor: 'pointer'}}/>&nbsp;
                        <img onClick={() => {}} src="../app_ios.png" style={{width: '140px', cursor: 'pointer'}}/>
                    </div>
                    <p style={{textAlign: 'center', fontSize: '10pt'}}>
                        &copy; IGend | Todos os direitos reservados
                    </p>
                </Card>
            )} 
        </>
    )
}
