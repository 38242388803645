import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Button, Card, FragmentView, Hr, Input, MapMarker, Modal, Option, Select, SpaceBox } from '../../components';
import Api from '../../Api';
import Utils from '../../Utils';
import { MainContext } from '../../helpers/MainContext';

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState();
    const [addressSelected, setAddressSelected] = useState(null);
    const [addressSelectedAction, setAddressSelectedAction] = useState(null);
    
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [mode, setMode] = useState(null);

    const [addressLatitude, setAddressLatitude] = useState(null);
    const [addressLogintude, setAddressLogintude] = useState(null);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [cep, setCep] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [uf, setUf] = useState(null);
    const [municipio, setMunicipio] = useState(null);

    useEffect(() => {
        requests();
    }, [])

    useEffect(() => {
        const request = async () =>{
            if(cep?.length >= 9 && mode != "UPDATE"){
                let viaCepResponse = await Api.geral.viaCep({cep});
                if(viaCepResponse){
                    setLogradouro(viaCepResponse?.bairro +", "+ viaCepResponse?.logradouro)
                    setUf(viaCepResponse?.uf);
                }else{
                    setLogradouro("");
                }
            }
        }
        request();
    }, [cep, setCep])

    useEffect(() => {
        if(uf){
            requestMunicipios();
        }
    }, [setUf, uf])

    const requestMunicipios = async () =>{
        let municipioResponse = await Api.geral.municipio({
            ufid: ufs.filter(_uf => {return _uf.sigla == uf})[0]?.id
        });
        let _municipios = municipioResponse?.data?.data;
        setMunicipios(_municipios);
    }

    const requests = async () =>{
        setLoading(false);
        let responseAddresses = await Api.cliente.getAddresses({
            forceToken: localStorage.getItem("tk_beauty_cliente_token")
        });

        let _addresses = responseAddresses?.data?.data;
        let _addressSelected = _addresses.filter(a => {return a.principal == 'S'})[0];
        
        setAddresses(_addresses);
        setAddressSelected(_addressSelected || null);

        let ufListResponse = await Api.geral.ufs();
        let _ufs = ufListResponse?.data?.data;
        setUfs(_ufs);

        setLoading(true);
    }

    const handleSelectedAddress = async (address) => {
        setAddressSelected(address);
        updateAddressPrincipal(address);
    }

    const handleAddAddress = () => {
        setAddressSelectedAction(null);
        setShow(true);
        setMode("CREATE");
    }

    const handleUpdateAddress = (a) => {
        setAddressSelectedAction(a);

        setCep(a.cep);
        setLogradouro(a?.logradouro);
        setNumero(a?.numero);
        setComplemento(a?.complemento);
        setUf(a?.uf);
        setMunicipio(a?.municipio);
        setAddressLatitude(a?.latitude);
        setAddressLogintude(a?.logintude);

        setShow(true);
        setMode("UPDATE");
    }

    const handleDeleteAddress = (a) => {
        setAddressSelectedAction(a);
        setShowDelete(true);
        setMode("DELETE");
    }
    
    const handleDeleteAddressOk = async () => {
        let request = await Api.cliente.removeAddress({
            id_cliente: user?.id_cliente,
            id_endereco: addressSelectedAction?.id_endereco,
        })
        Utils.processRequest(request, (data) => {
            setShowDelete(false);
            requests();
        }, () => {}, true);
        reset();
    }

    const onPositionChanged = ({latitude, longitude}) => {
        setAddressLatitude(latitude);
        setAddressLogintude(longitude);
    }

    const reset = () => {
        setCep("");
        setLogradouro("");
        setNumero("");
        setComplemento("");
        setUf(null);
        setMunicipio(null);
        setAddressSelectedAction(null);
        setMode(null);
    }

    const handleAddressSave = async () => {
        if(!cep || cep?.trim()?.length <= 0){
            Utils.notify("error", "Digite o CEP.");
            return;
        }
        if(!Utils.validateCep(cep)){
            Utils.notify("error", "CEP inválido.");
            return;
        }
        if(!logradouro || logradouro?.trim()?.length <= 0){
            Utils.notify("error", "Digite o logradouro.");
            return;
        }
        if(uf == null|| uf?.trim()?.length <= 0){
            Utils.notify("error", "Selecione a UF.");
            return;
        }
        if(!municipio){
            Utils.notify("error", "Selecione o município.");
            return;
        }
        if(!numero || numero?.trim()?.length <= 0){
            Utils.notify("error", "Selecione o número.");
            return;
        }

        let request = null;

        switch(mode){
            case "CREATE":
                request = await Api.cliente.addAddress({
                    id_cliente: user?.id_cliente,
                    cep: cep,
                    logradouro: logradouro,
                    uf: uf,
                    municipio: municipio,
                    numero: numero,
                    complemento: complemento,
                    latitude: addressLatitude,
                    logintude: addressLogintude,
                });
                break;
            case "UPDATE":
                request = await Api.cliente.updateAddress({
                    id_cliente: user?.id_cliente,
                    id_endereco: addressSelectedAction?.id_endereco,
                    cep: cep,
                    logradouro: logradouro,
                    uf: uf,
                    municipio: municipio,
                    numero: numero,
                    complemento: complemento,
                    latitude: addressLatitude,
                    logintude: addressLogintude,
                });
                break;
        }

        Utils.processRequest(request, (data) => {
            setShow(false);
            requests();
            reset();
        }, () => {}, true);

    }

    const updateAddressPrincipal = async (a) => {
        let request = await Api.cliente.updateAddressPrincipal({
            id_cliente: user?.id_cliente,
            id_endereco: a?.id_endereco,
        })
        Utils.processRequest(request, (data) => {
        }, () => {}, true);
    }

    const onCloseCallback = () => {
        reset();
    }

    return (
        <>
            <Modal setShow={setShow} show={show} onCloseCallback={onCloseCallback}>
                <div className='mb-2'>
                    <b style={{fontSize: '1.2rem'}}>{mode == "UPDATE" ? `Atualizar` : "Adicionar"} endereço</b>
                </div>                         
                <MapMarker locationIcon={"../location_move.png"} onPositionChanged={onPositionChanged}/>
                <SpaceBox space={10}/>
                <Input type={"cep"} label={"CEP"} setValue={setCep} value={cep}/>
                <>
                    <Input type={"text"} label={"Logradouro"} setValue={setLogradouro} value={logradouro}/>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Select hideInputBoxMargin width={"30%"}  setValue={setUf} value={uf}>
                            <Option value={""}>Selecione...</Option>
                            {ufs?.map(uf => (
                                <Option value={uf?.sigla}>{uf?.sigla}</Option>
                            ))}
                        </Select>&nbsp;&nbsp;
                        <Select hideInputBoxMargin width={"70%"} setValue={setMunicipio} value={municipio}>
                            <Option value={""}>Selecione...</Option>
                            {municipios?.map(m => (
                                <Option value={m?.id}>{m?.nome}</Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input type={"code-number"} label={"Numero"} setValue={setNumero} value={numero}/>&nbsp;&nbsp;
                        <Input type={"text"} maxLegth={20} label={"Complemento (opcional)"} setValue={setComplemento} value={complemento}/>
                    </div>
                </>
                <Button onClick={handleAddressSave} style={{width: '100%'}}>
                    <b>{mode == "UPDATE" ? `Atualizar` : "Adicionar"} Endereço</b>
                </Button>
            </Modal>
            <Modal setShow={setShowDelete} show={showDelete}>
                <div className='mb-2'>
                    <b style={{fontSize: '1.2rem'}}>Deseja remover o endereço?</b>
                </div> 
                <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Button style={{color: '#ff2e36', background: '#fceaeb', height: '40px',}} onClick={() => {setShowDelete(false)}} >&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>
                    <Button style={{height: '40px'}} onClick={handleDeleteAddressOk}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <FragmentView title={"Meus Endereços"} onHanderAction={{action: handleAddAddress, actionIconComponent: null,  text: "+ Endereço"}}>
                <div>
                    <div className='mt-1 mb-1'>
                        {addresses?.length > 0 ? (
                            addresses?.map((a, index) => (
                                <div key={index} style={{cursor: 'pointer'}}>
                                    <Card className={"mt-1"} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: a.id_endereco == addressSelected?.id_endereco ? 'solid 1px #000' : 'none'}}>
                                        <div onClick={() => {handleSelectedAddress(a)}} style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'black', background: '#ddd'}}>
                                                <ion-icon name="location-outline"></ion-icon>
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <p style={{marginTop: '0px', fontSize: '10pt'}}>{a?.logradouro}, {a.cep} - {a.uf}</p>
                                            </div>
                                        </div>&nbsp;&nbsp;
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Button onClick={() => {handleUpdateAddress(a)}} className={"shadow"} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '45px', height: '45px', background: '#fff', color: 'black'}}>
                                                <ion-icon size={"large"} name="create-outline"></ion-icon>
                                            </Button>&nbsp;
                                            <Button onClick={() => {handleDeleteAddress(a)}} className={"shadow"} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '45px', height: '45px', background: '#fff', color: 'red'}}>
                                                <ion-icon size={"large"}  name="trash-outline"></ion-icon>
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                            ))
                        ) : (
                            loading == true ? (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                    <b style={{color: 'gray'}}>Nenhum endereço!</b>
                                </div>
                            ) : (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                    <b style={{color: 'gray'}}>Carregando...</b>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <SpaceBox space={80}/>
            </FragmentView>
        </>
    )
}
