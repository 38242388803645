import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Button, Checkbox, Input } from '../../components';
import { useNavigate } from 'react-router-dom';
import Utils from '../../Utils';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';

export default ({loading}) => {

    const {user, setUser, setAuthenticated} = useContext(MainContext);

    const navigate = useNavigate();

    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(function () {
            setShow(true);
        }, 500);
        return () => {
            clearTimeout(timeout);
        }
    }, [])

    const handleForgot = async () => {
        navigate("/adm-forgot");
    }

    const handleRegister = async () => {
        navigate('/adm-register');
    }

    const handleLogin = async () => {

        if(phone?.trim()?.length <= 0){
            Utils.notify("error", "Digite seu celular.");
            return;
        }

        if(!Utils.validatePhoneNumber(phone)){
            Utils.notify("error", "Celular inválido.");
            return;
        }

        if(password?.trim()?.length <= 0){
            Utils.notify("error", "Digite sua senha.");
            return;
        }

        let loginRequest = await Api.colaborador.login({
            device_token: Utils.getDeviceToken(),
            role: "administrador",
            phone: phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("-", ""),
            password: password
        })

        Utils.processRequest(loginRequest, async (data) => {
            let token = await data?.token;
            await localStorage.setItem("tk_beauty_parceiro_token", token);
            let authResponse = await Api.colaborador.get(token);
            if(authResponse?.data?.success){
                Utils.notify("success", "Logado com sucesso!");
                setAuthenticated(true);
                setUser(authResponse?.data?.data);
            }
        }, (error) => {
            if(error){}
        }, false);

    }

    return (
        loading && show ? (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <div style={{width: Utils.mobileCheck() ? '90%' : '50%'}}>
                    <div style={{ background: '#fff', padding: '20px', borderRadius: '5px', width: '100%' }}>
                        <div style={{display: 'flex', alignItems:'center', alignItems: 'center', justifyContent: 'center', marginTop: '-58px'}}>
                            <img src='../logo.png' className='shadow' style={{width: '80px', borderRadius: '8px', border: '3px solid #fff'}}/>
                        </div>
                        <div className='mt-2 mb-2'>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                                <b style={{fontSize: '1.1rem'}}>Painel do paceiro - Login</b>
                            </div>
                        </div>
                        <Input type={"celular"} value={phone} setValue={setPhone} label={"DDD + Celular"} />
                        <Input type={"password"} value={password} setValue={setPassword} label={"Sua senha"} />
                        <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'right', cursor: 'pointer'}} onClick={handleForgot}>
                            Esqueceu sua <b>senha?</b>
                        </div>
                        <Button style={{width: '100%'}} onClick={handleLogin}>
                            <b>Entrar</b>
                        </Button>
                        <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'center', cursor: 'pointer'}} onClick={handleRegister}>
                            Não é parceiro? <b>registre-se</b>
                        </div>
                    </div>
                </div>
            </div>
        ) : (null)
    )
}
