import React, { useEffect, useState } from 'react'
import { Button, Card, Container, FragmentView, HeaderWithBack, Hr, Input, MapMarker, Option, Select, SpaceBox } from '../../components'
import { useParams } from 'react-router-dom'
import Environment from '../../Environment';
import Utils from '../../Utils';
import Api from '../../Api';
import { Helmet } from 'react-helmet';

export default () => {

    /**
     * Componente de confirmação de pagamento.
     * 
     * Parâmetros URL:
     * @param {string} type - Tipo da transação ("produto", "servico").
     * @param {string} phone - Celular do cliente.
     * @param {string} id_transaction - ID da transação (id_agendamento ou id_pedido).
     * @param {string} collect_address - Flag de coleta de endereco (true, false)
     * @param {string} id_ref - (id_servico, id_produto)
     */

    const {type, phone, id_transaction, collect_address, id_ref} = useParams();

    const [idCliente, setIdCliente] = useState(null);

    const [step, setStep] = useState(0);

    const [paymentMethod, setPaymentMethod] = useState(0);

    const [item, setItem] = useState({
        imagem: "placeholder-image.png",
        nome: "....",
        desricao: "...",
        preco: 0
    })

    const [nome, setNome] = useState(null);
    const [email, setEmail] = useState(null);
    const [celular, setCelular] = useState(Utils.formatarParaCelular(phone));
    const [cpf, setCpf] = useState(null);

    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);

    const [cep, setCep] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [uf, setUf] = useState(null);
    const [municipio, setMunicipio] = useState(null);

    const [latitude, setLatitude] = useState(null);
    const [logintude, setLogintude] = useState(null);

    const [solicitacao, setSolicitacao] = useState(null);

    const [agendamento, setAgendamento] = useState(null);
    const [pedido, setPedido] = useState(null);

    const [loading, setLoading] = useState(false);

    const getUserUserInfo = () => JSON.parse(localStorage.getItem("tk-beauty-precadastro"))
    const getUserAddress = () => JSON.parse(localStorage.getItem("tk-beauty-precadastro-endereco"))

    useEffect(() => {
        if(window.document.querySelectorAll('.navigation-container')?.length > 0){
            window.document.querySelectorAll('.navigation-container')[0].style.display = "none";
        }
        setPaymentMethod(0);
    }, [])

    useEffect(() => {
        loadRequests();
    }, [])

    const loadRequests = async () => {
        setLoading(false);

        let responseSolicitacao = await Api.geral.solicitacaoPaymentLink({
            id_payment_link_solicitacao: id_transaction
        })
        let _solicitacao = responseSolicitacao?.data?.data?.json;
        setSolicitacao(_solicitacao);

        switch(type){
            case "produto":
                break;
            case "servico":
                let responseVerifyIfAgendamentoRegistred = await Api.geral.verificarAgendamentoSolicitacao({
                    id_payment_link_solicitacao: id_transaction,
                })
                let _agendamento = responseVerifyIfAgendamentoRegistred?.data?.data;
                setAgendamento(_agendamento);

                let servicoResponse = await Api.geral.servico({
                    id_servico: id_ref
                })
                let _servico = servicoResponse?.data?.data;
                setItem({
                    imagem: _servico?.logo_parceiro,
                    nome: _servico?.nome_parceiro,
                    desricao: _servico?.nome_servico,
                    preco: Number(_solicitacao?.total)
                });
                break;
        }

        setLoading(true);
    }

    useEffect(() => {
        const load = async () => {
            let userInfo = await getUserUserInfo();
            if(userInfo != null){
                setNome(userInfo?.nome);
                setEmail(userInfo?.email);
                setCpf(userInfo?.cpf);
                setCelular(userInfo?.celular);
            }
            if(collect_address == "true"){
                let userAddress = await getUserAddress();
                if(userAddress != null){
                    setCep(userAddress?.cep);
                    setUf(userAddress?.uf);
                    setNumero(userAddress?.numero);
                    setComplemento(userAddress?.complemento);
                    await requestMunicipios(userAddress?.id);
                    setMunicipio(userAddress?.municipio);
                }
            }
        }
        load();
    }, [])

    useEffect(() => {
        const request = async () => {
            let ufListResponse = await Api.geral.ufs();
            let _ufs = ufListResponse?.data?.data;
            setUfs(_ufs);
        }
        request();
    }, [])

    useEffect(() => {
        const request = async () =>{
            if(cep?.length >= 9){
                let viaCepResponse = await Api.geral.viaCep({cep});
                if(viaCepResponse){
                    setLogradouro(viaCepResponse?.bairro +", "+ viaCepResponse?.logradouro)
                    setUf(viaCepResponse?.uf);
                }else{
                    setLogradouro("");
                }
            }
        }
        request();
    }, [cep, setCep])

    useEffect(() => {
        if(uf){
            requestMunicipios();
        }
    }, [setUf, uf])

    const requestMunicipios = async (forceId) =>{
        let municipioResponse = null;
        if(forceId){
            municipioResponse = await Api.geral.municipio({
                ufid: forceId
            });
        }else{
            municipioResponse = await Api.geral.municipio({
                ufid: ufs.filter(_uf => {return _uf.sigla == uf})[0]?.id
            });
        }
        let _municipios = municipioResponse?.data?.data;
        setMunicipios(_municipios);
        
        detectMunicipiohandler(_municipios);
    }

    const detectMunicipiohandler = (_municipios) => {
        let municipioFiltered = _municipios?.filter(municipio => {
            const regex = new RegExp(`\\b${municipio.nome.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'i');
            return regex.test(logradouro);
        })[0];
        if(municipioFiltered == undefined){
            setMunicipio(null);
        }else{
            setMunicipio(municipioFiltered?.id);
        }
    }

    const handlePaymentMethod = (paymentMethod) => {
        setPaymentMethod(paymentMethod);
    }

    const handleNext = () => {

        switch(step){
            case 0:
                if(!nome || nome?.trim()?.length <= 0){
                    Utils.notify("error", "Digite seu nome.");
                    return;
                }
                if(!email || email?.trim()?.length <= 0){
                    Utils.notify("error", "Digite seu email.");
                    return;
                }
                if(!Utils.validateEmail(email)){
                    Utils.notify("error", "Email inválido.");
                    return;
                }
                if(!celular || celular?.trim()?.length <= 0){
                    Utils.notify("error", "Digite seu celular.");
                    return;
                }
                if(!Utils.validatePhoneNumber(celular)){
                    Utils.notify("error", "Celular inválido.");
                    return;
                }
                if(!cpf || cpf?.trim()?.length <= 0){
                    Utils.notify("error", "Digite seu CPF.");
                    return;
                }
                if(!Utils.validateCpf(cpf)){
                    Utils.notify("error", "CPF inválido.");
                    return;
                }
                break;
            case 1:
                if(!cep || cep?.trim()?.length <= 0){
                    Utils.notify("error", "Digite o CEP.");
                    return;
                }
                if(!Utils.validateCep(cep)){
                    Utils.notify("error", "CEP inválido.");
                    return;
                }
                if(!logradouro || logradouro?.trim()?.length <= 0){
                    Utils.notify("error", "Digite o logradouro.");
                    return;
                }
                if(uf == null|| uf?.trim()?.length <= 0){
                    Utils.notify("error", "Selecione a UF.");
                    return;
                }
                if(!municipio){
                    Utils.notify("error", "Selecione o município.");
                    return;
                }
                if(!numero || numero?.trim()?.length <= 0){
                    Utils.notify("error", "Selecione o número.");
                    return;
                }
                break;
        }

        let newStep;

        if(collect_address == "true"){
            newStep = step + 1;
        }else{
            newStep = step + 2;
        }

        setStep(newStep);

        if(newStep == 2 && collect_address == "true"){
            procadastroHandler(0);
        }else if(newStep == 2 && collect_address == "false"){
            procadastroHandler(1);
        }

    }

    const onPositionChanged = ({latitude, longitude}) => {
        setLatitude(latitude);
        setLogintude(longitude);
    }

    const saveUserInfoBasics = () => {
        localStorage.setItem("tk-beauty-precadastro", JSON.stringify({
            nome: nome,
            email: email,
            cpf: cpf,
            celular: celular,
        }))
    }

    const saveUserAndress = () => {
        localStorage.setItem("tk-beauty-precadastro-endereco", JSON.stringify({
            cep: cep,
            logradouro: logradouro,
            uf: uf,
            municipio: municipio,
            numero: numero,
            complemento: complemento,
            id: ufs.filter(_uf => {return _uf.sigla == uf})[0]?.id
        }))
    }

    const getPaymentMethod = () => {
        switch (paymentMethod) {
            case 0:
                return "PIX";
            case 1:
                return "CARD";
        }
    }

    const procadastroHandler = async (_type) => {

        saveUserInfoBasics();

        let data = {
            device_token: localStorage.getItem("tk_beauty_device_token"),
            nome: nome,
            email: email,
            cpf: cpf.replaceAll(".","").replaceAll("-", ""),
            phone: celular.replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("-", ""),
            password: Utils.makeid(6),
            precadastro: 'S',
        }

        let id_cliente = null;

        let responseUserExists = await Api.cliente.verifyUserExists({
            cpf: cpf.replaceAll(".","").replaceAll("-", ""),
            phone: celular.replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("-", "")
        })

        id_cliente = responseUserExists?.data?.data;

        if(id_cliente == undefined){ //criar usuário caso não existir
            let userCreateResponse = await Api.cliente.register({
                device_token: data?.device_token, 
                nome: data?.nome, 
                email: data?.email,
                cpf: data?.cpf,
                phone: data?.phone, 
                password: data?.password, 
                precadastro: data?.precadastro
            })
            id_cliente = userCreateResponse?.data?.data?.id_cliente;
        }

        setIdCliente(id_cliente);

        switch(_type){
            case 0://com endereço
                saveUserAndress();
                await Api.cliente.addAddress({
                    id_cliente: id_cliente,
                    cep: cep,
                    logradouro: logradouro,
                    uf: uf,
                    municipio: municipio,
                    numero: numero,
                    complemento: complemento,
                    latitude: latitude,
                    logintude: logintude,
                });
                break;
            case 1://sem endereço
                break;
        }

        switch(type){
            case "servico": //insert na tabela de agendamento

                if(agendamento == null){

                    const data = {
                        id_payment_link_solicitacao: id_transaction,
                        id_cliente: id_cliente,
                        id_parceiro: solicitacao?.id_parceiro,
                        id_servico: solicitacao?.id_servico,
                        id_colaborador: solicitacao?.id_colaborador,
                        id_cupom: solicitacao?.id_cupom,
                        tipo_agendamento: collect_address == "true" ? 'M' : 'P',
                        subtotal: Number(solicitacao?.subtotal),
                        desconto: Number(solicitacao?.desconto),
                        total: Number(solicitacao?.total),
                        metodo_pagamento: getPaymentMethod(),
                        local_pagamento: solicitacao?.local_pagamento,
                        status_pagamento: solicitacao?.status_agendamento,
                        data_agendamento: solicitacao?.data_agendamento,
                        hora_agendamento: solicitacao?.hora_agendamento,
                        status_agendamento: solicitacao.status_agendamento,
                        codigo_agendamento: Utils.makeid(6).toUpperCase(),
                        codigo_confirmado: 0,
                    };
            
                    let agendamentoResponse = await Api.geral.agendarHorarioPaymentLink({
                        data: data
                    })

                    setAgendamento(agendamentoResponse?.data?.data);

                    loadRequests();
                }

                break;
            case "produto":
                break;
        }

    }

    return (
        <Container>
            <Helmet>
                <title>IGend pagamentos</title>
                <meta name="description" content={"IGend pagamentos"} />
                <meta property="og:title" content={"IGend pagamentos"} />
                <meta property="og:description" content={"IGend pagamentos"} />
                <meta property="og:image" content={"../../../../../logo.png"} />
                <meta property="description" content={"IGend pagamentos"} />

            </Helmet>
            <div className='mt-1 mb-2' style={{display: 'flex', alignItems: 'center'}}>
                <img className='shadow' src="../../../../../logo.png" style={{borderRadius: '8px', border: 'solid 3px #fff', width: '50px'}}/>&nbsp;
                <h3 style={{color: "black"}}>Confirmação de pagamento</h3> 
            </div>
            {step == 0 ? (
                <div style={{width: '100%'}}>
                    <div>
                        <Card>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="cube-outline"></ion-icon>&nbsp;
                                <b>Dados {type == "produto" ? (`da compra`) : (`do serviço`)}</b>
                            </div>
                            <div style={{background: "#f5f5f5", display: 'flex', alignItems: 'start', borderRadius: '4px', border: 'solid 1px rgba(0, 0, 0, 0.08)', padding: '8px'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img loading="lazy" src={Environment.API_IMAGE + "/imagem/" + item?.imagem || "../../placeholder-image.png"} style={{width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px'}}/>
                                </div>
                                <div style={{marginLeft: '8px', width: '100%'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <b style={{fontSize: '12pt'}}>{item?.nome}</b>&nbsp;
                                    </div>
                                    <p style={{fontSize: '10pt', color: '#2c2c2c'}}>{}</p>
                                    <div style={{display: 'flex', marginTop: '5px', marginBottom: '5px', alignItems: 'center'}}>
                                        {item?.desricao}
                                    </div>
                                    {solicitacao ? (
                                        type == "servico" ? (
                                            <>
                                                <div style={{fontSize: '10pt', display: 'flex', marginTop: '5px', marginBottom: '5px', alignItems: 'center', justifyContent: 'space-between'}}>
                                                    <b style={{color: 'black'}}>Data serviço: </b>
                                                    <span style={{color: ''}}>{solicitacao?.data_agendamento} ás {solicitacao?.hora_agendamento}</span>
                                                </div>
                                            </>
                                        ) : (null)
                                    ) : (null)}
                                    <Hr color={"#ddd"} elevation={1}/>
                                    <div style={{display: 'flex', marginTop: '5px', marginBottom: '5px', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <b style={{color: 'black'}}>Preço</b>
                                        <b style={{color: 'green'}}>{Number(solicitacao?.total || 0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card className='mb-1 mt-1'>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="person-outline"></ion-icon>&nbsp;
                                <b>Dados básicos</b>
                            </div>
                            <Input setValue={setNome} value={nome} type={"text"} maxLegth={50} label={"Seu Nome"}/>
                            <Input setValue={setEmail} value={email} type={"email"} label={"Seu email"}/>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <Input setValue={setCelular} style={{width: '100%'}} value={celular} hideInputBoxMargin type={"celular"} label={"Seu celular"}/>&nbsp;&nbsp;
                                <Input setValue={setCpf} style={{width: '100%'}} value={cpf} hideInputBoxMargin type={"cpf"} label={"Seu CPF"}/>
                            </div>
                        </Card>
                    </div>
                </div>
            ) : (null)}
            {collect_address == "true" && step == 1 ? (
                <div>
                    <Card className='mb-1 mt-1'>
                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                            <ion-icon name="location-outline"></ion-icon>&nbsp;
                            <b>
                                Localização {type == "produto" ? (`da entrega`) : (`do serviço`)}
                            </b>
                        </div>                    
                        <MapMarker locationIcon={"../../../../../location_move.png"} onPositionChanged={onPositionChanged}/>
                        <SpaceBox space={10}/>
                        <Input type={"cep"} label={"CEP"} setValue={setCep} value={cep}/>
                        <>
                            <Input type={"text"} label={"Logradouro"} setValue={setLogradouro} value={logradouro}/>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Select hideInputBoxMargin width={"30%"}  setValue={setUf} value={uf}>
                                    <Option value={""}>Selecione...</Option>
                                    {ufs?.map(uf => (
                                        <Option value={uf?.sigla}>{uf?.sigla}</Option>
                                    ))}
                                </Select>&nbsp;&nbsp;
                                <Select hideInputBoxMargin width={"70%"} setValue={setMunicipio} value={municipio}>
                                    <Option value={""}>Selecione...</Option>
                                    {municipios?.map(m => (
                                        <Option value={m?.id}>{m?.nome}</Option>
                                    ))}
                                </Select>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input type={"code-number"} style={{width: '100%'}} label={"Numero"} setValue={setNumero} value={numero}/>&nbsp;&nbsp;
                                <Input type={"text"} style={{width: '100%'}} maxLegth={20} label={"Complemento (opcional)"} setValue={setComplemento} value={complemento}/>
                            </div>
                        </>
                    </Card>
                </div>
            ) : (null)}
            {step == 2 ? (
                <>
                    <div className='mb-1'>
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '2px'}}>
                            <div className='period-button' style={{border: paymentMethod == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentMethod == 0 ? 'black' : 'black', background: 'white'}} onClick={() => {handlePaymentMethod(0)}}>
                                <img src={`../../../../../pix_icon.png`} style={{width: '15px'}}/><br/>
                                <b>Pix</b>
                            </div>
                            <div className='period-button' style={{border: paymentMethod == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentMethod == 1 ? 'black' : 'black', background: 'white'}} onClick={() => {handlePaymentMethod(1)}}>
                                <ion-icon name="card-outline"></ion-icon><br/>
                                <b>Cartão</b>
                            </div>
                        </div>
                    </div>
                    {idCliente ? (
                        type == "servico" && agendamento != null ? (
                            agendamento?.status_agendamento == 'A' ? (
                                loading ? (
                                    <iframe src={`https://payment.igend.com.br?type=servico&payment_type=${paymentMethod == 0 ? 'pix' : 'card'}&id_cliente=${idCliente}&id_agendamento=${agendamento?.id_agendamento}&card=true&force_body_block=true`} style={{border: 'none', width: '100%', height: paymentMethod == 0 ? 'calc(100vh - 303px)' : 'calc(100vh - 303px)'}}></iframe>
                                ) : (
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <b>Carregando...</b>
                                    </div>
                                )
                            ) : (agendamento?.status_agendamento == 'C' || agendamento?.status_agendamento == 'P') ? (
                                <>
                                    <img src='../../../../../404.png' style={{width: '100%'}}/>
                                    <div style={{display: 'flex', justifyContent: 'center'}}><b>Link de pagamento expirado!</b></div>
                                    <SpaceBox space={20}/>
                                </>
                            ): (null)
                        ) : type == "produto" && pedido != null ? (
                            pedido?.status_pedido == 'A' ? (
                                loading ? (
                                    <iframe src={`https://payment.igend.com.br?type=produto&payment_type=${paymentMethod == 0 ? 'pix' : 'card'}&id_cliente=${idCliente}&id_pedido=${pedido?.id_pedido}&card=true&force_body_block=true`} style={{border: 'none', width: '100%', height: paymentMethod == 0 ? 'calc(100vh - 303px)' : 'calc(100vh - 303px)'}}></iframe>
                                ):(
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <b>Carregando...</b>
                                    </div>
                                )
                            ):(
                                <>
                                    <img src='../../../../../404.png' style={{width: '100%'}}/>
                                    <div style={{display: 'flex', justifyContent: 'center'}}><b>Link de pagamento expirado!</b></div>
                                    <SpaceBox space={20}/>
                                </>
                            )
                        ) : (null)
                    ) : (null)}
                </>
            ) : (null)}
            {step < 2 ? (
                <>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button onClick={handleNext} style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                            <b>Próximo</b>&nbsp;
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </Button>
                    </div>
                </>
            ) : (null)}
            <SpaceBox space={10}/>
            <Hr color={"#ddd"} elevation={1}/>
            <div className="payment-methods-holder" style={{textAlign: 'center'}}>
                <div className="mb-1">
                    <div style={{fontSize: '10pt', marginTop: '5px'}}>
                        &copy; <b>IGend</b> pagamentos | Todos os direitos reservados
                    </div>
                </div>
                <div className="-payment-list">
                    <img alt="billet" width="39" height="26" src="../../../../../bandeiras/card-billet.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="amex" width="39" height="26" src="../../../../../bandeiras/card-amex.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="visa" width="39" height="26" src="../../../../../bandeiras/card-visa.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="diners" width="39" height="26" src="../../../../../bandeiras/card-diners.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="mastercard" width="39" height="26" src="../../../../../bandeiras/card-mastercard.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="discover" width="39" height="26" src="../../../../../bandeiras/card-discover.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="aura" width="39" height="26" src="../../../../../bandeiras/card-aura.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="hipercard" width="39" height="26" src="../../../../../bandeiras/card-hipercard.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="elo" width="39" height="26" src="../../../../../bandeiras/card-elo.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="hiper" width="39" height="26" src="../../../../../bandeiras/card-hiper.svg"/>
                    <span className="line-break hide"></span>
                                        <img alt="pix" width="39" height="26" src="../../../../../bandeiras/card-pix.svg"/>
                    <span className="line-break hide"></span>
                </div>
            </div>
        </Container>
    )
}
