import React, { useState } from 'react';
import './style.css';

const SwipperRefresh = ({ children, onRefreshHandler, heightForce }) => {

  const cancel_swipper_pages = ["adm-register"];

  const [indicatorVisible, setIndicatorVisible] = useState(false);
  const [scrollAtTop, setScrollAtTop] = useState(true);
  const [touchStartY, setTouchStartY] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [indicatorPosition, setIndicatorPosition] = useState(-60);

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const deltaY = e.touches[0].clientY - touchStartY;
    const deltaX = e.touches[0].clientX - touchStartX;

    // Verifique se o movimento é principalmente na direção vertical
    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      // Verifique se o scrollTop do elemento de rolagem é igual a 0
      const isScrollAtTop = e.currentTarget.scrollTop === 0;
  
      if (isScrollAtTop && deltaY > 150) {
        setScrollAtTop(true);
        setIndicatorVisible(true);
        setIndicatorPosition(Math.min(deltaY - 60, 10));
      } else {
        setScrollAtTop(false);
        setIndicatorVisible(false);
      }
    }
  };

  const handleTouchEnd = () => {
    if (indicatorVisible && scrollAtTop && window.scrollY === 0 && touchStartY <= 500) {
        if(onRefreshHandler){
            //onRefreshHandler();
        }
        window.location.reload();
    } else {
      setIndicatorVisible(false);
      setIndicatorPosition(-60);
    }
    setTouchStartY(0); // Reset touchStartY
  };

  function pageContentNotAnimator () {
    const regex = /parceiro/i;
    return regex.test(window.location.pathname);
  }

  return (
    window.location.pathname.includes(cancel_swipper_pages) ? (children) : (
    <div
      className="swipper-refresh-igend"
      style={{height: heightForce ? heightForce : undefined}}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Content provided as children */}
      <div
        className="content"
        style={pageContentNotAnimator() ? {} : {
          transform: scrollAtTop  && window.scrollY === 0 ? (indicatorVisible ? 'translateY(50px)' : 'none') : 'none',
          transition: 'transform 0.2s ease',
        }}
      >
        {children}
      </div>
      {/* Indicator */}
      {scrollAtTop && (
        <div
          className="indicator-igend shadow"
          style={{ transform: `translate(-50%, ${indicatorPosition}px)`, transition: 'transform 0.1s ease' }}
        >
          <ion-icon
            name="refresh"
            style={{
              fontSize: '24px',
              color: 'black',
              transform: indicatorVisible ? 'rotate(240deg)' : 'none',
              transition: 'transform 0.2s ease',
              marginRight: '2px'
            }}
          ></ion-icon>
        </div>
      )}
    </div>
  ))
};

export default SwipperRefresh;