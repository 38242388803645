import React, { useState, useEffect } from 'react';
import './style.css';
import { Button, Modal, SpaceBox } from '../';
import Api from '../../Api';
import Utils from '../../Utils';
import Environment from '../../Environment';

export default () => {
    const [show, setShow] = useState(false);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [star, setStar] = useState(0);
    const [comentario, setComentario] = useState("");
    const [avaliacoesParceiro, setAvaliacoesParceiro] = useState([]);
    const [nota, setNota] = useState(0); 

    useEffect(() => {
        fetchAvaliacoes();
    }, []);

    const fetchAvaliacoes = async () => {
        const response = await Api.cliente.avaliacoesDisponiveis({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
        });
        
        const _avaliacoes = response?.data?.data;
        
        if(_avaliacoes?.length > 0){
            setShow(true);
            getAvaliacoesParceiro(_avaliacoes[0]?.id_parceiro);

            setAvaliacoes(_avaliacoes);
        }

    };

    const getAvaliacoesParceiro = async (id_parceiro) => {
        const response = await Api.geral.avaliacaoParceiro({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: id_parceiro
        })
        let _avaliacoesParceiro = response?.data?.data;
        setAvaliacoesParceiro(_avaliacoesParceiro);
        const _nota = Utils.calcularAvaliacao(_avaliacoesParceiro);
        setNota(_nota);
    }

    const onCloseCallback = async () => {
        let request = await Api.cliente.avaliar({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_avaliacao: avaliacoes[0]?.id_avaliacao,
            estrelas: 0,
            comentario: "", 
            ignorado: 1,
            mostrado: 1
        })
        Utils.processRequest(request, () => {
            setShow(false); 
            fetchAvaliacoes();
        }, () => {});
    };

    const handleStar = (value) => {
        setStar(value);
    };

    const handleAvaliar = async () => {
        let request = await Api.cliente.avaliar({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_avaliacao: avaliacoes[0]?.id_avaliacao,
            estrelas: star,
            comentario: comentario, 
            ignorado: star == 0 ? 1 : 0,
            mostrado: 1
        })
        Utils.processRequest(request, () => {
            setShow(false); 
            setStar(0);
            setComentario("");
            fetchAvaliacoes();
        }, () => {});
    };

    return (
        <Modal show={show} setShow={setShow} onCloseCallback={onCloseCallback}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <b style={{ fontSize: '1.2rem' }}>Gostou do serviço? Avalie-nos!</b>
                </div>
            </div>
            <SpaceBox space={20}/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <img className='shadow' src={Environment.API_IMAGE + "/imagem/" + avaliacoes[0]?.logo_parceiro || "placeholder-image.png"} style={{width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%', border: 'solid 3px #fff'}}/>
            </div>
            <SpaceBox space={8}/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                <b style={{display: 'flex', alignItems: 'center',}}>{avaliacoes[0]?.nome_parceiro}</b>&nbsp;
                {nota != "0,0" ? (
                    <b style={{display: 'flex', alignItems: 'center', color: 'gray'}}><ion-icon name="star" style={{color: 'orange'}}></ion-icon>{nota}</b>
                ) : (null)}
            </div>
            <SpaceBox space={10}/>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '36pt' }}>
                {[1, 2, 3, 4, 5].map((index) => (
                    <ion-icon
                        key={index}
                        name={`${star >= index ? 'star' : 'star-outline'}`}
                        style={{color: star >= index ? 'orange' : 'gray'}}
                        onClick={() => handleStar(index)}
                    ></ion-icon>
                ))}
            </div>
            <SpaceBox space={20}/>
            {star > 0 ? (
                <textarea value={comentario} onChange={(e) => {setComentario(e.target.value)}} style={{fontWeight: 'bold', color: 'black', width: '100%', resize: 'no-resize', fontSize: '11pt', padding: '10px', borderRadius: '8px', background: '#f5f5f5', border: 'solid 1px gray'}} rows={3} placeholder='Deseja comentar algo? (opcional)'></textarea>
            ) : (null)}
            <SpaceBox space={10}/>
            <Button onClick={handleAvaliar} style={{ width: '100%' }}><b>Avaliar</b></Button>
        </Modal>
    );
};