import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { AccountModal, Button, Card, Container, Input, MapMarker, Modal, Option, Select, SpaceBox } from '../../components';
import AgendaProviderHelper from '../../helpers/AgendaProviderHelper';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import Utils from '../../Utils';
import { useNavigate } from 'react-router-dom';
import Environment from '../../Environment';

const $ = require( "jquery" );

const startEvents = (navigate) => {
    
    /*document.querySelector(".btn-modal-agenda").addEventListener("click", () => {
        document.querySelector(".bottom-sheet-wrapper").classList.add("show-modal");
    });*/
      
    document.querySelectorAll(".close, .backdrop").forEach(element => {
        element.addEventListener("click", () => {
            document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
            window.location.hash = '';
            navigate(-1);
        });
    });
}

const startSwipperLogic = () => {

    let elementsArray = [
        document.querySelector('.items-days'),
        document.querySelector('.items-hours'),
    ];

    let isDown = false;
    let startX;
    let scrollLeft;

    const end = (s) => {
        isDown = false;
        s.classList.remove('active');
    }

    const start = (e, s) => {
        isDown = true;
        s.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - s.offsetLeft;
        scrollLeft = s.scrollLeft;	
    }
    
    const move = (e, s) => {
        if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - s.offsetLeft;
        const dist = (x - startX);
        s.scrollLeft = scrollLeft - dist;
    }

    elementsArray.forEach(function(slider) {

        if(slider){
            slider.addEventListener('mousedown', function (e) { start(e, slider) });
            slider.addEventListener('touchstart', function (e) { start(e, slider) });
            slider.addEventListener('mousemove', function (e) { move(e, slider) });
            slider.addEventListener('touchmove', function (e) { move(e, slider) });
            slider.addEventListener('mouseleave', function () {end(slider) });
            slider.addEventListener('mouseup', function () { end(slider) });
            slider.addEventListener('touchend', function () { end(slider) });
        }

    });

}
 
export default ({parceiro, expediente, servico, assinante, comeToMe, handleResetComponent, bonus}) => {

    const navigate = useNavigate();

    const {user} = useContext(MainContext);

    const [modeSelected, setModeSelected] = useState(0);
    const [daySelected, setDaySelected] = useState(null);
    const [hourSelected, setHourSelected] = useState(null);
    const [paymentTypeSelected, setPaymentTypeSelected] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorOpenSelect, setColaboradorOpenSelect] = useState(false);
    const [colaboradorSelected, setColaboradorSelected] = useState(null);
    const [agendamentosByColaborador, setAgendamentosByColaborador] = useState([]);

    const [modalResume, setModalResume] = useState(false);
    const [accountModalShow, setAccountModalShow] = useState(false);

    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);

    const [show, setShow] = useState(false);

    const [addressSelectOpen, setAddressSelectOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [addressSelected, setAddressSelected] = useState(null);
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [addressLatitude, setAddressLatitude] = useState(null);
    const [addressLogintude, setAddressLogintude] = useState(null);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [cep, setCep] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [uf, setUf] = useState(null);
    const [municipio, setMunicipio] = useState(null);

    useEffect(() => {
        startSwipperLogic();
    }, [daySelected, colaboradorSelected])

    useEffect(() => {
        startSwipperLogic();
        startEvents(navigate);
        handleAgendar();
        setTimeout(function () {
            document.querySelector(".bottom-sheet-wrapper").classList.add("show-modal")
            setShow(true);
        }, 100)
    }, [])

    useEffect(() => {
        loadAddressByClient();
    }, [user])

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash !== '#modal') {
                setShow(false);
                window.removeEventListener('hashchange', handleHashChange);
                document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
                handleResetComponent();
            }
        };

        if (show) {
            window.location.hash = 'modal';
            window.addEventListener('hashchange', handleHashChange);
            document.body.style.overflow = 'hidden';  // Trava o scroll
        }else {
            document.body.style.overflow = 'auto';    // Ativa o scroll novamente
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
            document.body.style.overflow = 'auto';    // Ativa o scroll quando o componente desmonta
        };
    }, [show, setShow]);

    useEffect(() => {
        const setup = async () => {
            let colaboradoresResponse = await Api.geral.colaboradoresByParceiro({
                id_parceiro: parceiro?.id_parceiro
            })

            let _colaboradores = colaboradoresResponse?.data?.data;

            setColaboradores(_colaboradores);

            if(user){

                let ufListResponse = await Api.geral.ufs();
                let _ufs = ufListResponse?.data?.data;
                setUfs(_ufs);

                loadAddressByClient();
            }
        }
        setup();
    }, [])

    useEffect(() => {
        const request = async () =>{
            if(cep?.length >= 9){
                let viaCepResponse = await Api.geral.viaCep({cep});
                if(viaCepResponse){
                    setLogradouro(viaCepResponse?.bairro +", "+ viaCepResponse?.logradouro)
                    setUf(viaCepResponse?.uf);
                }else{
                    setLogradouro("");
                }
            }
        }
        request();
    }, [cep, setCep])

    useEffect(() => {
        if(uf){
            requestMunicipios();
        }
    }, [setUf, uf])

    const requestMunicipios = async () =>{
        let municipioResponse = await Api.geral.municipio({
            ufid: ufs.filter(_uf => {return _uf.sigla == uf})[0]?.id
        });
        let _municipios = municipioResponse?.data?.data;
        setMunicipios(_municipios);
    }

    const loadAddressByClient = async (callback) => {
        let addressListResponse = await Api.cliente.getAddresses({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"), 
        })    
        let _addressList = addressListResponse?.data?.data;
        setAddressList(_addressList);
        if(callback){
            callback(_addressList);
        }
    }

    const handleAgendar = async () => {
        setDaySelected(null);
        setHourSelected(null);
    }

    const loadHours = (day) => {
        let _dia = (day?.dia < 10) ? "0"+day?.dia : day?.dia;
        let _mes = (day?.mes < 10) ? "0"+day?.mes : day?.mes;
        let _ano = day?.ano;
        let _date = _ano+"-"+_mes+"-"+_dia;

        let {abertura, fechamento} = Utils.checkWeekDayAndReturnExpediente(day, expediente);
        
        let _hours = AgendaProviderHelper.getHoursPeriods(abertura, fechamento, _date);

        setHours(_hours);

        return _hours;
    }

    const handleModeClick = (mode) => {
        if(mode == 1 && user == null){
            setAccountModalShow(true);
            return;
        }
        setModeSelected(mode);
        setAddressSelectOpen(false);
        if(mode == 1 && user){
            setAddressSelected(addressList.filter(a => {return a.principal == 'S'})[0] || null);
        }
    }

    const converterParaMinutos = (hora) => {
        const [h, m] = hora.split(":").map(Number);
        return h * 60 + m;
    }
    
    const converterDuracaoParaMinutos = (duracao) => {
        const [h, m] = duracao.match(/\d+/g).map(Number);
        return h * 60 + m;
    }    
    
    function verificarHorario(hours, agendamentos) {
    
        agendamentos.forEach(agendamento => {
            const inicioAgendamento = converterParaMinutos(agendamento.hora);
            const duracaoAgendamento = converterDuracaoParaMinutos(agendamento.tempo_servico);
            const fimAgendamento = inicioAgendamento + duracaoAgendamento;

            //gambiarra preparada para calcular até 8 horas
            let intervalo = (duracaoAgendamento > 60 && duracaoAgendamento < 180) ? 60 : ((duracaoAgendamento >= 180 && duracaoAgendamento < 240) ? 90 : (duracaoAgendamento >= 240 && duracaoAgendamento < 300) ? 120 : (duracaoAgendamento >= 300 && duracaoAgendamento < 360) ? 160 : (duracaoAgendamento >= 360 && duracaoAgendamento < 420) ? 180 : (duracaoAgendamento >= 420 && duracaoAgendamento < 480) ? 210 : (duracaoAgendamento >= 480) ? 240 : 30);
            const intervaloAgendamento = Math.ceil(duracaoAgendamento / intervalo);

            hours.forEach((horaDisponivel, index) => {
                const horaAtual = converterParaMinutos(horaDisponivel.hora);
                const proximaHora = index < hours.length - 1 ? converterParaMinutos(hours[index + 1].hora) : horaAtual + 30;
    
                if (horaAtual >= inicioAgendamento && proximaHora <= fimAgendamento) {
                    for (let i = index; i < index + intervaloAgendamento && i < hours.length; i++) {
                        hours[i].livre = false;
                    }
                }
            });
        });
    
    }

    const handleDayClick = (day) => {
        let _hours = loadHours(day);

        let datasReservadas = [];

        for(let i = 0; i< agendamentosByColaborador?.length; i++){
            let _id_agendamento = agendamentosByColaborador[i]?.id_agendamento;
            let _id_servico = agendamentosByColaborador[i]?.id_servico;
            let _d = agendamentosByColaborador[i]?.data_agendamento;
            let _h = agendamentosByColaborador[i]?.hora_agendamento;
            let _tempo_servico = agendamentosByColaborador[i]?.tempo_servico;
            datasReservadas.push({
                id_agendamento: _id_agendamento,
                id_servico: _id_servico,
                tempo_servico: _tempo_servico,
                data: _d?.split('/')[2]+"-"+_d?.split('/')[1]+"-"+_d?.split('/')[0],
                hora: _h,
            });
        }

        let _horariosReservadosForDisable = [];

        _hours.forEach(item => {
            datasReservadas.forEach(dr => {
                if (item.hora === dr.hora && item.dataRef === dr.data) {
                    item.livre = false;
                    _horariosReservadosForDisable.push({
                        id_agendamento: dr.id_agendamento,
                        id_servico: dr.id_servico,
                        tempo_servico: dr.tempo_servico,
                        hora: item?.hora,
                        livre: false,
                    });
                }
            });
        });

        verificarHorario(_hours, _horariosReservadosForDisable);

        setHours(_hours);
        setDaySelected(day);
        setHourSelected(null);
    }

    function verificarDisponibilidadeFutura(hours, horaSelecionada, tempoServico) {
        const duracaoServicoMinutos = converterDuracaoParaMinutos(tempoServico);
        const horaSelecionadaMinutos = converterParaMinutos(horaSelecionada);
        const horaFinalAgendamento = horaSelecionadaMinutos + duracaoServicoMinutos;
    
        // Verifica se a hora final do agendamento ultrapassa o tamanho de hours
        if (horaFinalAgendamento > converterParaMinutos(hours[hours.length - 1].hora)) {
            return false;
        }
    
        let dentroDoIntervalo = false;
        let depoisDoIntervalo = false;
        
        for (let i = 0; i < hours.length; i++) {
            const horaAtualMinutos = converterParaMinutos(hours[i].hora);
    
            if (horaAtualMinutos === horaSelecionadaMinutos) {
                dentroDoIntervalo = true;
            }
    
            if (dentroDoIntervalo) {
                if (horaAtualMinutos >= horaSelecionadaMinutos && horaAtualMinutos < horaFinalAgendamento) {
                    if (!hours[i].livre) {
                        return false;
                    }
                } else {
                    dentroDoIntervalo = false;
                    depoisDoIntervalo = true;
                }
            }
    
            if (depoisDoIntervalo && horaAtualMinutos >= horaFinalAgendamento) {
                break;
            }
    
            if (depoisDoIntervalo && !hours[i].livre) {
                return false;
            }
        }
    
        return true;
    }

    const handleHourSelected = (hour) => {
        let valid = verificarDisponibilidadeFutura(hours, hour.hora, servico?.tempo_servico);
        if(valid){
            setHourSelected(hour);
        }else{
            Utils.notify("error", `Não há horários disponíveis para este serviço, que tem a duração de ${Utils.converterHoraPorExtenso(servico?.tempo_servico)}. Por favor, escolha outro dia ou horário.`)
        }
    }

    const handlePaymentTypeSelected = (paymentType) => {
        setPaymentTypeSelected(paymentType);
    }

    const handlePaymentMethod = (paymentMethod) => {
        setPaymentMethod(paymentMethod);
    }

    const resetComponent = () => {
        document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
        setShow(false);
        setTimeout(function () {
            handleResetComponent();
        }, 150);
    }

    const handleOpenSelectColaborador = async () => {
        setColaboradorOpenSelect(!colaboradorOpenSelect);
    }

    const handleSelectedColaborador = async (colaborador) => {
        setColaboradorSelected(colaborador);

        let agendamentosByColaboradorResponse = await Api.geral.agendamentosParceiroByColaborador({
            id_colaborador: colaborador?.id_colaborador,
            id_parceiro: parceiro?.id_parceiro
        })

        let _agendamentos = agendamentosByColaboradorResponse?.data?.data;
        let _days = AgendaProviderHelper.getDaysPeriods();

        setDays(_days);
        handleAgendar();
        setAgendamentosByColaborador(_agendamentos);
        setColaboradorOpenSelect(false);
    }

    const getDateSelectedFormated = () => {
        let dia = (Number(daySelected?.dia) < 10 ? '0'+daySelected?.dia : daySelected?.dia);
        let mes = (Number(daySelected?.mes) < 10 ? '0'+daySelected?.mes : daySelected?.mes);
        let ano = daySelected?.ano;
        return dia+'/'+mes+'/'+ano;
    }
    
    const getPaymentMethod = () => {
        if(assinante){
            return "SIGNATURE";
        }
        switch(paymentMethod){
            case 0:
                return "PIX";
            case 1:
                return "CARD";
            case 2: 
                return "MONEY";
            case 3:
                return "BONUS";
        }
    }

    const handleAgendamento = async () => {
        if(user){

            if(modeSelected != 1 && modeSelected != 0){
                Utils.notify("error", "Selecione o modo de agendamento.");
                return;
            }

            if(modeSelected == 1 && addressSelected == null){
                Utils.notify("error", "Selecione o endereço do agendamento.");
                return;
            }

            if(colaboradorSelected == null) {
                Utils.notify("error", "Selecione o profissional.");
                return;
            }

            if(daySelected == null){
                Utils.notify("error", "Selecione o dia do agendamento.");
                return;
            }

            if(hourSelected == null){
                Utils.notify("error", "Selecione a hora do agendamento.");
                return;
            }

            if(!assinante){
                if(paymentTypeSelected != 1 && paymentTypeSelected !=0){
                    Utils.notify("error", "Selecione o tipo de pagamento.");
                    return;
                }
                if(paymentMethod != 0 && paymentMethod !=1 && paymentMethod != 2 && paymentMethod != 3){
                    Utils.notify("error", "Selecione o método de pagamento.");
                    return;
                }
            }

            /*if(Utils.dataEhAtual(getDateSelectedFormated()) && parceiro?.status_parceiro == 'F'){
                Utils.notify("error", "Hoje o parceiro está fechado, escolha outro dia.");
                return;
            }*/

            setModalResume(true);
        }else{
            setAccountModalShow(true);
        }
    }

    const handleAgendamentoConfirm = async () => {

        const data = {
            id_parceiro: parceiro?.id_parceiro,
            id_servico: servico?.id_servico,
            id_colaborador: colaboradorSelected?.id_colaborador,
            id_cupom: null,
            tipo_agendamento: modeSelected == 0 ? 'P' : 'M',
            id_endereco: modeSelected == 1 ? addressSelected?.id_endereco : null,
            subtotal: Number(servico?.preco_servico),
            desconto: (assinante || paymentMethod == 3) ? Number(servico?.preco_servico) : 0,
            total: Number(servico?.preco_servico),
            metodo_pagamento: getPaymentMethod(),
            local_pagamento: paymentTypeSelected == 0 ? 'A' : 'P',
            status_pagamento: (assinante || paymentMethod == 3) ? 'P' : 'A',
            data_agendamento: getDateSelectedFormated(),
            hora_agendamento: hourSelected?.hora,
            status_agendamento: 'A',
            codigo_agendamento: Utils.makeid(6).toUpperCase(),
            codigo_confirmado: false,
        };

        let agendamentoResponse = await Api.cliente.agendarHorario({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            data: data
        })

        if(agendamentoResponse?.data?.data){
            Utils.notify("success", "Agendamento criado com sucesso.")
            localStorage.setItem("tk-beauty-has-agendamento", true);
            navigate("/reservations")
        }else{
            Utils.notify("error", agendamentoResponse?.data?.message)
        }

    }

    const handleOpenSelectAddress = async () => {
        setAddressSelectOpen(!addressSelectOpen);
    }

    const handleSelectedAddress = async (address) => {
        setAddressSelected(address);
        setAddressSelectOpen(false);
    }

    const handleAddAddress = () => {
        if(user){
            setAddAddressModal(true);
        }
    }

    const onPositionChanged = ({latitude, longitude}) => {
        setAddressLatitude(latitude);
        setAddressLogintude(longitude);
    }

    const handleAddressSave = async () => {
        if(!cep || cep?.trim()?.length <= 0){
            Utils.notify("error", "Digite o CEP.");
            return;
        }
        if(!Utils.validateCep(cep)){
            Utils.notify("error", "CEP inválido.");
            return;
        }
        if(!logradouro || logradouro?.trim()?.length <= 0){
            Utils.notify("error", "Digite o logradouro.");
            return;
        }
        if(uf == null|| uf?.trim()?.length <= 0){
            Utils.notify("error", "Selecione a UF.");
            return;
        }
        if(!municipio){
            Utils.notify("error", "Selecione o município.");
            return;
        }
        if(!numero || numero?.trim()?.length <= 0){
            Utils.notify("error", "Selecione o número.");
            return;
        }

        let request = await Api.cliente.addAddress({
            id_cliente: user?.id_cliente,
            cep: cep,
            logradouro: logradouro,
            uf: uf,
            municipio: municipio,
            numero: numero,
            complemento: complemento,
            latitude: addressLatitude,
            logintude: addressLogintude,
        });

        Utils.processRequest(request, (data) => {
            setAddAddressModal(false);
            loadAddressByClient((_addressList) => {
                setAddressSelected(_addressList.filter(a => {return a.principal == 'S'})[0] || null);
            });
        }, () => {}, true);

    }

    return (
        <>
            <AccountModal show={accountModalShow} setShow={setAccountModalShow}/>
            <Modal show={modalResume} setShow={setModalResume}>
                <div className='mb-2'>
                    <b style={{fontSize: '1.2rem'}}>Resumo do agendamento</b>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="location-outline"></ion-icon>&nbsp;
                        <b>Local</b>
                    </div>
                    <div>
                        <span>{modeSelected == 0 ? parceiro?.nome_parceiro : 'Minha localização'}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="cut-outline"></ion-icon>&nbsp;
                        <b>Serviço</b>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <span>{servico?.nome_servico}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="person-outline"></ion-icon>&nbsp;
                        <b>{Utils.parceiroCategorryMapper(parceiro?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME}</b>
                    </div>
                    <div>
                        <span>{colaboradorSelected?.nome}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="calendar-outline"></ion-icon>&nbsp;
                        <b>Data</b>
                    </div>
                    <div>
                        <span>{daySelected?.dia < 10 ? ('0'+daySelected?.dia) : daySelected?.dia}/{daySelected?.mes < 10 ? '0'+daySelected?.mes : daySelected?.mes}/{daySelected?.ano}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="time-outline"></ion-icon>&nbsp;
                        <b>Hora</b>
                    </div>
                    <div>
                        <span>{hourSelected?.hora}</span>
                    </div>
                </div>
                <SpaceBox space={15}/>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <b>Pagamento</b>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {paymentMethod == 0 ? (
                            <img src={`../pix_icon.png`} style={{width: '15px'}}/>
                        ) : paymentMethod == 1 ? (
                            <ion-icon name="card-outline"></ion-icon>
                        ) : paymentMethod == 2 ? (
                            <ion-icon name="cash-outline"></ion-icon>
                        ) : paymentMethod == 3 ? (
                            <ion-icon name="cash-outline"></ion-icon>
                        ) : ''}
                        &nbsp;<b>{paymentMethod == 0 ? 'Pix' : paymentMethod == 1 ? 'Cartão' : paymentMethod == 2 ? 'Dinheiro' : paymentMethod == 3 ? 'Bônus' : ''}</b>
                    </div>
                </div>

                <div className='totals'>
                    {assinante == true && hourSelected ? (
                        <>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Subtotal</b>
                                <b style={{textDecoration: 'line-through'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Desconto</b>
                                <b><span style={{background: '#f2fff5', color :'green', border: 'solid 1px green', borderRadius: '12px', paddingLeft: '6px', paddingRight: '6px'}}>assinatura</span>&nbsp;<b>-{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></b>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Total</b>
                                <b style={{color: 'green'}}>R$ 0,00</b>
                            </div>
                        </>
                    ) : (
                        paymentMethod != null ? (
                        <>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Subtotal</b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <>
                                        <b style={{textDecoration: Number(bonus) >= Number(servico?.preco_servico) ? 'line-through' : undefined}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </>
                                ):(
                                    <>
                                        <b>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </>
                                )}
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Desconto</b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <div>
                                        <span style={{background: '#f2fff5', color :'green', border: 'solid 1px green', borderRadius: '12px', paddingLeft: '6px', paddingRight: '6px'}}><b>bônus</b></span>&nbsp;
                                        <b>-{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </div>
                                ) : (
                                    <div>
                                        <b>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </div>
                                )}
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Total</b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <b style={{color: 'green'}}>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                ) : (
                                    <b style={{color: 'green'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                )}
                            </div>
                        </>) : (null)
                    )}
                </div>  
                
                <Button onClick={handleAgendamentoConfirm} style={{width: '100%'}} className={'mt-2'}><b>Confirmar agendamento</b></Button>
            </Modal>
            <Modal show={addAddressModal} setShow={setAddAddressModal}>
                <div className='mb-2'>
                    <b style={{fontSize: '1.2rem'}}>Adicionar endereço</b>
                </div>                         
                <MapMarker locationIcon={"../location_move.png"} onPositionChanged={onPositionChanged}/>
                <SpaceBox space={10}/>
                <Input type={"cep"} label={"CEP"} setValue={setCep} value={cep}/>
                <>
                    <Input type={"text"} label={"Logradouro"} setValue={setLogradouro} value={logradouro}/>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Select hideInputBoxMargin width={"30%"}  setValue={setUf} value={uf}>
                            <Option value={""}>Selecione...</Option>
                            {ufs?.map(uf => (
                                <Option value={uf?.sigla}>{uf?.sigla}</Option>
                            ))}
                        </Select>&nbsp;&nbsp;
                        <Select hideInputBoxMargin width={"70%"} setValue={setMunicipio} value={municipio}>
                            <Option value={""}>Selecione...</Option>
                            {municipios?.map(m => (
                                <Option value={m?.id}>{m?.nome}</Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input type={"code-number"} label={"Numero"} setValue={setNumero} value={numero}/>&nbsp;&nbsp;
                        <Input type={"text"} maxLegth={20} label={"Complemento (opcional)"} setValue={setComplemento} value={complemento}/>
                    </div>
                </>
                <Button onClick={handleAddressSave} style={{width: '100%'}}>
                    <b>Salvar Endereço</b>
                </Button>
            </Modal>
            <div className="mockup">
                <div className="bottom-sheet-wrapper">
                    <div className="backdrop" onClick={resetComponent}></div>
                    <div className="bottom-sheet" style={{bottom: Utils.detectarDispositivo() == "iPhone" ? `90px` : undefined}}>
                        <div className="close center" onClick={resetComponent}>
                            <ion-icon name="close-outline" style={{fontSize: '1.4rem'}}></ion-icon>
                        </div>
                        <Container>
                            <div className='mb-1'>
                                <b style={{fontSize: '1.2rem'}}>Data do agendamento</b>
                            </div>

                            <div className='mb-1 mt-1'>
                                <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '0px'}}>
                                    {comeToMe == true && servico?.atende_domicilio == 1 ? (
                                        <>
                                            <div className='period-button' style={{border: modeSelected == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeSelected == 0 ? 'black' : 'black'}} onClick={() => {handleModeClick(0)}}>Vou até ai</div>
                                            <div className='period-button' style={{border: modeSelected == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeSelected == 1 ? 'black' : 'black'}} onClick={() => {handleModeClick(1)}}>Vir até mim</div>
                                        </>
                                    ) : (null) }
                                </div>
                            </div>

                            <div className='mb-1' style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div>
                                            <b>{servico?.nome_servico} - {Utils.converterHoraPorExtenso(servico?.tempo_servico)}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {modeSelected  == 1 ? (
                                <div className='mb-1'>
                                    <div onClick={handleOpenSelectAddress} style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <img 
                                                src={"../loc.webp"} 
                                                style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px'}}/>&nbsp;
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>
                                                    <label style={{fontSize: '0.8rem'}}>Localização</label><br/>
                                                    <b>{addressSelected != null ? (
                                                        <div style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                            <b style={{ fontSize: '10pt' }}>
                                                                {addressSelected?.logradouro}, {addressSelected?.numero}{addressSelected?.complemento} - {addressSelected?.uf}
                                                            </b>
                                                        </div>
                                                    ) : (`Selecionar...`)}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <ion-icon name={addressSelectOpen ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                                    </div>
                                    {addressSelectOpen && user ? (
                                        <Card style={{background: "#fff", border: 'solid 1px #ddd', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', marginTop: '-4px', maxHeight: '180px', overflowY: 'auto'}}>
                                            <ul style={{listStyle: 'none'}}>
                                                <div className='mb-1' key={"add-address"}>
                                                    <li onClick={() => {handleAddAddress()}}>
                                                        <Button style={{width: '100%'}}>+ Adicionar Endereço</Button>
                                                    </li>
                                                </div>
                                                {addressList?.map((address, index) => (
                                                    <div key={index}>
                                                        <li style={{height: '60px'}} className='li-colaborador' onClick={() => {handleSelectedAddress(address)}}>
                                                            <b style={{fontSize: '10pt'}}>{address?.logradouro}, {address?.numero}{address?.complemento} - {address?.uf}</b>
                                                        </li>
                                                    </div>
                                                ))}
                                            </ul>
                                        </Card>
                                    ) : (null)}
                                </div>
                            ) : (null)}

                            <div onClick={handleOpenSelectColaborador} style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img 
                                        src={colaboradorSelected?.foto != undefined ? Environment.API_IMAGE +"/imagem/"+ colaboradorSelected?.foto : "../placeholder-image.png"} 
                                        style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px'}}/>&nbsp;
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div>
                                            <label style={{fontSize: '0.8rem'}}>{Utils.parceiroCategorryMapper(parceiro?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME}</label><br/>
                                            <b>{colaboradorSelected != null ? (
                                                <>
                                                    {colaboradorSelected?.nome}
                                                </>
                                            ) : (`Selecionar...`)}</b>
                                        </div>
                                    </div>
                                </div>
                                <ion-icon name={colaboradorOpenSelect ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                            </div>
                            {colaboradorOpenSelect ? (
                                <Card style={{background: "#fff", border: 'solid 1px #ddd', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', marginTop: '-4px', maxHeight: '180px', overflowY: 'auto'}}>
                                    <ul style={{listStyle: 'none'}}>
                                        {colaboradores?.map((colaborador, index) => (
                                            <div key={index}>
                                                <li className='li-colaborador' onClick={() => {handleSelectedColaborador(colaborador)}}>
                                                    <img src={colaborador?.foto != undefined ? Environment.API_IMAGE +"/imagem/"+ colaborador?.foto : "../placeholder-image.png"} style={{width: '45px', height: '45px', objectFit: 'cover', borderRadius: '4px'}}/>&nbsp;
                                                    <b>{colaborador?.nome}</b>
                                                </li>
                                            </div>
                                        ))}
                                    </ul>
                                </Card>
                            ) : (null)}

                            {colaboradorSelected ? (
                                <div className='mb-1 mt-2'>
                                    <div className="wrapper mb-2">
                                        <ul className="items-category items-days">
                                            {days?.map((ag, index) => (
                                                <li key={index} className="item-agenda" style={{background: ag?.id == daySelected?.id ? '#000000' : '#f5f5f5', color: ag?.id == daySelected?.id ? 'white' : 'black'}} onClick={() => {handleDayClick(ag)}}>
                                                    <div className='text-center' style={{marginBottom: '5px'}}>
                                                        <b style={{fontSize: '1.2rem'}}>{ag.dia}</b>
                                                    </div>
                                                    <div className='text-center'>
                                                    <b>{ag.diaSemana}</b>
                                                    </div>
                                                </li>
                                            ))}  
                                        </ul>
                                    </div>
                                </div>
                            ) : (null)}

                            {daySelected ? (
                                <>
                                    {hours?.length <= 0 ? (
                                        <div style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%'}}>
                                            <ion-icon name="information-circle-outline"></ion-icon>
                                            <b>Nenhum horário disponível.</b>
                                        </div>
                                    ) : (null)}
                                    
                                    <div className='mb-1 mt-1'>
                                        <div className="wrapper mb-2">
                                            <ul className="items-category items-hours">
                                                {hours?.length > 0 && hours?.map((h, index) => (
                                                    h?.livre ? (
                                                        <div style={{background: h?.id == hourSelected?.id ? '#000000' : '#f5f5f5', color: h?.id == hourSelected?.id ? 'white' : 'black'}} className={`hora-button item-hour ${h.hora.replace(":", '-')}`} onClick={() => {handleHourSelected(h)}}>{h?.hora}</div>
                                                    ) : (
                                                        <div style={{background: '#ff34344d', color: 'black', textDecoration: 'line-through'}} className={`hora-button item-hour ${h.hora}`}>{h?.hora.replace(":", '-')}</div>
                                                    )
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            ) : (null)}

                            {/*hourSelected ? (
                                <>
                                    {assinante == true ? (null) : (
                                        <>
                                            <div className='mt-2'>
                                                <b style={{fontSize: '0.90rem'}}>Qual método de pagamento?</b>
                                            </div>
                                            <div className='mb-1 mt-1'>
                                                <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '0px'}}>
                                                    <div className='period-button' style={{border: paymentTypeSelected == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentTypeSelected == 0 ? 'black' : 'black'}} onClick={() => {handlePaymentTypeSelected(0)}}>Pelo App</div>
                                                    <div className='period-button' style={{border: paymentTypeSelected == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentTypeSelected == 1 ? 'black' : 'black'}} onClick={() => {handlePaymentTypeSelected(1)}}>Presencial</div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                                    ) : (null)*/}

                            {paymentTypeSelected != null && assinante == false && hourSelected ? (
                                <>
                                    <div className='mb-1 mt-1'>
                                        <div style={{display: 'grid', gridTemplateColumns: paymentTypeSelected == 1 ? 'repeat(3, 1fr)' : Number(bonus) >= Number(servico?.preco_servico) ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)', justifyContent: 'center', gap: '2px'}}>
                                            <div className='period-button' style={{border: paymentMethod == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentMethod == 0 ? 'black' : 'black'}} onClick={() => {handlePaymentMethod(0)}}>
                                                <img src={`../pix_icon.png`} style={{width: '15px'}}/><br/>
                                                <b>Pix</b>
                                            </div>
                                            <div className='period-button' style={{border: paymentMethod == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentMethod == 1 ? 'black' : 'black'}} onClick={() => {handlePaymentMethod(1)}}>
                                                <ion-icon name="card-outline"></ion-icon><br/>
                                                <b>Cartão</b>
                                            </div>
                                            {Number(bonus) >= Number(servico?.preco_servico) ? (
                                                <div className='period-button' style={{border: paymentMethod == 3 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentMethod == 3 ? 'black' : 'black'}} onClick={() => {handlePaymentMethod(3)}}>
                                                    <ion-icon name="cash-outline"></ion-icon><br/>
                                                    <b>Bônus</b>
                                                </div>
                                            ):(null)}
                                            {paymentTypeSelected == 1 ? (
                                                <div className='period-button' style={{border: paymentMethod == 2 ? 'solid 2px #000' : 'solid 2px #ddd', color: paymentMethod == 2 ? 'black' : 'black'}} onClick={() => {handlePaymentMethod(2)}}>
                                                    <ion-icon name="cash-outline"></ion-icon><br/>
                                                    <b>Dinheiro</b>
                                                </div>
                                            ) : (null)}
                                        </div>
                                    </div>
                                </>
                            ) : (null)}
                            
                            <div className='totals'>
                                {assinante == true && hourSelected ? (
                                    <>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <b>Subtotal</b>
                                            <b style={{textDecoration: 'line-through'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <b>Desconto</b>
                                            <b><span style={{background: '#f2fff5', color :'green', border: 'solid 1px green', borderRadius: '12px', paddingLeft: '6px', paddingRight: '6px'}}>assinatura</span>&nbsp;<b>-{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></b>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <b>Total</b>
                                            <b style={{color: 'green'}}>R$ 0,00</b>
                                        </div>
                                    </>
                                ) : (
                                    paymentMethod != null ? (
                                    <>
                                        {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#f2fff5', color :'green', padding: '8px'}}>
                                                <b>Saldo Bônus</b>
                                                <b>{bonus?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                            </div>
                                        ) : (null)}
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <b>Subtotal</b>

                                            {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                                <>
                                                    <b style={{textDecoration: Number(bonus) >= Number(servico?.preco_servico) ? 'line-through' : undefined}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                                </>
                                            ) : (
                                                <>
                                                    <b>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                                </>
                                            )}

                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <b>Desconto</b>
                                            <b>
                                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                                    <>
                                                        <span style={{background: '#f2fff5', color :'green', border: 'solid 1px green', borderRadius: '12px', paddingLeft: '6px', paddingRight: '6px'}}>bônus</span>&nbsp;
                                                        -{Number(bonus) >= Number(servico?.preco_servico) ? Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : `R$ 0,00`}
                                                    </>
                                                ) : (
                                                    <>
                                                        {`R$ 0,00`}
                                                    </>
                                                )}
                                            </b>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <b>Total</b>
                                            {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                                <>
                                                    <b style={{color: 'green'}}>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                                </>
                                            ):(
                                                <>
                                                    <b style={{color: 'green'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                                </>
                                            )}
                                        </div>
                                    </>) : (null)
                                )}
                            </div>  

                            {assinante == true ? (
                                hourSelected != null ? (
                                    <Button onClick={handleAgendamento} style={{width: '100%'}} className={'mt-2'}><b>Confirmar agendamento</b></Button>
                                ) : (null) 
                            ) : (
                                paymentMethod != null ? (
                                    <Button onClick={handleAgendamento}  style={{width: '100%'}} className={'mt-2'}><b>Confirmar agendamento</b></Button>
                                ) : (null)
                            )}

                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}
