import React, { useEffect } from 'react';
import {Card, Container, HeaderWithBack, SpaceBox} from '../../components';
import './style.css';

export default () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Container>
            <HeaderWithBack title={"Política de Privacidade"}/>
            <SpaceBox space={20}/>
            <Card className={"body-terms"}>
                <h1>Política de Privacidade</h1>
                <p>A IGend valoriza a sua privacidade. Esta política de privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações ao utilizar nosso site e serviços. Ao acessar nosso site e utilizar nossos serviços, você concorda com a coleta e uso das informações conforme descrito nesta política.</p>

                <div class="section-terms">
                    <h2>1. Informações Coletadas</h2>
                    <h3>1.1. Informações Pessoais</h3>
                    <p>Coletamos informações pessoais que você nos fornece diretamente, como:</p>
                    <ul>
                        <li>Nome completo</li>
                        <li>Endereço de e-mail</li>
                        <li>Número de telefone</li>
                        <li>Documentos de identificação</li>
                        <li>Endereço</li>
                    </ul>

                    <h3>1.2. Informações de Uso</h3>
                    <p>Coletamos automaticamente informações sobre sua interação com nossa plataforma, incluindo:</p>
                    <ul>
                        <li>Endereço IP</li>
                        <li>Tipo de navegador</li>
                        <li>Páginas visitadas</li>
                        <li>Horário e data das visitas</li>
                        <li>Tempo gasto em cada página</li>
                    </ul>

                    <h3>1.3. Dados de Pagamento</h3>
                    <p>Quando você realiza transações financeiras na plataforma, coletamos informações de pagamento, como:</p>
                    <ul>
                        <li>Dados do cartão de crédito/débito</li>
                        <li>Informações de transações bancárias</li>
                        <li>Histórico de transações</li>
                    </ul>
                </div>

                <div class="section-terms">
                    <h2>2. Uso das Informações</h2>
                    <p>Utilizamos as informações coletadas para diversos fins, incluindo:</p>
                    <h3>2.1. Prestação de Serviços</h3>
                    <p>Para fornecer, operar, manter e melhorar nossos serviços, incluindo:</p>
                    <ul>
                        <li>Gestão de agendamentos</li>
                        <li>Gerenciamento de listas de serviços e produtos</li>
                        <li>Administração de sistemas de fidelidade e cupons</li>
                        <li>Relatórios e análises de desempenho</li>
                    </ul>

                    <h3>2.2. Comunicação</h3>
                    <p>Para comunicar-se com você, incluindo:</p>
                    <ul>
                        <li>Envio de notificações e atualizações</li>
                        <li>Respostas a dúvidas e solicitações de suporte</li>
                        <li>Envio de mensagens promocionais e de marketing, conforme permitido por lei</li>
                    </ul>

                    <h3>2.3. Melhoria do Serviço</h3>
                    <p>Para entender como nossos usuários utilizam nossa plataforma, a fim de melhorar nossos serviços e desenvolver novos recursos.</p>
                </div>

                <div class="section-terms">
                    <h2>3. Compartilhamento de Informações</h2>
                    <p>Não vendemos, trocamos ou transferimos suas informações pessoais para terceiros, exceto nas seguintes circunstâncias:</p>
                    <h3>3.1. Provedores de Serviço</h3>
                    <p>Podemos compartilhar informações com terceiros que prestam serviços em nosso nome, como:</p>
                    <ul>
                        <li>Processamento de pagamentos</li>
                        <li>Hospedagem de dados</li>
                        <li>Envio de comunicações</li>
                        <li>Análise de dados</li>
                    </ul>

                    <h3>3.2. Obrigações Legais</h3>
                    <p>Podemos divulgar informações para cumprir com obrigações legais, como:</p>
                    <ul>
                        <li>Atender a ordens judiciais</li>
                        <li>Respeitar leis e regulamentos aplicáveis</li>
                        <li>Responder a solicitações governamentais</li>
                    </ul>

                    <h3>3.3. Proteção de Direitos</h3>
                    <p>Podemos divulgar informações para proteger nossos direitos, propriedade ou segurança, bem como os de nossos usuários e do público em geral.</p>
                </div>

                <div class="section-terms">
                    <h2>4. Proteção das Informações</h2>
                    <p>Implementamos uma série de medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, uso indevido ou divulgação, incluindo:</p>
                    <ul>
                        <li>Criptografia de dados durante a transmissão</li>
                        <li>Controles de acesso restritos</li>
                        <li>Monitoramento regular de nossos sistemas para identificar vulnerabilidades</li>
                        <li>Políticas de segurança rigorosas</li>
                    </ul>
                </div>

                <div class="section-terms">
                    <h2>5. Seus Direitos</h2>
                    <p>Você tem o direito de:</p>
                    <ul>
                        <li>Acessar suas informações pessoais que possuímos</li>
                        <li>Solicitar a correção de informações pessoais imprecisas</li>
                        <li>Solicitar a exclusão de suas informações pessoais, salvo em casos onde temos uma obrigação legal de mantê-las</li>
                        <li>Optar por não receber comunicações de marketing</li>
                    </ul>

                    <p>Para exercer esses direitos, entre em contato conosco através dos meios fornecidos na seção de contato.</p>
                </div>

                <div class="section-terms">
                    <h2>6. Política de Cancelamento</h2>
                    <p>As políticas de cancelamento após o pagamento são as seguintes:</p>
                    <ul>
                        <li>Cancelamentos realizados até 30 minutos após o pagamento resultarão em um crédito como saldo bônus para o usuário.</li>
                        <li>Cancelamentos realizados após 30 minutos resultarão em um reembolso de 50% para o cliente e 50% para o parceiro.</li>
                    </ul>
                </div>

                <div class="section-terms">
                    <h2>7. Política de Assinaturas</h2>
                    <p>Os preços das assinaturas de cada parceiro estão sujeitos a alterações para as próximas parcelas. Os usuários serão notificados dessas alterações com antecedência, permitindo que escolham continuar ou cancelar a assinatura.</p>
                </div>

                <div class="section-terms">
                    <h2>8. Cookies e Tecnologias de Rastreamento</h2>
                    <p>Utilizamos cookies e tecnologias similares para melhorar a sua experiência em nosso site. Os cookies são pequenos arquivos de dados armazenados em seu dispositivo. Eles ajudam a personalizar o conteúdo, fornecer funcionalidades de redes sociais e analisar nosso tráfego.</p>

                    <h3>8.1. Tipos de Cookies Utilizados</h3>
                    <ul>
                        <li><strong>Cookies Essenciais:</strong> Necessários para o funcionamento do site e para que você possa utilizar nossos serviços.</li>
                        <li><strong>Cookies de Desempenho:</strong> Coletam informações sobre como os usuários interagem com o site, permitindo-nos melhorar seu funcionamento.</li>
                        <li><strong>Cookies Funcionais:</strong> Permitem que o site se lembre de suas escolhas (como seu idioma ou região) para proporcionar uma experiência mais personalizada.</li>
                        <li><strong>Cookies de Publicidade:</strong> Utilizados para fornecer anúncios mais relevantes para você e seus interesses.</li>
                    </ul>

                    <h3>8.2. Gerenciamento de Cookies</h3>
                    <p>Você pode gerenciar as configurações de cookies no seu navegador. No entanto, desativar cookies pode afetar a funcionalidade do site e a sua experiência de usuário.</p>
                </div>

                <div class="section-terms">
                    <h2>9. Alterações na Política de Privacidade</h2>
                    <p>Podemos atualizar esta política de privacidade periodicamente. Quando fizermos alterações significativas, notificaremos você por meio de um aviso em nosso site ou por outros meios apropriados. Recomendamos que você revise esta política regularmente para estar ciente de quaisquer atualizações.</p>
                </div>

                <div class="section-terms">
                    <h2>10. Contato</h2>
                    <p>Se você tiver dúvidas ou preocupações sobre esta política de privacidade, entre em contato conosco:</p>
                    <ul>
                        <li>Telefone: +55 (61) 99193-0897</li>
                        <li>Email: suporte@igend.com.br</li>
                        <li>Local: Brasil - Distrito Federal</li>
                    </ul>
                </div>
            </Card>
        </Container>
    )
}
