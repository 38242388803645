import React from 'react'
import { FragmentView } from '../../components'

export default () => {
    return (
        <>
            <FragmentView title={"Clientes"}>
                
            </FragmentView>
        </>
    )
}
