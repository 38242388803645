import React, { useState } from 'react';
import './style.css';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import Utils from '../../Utils';
import Api from '../../Api';

export default () => {

    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [senha, setSenha] = useState("");
    const [senhaC, setSenhaC] = useState("");

    const handleGoBack = () => {
        navigate(-1);    
    }

    const handleRegister = () => {
        navigate('/adm-register')   
    }

    const sendCode = async () => {

        let _phone = phone?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", "");

        if(!Utils.validatePhoneNumber(phone)){
            Utils.notify("error", "O número do celular é inválido.")
            return;
        }

        let requestSendCode = await Api.colaborador.sendCodeRecoveryPassword({
            phone: _phone
        })

        Utils.processRequest(requestSendCode, () => {
            setStep(1);
        }, () => {}, true);

    }

    const verifyCode = async () => {

        let _phone = phone?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", "");
        
        if(code?.trim()?.length < 6){
            Utils.notify("error", "Código de verificação inválido.")
            return;
        }

        let requestVerifyCode = await Api.colaborador.verifyCodeRecoveryPassword({
            phone: _phone,
            code: code
        })

        Utils.processRequest(requestVerifyCode, () => {
            setStep(2);
        }, () => {}, true);

    }

    const changePassword = async () => {

        let _phone = phone?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", "");

        if(senha?.trim()?.length <= 0){
            Utils.notify("error", "Digite sua nova senha.");
            return;
        }

        if(senhaC != senha){
            Utils.notify("error", "As senhas nao se coincidem.");
            return;
        }

        if(!Utils.checkPasswordStrength(senha)){
            Utils.notify("error", "A nova senha precisa ter letras e números e ter no mínimo 6 letras.");
            return;
        }

        let requestChangePassword = await Api.colaborador.recoveryChangePassword({
            phone: _phone,
            code: code,
            senha: senha
        })

        Utils.processRequest(requestChangePassword, () => {
            navigate('/');
        }, () => {}, true);

    }

    return (
        <>  
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ background: '#fff', padding: '20px', borderRadius: '5px', width: Utils.mobileCheck() ? '90%' : '25%' }}>
                    <div style={{display: 'flex', alignItems:'center', alignItems: 'center', justifyContent: 'center', marginTop: '-58px'}}>
                        <img src='../logo.png' className='shadow' style={{width: '80px', borderRadius: '8px', border: '3px solid #fff'}}/>
                    </div>
                    <div className='mt-2 mb-2'>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                            <b style={{fontSize: '1.1rem'}}>Recuperação de conta.</b>
                        </div>
                    </div>
                    {step == 0 ? (
                        <>
                            <Input type={"celular"} label={"DDD + Celular"} value={phone} setValue={setPhone}/>
                            <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'right', cursor: 'pointer'}} onClick={handleGoBack}>
                                Lembrou sua <b>senha?</b>
                            </div>
                            <Button onClick={sendCode} style={{width: '100%'}}>
                                <b>Enviar código</b>
                            </Button>
                        </>
                    ) : (null)}
                    {step == 1 ? (
                        <>
                            <Input type={"code"} label={"Código de recuperação"} value={code} setValue={setCode}/>
                            <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'right', cursor: 'pointer'}} onClick={handleGoBack}>
                                Lembrou sua <b>senha?</b>
                            </div>
                            <Button onClick={verifyCode} style={{width: '100%'}}>
                                <b>Confirmar código</b>
                            </Button>
                        </>
                    ) : (null)}
                    {step == 2 ? (
                        <>
                            <Input type={"password"} label={"Nova senha"} value={senha} setValue={setSenha}/>
                            <Input type={"password"} label={"Confirmar senha"} value={senhaC} setValue={setSenhaC}/>
                            <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'right', cursor: 'pointer'}} onClick={handleGoBack}>
                                Lembrou sua <b>senha?</b>
                            </div>
                            <Button onClick={changePassword} style={{width: '100%'}}>
                                <b>Confirmar senha</b>
                            </Button>
                        </>
                    ) : (null)}
                    <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'center', cursor: 'pointer'}} onClick={handleRegister}>
                        Não tem conta? <b>registre-se</b>
                    </div>
                </div>
            </div>
        </>
    )
}
