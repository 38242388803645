import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainContext } from '../../helpers/MainContext';
import Environment from '../../Environment';
import Modal from '../Modal';
import Button from '../Button';
import Utils from '../../Utils';

const MENU_ITEMS = [
    { path: '/', index: 1, icon: 'calendar-outline', label: 'Agenda' },
    { path: '/adm-services', index: 2, icon: 'cut-outline', label: 'Serviços' },
    { path: '/adm-products', index: 3, icon: 'bag-handle-outline', label: 'Marketplace' },
    { path: '/adm-team', index: 4, icon: 'people-outline', label: 'Equipe' },
    { path: '/adm-profile', index: 5, icon: 'person-outline', label: 'Perfil' },
    { path: '/adm-saldo', index: 6, icon: 'wallet-outline', label: 'Financeiro' },
    { path: '/adm-pedidos', index: 7, icon: 'reader-outline', label: 'Pedidos' },
    { path: '/adm-cupons', index: 8, icon: 'pricetags-outline', label: 'Cupons' },
    { path: '/adm-ads', index: 9, icon: 'megaphone-outline', label: 'Impulsionar' },
    { path: '/adm-assinaturas', index: 10, icon: 'diamond-outline', label: 'Assinaturas' },
    { path: '/adm-clientes', index: 11, icon: 'people-outline', label: 'Clientes' },
    { path: '/adm-avaliacoes', index: 12, icon: 'star-outline', label: 'Avaliações' },
    { path: '/adm-galeria', index: 13, icon: 'images-outline', label: 'Galeria' },
    { path: '/adm-notificacoes', index: 14, icon: 'notifications-outline', label: 'Notificações' },
    { path: '/adm-personalizar', index: 15, icon: 'brush-outline', label: 'Personalizar'}
];

const Sidebar = ({ pageIndex, setPageIndex }) => {
    const navigate = useNavigate();
    const { user, setUser, setAuthenticated, logout } = useContext(MainContext);
    const [show, setShow] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const updatePageIndex = () => {
            const currentItem = MENU_ITEMS.find(item => item.path === location.pathname);
            if (currentItem) {
                setPageIndex(currentItem.index);
            }
        };
        updatePageIndex();
    }, [location, setPageIndex]);

    const handleLogoutConfirm = async () => {
        logout(() => {
            setUser(null);
            setAuthenticated(false);
            navigate("/");
        });
    };

    const clickSaveButtonIndex = (index, route) => {
        localStorage.setItem("tk_bauty_bottomtab_index_desktop", index);
        setPageIndex(index);
        navigate(route);
    };

    const handleIndex = () => {
        navigate("/");
    };

    return (
        <>
            <Modal show={show} setShow={setShow}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <b>Deseja desconectar sua conta agora?</b>
                </div>
                <div className='mt-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Button style={{ color: '#ff2e36', background: '#fceaeb', height: '40px' }} onClick={() => { setShow(false) }}>&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>
                    <Button style={{ height: '40px' }} onClick={handleLogoutConfirm}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <div className="sidebar">
                <div className='mb-1' style={{ display: 'flex', alignItems: 'center', padding: '0px 20px', cursor: 'pointer' }} onClick={handleIndex}>
                    <img src={Environment.API_IMAGE + "/imagem/" + user?.logo_parceiro || "../placeholder-image.png"} className='shadow' width={'50px'} height={'50px'} style={{ borderRadius: '8px', border: 'solid 2px #fff' }} />&nbsp;
                    <b>{user?.nome_parceiro}</b>
                </div>
                <ul className="menu">
                    {MENU_ITEMS.map(item => (
                        <li key={item.index} className={`menu-item ${pageIndex === item.index ? `menu-item-active` : ``}`} onClick={() => clickSaveButtonIndex(item.index, item.path)}>
                            <ion-icon name={item.icon}></ion-icon>
                            <span>{item.index === 4 ? Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_BOTTOM_MENUNAME_EQUIPE : item.label}</span>
                        </li>
                    ))}
                    <li className="menu-item" onClick={() => { setShow(true) }}>
                        <ion-icon name="log-out-outline"></ion-icon>
                        <span>Sair</span>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Sidebar;