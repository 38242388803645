import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

export default ({title, center, onHanderAction, actionIconComponent}) => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: onHanderAction ? 'space-between' : center ? 'center' : 'flex-start'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: center ? 'center' : 'flex-start'}}>
                <ion-icon name="chevron-back-outline" onClick={handleGoBack} style={{color: "black", fontSize: '2.3rem', cursor: 'pointer'}}></ion-icon>
                <h3 style={{color: "black"}}>&nbsp;{title}</h3> 
            </div>
            {onHanderAction ? (
                <Button style={{height: '50px', display: 'flex', alignItems: 'center', color: 'white'}} onClick={onHanderAction.action}>
                    &nbsp;&nbsp;&nbsp;<b>{onHanderAction?.text}</b>&nbsp;&nbsp;&nbsp;
                    {actionIconComponent ? (
                        <>
                            <ion-icon style={{fontSize: '16pt'}} name={`${actionIconComponent}`}></ion-icon>&nbsp;&nbsp;
                        </>
                    ) : (null)}
                </Button>
            ) : (null)}
        </div>
    )
}
