import React from 'react';
import './style.css';
import Card from '../Card';
import Environment from '../../Environment';
import Utils from '../../Utils';

export default ({produto, style, onClick, actionViewMode, openModalEdit, handleDeleteProduto}) => {
    return (
        <>
            {actionViewMode ? (
                Utils.mobileCheck() ? (                
                    <Card className="item-product" style={{width: '100%', padding: '6px', background: produto?.ativo == 0 ? '#fbefef' : '', borderTop: produto?.ativo == 0 ? 'solid 3px red' : '', marginRight: '0px'}}>
                        <div className='item-product-icon' style={{width: '100%', height: '72px', objectFit: 'cover'}}>
                            <img loading="lazy" style={{borderRadius: '8px'}} src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"}/>
                        </div>
                        <div className='text-center mt-1 mb-1'>
                            {Number(produto?.estoque) && Number(produto.estoque) >= 0 ? (
                                <>
                                    <b className='item-product-b'>{produto?.nome}</b>
                                    <p style={{fontSize: '10pt', fontWeight: 'normal'}}>Estoque: <b>{produto?.estoque}</b></p>
                                </>
                            ) : (<b className='item-product-b'>{produto?.nome}</b>)}
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div onClick={() => {openModalEdit(produto)}} style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="create-outline" size="large"></ion-icon>
                            </div>&nbsp;&nbsp;
                            <div onClick={() => {handleDeleteProduto(produto)}} style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon style={{color: 'red'}} name="trash-outline" size="large"></ion-icon>
                            </div>
                        </div>
                    </Card>
                ):(
                    <Card style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: produto?.ativo == 0 ? '#fbefef' : ''}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img loading="lazy" style={{borderRadius: '8px', width: '50px', height: '50px', objectFit: 'cover'}} src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"}/>&nbsp;
                            {Number(produto?.estoque) && Number(produto.estoque) >= 0 ? (
                                <div>
                                    <b className='item-product-b'>{produto?.nome}</b>
                                    <p style={{fontSize: '10pt'}}>Estoque: <b>{produto?.estoque}</b></p>
                                </div>
                            ) : (<b className='item-product-b'>{produto?.nome}</b>)}
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div onClick={() => {openModalEdit(produto)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                <ion-icon name="create-outline" size="large"></ion-icon>
                            </div>&nbsp;&nbsp;
                            <div onClick={() => {handleDeleteProduto(produto)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                <ion-icon style={{color: 'red'}} name="trash-outline" size="large"></ion-icon>
                            </div>
                        </div>
                    </Card>
                )
            ) : (
                <li className="item-product" style={style} onClick={onClick}>
                    <div className='item-product-icon' style={{height: '72px',}}>
                        <img loading="lazy" src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"}/>
                    </div>
                    <div className='text-center' style={{
                        textWrap: 'nowrap',
                        overflow: 'hidden',
                        textOoverflow: 'ellipsis'
                    }}>
                        <b className='item-product-b'>{produto?.nome}</b>
                    </div>
                    <div className='text-center' style={{marginTop: '5px'}}>
                        <b className='item-product-price'>{Number(produto?.preco)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                    </div>
                </li>
            )}
        </>
    )
}
