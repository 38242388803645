import React, { useEffect, useState } from 'react'
import { Button, Card, FragmentView, Hr, Input, SpaceBox } from '../../components'
import Utils from '../../Utils';
import Api from '../../Api';

export default () => {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(localStorage.getItem("tk_beauty_cliente_bonus_show") || true);
    const [bonus, setBonus] = useState(0);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        if(localStorage.getItem("tk_beauty_cliente_bonus_show") != null){
            setShow(localStorage.getItem("tk_beauty_cliente_bonus_show") == "true");
        }
        const requests = async () => {
            let responseBonus = await Api.cliente.obterSaldoBonus({
              forceToken: localStorage.getItem("tk_beauty_cliente_token"),  
            })
            let responseHistory = await Api.cliente.obterHistoricoSaldoBonus({
                forceToken: localStorage.getItem("tk_beauty_cliente_token"),  
            })
            let _bonus = responseBonus?.data?.data?.valor;
            let _history = responseHistory?.data?.data;
            setBonus(Number(_bonus));
            setHistory(_history);
            setLoading(true);
        }
        requests();
    }, []);

    const handleEye = async (value) => {
        setShow(value);
        localStorage.setItem("tk_beauty_cliente_bonus_show", value);
    }

    return (
        <FragmentView title={"Saldo Bônus"}>
            <Card>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{padding: '10px'}}>
                            <ion-icon name="wallet-outline" size="large"></ion-icon>
                        </div>
                        <div>
                            <div>
                                <span style={{color: 'rgb(140, 140, 140)'}}>Seu saldo</span>
                            </div>
                            <div>
                                {loading ? (
                                    show ? (
                                        <b style={{fontSize:'24pt'}}>{bonus?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>
                                    ) : (
                                        <b style={{fontSize: '24pt'}}>R$ *****</b>
                                    )
                                ) : (
                                    <b style={{fontSize:'24pt'}}>R$ ...</b>
                                )}
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {handleEye(!show)}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px'}}>
                        {show ? (
                            <ion-icon name="eye-off-outline" size="large"></ion-icon>
                        ) : (
                            <ion-icon name="eye-outline" size="large"></ion-icon>
                        )}
                    </div>
                </div>
            </Card>
            <SpaceBox space={20}/>
            <Card style={{padding: '10px'}}>
                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                    <ion-icon name="time-outline"></ion-icon>&nbsp;
                    <b>Extrato de saldo</b>
                </div>
                <div className='mt-1 mb-1'>
                    {history?.length > 0 ? (
                        history?.map((h, index) => (
                            <>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: h?.tipo == 'ENTRADA' ? 'green' : 'red', background: h?.tipo == "ENTRADA" ? 'rgb(242, 255, 245)' : 'rgb(244 183 183 / 30%)'}}>
                                            {h?.tipo == "ENTRADA" ? (
                                                <ion-icon name="arrow-up-outline"></ion-icon>
                                            ) : (
                                                <ion-icon name="arrow-down-outline"></ion-icon>
                                            )}
                                        </div>&nbsp;&nbsp;&nbsp;
                                        <div>
                                            <b style={{}}>{h?.tipo == 'ENTRADA' ? `Entrada` : `Saída`}</b>
                                            <p style={{color: 'gray'}}>{h?.descricao}</p>
                                            <p style={{color: 'gray'}}>{Number(h?.valor)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{color: 'gray', fontSize: '10pt', textAlign: 'right', width: '90px'}}>
                                            {Utils.converterDate(h?.ts_registro)}
                                        </p>
                                    </div>
                                </div>
                                {index < (history?.length - 1) ? (
                                    <Hr elevation={0.5}/>
                                ) : (null)}
                            </>
                        ))
                    ) : (
                        loading == true ? (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                <b style={{color: 'gray'}}>Nenhuma atividade!</b>
                            </div>
                        ) : (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                <b style={{color: 'gray'}}>Carregando...</b>
                            </div>
                        )
                    )}
                </div>
            </Card>
            <SpaceBox space={80}/>
        </FragmentView>
    )
}
