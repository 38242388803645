import React, { useEffect, useState } from 'react';
import './style.css';
import Card from '../Card';
import Ship from '../Ship';
import SpaceBox from '../SpaceBox';
import Button from '../Button';
import QRCode from "react-qr-code";
import Utils from '../../Utils';
import Api from '../../Api';
import Modal from '../Modal';
import AgendaProviderHelper from '../../helpers/AgendaProviderHelper';
import Environment from '../../Environment';

const $ = require( "jquery" );

const startEvents = () => {
    
    /*document.querySelector(".btn-modal-agenda").addEventListener("click", () => {
        document.querySelector(".bottom-sheet-wrapper").classList.add("show-modal");
    });*/
      
    document.querySelectorAll(".close, .backdrop").forEach(element => {
        element.addEventListener("click", () => {
            document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
            //window.location.hash = "";
        });
    });
}

const startSwipperLogic = () => {

    let elementsArray = [
        document.querySelector('.items-days'),
        document.querySelector('.items-hours'),
    ];

    let isDown = false;
    let startX;
    let scrollLeft;

    const end = (s) => {
        isDown = false;
        s.classList.remove('active');
    }

    const start = (e, s) => {
        isDown = true;
        s.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - s.offsetLeft;
        scrollLeft = s.scrollLeft;	
    }
    
    const move = (e, s) => {
        if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - s.offsetLeft;
        const dist = (x - startX);
        s.scrollLeft = scrollLeft - dist;
    }

    elementsArray.forEach(function(slider) {

        if(slider){
            slider.addEventListener('mousedown', function (e) { start(e, slider) });
            slider.addEventListener('touchstart', function (e) { start(e, slider) });
            slider.addEventListener('mousemove', function (e) { move(e, slider) });
            slider.addEventListener('touchmove', function (e) { move(e, slider) });
            slider.addEventListener('mouseleave', function () {end(slider) });
            slider.addEventListener('mouseup', function () { end(slider) });
            slider.addEventListener('touchend', function () { end(slider) });
        }

    });

}

export default ({reserva, expanded, className, style, handleOpenModalPayment, handleOpenModalConfirmation, onFetchReservations}) => {

    const [open, setOpen] = useState(expanded);

    const [showSettings, setShowSettings] = useState(false); 
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [showModalEditReservation, setShowModalEditReservation] = useState(false);
    const [showModalQrCode, setShowModalQrCode] = useState(false);

    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorOpenSelect, setColaboradorOpenSelect] = useState(false);
    const [colaboradorSelected, setColaboradorSelected] = useState(null);
    const [agendamentosByColaborador, setAgendamentosByColaborador] = useState([]);

    const [daySelected, setDaySelected] = useState(null);
    const [hourSelected, setHourSelected] = useState(null);

    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);

    useEffect(() => {
        startSwipperLogic();
    }, [daySelected, colaboradorSelected])

    useEffect(() => {
        startSwipperLogic();
        startEvents();
        setOpen(expanded);
    }, [])

    const handleClick = () => {
        setOpen(!open);
        setShowSettings(false);
    }

    const handlePayment = () => {
        handleOpenModalPayment();
    }

    const handleConfirm = () => {
        handleOpenModalConfirmation();
    }

    const onClickBackdrop = () => {
        setShowSettings(false);
    }

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    const handleChange = async () => {

        setShowModalEditReservation(true);

        let colaboradoresResponse = await Api.geral.colaboradoresByParceiro({
            id_parceiro: reserva?.id_parceiro
        })

        let _colaboradores = colaboradoresResponse?.data?.data;
        let _colaboradorSelected = _colaboradores?.filter(item => {return item?.id_colaborador == reserva?.id_colaborador})[0];

        setColaboradorSelected(_colaboradorSelected);
        handleSelectedColaborador(_colaboradorSelected);

        setColaboradores(_colaboradores)

    }   

    const handleAgendar = async () => {
        setDaySelected(null);
        setHourSelected(null);
    }

    const handleSelectedColaborador = async (colaborador) => {
        setColaboradorSelected(colaborador);

        let agendamentosByColaboradorResponse = await Api.geral.agendamentosParceiroByColaborador({
            id_colaborador: colaborador?.id_colaborador,
            id_parceiro: reserva?.id_parceiro
        })

        let _agendamentos = agendamentosByColaboradorResponse?.data?.data;
        let _days = AgendaProviderHelper.getDaysPeriods();

        setDays(_days);
        handleAgendar();
        setAgendamentosByColaborador(_agendamentos);
        setColaboradorOpenSelect(false);
    }

    const loadHours = async (day) => {
        let _dia = (day?.dia < 10) ? "0"+day?.dia : day?.dia;
        let _mes = (day?.mes < 10) ? "0"+day?.mes : day?.mes;
        let _ano = day?.ano;
        let _date = _ano+"-"+_mes+"-"+_dia;

        let expedienteResponse = await Api.geral.parceiroExpedientes({
            id_parceiro: reserva?.id_parceiro
        })

        let _expediente = expedienteResponse?.data?.data;

        let {abertura, fechamento} = Utils.checkWeekDayAndReturnExpediente(day, _expediente);
        
        let _hours = AgendaProviderHelper.getHoursPeriods(abertura, fechamento, _date);
        
        setHours(_hours);

        return _hours;
    }

    const handleOpenSelectColaborador = async () => {
        setColaboradorOpenSelect(!colaboradorOpenSelect);
    }

    const converterParaMinutos = (hora) => {
        const [h, m] = hora.split(":").map(Number);
        return h * 60 + m;
    }
    
    const converterDuracaoParaMinutos = (duracao) => {
        const [h, m] = duracao.match(/\d+/g).map(Number);
        return h * 60 + m;
    }    
    
    function verificarHorario(hours, agendamentos) {
    
        agendamentos.forEach(agendamento => {
            const inicioAgendamento = converterParaMinutos(agendamento.hora);
            const duracaoAgendamento = converterDuracaoParaMinutos(agendamento.tempo_servico);
            const fimAgendamento = inicioAgendamento + duracaoAgendamento;
    
            //gambiarra preparada para calcular até 8 horas
            let intervalo = (duracaoAgendamento > 60 && duracaoAgendamento < 180) ? 60 : ((duracaoAgendamento >= 180 && duracaoAgendamento < 240) ? 90 : (duracaoAgendamento >= 240 && duracaoAgendamento < 300) ? 120 : (duracaoAgendamento >= 300 && duracaoAgendamento < 360) ? 160 : (duracaoAgendamento >= 360 && duracaoAgendamento < 420) ? 180 : (duracaoAgendamento >= 420 && duracaoAgendamento < 480) ? 210 : (duracaoAgendamento >= 480) ? 240 : 30);
            const intervaloAgendamento = Math.ceil(duracaoAgendamento / intervalo);
    
            hours.forEach((horaDisponivel, index) => {
                const horaAtual = converterParaMinutos(horaDisponivel.hora);
                const proximaHora = index < hours.length - 1 ? converterParaMinutos(hours[index + 1].hora) : horaAtual + 30;
    
                if (horaAtual >= inicioAgendamento && proximaHora <= fimAgendamento) {
                    for (let i = index; i < index + intervaloAgendamento && i < hours.length; i++) {
                        hours[i].livre = false;
                    }
                }
            });
        });
    
    }

    const handleDayClick = async (day) => {

        let _hours = await loadHours(day);

        let datasReservadas = [];

        for(let i = 0; i< agendamentosByColaborador?.length; i++){
            let _id_agendamento = agendamentosByColaborador[i]?.id_agendamento;
            let _id_servico = agendamentosByColaborador[i]?.id_servico;
            let _d = agendamentosByColaborador[i]?.data_agendamento;
            let _h = agendamentosByColaborador[i]?.hora_agendamento;
            let _tempo_servico = agendamentosByColaborador[i]?.tempo_servico;
            datasReservadas.push({
                id_agendamento: _id_agendamento,
                id_servico: _id_servico,
                tempo_servico: _tempo_servico,
                data: _d?.split('/')[2]+"-"+_d?.split('/')[1]+"-"+_d?.split('/')[0],
                hora: _h
            });
        }

        let _horariosReservadosForDisable = [];

        _hours.forEach(item => {
            datasReservadas.forEach(dr => {
                if (item.hora === dr.hora && item.dataRef === dr.data) {
                    item.livre = false;
                    _horariosReservadosForDisable.push({
                        id_agendamento: dr.id_agendamento,
                        id_servico: dr.id_servico,
                        tempo_servico: dr.tempo_servico,
                        hora: item?.hora,
                        livre: false,
                    });
                }
            });
        });

        verificarHorario(_hours, _horariosReservadosForDisable);

        setHours(_hours);
        setDaySelected(day);
        setHourSelected(null);
    }

    function verificarDisponibilidadeFutura(hours, horaSelecionada, tempoServico) {
        const duracaoServicoMinutos = converterDuracaoParaMinutos(tempoServico);
        const horaSelecionadaMinutos = converterParaMinutos(horaSelecionada);
        const horaFinalAgendamento = horaSelecionadaMinutos + duracaoServicoMinutos;
    
        // Verifica se a hora final do agendamento ultrapassa o tamanho de hours
        if (horaFinalAgendamento > converterParaMinutos(hours[hours.length - 1].hora)) {
            return false;
        }
    
        let dentroDoIntervalo = false;
        let depoisDoIntervalo = false;
        
        for (let i = 0; i < hours.length; i++) {
            const horaAtualMinutos = converterParaMinutos(hours[i].hora);
    
            if (horaAtualMinutos === horaSelecionadaMinutos) {
                dentroDoIntervalo = true;
            }
    
            if (dentroDoIntervalo) {
                if (horaAtualMinutos >= horaSelecionadaMinutos && horaAtualMinutos < horaFinalAgendamento) {
                    if (!hours[i].livre) {
                        return false;
                    }
                } else {
                    dentroDoIntervalo = false;
                    depoisDoIntervalo = true;
                }
            }
    
            if (depoisDoIntervalo && horaAtualMinutos >= horaFinalAgendamento) {
                break;
            }
    
            if (depoisDoIntervalo && !hours[i].livre) {
                return false;
            }
        }
    
        return true;
    }

    const handleHourSelected = (hour) => {
        let valid = verificarDisponibilidadeFutura(hours, hour.hora, reserva?.tempo_servico);
        if(valid){
            setHourSelected(hour);
        }else{
            Utils.notify("error", `Não há horários disponíveis para este serviço, que tem a duração de ${reserva?.tempo_servico}. Por favor, escolha outro dia ou horário.`)
        }
    }

    const handleModalCancel = () => {
        setShowModalCancel(true);
    }

    const handleModalConfirmCancel = async () => {
        let responseCancelamento = await Api.cliente.cancelarAgendamento({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            id_agendamento: reserva?.id_agendamento
        })
        if(responseCancelamento?.data?.data){
            Utils.notify("success", responseCancelamento?.data?.message);
            onFetchReservations();
            setOpen(false);
        }else{
            Utils.notify("error", responseCancelamento?.data?.message);
        }
    }

    const onCloseCallback = () => {
        setShowSettings(false);
        setShowModalCancel(false);
    }

    const onCloseReservationEditCallback = () => {
        handleAgendar();
        setColaboradorOpenSelect(false);
        setColaboradorSelected(null);
    }

    const handleViewCancelPolitcs = () => {

    }

    const getDateSelectedFormated = () => {
        let dia = (Number(daySelected?.dia) < 10 ? '0'+daySelected?.dia : daySelected?.dia);
        let mes = (Number(daySelected?.mes) < 10 ? '0'+daySelected?.mes : daySelected?.mes);
        let ano = daySelected?.ano;
        return dia+'/'+mes+'/'+ano;
    }

    const handleConfirmEdit = async () => {
        
        if(colaboradorSelected == null) {
            Utils.notify("error", "Selecione o profissional.");
            return;
        }

        if(daySelected == null){
            Utils.notify("error", "Selecione o dia do agendamento.");
            return;
        }

        if(hourSelected == null){
            Utils.notify("error", "Selecione a hora do agendamento.");
            return;
        }

        //se for o mesmo cliente pode efeturar o update

        const data = {
            id_agendamento: reserva?.id_agendamento,
            id_colaborador: colaboradorSelected?.id_colaborador,
            data_agendamento: getDateSelectedFormated(),
            hora_agendamento: hourSelected?.hora,
            id_parceiro: reserva?.id_parceiro,
        };

        let agendamentoResponse = await Api.cliente.remarcarHorario({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            data: data
        })

        if(agendamentoResponse?.data?.data){
            Utils.notify("success", agendamentoResponse?.data?.message)
            onFetchReservations();
            setShowModalEditReservation(false);

        }else{
            Utils.notify("error", agendamentoResponse?.data?.message)
        }

    }

    return (
        <>
            <Modal show={showModalEditReservation} setShow={setShowModalEditReservation} onCloseCallback={onCloseReservationEditCallback}>
                <div className='mb-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                    <b>Remarcação de reserva</b>
                </div>

                <div className='mb-1' style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <b>{reserva?.nome_servico} - {Utils.converterHoraPorExtenso(reserva?.tempo_servico)}</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div onClick={handleOpenSelectColaborador} style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img 
                            src={colaboradorSelected?.foto != undefined ? Environment.API_IMAGE +"/imagem/"+ colaboradorSelected?.foto : "../placeholder-image.png"} 
                            style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px'}}/>&nbsp;
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <label style={{fontSize: '0.8rem'}}>Profissional</label><br/>
                                <b>{colaboradorSelected != null ? (
                                    <>
                                        {colaboradorSelected?.nome}
                                    </>
                                ) : (`Selecionar...`)}</b>
                            </div>
                        </div>
                    </div>
                    <ion-icon name={colaboradorOpenSelect ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                </div>
                {colaboradorOpenSelect ? (
                    <Card style={{background: "#fff", border: 'solid 1px #ddd', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', marginTop: '-4px', maxHeight: '180px', overflowY: 'auto'}}>
                        <ul style={{listStyle: 'none'}}>
                            {colaboradores?.map((colaborador, index) => (
                                <div key={index}>
                                    <li className='li-colaborador' onClick={() => {handleSelectedColaborador(colaborador)}}>
                                        <img src={colaborador?.foto != undefined ? Environment.API_IMAGE +"/imagem/"+ colaborador?.foto : "../placeholder-image.png"} style={{width: '45px', height: '45px', objectFit: 'cover', borderRadius: '4px'}}/>&nbsp;
                                        <b>{colaborador?.nome}</b>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </Card>
                ) : (null)}

                <div className='mb-1 mt-2'>
                    <div className="wrapper">
                        <ul className="items-category items-days">
                            {days?.map((ag, index) => (
                                <li key={index} className="item-agenda" style={{background: ag?.id == daySelected?.id ? '#000000' : '#f5f5f5', color: ag?.id == daySelected?.id ? 'white' : 'black'}} onClick={() => {handleDayClick(ag)}}>
                                    <div className='text-center' style={{marginBottom: '5px'}}>
                                        <b style={{fontSize: '1.2rem'}}>{ag.dia}</b>
                                    </div>
                                    <div className='text-center'>
                                    <b>{ag.diaSemana}</b>
                                    </div>
                                </li>
                            ))}  
                        </ul>
                    </div>
                </div>

                {daySelected ? (
                    <>
                        {hours?.length <= 0 ? (
                            <div className='mt-2' style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%'}}>
                                <ion-icon name="information-circle-outline"></ion-icon>
                                <b>Nenhum horário disponível.</b>
                            </div>
                        ) : (null)}
                        
                        <div className='mb-1 mt-1'>
                            <div className={`wrapper`}>
                                <ul className="items-category items-hours">
                                    {hours?.length > 0 && hours?.map((h, index) => (
                                        h?.livre ? (
                                            <div style={{background: h?.id == hourSelected?.id ? '#000000' : '#f5f5f5', color: h?.id == hourSelected?.id ? 'white' : 'black'}} className={`hora-button item-hour ${h.hora.replace(":", '-')}`} onClick={() => {handleHourSelected(h)}}>{h?.hora}</div>
                                        ) : (
                                            <div style={{background: '#ff34344d', color: 'black', textDecoration: 'line-through'}} className={`hora-button item-hour ${h.hora}`}>{h?.hora.replace(":", '-')}</div>
                                        )
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <Button className={'mt-2'} onClick={handleConfirmEdit} style={{width: '100%'}}><b>Confirmar remarcação</b></Button>
                    </>
                ) : (null)}

            </Modal>
            <Modal setShow={setShowModalCancel} show={showModalCancel} onCloseCallback={onCloseCallback}>
                <div className='mb-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <b>Confirmar cancelamento de reserva?</b>
                </div>
                {reserva?.status_pagamento == 'A' ? (
                    <></>
                ) : (
                    Utils.checkMinutesDate(reserva?.ts_registro, 30) ? 
                    (reserva?.metodo_pagamento == "SIGNATURE" || reserva?.metodo_pagamento == "BONUS") ? (null) : (
                        <p>Ao cancelar a reserva 50% do valor será transferido á você e outros 50% para o parceiro (<b>{(Number(reserva?.total) * 0.5)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>).</p>  
                    )
                    :
                    (reserva?.metodo_pagamento == "SIGNATURE" || reserva?.metodo_pagamento == "BONUS") ? (null) : (
                        <p>Ao cancelar a reserva seu valor será estornado como bônus, para usar como quiser no app (<b>{(Number(reserva?.total))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>).</p>  
                    )
                )}
                <div style={{display: 'flex', alignItems: 'center'}} className='mt-1' onClick={handleViewCancelPolitcs}>
                    <ion-icon name="newspaper-outline"></ion-icon>&nbsp;
                    <span style={{textDecoration: 'underline'}}>veja nossa política de cancelamento</span>
                </div>
                <div className='mt-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Button style={{color: '#ff2e36', background: '#fceaeb', height: '40px',}} onClick={onCloseCallback}>&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>&nbsp;
                    <Button style={{height: '40px'}} onClick={handleModalConfirmCancel}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <Modal show={showModalQrCode} setShow={setShowModalQrCode}>
                <QRCode
                    size={50}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={reserva?.codigo_agendamento}
                    viewBox={`0 0 256 256`}
                />
            </Modal>
            <Card className={`mb-1 ${className}`} style={style}> 
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={handleClick}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img 
                            src={Environment.API_IMAGE + "/imagem/" + reserva?.logo_parceiro || "../placeholder-image.png"}
                            style={{width: '20px', height: '20px', objectFit: 'cover', borderRadius: '50%'}} 
                        />
                        <b style={{fontSize: '0.90rem'}}>&nbsp;{reserva?.nome_parceiro}</b>
                    </div>
                    
                    {reserva?.status_agendamento == 'C' ? (
                        <Ship dotStyle={'red'}>
                            cancelado
                        </Ship>
                    ) : (

                        <>
                            {reserva?.solicitacao_cancelamento == 1 ? (
                                <Ship dotStyle={'orange'}>
                                    cancelamento solicitado
                                </Ship>
                            ) : (
                                <Ship dotStyle={reserva?.status_pagamento == 'A' ? `orange` : reserva?.status_pagamento == 'P' ? `green` : 'red'}>
                                    {reserva?.status_pagamento == 'A' ? `aguardando pagamento` : reserva?.status_pagamento == 'P' ? `pago` : 'cancelado'}
                                </Ship>
                            )}

                        </>

                    )}

                    {reserva?.status_pagamento == 'P' && reserva?.solicitacao_cancelamento == 0 ? (
                        Utils.verifyPastDate(reserva?.data_agendamento) ? (
                            <b style={{color: 'gray', fontSize: '9pt'}}>{reserva?.executado == 0 ? '• expirado' : '• finalizado'}</b>
                        ) : (
                            <b style={{color: 'gray', fontSize: '9pt'}}>{reserva?.executado == 0 ? '• a executar' : '• finalizado'}</b>
                        )
                    ) : (null)}
                    &nbsp;<ion-icon style={{fontSize: '1rem'}} name={open ? "chevron-up" : "chevron-down"}></ion-icon>
                </div>
                {open ? (
                    <>
                        <div className='mt-2' style={{border: 'solid rgba(0,0,0, 0.05) 1px', borderRadius: '4px', padding: '8px'}}>
                            <div style={{position: 'absolute', marginTop: '-20px'}}>
                                <label style={{background: '#fff'}}>Dados do agendamento</label>
                            </div>
                            <div className='mb-05 mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="location-outline"></ion-icon>&nbsp;
                                    <b>Local</b>
                                </div>
                                <div>
                                    <span>{reserva?.tipo_agendamento == "P" ? reserva?.nome_parceiro : 'Minha localização'}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="cut-outline"></ion-icon>&nbsp;
                                    <b>Serviço</b>
                                </div>
                                <div style={{textAlign: 'right'}}>
                                    <span>{reserva?.nome_servico}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="person-outline"></ion-icon>&nbsp;
                                    <b>Profissional</b>
                                </div>
                                <div>
                                    <span>{reserva?.nome_colaborador}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="calendar-outline"></ion-icon>&nbsp;
                                    <b>Data</b>
                                </div>
                                <div>
                                    <span>{reserva?.data_agendamento}</span>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="time-outline"></ion-icon>&nbsp;
                                    <b>Hora</b>
                                </div>
                                <div>
                                    <span>{reserva?.hora_agendamento}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className='mt-2' style={{border: 'dashed rgba(0,0,0, 0.1) 1px', borderRadius: '4px', padding: '8px'}}>
                            <div style={{position: 'absolute', marginTop: '-20px'}}>
                                <label style={{background: '#fff'}}>Código do agendamento</label>
                            </div>
                            <div className='mb-05 mt-1' style={{display: 'flex', alignItems: 'center'}} onClick={() => {setShowModalQrCode(true)}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <QRCode
                                        size={50}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={reserva?.codigo_agendamento}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>&nbsp;&nbsp;
                                <div>
                                    <b>Código: {reserva?.codigo_agendamento}</b><br/>
                                    <div style={{fontSize: '0.80rem'}}>{Utils.converterDate(reserva?.ts_registro)}</div>
                                </div>
                            </div>
                        </div>

                        <SpaceBox space={10}/>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <b>Pagamento</b>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {reserva.metodo_pagamento == "PIX" ? (
                                    <img src={`../pix_icon.png`} style={{width: '15px'}}/>
                                ) : reserva.metodo_pagamento == "CARD" ? (
                                    <ion-icon name="card-outline"></ion-icon>
                                ) : reserva.metodo_pagamento == "MONEY" ? (
                                    <ion-icon name="cash-outline"></ion-icon>
                                ) : reserva.metodo_pagamento == "BONUS" ? (
                                    <ion-icon name="cash-outline"></ion-icon>
                                ) : (<ion-icon name="diamond-outline"></ion-icon>)}
                                &nbsp;<b>{reserva.metodo_pagamento == "PIX" ? 'Pix' : reserva.metodo_pagamento == "CARD" ? 'Cartão' : reserva.metodo_pagamento == "MONEY" ? 'Dinheiro' : reserva?.metodo_pagamento == "BONUS" ? "Bônus" : 'Assinatura'}</b>
                            </div>
                        </div>
                        
                        {reserva?.metodo_pagamento == "SIGNATURE" ? (
                            <div className='totals'>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Subtotal</b>
                                    <b style={{textDecoration: 'line-through'}}>{Number(reserva?.subtotal)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Desconto</b>
                                    <b>-{Number(reserva?.desconto)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Total</b>
                                    <b style={{color: 'green'}}>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </div>
                            </div>
                        ) : (
                            <div className='totals'>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Subtotal</b>
                                    <b style={{textDecoration: reserva.metodo_pagamento == "BONUS" ? 'line-through' : undefined}}>{Number(reserva?.subtotal)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Desconto</b>
                                    {reserva.metodo_pagamento == "BONUS" ? (
                                        <b>-{Number(reserva?.desconto)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    ) : (
                                        <b>{Number(reserva?.desconto)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    )}
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Total</b>
                                    {reserva.metodo_pagamento == "BONUS" ? (
                                        <b style={{color: 'green'}}>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    ) : (
                                        <b style={{color: 'green'}}>{Number(reserva?.total)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    )}
                                </div>
                            </div>
                        )}
                        
                        {reserva?.status_agendamento == 'A' ? (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                {reserva?.local_pagamento == 'P' ? (
                                    <>
                                        <Button className={'shadow'} style={{display: "flex", alignItems: 'center', background: '#f5f5f5', color: 'black'}}>&nbsp;&nbsp;<ion-icon name="ellipsis-horizontal-outline"></ion-icon>&nbsp;&nbsp;</Button>&nbsp;
                                        <Button style={{width: '100%'}} onClick={handleConfirm}><b>Confirmar presença</b></Button>
                                    </>
                                ) : (
                                    <>
                                        {(reserva?.status_pagamento == 'P' || reserva?.status_pagamento == 'A') && reserva?.executado != 1 && reserva?.solicitacao_cancelamento == 0 ? (
                                            <>
                                                <Button onClick={() => {setShowSettings(true)}} className={'shadow'} style={{display: "flex", alignItems: 'center', background: '#f5f5f5', color: 'black'}}>&nbsp;&nbsp;<ion-icon name="ellipsis-horizontal-outline"></ion-icon>&nbsp;&nbsp;</Button>&nbsp;
                                            </>
                                        ) : (null)}
                                        {showSettings ? (
                                            <>
                                                <div onClick={onClickBackdrop} style={{position: 'fixed', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0)', top: '0', left: '0', zIndex: 999}}></div>
                                                <div onClick={handleContentClick}>
                                                    <Card style={{zIndex: 9999, position: 'absolute', width: '200px', marginTop: '5px', border: 'solid 1px #ddd', marginLeft: '-40px'}}>
                                                        <Button onClick={handleModalCancel} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#ff2e36', background: '#fceaeb', height: '40px', width: '100%'}}>
                                                            <ion-icon name="close-circle"></ion-icon>&nbsp;
                                                            <b>Cancelar</b>
                                                        </Button>
                                                    </Card>
                                                </div>
                                            </>
                                        ) : (null)}
                                        {reserva?.solicitacao_cancelamento == 0 ?(
                                            <>
                                                {reserva?.status_pagamento == 'A' && reserva?.executado == 0 ? (
                                                    <Button className={'mt-1'} style={{width: '100%'}} onClick={handlePayment}><b>Fazer pagamento</b></Button>
                                                ) : (null)}
                                                {reserva?.status_pagamento == 'P' && reserva?.executado == 0 ? (
                                                    <Button className={'mt-1'} style={{width: '100%'}} onClick={handleChange}><b>Remarcar reserva</b></Button>
                                                ) : (null)}
                                            </>
                                        )
                                        : (null) }
                                    </>
                                )}
                            </div>
                        ) : (null)}
                        
                    </>
                ) : (null)}
            </Card>
        </>
    )
}
