import React, { useEffect, useState, useRef } from 'react';
import { Container, ParceiroList } from '../../components';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Api from '../../Api';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';

export default () => {
    
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("");
    const [categorySearchTerm, setCategorySearchTerm] = useState();
    const [parceiros, setParceiros] = useState([]);
    const [coords, setCoords] = useState(null);

    useEffect(() => {
        setLoading(false);
        CoordsProviderHelper((coords) => {
            setCoords(coords);
            if(localStorage.getItem("tk_beauty_search_by_category")){
                const setup = async () => {
                    let term = localStorage.getItem("tk_beauty_search_by_category");
                    requestSearch(term, coords);
                    localStorage.removeItem("tk_beauty_search_by_category");
                }
                setup();
            } else {
                inputRef?.current?.focus();
            }
            setLoading(true);
        })
    }, [])

    const handleGoBack = () => {
        navigate(-1);
    }

    const requestSearch = async (term, coords) => {

        setCategorySearchTerm(term);

        setText("Carregando...");

        if(term?.trim()?.length <= 0){
            term = "null";
        }
        
        if(coords){
            let parceirosResponse = await Api.geral.parceirosSearch({
                lat: coords?.latitude,
                lon: coords?.longitude,
                filtro: term,
            })
            setParceiros(parceirosResponse?.data?.data);
            if(parceirosResponse?.data?.data?.length <= 0){
                setText("Nenhum resultado encontrado...");
            }
        }

    }
    
    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <ion-icon name="chevron-back-outline" onClick={handleGoBack} style={{color: "black", fontSize: '2.3rem'}}></ion-icon>
                <div style={{background: "#fff", border: 'solid 1px #ddd', borderRadius: '16px', padding: '8px', width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{overflow: 'hidden', flex: '0.99'}}> 
                        {localStorage.getItem("tk_beauty_search_by_category") == null ? (
                            <input ref={inputRef} type='text' placeholder='Qual serviço você precisa?' style={{width: '100%', border: 'none', fontSize: '1rem'}} onChange={e => requestSearch(e.target.value, coords)} value={categorySearchTerm} />
                        ):(
                            <input type='text' placeholder='Qual serviço você precisa?' style={{width: '100%', border: 'none', fontSize: '1rem'}} onChange={e => requestSearch(e.target.value, coords)} value={categorySearchTerm} />
                        )}
                    </div>
                    <ion-icon name="search-outline" style={{ fontSize: "1.5rem"}}></ion-icon>
                </div>
            </div>
            {loading ? (
                <div className='search-results'>
                    {parceiros?.length > 0 ? (
                        <>
                            <ParceiroList parceiros={parceiros} hideText hideFilter/>
                        </>
                    ) : (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <b className='mt-3' style={{color: 'gray'}}>{text}</b>
                        </div>
                    )}
                </div>
            ) : (null)}
        </Container>
    )
}