import React, { useContext, useEffect, useState } from 'react';
import {Container, ParceiroProductList, HeaderWithBack, SpaceBox} from '../../components';
import './style.css';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';

export default () => {

    const {user, parceiros, produtos, coords} = useContext(MainContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <HeaderWithBack title={"Marketplace"}/>
            {loading == true ? (
                <ParceiroProductList parceiros={parceiros} produtos={produtos} useFilter={false} useMarginBottomLarge={true}/>
            ) : (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                    <b style={{color: 'gray'}}>Carregando...</b>
                </div>
            )}
            <SpaceBox space={80}/>
        </Container>
    )
}
