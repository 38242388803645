import React, { useEffect, useState } from 'react'
import { Button, Card, FragmentView, Input, Parceiro, Ship, SpaceBox } from '../../components'
import Utils from '../../Utils';
import Api from '../../Api';


export default () => {

    const [loading, setLoading] = useState(false);
    const [assinaturas, setAssianturas] = useState([]);

    useEffect(() => {
        const requests = async () => {
            let response = await Api.cliente.assinaturasByClienteTodos({
                forceToken: localStorage.getItem("tk_beauty_cliente_token"), 
            });
            let _assinaturas = response?.data?.data;
            setAssianturas(_assinaturas);
            setLoading(true);
        }
        requests();
    }, [])

    return (
        <FragmentView title={"Minhas Assinaturas"}>
            {loading > 0 ? (
                assinaturas.length > 0 ? (
                    assinaturas?.map((assinatura, index) => (
                        <>
                            <Card className={"mb-1"}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="diamond-outline" style={{color: "black"}}></ion-icon>
                                        <b style={{fontSize: '0.90rem'}}>&nbsp;{assinatura?.nome_assinatura}</b>
                                    </div>
                                    
                                    {assinatura?.status == "R" ? (
                                        <Ship dotStyle={'orange'}>
                                            Processando pagamento
                                        </Ship>
                                    ) : assinatura?.status == "C" ? (
                                        <Ship dotStyle={'red'}>
                                            cancelado
                                        </Ship>
                                    ) : (
                                        <Ship dotStyle={'green'}>
                                            Ativo
                                        </Ship>
                                    )}

                                </div>
                                <SpaceBox space={10}/>
                                <Parceiro key={index} parceiro={assinatura}/>
                                <SpaceBox space={10}/>
                                <div>
                                    <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <b>Ciclo:</b>
                                        </div>
                                        <div>
                                            <span>{assinatura?.ciclo == "M" ? "Mensal" : assinatura?.ciclo == "S" ? "Semestral" : "Anual"}</span>
                                        </div>
                                    </div>
                                    <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <b>Valor:</b>
                                        </div>
                                        <div>
                                            <span>{Number(assinatura?.preco)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Button style={{width: '100%'}}><b>Detalhes</b></Button>
                                </div>
                            </Card>
                        </>
                    ))
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                        <b style={{color: 'gray'}}>Não há assinaturas.</b>
                    </div>
                )
            ) : (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                    <b style={{color: 'gray'}}>Carregando...</b>
                </div>
            )}
        </FragmentView>
    )
}
