import React, { useEffect } from 'react';
import './style.css';
import HeaderWithBack from '../HeaderWithBack';
import Container from '../Container';

export default ({title, children, onHanderAction, actionIconComponent}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container>
      <HeaderWithBack title={title} onHanderAction={onHanderAction} actionIconComponent={actionIconComponent}/>
      <div className='mt-1 mb-2'></div>
      {children}
    </Container>
  )
}
