import React, { useState } from 'react'
import { Button, Card, FragmentView, Input } from '../../components'
import Utils from '../../Utils';
import Api from '../../Api';

export default () => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const save = async () => {
        if(oldPassword?.trim()?.length <= 0){
            Utils.notify("error", "Digite sua senha antiga.");
            return;
        }
        if(newPassword?.trim()?.length <= 0){
            Utils.notify("error", "Digite sua nova antiga.");
            return;
        }
        if(confirmPassword?.trim()?.length <= 0){
            Utils.notify("error", "Confirme sua nova senha.");
            return;
        }
        if(newPassword != confirmPassword){
            Utils.notify("error", "As senhas não se coincidem.");
            return;
        }

        let response = await Api.cliente.editPassword({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            newPassword: newPassword,
            oldPassword: oldPassword
        })

        Utils.processRequest(response, (data) => {
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
        }, (data) => {}, true)
    }

    return (
        <FragmentView title={"Alterar Senha"}>
            <Card>
                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                    <ion-icon name="shield-checkmark-outline"></ion-icon>&nbsp;
                    <b>Alterar senha</b>
                </div>
                <Input type="password" label={"Senha antiga"} value={oldPassword} setValue={setOldPassword} />
                <Input type="password" label={"Nova senha"} value={newPassword} setValue={setNewPassword} />
                <Input type="password" label={"Confirmar senha"} value={confirmPassword} setValue={setConfirmPassword} />
                <Button style={{width: '100%'}} onClick={save}>Salvar</Button>
            </Card>
        </FragmentView>
    )
}
