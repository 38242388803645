import React from 'react'
import { Button, Card, Container } from '../../components'

export default () => {
    return (
        <Container>
            <Card>
                <h4>Qual seu email?</h4>
                <input type='email' placeholder='Digite seu email'/>
                <Button>Excluir conta e dados</Button>
            </Card>
        </Container>
    )
}
