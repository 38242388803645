import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, FlatButton, FragmentView, Input, Modal, Option, ProductItem, Select, SpaceBox } from '../../components'
import './style.css';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import Utils from '../../Utils';
import Environment from '../../Environment';
import ImageCompressor from 'image-compressor';
import { useNavigate } from 'react-router-dom';

export default () => {

    const {user} = useContext(MainContext);

    const navigate = useNavigate();

    const [showBalances, setShowBalances] = useState(localStorage.getItem("tk_beauty_parceiro_show_balances") == 'true');

    const [indicadores, setIndicadores] = useState(null);

    const [loading, setLoading] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [search, setSearch] = useState("");
    const [mode, setMode] = useState(null);
    const [produtoSelected, setProdutoSelected] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [ativo, setAtivo] = useState(true);
    const [nome, setNome] = useState("");
    const [preco, setPreco] = useState("");
    const [descricao, setDescricao] = useState("");
    const [estado, setEstado] = useState("");
    const [altura, setAltura] = useState("");
    const [largura, setLargura] = useState("");
    const [comprimento, setComprimento] = useState("");
    const [peso, setPeso] = useState("");
    const [estoque, setEstoque] = useState("");

    useEffect(() => {
        requestIndicadores();
        requestProdutos();
    }, [])

    const requestIndicadores = async () => {
        let response = await Api.parceiro.getPedidosIndicadores({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro
        })
        let _indicadores = response?.data?.data;
        setIndicadores(_indicadores);
    }

    const requestProdutos = async () => {
        let response = await Api.colaborador.produtos({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro
        })
        let _produtos = response?.data?.data;
        setProdutos(_produtos);
        setLoading(true);
    } 

    const modalProduto = () => {
        setMode("CREATE");
        setProdutoSelected(null);
        setShow(true);
    }

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
    
        // Comprimir a imagem
        try {
            const compressedFile = await new ImageCompressor(file, {
                quality: 0.5, // Ajuste a qualidade conforme necessário
                maxWidth: 200, // Ajuste o tamanho máximo conforme necessário
                maxHeight: 200, // Ajuste o tamanho máximo conforme necessário
                convertSize: 5000000, // Limite de tamanho do arquivo em bytes (neste caso, 5MB)
            });
    
            setImageFile(compressedFile);
        } catch (error) {
            console.error(error);
            setImageFile(file); // Em caso de erro, use o arquivo original
        }
    };

    const requestSearch = async (term) => {
        setSearch(term);
    }

    const getProductBySearch = () => {
        if(search?.trim()?.length <= 0){
            return produtos;
        }else{
            return produtos.filter(p => {return p?.nome?.toLowerCase().includes(search.toLowerCase())})
        }
    }

    const onCloseCallback = () => {
        setAtivo(true);
        setImageFile(null);
        setNome("");
        setPreco("");
        setDescricao("");
        setEstado("");
        setAltura("");
        setLargura("");
        setComprimento("");
        setPeso("");
        setEstoque("");
        setProdutoSelected(null)
    }

    const saveProduto = async () => {
        if(nome?.trim()?.length <= 0){
            Utils.notify("error", "Digite o nome do produto.");
            return;
        }

        if(preco?.trim()?.length <= 0){
            Utils.notify("error", "Digite o preço do produto.");
            return;
        }

        if(descricao?.trim()?.length <= 0){
            Utils.notify("error", "Digite a descrição do produto.");
            return;
        }

        if(estado?.trim()?.length <= 0){
            Utils.notify("error", "Selecione o estado do produto.");
            return;
        }
        
        if(mode == "CREATE"){
            if (imageFile && !Utils.isImageValid(imageFile)) {
                return;
            }
        }else{
            if(!imageFile){
                Utils.notify("error", "Selecione uma imagem.");
            }
        }

        let request = null;

        switch(mode){
            case "CREATE":
                request = await Api.colaborador.addProduto({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    id_parceiro: user?.id_parceiro,
                    ativo: ativo ? 1 : 0,
                    nome: nome,
                    imagem: imageFile,
                    preco: Utils.converterMoedaParaNumero(preco),
                    descricao: descricao,
                    estado: estado,
                    altura: altura,
                    largura: largura,
                    comprimento: comprimento,
                    peso: peso,
                    estoque: estoque
                });
                break;
            case "UPDATE":
                request = await Api.colaborador.updateProduto({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    id_parceiro: produtoSelected?.id_parceiro,
                    id_produto: produtoSelected?.id_produto,
                    ativo: ativo ? 1 : 0,
                    nome: nome,
                    imagem: imageFile,
                    preco: Utils.converterMoedaParaNumero(preco),
                    descricao: descricao,
                    estado: estado,
                    altura: altura,
                    largura: largura,
                    comprimento: comprimento,
                    peso: peso,
                    estoque: estoque
                });
                break;
        }

        Utils.processRequest(request, (data) => {
            if(mode == "CREATE"){
                Utils.notify("success", "Produto cadastrado com sucesso.");
            }else{
                Utils.notify("success", "Produto atualizado com sucesso.");
            }
            requestProdutos();
            onCloseCallback();
            setShow(false);
        }, (error) => {});
    }

    const openModalEdit = async (produto) => {
        setShow(true);
        setMode("UPDATE");
        setProdutoSelected(produto);
        setAtivo(produto?.ativo == 1);
        setNome(produto?.nome);
        setPreco(Number(produto?.preco)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
        setDescricao(produto?.descricao);
        setEstado(produto?.estado);
        setAltura(produto?.altura);
        setLargura(produto?.largura);
        setComprimento(produto?.comprimento);
        setPeso(produto?.peso);
        setEstoque(produto?.estoque);
        setImageFile(await Utils.fetchImage(Environment.API_IMAGE+'/imagem/'+produto.imagem))
    }

    const handleDeleteProduto = (produto) => {
        setShowDelete(true);
        setProdutoSelected(produto);
    }

    const handleDeleteProdutoOk = async () => {
        let request = await Api.colaborador.deleteProduto({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro,
            id_produto: produtoSelected?.id_produto
        })
        Utils.processRequest(request, (data) => {
            Utils.notify("success", "Produto excluído com sucesso!");
            requestProdutos();
            onCloseCallback();
            setShowDelete(false);
        }, (error) => {});
    }

    const handleGoPedidos = () => {
        navigate('/adm-pedidos');
    }

    const convertMoney = (str) => {
        if(str == 0) {
            return loading ? Number(str)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) :  "..." 
        }
        return Number(str)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }

    return (
        <>
            <FlatButton text={"Adicionar"} onClick={modalProduto}>+</FlatButton>
            <Modal setShow={setShow} show={show} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>{mode == "CREATE" ? "Adicionar produto" : "Atualizar produto"}</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>{mode == "CREATE" ? "Adicione" : "Atualize"} seus produtos.</div>
                    </div>
                </div>
                <div className='mb-1 mt-1' style={{height: 'calc(100vh - 230px)', overflow: 'auto'}}>
                    <div className='mb-1' style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <Input type='file' accept="image/*" id='imagem' onChange={handleImageChange} hidden/>
                        <label htmlFor='imagem'>
                            <div className='shadow' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray', border: 'solid 1px gray', borderRadius: '50%', padding: imageFile ? '1px' : '8px', width: '70px', height: '70px'}}>
                                {imageFile ? <img src={URL.createObjectURL(imageFile)} alt="Imagem selecionada" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}} /> : <ion-icon name="image-outline" size={"large"}></ion-icon>}  
                            </div>
                        </label>
                        <label htmlFor='imagem'>
                            <b style={{color: 'gray'}}>&nbsp;&nbsp;Foto do produto</b>
                        </label>
                    </div>
                    <Checkbox flexStart setChecked={setAtivo} checked={ativo}>Ativo</Checkbox>
                    <Card className='mb-1'>
                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                            <ion-icon name="information-circle-outline"></ion-icon>&nbsp; 
                            <b>Informações</b>
                        </div>
                        <Input type="text" label="Nome do produto" value={nome} setValue={setNome}/>
                        <Input type="moeda" label="Preço do produto (R$)" value={preco} setValue={setPreco}/>
                        <Input type="textarea" label="Descrição do produto" value={descricao} setValue={setDescricao}/>
                        <Select setValue={setEstado} value={estado} label={"Qual estado do produto?"}>
                            <Option value={""}>Selecione...</Option>
                            <Option value={"N"}>Novo</Option>
                            <Option value={"U"}>Usado</Option>
                        </Select>
                    </Card>
                    <Card className='mb-1'>
                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                            <ion-icon name="options-outline"></ion-icon>&nbsp; 
                            <b>Especificações (opcional)</b>
                        </div>
                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                            <Input hideInputBoxMargin setValue={setAltura} value={altura} type="number" label="Altura *cm" style={{width: '100%'}}/>&nbsp;&nbsp;
                            <Input hideInputBoxMargin setValue={setLargura} value={largura} type="number" label="Largura *cm" style={{width: '100%'}}/>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Input hideInputBoxMargin setValue={setComprimento} value={comprimento} type="number" label="Comprimento *cm" style={{width: '100%'}}/>&nbsp;&nbsp;
                            <Input hideInputBoxMargin setValue={setPeso} value={peso} type="number" label="Peso *kg" style={{width: '100%'}}/>
                        </div>
                    </Card>
                    <Card className='mb-1'>
                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                            <ion-icon name="cube-outline"></ion-icon>&nbsp; 
                            <b>Estoque</b>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', background: '#f5f5f5', borderRadius: '16px', padding: '4px'}}>
                            <ion-icon size={"large"} style={{color: 'gray'}} name="alert-circle-outline"></ion-icon>&nbsp;
                            <label style={{color: '#000', fontSize: '10pt'}}>* Deixe o campo em branco para <b>desativar</b> o controle de estoque.</label>
                        </div>
                        <Input type="number" setValue={setEstoque} value={estoque} label="Estoque (opcional)" style={{width: '100%'}}/>
                    </Card>
                </div>
                <div>
                    <Button onClick={saveProduto} style={{width: '100%'}}><b>Salvar</b></Button>
                </div>
            </Modal>
            <Modal show={showDelete} setShow={setShowDelete} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <b>Confirmar exclusão do produto?</b>
                </div>
                <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Button style={{color: '#ff2e36', background: '#fceaeb', height: '40px',}} onClick={() => {setShowDelete(false)}} >&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>
                    <Button style={{height: '40px'}} onClick={handleDeleteProdutoOk}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <FragmentView title={"Marketplace"} onHanderAction={{action: handleGoPedidos, actionIconComponent: <ion-icon name="eye-outline"/>,  text: "Ver Pedidos"}}>
                {loading ? (
                    <>
                        <div className='mb-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {user?.role  == 'A' ? (
                                Utils?.mobileCheck() ? (
                                    <>
                                        <div style={{width: '100%'}}>
                                            <Card className={'shadow mb-1'} style={{width: '100%', borderTop: 'solid 3px #24a028'}}>
                                                <div>
                                                    <span style={{fontSize: '10pt'}}>Vendas hoje</span>
                                                </div>
                                                <div>
                                                    <b style={{fontSize: '110%'}}>{showBalances ? convertMoney(indicadores?.total_hoje || 0) : 'R$ ****'}</b>
                                                </div>
                                            </Card>
                                            <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #4a90e2'}}>
                                                <div>
                                                    <span style={{fontSize: '10pt'}}>Vendas totais</span>
                                                </div>
                                                <div>
                                                    <b style={{fontSize: '110%'}}>{showBalances ? convertMoney(indicadores?.total || 0) : 'R$ ****'}</b>
                                                </div>
                                            </Card>
                                        </div>&nbsp;&nbsp;
                                        <div style={{width: '100%'}}>
                                            <Card className={'shadow mb-1'} style={{width: '100%', borderTop: 'solid 3px #d49730'}}>
                                                <div>
                                                    <span style={{fontSize: '10pt'}}>Vendas pendentes</span>
                                                </div>
                                                <div>
                                                    <b style={{fontSize: '110%'}}>{showBalances ? indicadores?.pendentes || "..." : 'R$ ****'}</b>
                                                </div>
                                            </Card>
                                            <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px red'}}>
                                                <div>
                                                    <span style={{fontSize: '10pt'}}>Vendas canceladas</span>
                                                </div>
                                                <div>
                                                    <b style={{fontSize: '110%'}}>{showBalances ? indicadores?.cancelados || "..." : '****'}</b>
                                                </div>
                                            </Card>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #24a028'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Vendas hoje</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? convertMoney(indicadores?.total_hoje || 0) : 'R$ ****'}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #4a90e2'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Vendas totais</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? convertMoney(indicadores?.total || 0) : 'R$ ****'}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #d49730'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Vendas pendentes</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? indicadores?.pendentes || "..." : 'R$ ****'}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px red'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Vendas canceladas</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? indicadores?.cancelados || "..." : '****'}</b>
                                            </div>
                                        </Card>
                                    </>
                                )
                            ) : (null)}
                        </div>
                        {produtos?.length > 0 ? (
                            <>
                                <div className='mb-1'>
                                    <b>Produtos ({produtos?.length})</b>
                                </div>
                                <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{background: "#fff", border: 'solid 1px #ddd', borderRadius: '16px', padding: '8px', width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{overflow: 'hidden', flex: '0.99'}}> 
                                            <input type='text' placeholder='Pesquisar produto...' style={{width: '100%', border: 'none', fontSize: '1rem'}} onChange={e => requestSearch(e.target.value)} value={search} />
                                        </div>
                                        <ion-icon name="search-outline" style={{ fontSize: "1.5rem"}}></ion-icon>
                                    </div>
                                </div>
                                <div style={{display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(3, 1fr)', justifyContent: produtos?.length < 3 ? 'start' : 'center'}}>
                                    {getProductBySearch()?.length > 0 && getProductBySearch()?.map(produto => (
                                        <ProductItem actionViewMode produto={produto} handleDeleteProduto={handleDeleteProduto} openModalEdit={openModalEdit}/>
                                    ))}
                                </div>
                                {getProductBySearch()?.length <= 0 && search?.trim()?.length > 0 ? (
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', overflowX: 'auto'}} className='mt-1 mb-1'>
                                        <b style={{color: 'gray'}}>Nenhum resultado para '{search}'</b>
                                    </div>
                                ) : (null)}
                            </>
                        ) : (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}} className='mt-1 mb-1'>
                                <b style={{color: 'gray'}}>Nenhum produto cadastrado.</b>
                            </div>
                        )}
                    </>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                        <b style={{color: 'gray'}}>Carregando...</b>
                    </div>
                )}
                <SpaceBox space={80}/>
            </FragmentView>
        </>
    )
}
