import React, { useEffect } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Environment from '../../Environment';

export default ({id, index, imagem, nome}) => {

    const navigate = useNavigate();

    const handleCategoryClick = () => {
        if(id == null) return;

        localStorage.setItem("tk_beauty_search_by_category", nome);
        navigate("/search");
    }

    return (
        <>
            <li key={index} onClick={() => {handleCategoryClick()}} className="item-category">
                <div className='item-category-icon'>
                    <img loading="lazy" src={Environment.API_IMAGE + "/imagem/" + imagem || "placeholder-image.png"}/>
                </div>
                <div className='text-center' style={{textWrap: 'nowrap', overflow: 'hidden'}}>
                    <b className='item-category-b'>{nome}</b>
                </div>
            </li>
        </>
    )
}
