import React from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Environment from '../../Environment';

export default ({index, item}) => {

    const navigate = useNavigate();

    const handleClick = async () => {
        switch(item?.tipo_link){
            case "I": //interno
                navigate(item?.link);
                break;
            case "E": //externo
                break;
        }
    }

    return (
        <>
            <div key={index} className="item" style={{background: `url(${Environment.API_IMAGE + "/imagem/" + item?.imagem || "placeholder-image.png"})`}} onClick={handleClick}>
                {item?.id_banner != null ? (
                    <div style={{background: 'rgba(0, 0, 0, 0.5)', borderRadius: '8px', width: '100%', height: '100%', display: 'flex', alignItems: 'end'}}>
                        <div style={{paddingLeft: '5px', paddingRight: '5px', paddingBottom: '10px'}}>
                            <b style={{color: 'white', fontSize: "1.2rem"}}>{item?.titulo}</b><br/>
                            <span style={{color: 'white', fontSize: '0.95rem', fontWeight: 'normal'}}>{item?.descricao}</span>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="star" style={{color: 'orange', fontSize: '8pt'}}></ion-icon>
                                <b style={{fontSize: '8pt', color: "white"}}>5,0</b>
                            </div>
                        </div>
                    </div>
                ) : (
                    <img loading="lazy" src={item?.imagem} style={{width: '100%', height: "100%", objectFit: "cover"}} />
                )}
            </div>
        </>
    )
}
