import React, { useEffect, useState } from 'react';
import "./style.css";
import Button from '../Button';
import Utils from '../../Utils';

export default ({callback}) => {

    const [switched, setSwitched] = useState(localStorage.getItem("tk_beauty_switched") || null);

    useEffect(() => {
        setSwitched(localStorage.getItem("tk_beauty_switched"));
    }, [])

    const handleSwitched = (app) => {
        localStorage.setItem("tk_beauty_switched", app);
        setSwitched(app);
        if(callback){
            callback();
        }
    }

    return (
        switched == null ? (
            <div style={{position: 'fixed', top: '0', right: '0px', background: "#fff", width: '100%', height: "100%", backgroundSize: 'contain', zIndex: 99999, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                    <center><img src='../foto2.png' style={{width: Utils.mobileCheck() ? '100%' : '250px'}}/></center>
                    <div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <b style={{fontSize: '1.3rem'}}>Descubra sua beleza com nosso app!</b>
                        </div>
                        <div className='mt-1'>
                            <p style={{textAlign: 'center'}}>Marque horários com salões, barbearias, manicures, pedicures, massagista perto de você!</p>
                        </div>
                    </div>
                    <div className='mt-2' style={{padding: '8px'}}>
                        <Button onClick={() => {handleSwitched("cliente")}} className={'mb-1'} style={{width: '100%', fontWeight: 'bold'}}>Sou Cliente</Button>
                        <Button onClick={() => {handleSwitched("parceiro")}} className={'mb-1 shadow'} style={{width: '100%', background: '#f5f5f5', border: "solid #ddd 0.5px", color: '#000', fontWeight: 'bold'}}>Sou Parceiro</Button>
                    </div>
                </div>
            </div>
        ) : (null)
    )
}
