import React, { useContext, useEffect, useState } from 'react';
import { AccountModal, Button, Card, Container, Hr, Input } from '../';
import './style.css';
import { MainContext } from '../../helpers/MainContext';
import Environment from '../../Environment';
import { useNavigate } from 'react-router-dom';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Api from '../../Api';
import Utils from '../../Utils';

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

const startEvents = (navigate) => {
    document.querySelectorAll(".close, .backdrop").forEach(element => {
        element.addEventListener("click", () => {
            document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
            window.location.hash = '';
            navigate(-1);
        });
    });
}

export default ({parceiro, produto, handleResetComponent}) => {

    const navigate = useNavigate();

    const {user} = useContext(MainContext);
    const [accountModalShow, setAccountModalShow] = useState(false);

    const [show, setShow] = useState(false);

    const [modeSelected, setModeSelected] = useState(Number(0));
    const [addressSelected, setAddressSelected] = useState(null);
    const [addressSelectOpen, setAddressSelectOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [frete, setFrete] = useState(null);

    const [cupom, setCupom] = useState("");

    useEffect(() => {
        setModeSelected(0);
    }, [])

    useEffect(() => {
        startEvents(navigate);
        setTimeout(function () {
            document.querySelector(".bottom-sheet-wrapper").classList.add("show-modal")
            setShow(true);
        }, 100)
    }, [])

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash !== '#modal') {
                setShow(false);
                window.removeEventListener('hashchange', handleHashChange);
                document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
                handleResetComponent();
            }
        };

        if (show) {
            window.location.hash = 'modal';
            window.addEventListener('hashchange', handleHashChange);
            document.body.style.overflow = 'hidden';  // Trava o scroll
        }else{
            document.body.style.overflow = 'auto';    // Ativa o scroll novamente
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
            document.body.style.overflow = 'auto';    // Ativa o scroll quando o componente desmonta
        };
    }, [show, setShow]);

    const resetComponent = () => {
        document.querySelector(".bottom-sheet-wrapper").classList.remove("show-modal");
        setShow(false);
        setTimeout(function () {
            handleResetComponent();
        }, 150);
    }

    const load = async () => {
        let addressListResponse = await Api.cliente.getAddresses({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"), 
        })    
        let _addressList = addressListResponse?.data?.data;
        setAddressList(_addressList);
    }

    useState(() => {
        if(user){
            load();
        }
    }, [user])

    useEffect(() => {
        calculateFrete();
    }, [setAddressSelected, addressSelected])

    const handleModeClick = (mode) => {
        if(user == null){
            setAccountModalShow(true);
            return;
        }
        load();
        setModeSelected(mode);
        setAddressSelectOpen(false);
        if(mode == 1 && user){
            setAddressSelected(addressList.filter(a => {return a.principal == 'S'})[0] || null);
        }
    }

    const calculateFrete = async () => {
        setFrete(null);
        if(addressSelected != null){
            let requestFrete = await Api.cliente.calcularFrete({
                id_cliente: user?.id_cliente,
                id_parceiro: produto?.id_parceiro,
                id_endereco: addressSelected?.id_endereco,
                id_produto: produto?.id_produto
            })

            let _frete = requestFrete?.data?.data;

            if(_frete?.json?.precificacao != null){
                switch(_frete?.json?.precificacao?.tipoPrecificacao){
                    case 0: //preço específico
                        let valorFrete = Utils.obterValorMunicipio(_frete?.json?.pontosEntrega, addressSelected?.uf, Number(addressSelected?.municipio)) || 0;
                        _frete["preco"] = valorFrete;
                        break;
                    case 1: //preço km
                        let {latitude, logintude} = parceiro;
                        let distanciaKm = Utils.calculateDistance(addressSelected?.latitude, addressSelected.logintude, latitude, logintude);
                        let _preco = Number(_frete?.json?.precificacao?.valor) * distanciaKm;
                        _frete["preco"] = _preco;
                        break;
                    case 2: //preço fixo
                        let _precoFixo = Number(_frete?.json?.precificacao?.valor)
                        _frete["preco"] = _precoFixo;
                        break;
                }
            }
            setFrete(_frete);
        }
    }

    const calculateCupom = async () => {

    }

    const handleOpenSelectAddress = async () => {
        setAddressSelectOpen(!addressSelectOpen);
    }

    const handleSelectedAddress = async (address) => {
        setAddressSelected(address);
        setAddressSelectOpen(false);
    }

    const validFrete = () => {
        if(Number(frete?.json?.locaisEntrega) == 1){ //lugares especificos do brasil
            let _addressSelected = addressSelected;
            let _pontosEntrega = frete?.json?.pontosEntrega;

            if(!Utils.validarPontoEntrega(_pontosEntrega, _addressSelected?.uf, Number(_addressSelected?.municipio))){
                Utils.notify("error", "Ainda não entregamos nessa localidade.");
                return false;
            }
        }else{
            return true;
        }
    }

    const handleComprar = async () => {
        if(user){
            if(!validFrete()) return;
            
        }else{
            console.log("Não autenticado")
            setAccountModalShow(true);
        }
    }

    return (
        <>
            <AccountModal show={accountModalShow} setShow={setAccountModalShow}/>
            <div className="mockup">
                <div className="bottom-sheet-wrapper">
                    <div className="backdrop" onClick={resetComponent}></div>
                    <div className="bottom-sheet" style={{bottom: Utils.detectarDispositivo() == "iPhone" ? `90px` : undefined}}>
                        <div className="close center" onClick={resetComponent}>
                            <ion-icon name="close-outline" style={{fontSize: '1.4rem'}}></ion-icon>
                        </div>
                        <Container>
                            <div className='mb-1'>
                                <b style={{fontSize: '1.2rem'}}>{produto?.nome}</b>
                            </div>
                            <div style={{minHeight: modeSelected == 0 ? `calc(100vh - ${Utils.detectarDispositivo() == "iPhone" ? '460px' : '390px'})` : `calc(100vh - ${Utils.detectarDispositivo() == "iPhone" ? '554px' : '485px'})`, maxHeight: modeSelected == 0 ? `calc(100vh - ${Utils.detectarDispositivo() == "iPhone" ? '460px' : '390px'})` : `calc(100vh - ${Utils.detectarDispositivo() == "iPhone" ? '554px' : '485px'})`, overflow: 'auto'}}>
                                <Carousel 
                                    responsive={responsive}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}    
                                >
                                    <img src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px'}} />  
                                    <img src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px'}} />  
                                    <img src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px'}} />  
                                    <img src={Environment.API_IMAGE + "/imagem/" + produto?.imagem || "../placeholder-image.png"} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px'}} />  
                                </Carousel>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div>
                                        <p className='mt-1 mb-1' style={{fontSize: '0.90rem', lineHeight: '17px'}}>
                                            {produto?.descricao}
                                        </p>
                                        <div style={{display :'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <span>Estado: <b>Novo</b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Hr elevation={1}/>
                            <div>
                                <div className='mb-1'>
                                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
                                        <Input setValue={setCupom} value={cupom} style={{width: '100%'}} type={"cupom"} label={"Cupom (digite)"}/>&nbsp;&nbsp;
                                        <Button style={{height: '50px'}}>&nbsp;&nbsp;Aplicar&nbsp;&nbsp;</Button>
                                    </div>
                                </div>
                                <div className='mb-1 mt-1'>
                                    <div style={{display: 'grid', gridTemplateColumns: `repeat(${parceiro?.flg_delivery == 1 ? '2' : '1'}, 1fr)`, justifyContent: 'center', gap: '0px'}}>
                                        {parceiro?.flg_delivery == 1 ? (
                                            <>
                                                <div className='period-button' style={{border: modeSelected == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeSelected == 0 ? 'black' : 'black'}} onClick={() => {handleModeClick(0)}}>Retirada</div>
                                                <div className='period-button' style={{border: modeSelected == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeSelected == 1 ? 'black' : 'black'}} onClick={() => {handleModeClick(1)}}>Entrega</div>
                                            </>
                                        ):(
                                            <div style={{display: 'flex', alignItems: 'center', background: '#f5f5f5', borderRadius: '16px', padding: '4px'}}>
                                                <ion-icon size={"large"} style={{color: 'gray'}} name="alert-circle-outline"></ion-icon>&nbsp;
                                                <label style={{color: '#000', fontSize: '10pt'}}>*Nao trabalhamos com <b>entrega</b> somente <b>retirada.</b></label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {modeSelected  == 1 ? (
                                    <div className='mb-1'>
                                        <div onClick={handleOpenSelectAddress} style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <img 
                                                    src={"../loc.webp"} 
                                                    style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px'}}/>&nbsp;
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div>
                                                        <label style={{fontSize: '0.8rem'}}>Localização da entrega</label><br/>
                                                        <b>{addressSelected != null ? (
                                                            <div style={{ width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                <b style={{ fontSize: '10pt' }}>
                                                                    {addressSelected?.logradouro}, {addressSelected?.numero}{addressSelected?.complemento} - {addressSelected?.uf}
                                                                </b>
                                                            </div>
                                                        ) : (`Selecionar...`)}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <ion-icon name={addressSelectOpen ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
                                        </div>
                                        {addressSelectOpen && user ? (
                                            <Card style={{background: "#fff", border: 'solid 1px #ddd', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', marginTop: '-4px', maxHeight: '180px', overflowY: 'auto'}}>
                                                <ul style={{listStyle: 'none'}}>
                                                    <div className='mb-1' key={"add-address"}>
                                                        <li onClick={() => {navigate("/profile/address")}}>
                                                            <Button style={{width: '100%'}}>+ Adicionar Endereço</Button>
                                                        </li>
                                                    </div>
                                                    {addressList?.map((address, index) => (
                                                        <div key={index}>
                                                            <li style={{height: '60px'}} className='li-colaborador' onClick={() => {handleSelectedAddress(address)}}>
                                                                <b style={{fontSize: '10pt'}}>{address?.logradouro}, {address?.numero}{address?.complemento} - {address?.uf}</b>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </Card>
                                        ) : (null)}
                                    </div>
                                ) : (null)}
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                        <div>
                                            <b>Subtotal</b>
                                        </div>
                                        <div>
                                            <b style={{color: 'green'}}>{Number(produto?.preco)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                        <div>
                                            <b>Desconto</b>
                                        </div>
                                        <div>
                                            <b style={{color: 'green'}}>- R$ 0,00</b>
                                        </div>
                                    </div>
                                    {modeSelected == 1 ? (
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                            {frete == null ? (
                                                <>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <b>Frete</b>&nbsp;
                                                    </div>
                                                    <div>
                                                        <b style={{color: 'green'}}>{addressSelected ? (<>Calculando...</>) : (<>(endereço)</>)}</b>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <b>Frete ({frete?.name})</b>&nbsp;
                                                        <img style={{width: '70px'}} src={frete?.company?.picture}/>
                                                    </div>
                                                    <div>
                                                        <b style={{color: 'green'}}>{addressSelected ? (<>{Number(frete?.preco)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</>) : (<>(endereço)</>)}</b>
                                                    </div>
                                                </>
                                            )}
                                        </div>):
                                    (null)}
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                                        <div>
                                            <b>Total</b>
                                        </div>
                                        <div>
                                            {modeSelected == 0 ? (
                                                <b style={{color: 'green'}}>{(Number(produto?.preco))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                            ) : (
                                                <b style={{color: 'green'}}>{((Number(frete?.preco) || 0) + Number(produto?.preco))?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', marginTop: '15px'}}>
                                    {modeSelected == 0 ? (
                                        <Button onClick={handleComprar} style={{width: '100%'}}><b>Comprar agora</b></Button>
                                    ): modeSelected == 1 ? (
                                        frete != null ? (
                                            <Button onClick={handleComprar} style={{width: '100%'}}><b>Comprar agora</b></Button>
                                        ):(
                                            <Button style={{width: '100%', opacity: '0.6'}}><b>Calculando frete...</b></Button>
                                        )
                                    ):(null)}
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}
