import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, FlatButton, FragmentView, Input, Modal, Option, Select, SpaceBox } from '../../components'
import './style.css';
import Utils from '../../Utils';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);
    const [servicos, setServicos] = useState([]);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [mode, setMode] = useState(null);
    const [servicoSelected, setServicoSelected] = useState(null);
    const [ativo, setAtivo] = useState(true);
    const [nome, setNome] = useState("");
    const [preco, setPreco] = useState("");
    const [tempo, setTempo] = useState("");
    const [incluso, setIncluso] = useState(true); 
    const [domicilio, setDomicilio] = useState(true); 

    useEffect(() => {
        requestServicos();
    }, [])

    const requestServicos = async () => {
        let response = await Api.colaborador.servicosByParceiro({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro,
        });
        let _servicos = response?.data?.data;
        setServicos(_servicos);
        setLoading(true);
    }

    const servicoModal = () => {
        setMode("CREATE");
        setServicoSelected(null);
        setShow(true);
    }

    const saveServico = async () => {

        if(nome?.trim()?.length <= 0){
            Utils.notify("error", "Digite o nome do serviço.");
            return;
        }

        if(preco?.trim()?.length <= 0){
            Utils.notify("error", "Digite o preço do serviço.");
            return;
        }

        if(tempo == null){
            Utils.notify("error", "Selecione o tempo do serviço.");
            return;
        }

        if(tempo?.trim()?.length <= 0){
            Utils.notify("error", "Selecione o tempo do serviço.");
            return;
        }

        if(Utils.converterMoedaParaNumero(preco) < 5){
            Utils.notify("error", "Segundo nossas políticas de pagamentos, o valor do serviço deve ser no mínimo R$ 5,00.");
            return;
        }

        let request = null;

        switch(mode){
            case "CREATE":
                request = await Api.colaborador.addServico({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    ativo: ativo == true ? 1 : 0,
                    id_parceiro: user?.id_parceiro, 
                    nome_servico: nome, 
                    descricao_servico: "", 
                    preco_servico: Utils.converterMoedaParaNumero(preco), 
                    tempo_servico: tempo,
                    incluso_na_assinatura: incluso == true ? 1 : 0,
                    atende_domicilio: user?.flg_movel == 1 ? domicilio : 0
                });
                break;
            case "UPDATE":
                request = await Api.colaborador.updateServico({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    id_servico: servicoSelected?.id_servico, 
                    ativo: ativo == true ? 1 : 0,
                    nome_servico: nome, 
                    descricao_servico: "", 
                    preco_servico: Utils.converterMoedaParaNumero(preco), 
                    tempo_servico: tempo,
                    incluso_na_assinatura: incluso == true ? 1 : 0,
                    atende_domicilio: user?.flg_movel == 1 ? domicilio : 0
                });
                break;
        }

        Utils.processRequest(request, (data) => {
            Utils.notify("success", "Serviço salvo com sucesso!");
            requestServicos();
            onCloseCallback();
            setShow(false);
        }, (error) => {});

    }

    const openModalEdit = (servico) => {
        setMode("UPDATE");
        setAtivo(servico?.ativo == 1);
        setNome(servico?.nome_servico);
        setTempo(servico?.tempo_servico);
        setPreco(Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
        setIncluso(servico?.incluso_na_assinatura == 1);
        setDomicilio(servico?.atende_domicilio == 1);
        setServicoSelected(servico);
        setShow(true);
    }

    const onCloseCallback = () => {
        setAtivo(true);
        setNome("");
        setTempo("");
        setPreco("");
        setIncluso(true);
        setServicoSelected(null);
    }

    const handleDeleteServico = (servico) => {
        setShowDelete(true);
        setServicoSelected(servico);
    }

    const handleDeleteServicoOk = async () => {
        let request = await Api.colaborador.deleteServico({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_servico: servicoSelected?.id_servico
        })
        Utils.processRequest(request, (data) => {
            Utils.notify("success", "Serviço excluído com sucesso!");
            requestServicos();
            onCloseCallback();
            setShowDelete(false);
        }, (error) => {});
    }

    return (
        <>
            <FlatButton text={"Adicionar"} onClick={servicoModal}>+</FlatButton>
            <Modal setShow={setShow} show={show} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>{mode == "CREATE" ? "Adicionar serviço" : "Atualizar serviço"}</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>{mode == "CREATE" ? "Adicione" : "Atualize"} serviços para seus clientes.</div>
                    </div>
                </div>
                <div>
                    <Checkbox flexStart setChecked={setAtivo} checked={ativo}>Ativo</Checkbox>
                    <Input type="text" label="Nome do serviço" value={nome} setValue={setNome}/>
                    <Input type="moeda" label="Preço do serviço (R$)" value={preco} setValue={setPreco}/>
                    <Select label="Tempo de Serviço" setValue={setTempo} value={tempo}>
                        <Option value={""}>Selecione...</Option>
                        <Option value={"5m"}>5 Minutos</Option>
                        <Option value={"10m"}>10 Minutos</Option>
                        <Option value={"20m"}>20 Minutos</Option>
                        <Option value={"30m"}>30 Minutos</Option>
                        <Option value={"40m"}>40 Minutos</Option>
                        <Option value={"50m"}>50 Minutos</Option>
                        <Option value={"1h00m"}>1 Hora</Option>
                        <Option value={"1h29m"}>1h e 30 Minutos</Option>
                        <Option value={"2h00m"}>2 Horas</Option>
                        <Option value={"2h29m"}>2h e 30 Minutos</Option>
                        <Option value={"3h00m"}>3 Horas</Option>
                        <Option value={"3h29m"}>3h e 30 Minutos</Option>
                        <Option value={"4h00m"}>4 Horas</Option>
                        <Option value={"4h29m"}>4h e 30 Minutos</Option>
                        <Option value={"5h00m"}>5 Horas</Option>
                        <Option value={"5h29m"}>5 Horas e 30 minutos</Option>
                        <Option value={"6h00m"}>6 Horas</Option>
                        <Option value={"6h29m"}>6 Horas e 30 minutos</Option>
                        <Option value={"7h00m"}>7 Horas</Option>
                        <Option value={"7h29m"}>7 Horas e 30 minutos</Option>
                        <Option value={"8h00m"}>8 Horas</Option>
                    </Select>
                    <Checkbox flexStart setChecked={setIncluso} checked={incluso}>Incluso para assinantes?</Checkbox>
                    {user?.flg_movel == 1 ? (
                        <Checkbox flexStart setChecked={setDomicilio} checked={domicilio}>Atende á domocílio?</Checkbox>
                    ) : (null)}
                </div>
                <div>
                    <Button onClick={saveServico} style={{width: '100%'}}>Salvar</Button>
                </div>
            </Modal>
            <Modal show={showDelete} setShow={setShowDelete} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <b>Confirmar exclusão do serviço?</b>
                </div>
                <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Button style={{color: '#ff2e36', background: '#fceaeb', height: '40px',}} onClick={() => {setShowDelete(false)}} >&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>
                    <Button style={{height: '40px'}} onClick={handleDeleteServicoOk}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <SpaceBox space={7}/>
            <FragmentView title={"Serviços"}>
                {loading ? (
                    servicos?.length > 0 ? (
                        servicos?.map((servico) => (
                            <Card className={"mb-1"} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderLeft: servico?.ativo == 0 ? 'solid 3px red' : '', background: servico?.ativo == 0 ? '#fbefef' : 'white'}}>
                                <div>
                                    <div>
                                        <b style={{fontSize: '0.90rem'}}>{servico?.nome_servico}</b>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="time-outline"></ion-icon>
                                        <span style={{fontSize: '0.90rem'}}>{Utils.converterHoraPorExtenso(servico?.tempo_servico)}</span>&nbsp;•&nbsp;
                                        <b style={{fontSize: '0.90rem', color: 'green'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                        {servico?.ativo == 0 ? (
                                            <span style={{fontSize: '0.90rem', color: 'gray'}}>&nbsp;•&nbsp;desativado</span>
                                        ) : (null)}
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div onClick={() => {openModalEdit(servico)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                        <ion-icon name="create-outline" size="large"></ion-icon>
                                    </div>&nbsp;&nbsp;
                                    <div onClick={() => {handleDeleteServico(servico)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                        <ion-icon style={{color: 'red'}} name="trash-outline" size="large"></ion-icon>
                                    </div>
                                </div>
                            </Card>
                        ))
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                            <b style={{color: 'gray'}}>Nenhum serviço cadastrado.</b>
                        </div>
                    )
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                        <b style={{color: 'gray'}}>Carregando...</b>
                    </div>
                )}
                <SpaceBox space={80}/>
            </FragmentView>
        </>
    )
}
