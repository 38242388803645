import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Button, Card, FragmentView, Hr, Input, Modal, Option, Select, SpaceBox } from '../../components';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import Utils from '../../Utils';

export default () => {

    const {user} = useContext(MainContext);

    const [show, setShow] = useState(localStorage.getItem("tk_beauty_parceiro_saldo_show") || true);

    const [loading, setLoading] = useState(false);
    const [saqueModalShow, setSaqueModalShow] = useState(false);
    const [saldo, setSaldo] = useState(0);
    const [history, setHistory] = useState([]);
    const [contasPix, setContasPix] = useState([]);

    const [contaPixSelected, setContaPixSelected] = useState(null);

    const [addContaPixModal, setAddContaPixModal] = useState(false);
    const [tiposChavePix, setTiposChavePix] = useState(["CPF", "CNPJ", "Email", "Celular"]);
    const [tipoChavePix, setTipoChavePix] = useState("");
    const [chavePix, setChavePix] = useState("");
    const [proprietario, setProprietario] = useState("");

    useEffect(() => {
        if(localStorage.getItem("tk_beauty_parceiro_saldo_show") != null){
            setShow(localStorage.getItem("tk_beauty_parceiro_saldo_show") == "true");
        }
        requests();
    }, []);

    useEffect(() => {
        setChavePix("");
    }, [tipoChavePix])

    const requests = async () => {
        let responseSaldo = await Api.parceiro.saldo({
            id_parceiro: user?.id_parceiro,
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
        })

        let responseHistorico = await Api.parceiro.historico({
            id_parceiro: user?.id_parceiro,
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
        })

        let responseContasPix = await Api.parceiro.contasPix({
            id_parceiro: user?.id_parceiro,
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
        })

        let _saldo = Number(responseSaldo?.data?.data);
        let _history = responseHistorico?.data?.data;
        let _contasPix = responseContasPix?.data?.data;

        setSaldo(_saldo);
        setHistory(_history);
        setContasPix(_contasPix);
        setLoading(true);
    }

    const handleEye = async (value) => {
        setShow(value);
        localStorage.setItem("tk_beauty_parceiro_saldo_show", value);
    }

    const handleSaque = () => {
        setSaqueModalShow(true);
    }

    const handleSaqueOk = async () => {

        if(saldo <= 0){
            Utils.notify("error", "Saldo insuficiênte para efetuar o saque.");
            return;
        }

        if(contaPixSelected == null){
            Utils.notify("error", "Selecione a conta pix.");
            return;
        }

        let responseSaque = await Api.parceiro.saque({
            id_parceiro: user?.id_parceiro,
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_conta_pix: contaPixSelected
        })

        Utils.processRequest(responseSaque, (data) => {
            setSaqueModalShow(false);
            requests();
        }, (error) => {}, true);

    }

    const handleAddContaPix = () => {
        setAddContaPixModal(true);
    }

    const validatePix = () => {

        if(!tipoChavePix){
            return {
                success: false,
                message: "Selecione o tipo de chave."
            };
        }

        switch(tipoChavePix){
            case "CPF":
                if(!Utils.validateCpf(chavePix)){
                    return {
                        success: false,
                        message: "Chave pix 'cpf' inválida."
                    };
                }
                break;
            case "CNPJ":
                if(!Utils.validateCNPJ(chavePix)){
                    return {
                        success: false,
                        message: "Chave pix 'cnpj' inválida."
                    };
                }
                break;
            case "Email":
                if(!Utils.validateEmail(chavePix)){
                    return {
                        success: false,
                        message: "Chave pix 'email' inválida."
                    };
                }
                break;
            case "Celular":
                if(!Utils.validatePhoneNumber(chavePix)){
                    return {
                        success: false,
                        message: "Chave pix 'celular' inválida."
                    };
                }
                break;
        }
        if (Utils.isEmpty(proprietario)) {
            return {
                success: false,
                message: "Digite o nome do proprietário."
            };
        }
    }

    const handleAddContaPixOk = async () => {
        
        let valid = validatePix();

        if(!valid?.success && valid != undefined){
            Utils.notify("error", valid?.message);
            return;
        }

        let request = await Api.parceiro.addContaPix({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro,
            nome: `${tipoChavePix} - ${chavePix}`, 
            tipo: tipoChavePix, 
            chave: chavePix, 
            proprietario: proprietario
        })

        Utils.processRequest(request, () => {
            setAddContaPixModal(false);
            setTipoChavePix(null);
            setChavePix("");
            setProprietario("");
            requests();
        }, () => {}, true);

    }

    const getStatusText = (status, status_pagamento) => {
        switch(status){
            case "A":
                return status_pagamento == "A" ? "Aguardando pagamento" : status_pagamento == "C" ? "Transação cancelada" : "Aguardando saque";
            case "P":
                return "Processando saque";
            case "S":
                return "Saque efetuado";
            case "C":
                return "Saque cancelado";
            case "F":
                return "Falha no saque";
        }
    }

    const getStatusIcon = (status, status_pagamento) => {
        switch(status){
            case "A":
                return status_pagamento == "A" ? (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'orange', background: '#fffae5'}}>
                        <ion-icon name="time-outline"></ion-icon>
                    </div>
                ) : status_pagamento == "C" ? (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'red', background: 'rgb(244 183 183 / 30%)'}}>
                        <ion-icon name="close-circle-outline"></ion-icon>
                    </div>
                ) : (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'green' , background: 'rgb(242, 255, 245)'}}>
                        <ion-icon name="receipt-outline"></ion-icon>
                    </div>
                );
            case "P":
                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'blue', background: '#e2eeff'}}>
                        <ion-icon name="time-outline"></ion-icon>
                    </div>
                );
            case "S":
                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'green' , background: 'rgb(242, 255, 245)'}}>
                        <ion-icon name="checkmark-done-outline"></ion-icon>
                    </div>
                );
            case "C":
                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'red', background: 'rgb(244 183 183 / 30%)'}}>
                        <ion-icon name="close-circle-outline"></ion-icon>
                    </div>
                );
            case "F":
                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'red', background: 'rgb(244 183 183 / 30%)'}}>
                        <ion-icon name="alert-circle-outline"></ion-icon>
                    </div>
                );
        }
    }

    return (
        <>
            <Modal setShow={setSaqueModalShow} show={saqueModalShow}>
                <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '14pt'}}>Fazer saque</b><br/>
                        <span style={{color: 'rgba(0, 0, 0, 0.9)', fontSize: '10pt'}}>Sacar lucros ganhos por transações no app.</span>
                    </div>
                </div>
                <div style={{display: "flex", alignItems: 'center'}}> 
                    <Select width={"100%"} label={"Em qual pix?"} setValue={setContaPixSelected} value={contaPixSelected}>
                        <Option value={""}>Selecione...</Option>
                        {contasPix?.map(c => (
                            <Option value={c?.id_conta_pix}>{c?.nome}</Option>
                        ))}
                    </Select>&nbsp;&nbsp;
                    <Button onClick={handleAddContaPix} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px', width: '50px'}}>
                        <ion-icon name="add"></ion-icon>
                    </Button>
                </div>
                <Button className={"mt-1"} onClick={handleSaqueOk} style={{width: '100%'}}>
                    <b>Realizar saque ({saldo?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })})</b>
                </Button>
            </Modal>
            <Modal setShow={setAddContaPixModal} show={addContaPixModal}>
                <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '14pt'}}>Adicionar conta PIX</b><br/>
                        <span style={{color: 'rgba(0, 0, 0, 0.9)', fontSize: '10pt'}}>Adicione sua conta PIX para saques no app.</span>
                    </div>
                </div>
                <Select label={"Qual tipo de chave pix?"} setValue={setTipoChavePix} value={tipoChavePix}>
                    <Option value={""}>Selecione...</Option>
                    {tiposChavePix?.map(c => (
                        <Option value={c}>{c}</Option>
                    ))}
                </Select>
                {tipoChavePix?.trim()?.length > 0 ? (
                    <>
                        {tipoChavePix == "CPF" ? (
                            <Input type={"cpf"} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                        ) : tipoChavePix == "CNPJ" ? (
                            <Input type={"cnpj"} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                        ) : tipoChavePix == "Email" ? (
                            <Input type={"text"} maxLegth={100} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                        ) : (
                            <Input type={"celular"} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                        )}
                        <Input type={"text"} maxLegth={50} label={"Nome (completo)"} setValue={setProprietario} value={proprietario}/>
                    </>
                ) : (null)}
                         
                <Button className={"mt-1"} onClick={handleAddContaPixOk} style={{width: '100%'}}>
                    <b>Salvar</b>
                </Button>
            </Modal>
            <FragmentView title={"Saque e Extrato"}>
                <Card>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{padding: '10px'}}>
                                <ion-icon name="wallet-outline" size="large"></ion-icon>
                            </div>
                            <div>
                                <div>
                                    <span style={{color: 'rgb(140, 140, 140)'}}>Seu saldo</span>
                                </div>
                                <div>
                                    {loading ? (
                                        show ? (
                                            <b style={{fontSize:'24pt'}}>{saldo?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>
                                        ) : (
                                            <b style={{fontSize: '24pt'}}>R$ *****</b>
                                        )
                                    ) : (
                                        <b style={{fontSize:'24pt'}}>R$ ...</b>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {handleEye(!show)}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px'}}>
                            {show ? (
                                <ion-icon name="eye-off-outline" size="large"></ion-icon>
                            ) : (
                                <ion-icon name="eye-outline" size="large"></ion-icon>
                            )}
                        </div>
                    </div>
                    <div className='mt-1'>
                        <Button onClick={handleSaque} style={{height: '50px', width: '100%'}}>
                            <b>Fazer saque</b>
                        </Button>
                    </div>
                </Card>
                <SpaceBox space={20}/>
                <Card>
                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="repeat-outline"></ion-icon>&nbsp;
                        <b>Seu extrato</b>
                    </div>
                    <div className='mt-1 mb-1'>
                        {history?.length > 0 ? (
                            history?.map((h, index) => (
                                <>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: h?.tipo == 'ENTRADA' ? 'green' : 'red', background: h?.tipo == "ENTRADA" ? 'rgb(242, 255, 245)' : 'rgb(244 183 183 / 30%)'}}>
                                                {getStatusIcon(h?.status, h?.status_pagamento)}
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <b style={{}}>{getStatusText(h?.status, h?.status_pagamento)}</b>
                                                <p style={{color: 'gray'}}>{h?.descricao}</p>
                                                <p style={{color: 'gray'}}>{Number(h?.valor)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{color: 'gray', fontSize: '10pt', textAlign: 'right', width: '90px'}}>
                                                {Utils.converterDate(h?.ts_registro)}
                                            </p>
                                        </div>
                                    </div>
                                    {index < (history?.length - 1) ? (
                                        <Hr elevation={0.5}/>
                                    ) : (null)}
                                </>
                            ))
                        ) : (
                            loading == true ? (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                    <b style={{color: 'gray'}}>Nenhuma atividade!</b>
                                </div>
                            ) : (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                    <b style={{color: 'gray'}}>Carregando...</b>
                                </div>
                            )
                        )}
                    </div>
                </Card>
                <SpaceBox space={100}/>  
            </FragmentView>
        </>
    )
}
