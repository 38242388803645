import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { AccountModal, Button, Card, Container, Modal, SpaceBox } from '..';
import AgendaProviderHelper from '../../helpers/AgendaProviderHelper';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import Utils from '../../Utils';
import { useNavigate } from 'react-router-dom';
import Environment from '../../Environment';

const startSwipperLogic = () => {

    let elementsArray = [
        document.querySelector('.items-days'),
        document.querySelector('.items-hours'),
    ];

    let isDown = false;
    let startX;
    let scrollLeft;

    const end = (s) => {
        isDown = false;
        s.classList.remove('active');
    }

    const start = (e, s) => {
        isDown = true;
        s.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - s.offsetLeft;
        scrollLeft = s.scrollLeft;	
    }
    
    const move = (e, s) => {
        if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - s.offsetLeft;
        const dist = (x - startX);
        s.scrollLeft = scrollLeft - dist;
    }

    elementsArray.forEach(function(slider) {

        if(slider){
            slider.addEventListener('mousedown', function (e) { start(e, slider) });
            slider.addEventListener('touchstart', function (e) { start(e, slider) });
            slider.addEventListener('mousemove', function (e) { move(e, slider) });
            slider.addEventListener('touchmove', function (e) { move(e, slider) });
            slider.addEventListener('mouseleave', function () {end(slider) });
            slider.addEventListener('mouseup', function () { end(slider) });
            slider.addEventListener('touchend', function () { end(slider) });
        }

    });

}

export default ({parceiro, name, phone, expediente, servico, assinante, comeToMe, handleResetComponent, onScrollBottomAnimate, bonus}) => {

    const navigate = useNavigate();

    const {user} = useContext(MainContext);

    const [modeSelected, setModeSelected] = useState(0);
    const [daySelected, setDaySelected] = useState(null);
    const [hourSelected, setHourSelected] = useState(null);
    const [paymentTypeSelected, setPaymentTypeSelected] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorOpenSelect, setColaboradorOpenSelect] = useState(false);
    const [colaboradorSelected, setColaboradorSelected] = useState(null);
    const [agendamentosByColaborador, setAgendamentosByColaborador] = useState([]);

    const [modalResume, setModalResume] = useState(false);

    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);

    useEffect(() => {
        startSwipperLogic();
    }, [daySelected, colaboradorSelected])

    useEffect(() => {
        startSwipperLogic();
        handleAgendar();
    }, [])

    useEffect(() => {
        const setup = async () => {
            let colaboradoresResponse = await Api.geral.colaboradoresByParceiro({
                id_parceiro: parceiro?.id_parceiro
            })

            let _colaboradores = colaboradoresResponse?.data?.data;

            setColaboradores(_colaboradores);
        }
        setup();
    }, [])

    useEffect(() => {
        if(onScrollBottomAnimate){
            onScrollBottomAnimate();
        }
    }, [hourSelected, setHourSelected])

    const handleAgendar = async () => {
        setDaySelected(null);
        setHourSelected(null);
    }

    const loadHours = (day) => {
        let _dia = (day?.dia < 10) ? "0"+day?.dia : day?.dia;
        let _mes = (day?.mes < 10) ? "0"+day?.mes : day?.mes;
        let _ano = day?.ano;
        let _date = _ano+"-"+_mes+"-"+_dia;

        let {abertura, fechamento} = Utils.checkWeekDayAndReturnExpediente(day, expediente);
        
        let _hours = AgendaProviderHelper.getHoursPeriods(abertura, fechamento, _date);

        setHours(_hours);

        return _hours;
    }

    const handleModeClick = (mode) => {
        setModeSelected(mode);
    }

    const converterParaMinutos = (hora) => {
        const [h, m] = hora.split(":").map(Number);
        return h * 60 + m;
    }
    
    const converterDuracaoParaMinutos = (duracao) => {
        const [h, m] = duracao.match(/\d+/g).map(Number);
        return h * 60 + m;
    }    
    
    function verificarHorario(hours, agendamentos) {
    
        agendamentos.forEach(agendamento => {
            const inicioAgendamento = converterParaMinutos(agendamento.hora);
            const duracaoAgendamento = converterDuracaoParaMinutos(agendamento.tempo_servico);
            const fimAgendamento = inicioAgendamento + duracaoAgendamento;

            //gambiarra preparada para calcular até 8 horas
            let intervalo = (duracaoAgendamento > 60 && duracaoAgendamento < 180) ? 60 : ((duracaoAgendamento >= 180 && duracaoAgendamento < 240) ? 90 : (duracaoAgendamento >= 240 && duracaoAgendamento < 300) ? 120 : (duracaoAgendamento >= 300 && duracaoAgendamento < 360) ? 160 : (duracaoAgendamento >= 360 && duracaoAgendamento < 420) ? 180 : (duracaoAgendamento >= 420 && duracaoAgendamento < 480) ? 210 : (duracaoAgendamento >= 480) ? 240 : 30);
            const intervaloAgendamento = Math.ceil(duracaoAgendamento / intervalo);

            hours.forEach((horaDisponivel, index) => {
                const horaAtual = converterParaMinutos(horaDisponivel.hora);
                const proximaHora = index < hours.length - 1 ? converterParaMinutos(hours[index + 1].hora) : horaAtual + 30;
    
                if (horaAtual >= inicioAgendamento && proximaHora <= fimAgendamento) {
                    for (let i = index; i < index + intervaloAgendamento && i < hours.length; i++) {
                        hours[i].livre = false;
                    }
                }
            });
        });
    
    }

    const handleDayClick = (day) => {
        let _hours = loadHours(day);

        let datasReservadas = [];

        for(let i = 0; i< agendamentosByColaborador?.length; i++){
            let _id_agendamento = agendamentosByColaborador[i]?.id_agendamento;
            let _id_servico = agendamentosByColaborador[i]?.id_servico;
            let _d = agendamentosByColaborador[i]?.data_agendamento;
            let _h = agendamentosByColaborador[i]?.hora_agendamento;
            let _tempo_servico = agendamentosByColaborador[i]?.tempo_servico;
            datasReservadas.push({
                id_agendamento: _id_agendamento,
                id_servico: _id_servico,
                tempo_servico: _tempo_servico,
                data: _d?.split('/')[2]+"-"+_d?.split('/')[1]+"-"+_d?.split('/')[0],
                hora: _h,
            });
        }

        let _horariosReservadosForDisable = [];

        _hours.forEach(item => {
            datasReservadas.forEach(dr => {
                if (item.hora === dr.hora && item.dataRef === dr.data) {
                    item.livre = false;
                    _horariosReservadosForDisable.push({
                        id_agendamento: dr.id_agendamento,
                        id_servico: dr.id_servico,
                        tempo_servico: dr.tempo_servico,
                        hora: item?.hora,
                        livre: false,
                    });
                }
            });
        });

        verificarHorario(_hours, _horariosReservadosForDisable);

        setHours(_hours);
        setDaySelected(day);
        setHourSelected(null);
    }

    function verificarDisponibilidadeFutura(hours, horaSelecionada, tempoServico) {
        const duracaoServicoMinutos = converterDuracaoParaMinutos(tempoServico);
        const horaSelecionadaMinutos = converterParaMinutos(horaSelecionada);
        const horaFinalAgendamento = horaSelecionadaMinutos + duracaoServicoMinutos;
    
        // Verifica se a hora final do agendamento ultrapassa o tamanho de hours
        if (horaFinalAgendamento > converterParaMinutos(hours[hours.length - 1].hora)) {
            return false;
        }
    
        let dentroDoIntervalo = false;
        let depoisDoIntervalo = false;
        
        for (let i = 0; i < hours.length; i++) {
            const horaAtualMinutos = converterParaMinutos(hours[i].hora);
    
            if (horaAtualMinutos === horaSelecionadaMinutos) {
                dentroDoIntervalo = true;
            }
    
            if (dentroDoIntervalo) {
                if (horaAtualMinutos >= horaSelecionadaMinutos && horaAtualMinutos < horaFinalAgendamento) {
                    if (!hours[i].livre) {
                        return false;
                    }
                } else {
                    dentroDoIntervalo = false;
                    depoisDoIntervalo = true;
                }
            }
    
            if (depoisDoIntervalo && horaAtualMinutos >= horaFinalAgendamento) {
                break;
            }
    
            if (depoisDoIntervalo && !hours[i].livre) {
                return false;
            }
        }
    
        return true;
    }

    const handleHourSelected = (hour) => {
        let valid = verificarDisponibilidadeFutura(hours, hour.hora, servico?.tempo_servico);
        if(valid){
            setHourSelected(hour);
        }else{
            Utils.notify("error", `Não há horários disponíveis para este serviço, que tem a duração de ${Utils.converterHoraPorExtenso(servico?.tempo_servico)}. Por favor, escolha outro dia ou horário.`)
        }
    }

    const handleOpenSelectColaborador = async () => {
        setColaboradorOpenSelect(!colaboradorOpenSelect);
    }

    const handleSelectedColaborador = async (colaborador) => {
        setColaboradorSelected(colaborador);

        let agendamentosByColaboradorResponse = await Api.geral.agendamentosParceiroByColaborador({
            id_colaborador: colaborador?.id_colaborador,
            id_parceiro: parceiro?.id_parceiro
        })

        let _agendamentos = agendamentosByColaboradorResponse?.data?.data;
        let _days = AgendaProviderHelper.getDaysPeriods();

        setDays(_days);
        handleAgendar();
        setAgendamentosByColaborador(_agendamentos);
        setColaboradorOpenSelect(false);
    }

    const getDateSelectedFormated = () => {
        let dia = (Number(daySelected?.dia) < 10 ? '0'+daySelected?.dia : daySelected?.dia);
        let mes = (Number(daySelected?.mes) < 10 ? '0'+daySelected?.mes : daySelected?.mes);
        let ano = daySelected?.ano;
        return dia+'/'+mes+'/'+ano;
    }
    
    const getPaymentMethod = () => {
        if(assinante){
            return "SIGNATURE";
        }
        switch(paymentMethod){
            case 0:
                return "PIX";
            case 1:
                return "CARD";
            case 2: 
                return "MONEY";
            case 3:
                return "BONUS";
        }
    }

    const handleAgendamento = async () => {
        if(user){

            if(modeSelected != 1 && modeSelected != 0){
                Utils.notify("error", "Selecione o modo de agendamento.");
                return;
            }

            if(colaboradorSelected == null) {
                Utils.notify("error", "Selecione o profissional.");
                return;
            }

            if(daySelected == null){
                Utils.notify("error", "Selecione o dia do agendamento.");
                return;
            }

            if(hourSelected == null){
                Utils.notify("error", "Selecione a hora do agendamento.");
                return;
            }

            setModalResume(true);
        }
    }

    const handleAgendamentoConfirm = async () => {

        const data = {
            id_parceiro: parceiro?.id_parceiro,
            id_servico: servico?.id_servico,
            id_colaborador: colaboradorSelected?.id_colaborador,
            id_cupom: null,
            tipo_agendamento: modeSelected == 0 ? 'P' : 'M',
            subtotal: Number(servico?.preco_servico),
            desconto: (assinante || paymentMethod == 3) ? Number(servico?.preco_servico) : 0,
            total: Number(servico?.preco_servico),
            metodo_pagamento: getPaymentMethod(),
            local_pagamento: paymentTypeSelected == 0 ? 'A' : 'P',
            status_pagamento: (assinante || paymentMethod == 3) ? 'P' : 'A',
            data_agendamento: getDateSelectedFormated(),
            hora_agendamento: hourSelected?.hora,
            status_agendamento: 'A',
            codigo_agendamento: null,
            codigo_confirmado: false,
            nome_cliente: name,
            celular_cliente: phone
        };

        let solicitacaoResponse = await Api.colaborador.solicitacaoAgendamentoPaymentLink({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            data: data,
        })

        let _phone = phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", '');

        if(solicitacaoResponse?.data?.data){
            let id_payment_link_solicitacao = solicitacaoResponse?.data?.data;
            console.log(id_payment_link_solicitacao);
            let payment_link = `${window.location.origin}/payment-link/servico/${_phone}/${id_payment_link_solicitacao}/${modeSelected == 0 ? 'false' : 'true'}/${data?.id_servico}`;
            await Api.colaborador.agendamentoPaymentLinkUpdate({
                forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                phone: _phone, 
                name: name,
                payment_link: payment_link,
                id_payment_link_solicitacao: id_payment_link_solicitacao
            })
            Utils.notify("success", "Link de confirmação enviado ao cliente.");
            setModalResume(false);
            navigate(-1);
        }

    }

    return (
        <div>
            <Modal show={modalResume} setShow={setModalResume}>
                <div className='mb-2'>
                    <b style={{fontSize: '1.2rem'}}>Resumo do agendamento</b>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="location-outline"></ion-icon>&nbsp;
                        <b>Local</b>
                    </div>
                    <div>
                        <span>{modeSelected == 0 ? parceiro?.nome_parceiro : 'Minha localização'}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="cut-outline"></ion-icon>&nbsp;
                        <b>Serviço</b>
                    </div>
                    <div>
                        <span>{servico?.nome_servico}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="person-outline"></ion-icon>&nbsp;
                        <b>{Utils.parceiroCategorryMapper(parceiro?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME}</b>
                    </div>
                    <div>
                        <span>{colaboradorSelected?.nome}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="calendar-outline"></ion-icon>&nbsp;
                        <b>Data</b>
                    </div>
                    <div>
                        <span>{daySelected?.dia < 10 ? ('0'+daySelected?.dia) : daySelected?.dia}/{daySelected?.mes < 10 ? '0'+daySelected?.mes : daySelected?.mes}/{daySelected?.ano}</span>
                    </div>
                </div>
                <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="time-outline"></ion-icon>&nbsp;
                        <b>Hora</b>
                    </div>
                    <div>
                        <span>{hourSelected?.hora}</span>
                    </div>
                </div>
                <SpaceBox space={15}/>
                <div className='totals'>
                    {
                        <>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Subtotal</b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <>
                                        <b style={{textDecoration: Number(bonus) >= Number(servico?.preco_servico) ? 'line-through' : undefined}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </>
                                ):(
                                    <>
                                        <b>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </>
                                )}
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Desconto</b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <div>
                                        <span style={{background: '#f2fff5', color :'green', border: 'solid 1px green', borderRadius: '12px', paddingLeft: '6px', paddingRight: '6px'}}><b>bônus</b></span>&nbsp;
                                        <b>-{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </div>
                                ) : (
                                    <div>
                                        <b>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                    </div>
                                )}
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <b>Total</b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <b style={{color: 'green'}}>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                ) : (
                                    <b style={{color: 'green'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                )}
                            </div>
                        </>
                    }
                </div>  
                
                <Button onClick={handleAgendamentoConfirm} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={'mt-2'}>
                    <b>Enviar cobrança para cliente</b>&nbsp;
                    <ion-icon name="logo-whatsapp"></ion-icon>
                </Button>
            </Modal>

            <div className='mb-1 mt-1'>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '0px'}}>
                    {comeToMe == true  && servico?.atende_domicilio == 1 ? (
                        <>
                            <div className='period-button' style={{cursor: 'pointer', border: modeSelected == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeSelected == 0 ? 'black' : 'black'}} onClick={() => {handleModeClick(0)}}>Estabelecimento</div>
                            <div className='period-button' style={{cursor: 'pointer', border: modeSelected == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeSelected == 1 ? 'black' : 'black'}} onClick={() => {handleModeClick(1)}}>Domicílio</div>
                        </>
                    ) : (null) }
                </div>
            </div>

            <div onClick={handleOpenSelectColaborador} style={{cursor: 'pointer', display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center',}}>
                    <img 
                        src={colaboradorSelected?.foto != undefined ? Environment.API_IMAGE +"/imagem/"+ colaboradorSelected?.foto : "../placeholder-image.png"} 
                        style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '8px'}}/>&nbsp;
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div>
                            <label style={{fontSize: '0.8rem'}}>{Utils.parceiroCategorryMapper(parceiro?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME}</label><br/>
                            <b>{colaboradorSelected != null ? (
                                <>
                                    {colaboradorSelected?.nome}
                                </>
                            ) : (`Selecionar...`)}</b>
                        </div>
                    </div>
                </div>
                <ion-icon name={colaboradorOpenSelect ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
            </div>
            {colaboradorOpenSelect ? (
                <Card style={{cursor: 'pointer', background: "#fff", border: 'solid 1px #ddd', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', marginTop: '-4px', maxHeight: '180px', overflowY: 'auto'}}>
                    <ul style={{listStyle: 'none'}}>
                        {colaboradores?.map((colaborador, index) => (
                            <div key={index}>
                                <li className='li-colaborador' onClick={() => {handleSelectedColaborador(colaborador)}}>
                                    <img src={colaborador?.foto != undefined ? Environment.API_IMAGE +"/imagem/"+ colaborador?.foto : "../placeholder-image.png"} style={{width: '45px', height: '45px', objectFit: 'cover', borderRadius: '4px'}}/>&nbsp;
                                    <b>{colaborador?.nome}</b>
                                </li>
                            </div>
                        ))}
                    </ul>
                </Card>
            ) : (null)}

            {colaboradorSelected ? (
                <div className='mb-1 mt-2'>
                    <div className="wrapper mb-2">
                        <ul className="items-category items-days">
                            {days?.map((ag, index) => (
                                <li key={index} className="item-agenda" style={{background: ag?.id == daySelected?.id ? '#000000' : '#f5f5f5', color: ag?.id == daySelected?.id ? 'white' : 'black'}} onClick={() => {handleDayClick(ag)}}>
                                    <div className='text-center' style={{marginBottom: '5px'}}>
                                        <b style={{fontSize: '1.2rem'}}>{ag.dia}</b>
                                    </div>
                                    <div className='text-center'>
                                    <b>{ag.diaSemana}</b>
                                    </div>
                                </li>
                            ))}  
                        </ul>
                    </div>
                </div>
            ) : (null)}

            {daySelected ? (
                <>
                    {hours?.length <= 0 ? (
                        <div style={{display: 'flex', alignItems: 'center', borderRadius: '8px', border: 'solid 1px #ddd', background: '#f5f5f5', padding: '8px', width: '100%'}}>
                            <ion-icon name="information-circle-outline"></ion-icon>
                            <b>Nenhum horário disponível.</b>
                        </div>
                    ) : (null)}
                    
                    <div className='mb-1 mt-1'>
                        <div className="wrapper mb-2">
                            <ul className="items-category items-hours">
                                {hours?.length > 0 && hours?.map((h, index) => (
                                    h?.livre ? (
                                        <div style={{background: h?.id == hourSelected?.id ? '#000000' : '#f5f5f5', color: h?.id == hourSelected?.id ? 'white' : 'black'}} className={`hora-button item-hour ${h.hora.replace(":", '-')}`} onClick={() => {handleHourSelected(h)}}>{h?.hora}</div>
                                    ) : (
                                        <div style={{background: '#ff34344d', color: 'black', textDecoration: 'line-through'}} className={`hora-button item-hour ${h.hora}`}>{h?.hora.replace(":", '-')}</div>
                                    )
                                ))}
                            </ul>
                        </div>
                    </div>
                </>
            ) : (null)}
            
            <div className='totals'>
                {
                    paymentTypeSelected != null && hourSelected  ? (
                    <>
                        {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#f2fff5', color :'green', padding: '8px'}}>
                                <b>Saldo Bônus</b>
                                <b>{bonus?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                            </div>
                        ) : (null)}
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <b>Subtotal</b>

                            {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                <>
                                    <b style={{textDecoration: Number(bonus) >= Number(servico?.preco_servico) ? 'line-through' : undefined}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </>
                            ) : (
                                <>
                                    <b>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </>
                            )}

                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <b>Desconto</b>
                            <b>
                                {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                    <>
                                        <span style={{background: '#f2fff5', color :'green', border: 'solid 1px green', borderRadius: '12px', paddingLeft: '6px', paddingRight: '6px'}}>bônus</span>&nbsp;
                                        -{Number(bonus) >= Number(servico?.preco_servico) ? Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : `R$ 0,00`}
                                    </>
                                ) : (
                                    <>
                                        {`R$ 0,00`}
                                    </>
                                )}
                            </b>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <b>Total</b>
                            {Number(bonus) >= Number(servico?.preco_servico) && paymentMethod == 3 ? (
                                <>
                                    <b style={{color: 'green'}}>{Number(0)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </>
                            ):(
                                <>
                                    <b style={{color: 'green'}}>{Number(servico?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </>
                            )}
                        </div>
                    </>) : (null)
                }
            </div>  
            {
                hourSelected != null ? (
                    <Button onClick={handleAgendamento}  style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={'mt-2'}>
                        <b>Confirmar agendamento</b>&nbsp;
                        <ion-icon name="checkmark-done"></ion-icon>
                    </Button>
                ) : (null)
            }
                    
        </div>
    )
}
