import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import Utils from '../../Utils';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';
import Api from '../../Api';
import Environment from '../../Environment';

export default ({index, parceiro, desfavoritedButton, onDesfavoriteCallback}) => {

    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [distance, setDistance] = useState(null);
    const [assinaturas, setAssinaturas] = useState([]);
    const [avaliacoesParceiro, setAvaliacoesParceiro] = useState([]);
    const [nota, setNota] = useState(0); 

    useEffect(() => {

        const setup = async () => {
            if(parceiro?.id_parceiro != null){

                getAvaliacoesParceiro(parceiro?.id_parceiro);

                CoordsProviderHelper(async (coords) => {
                    let distance = Utils.calculateDistance(
                        Number(parceiro?.latitude),
                        Number(parceiro?.logintude),
                        coords?.latitude,
                        coords?.longitude,
                    )
                    setDistance(distance);
                })

                let assianturasResponse = await Api.geral.assinaturasByParceiro({
                    id_parceiro: parceiro?.id_parceiro
                })

                let _assinaturas = assianturasResponse?.data?.data;

                setAssinaturas(_assinaturas);

                setLoading(true);
            }
        }
        setup();
    }, [parceiro])

    const getAvaliacoesParceiro = async (id_parceiro) => {
        const response = await Api.geral.avaliacaoParceiro({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: id_parceiro
        })
        let _avaliacoesParceiro = response?.data?.data;
        setAvaliacoesParceiro(_avaliacoesParceiro);
        const _nota = Utils.calcularAvaliacao(_avaliacoesParceiro);
        setNota(_nota);
    }

    const handleClick = () => {
        if(parceiro?.id_parceiro != null){
            navigate('/parceiro/'+parceiro?.id_parceiro);
        }
    }

    const handleDesfavorite = async () => {
        document.getElementsByClassName(`parceiro-${parceiro?.id_parceiro}`)[0].style.display = 'none';
        await Api.cliente.desfavoritar({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            id_parceiro: parceiro?.id_parceiro
        })
        if(onDesfavoriteCallback){
            onDesfavoriteCallback();
        }
    }

    return (
        <div className={`parceiro-${parceiro?.id_parceiro}`} key={index} style={{justifyContent: desfavoritedButton ? 'space-between' : 'normal', display: 'flex', background: '#ffffff', padding: '0.5rem', borderRadius: '8px', alignItems: 'center', cursor: 'pointer', boxShadow: '0 3px 10px rgb(0 0 0 / 0.05)'}}>
            <div style={{display: 'flex', alignItems: 'center'}} onClick={handleClick}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img loading="lazy" src={Environment.API_IMAGE + "/imagem/" + parceiro?.logo_parceiro || "../placeholder-image.png"} style={{width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px'}}/>
                </div>
                <div style={{marginLeft: '8px'}}>
                    {parceiro?.id_parceiro == null ? (
                        <>  
                            <div style={{marginBottom: '2px'}}><b style={{fontSize: '12pt', background: 'rgba(0,0,0,0.1)'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></div>
                            <p style={{fontSize: '10pt', background: 'rgba(0,0,0,0.1)'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        </>
                    ) : (
                        <>  
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <b style={{fontSize: '12pt'}}>{parceiro?.nome_parceiro}</b>&nbsp;
                                {parceiro?.cadastro_verificado == 'S' ? (
                                    <svg aria-label="Verificado" class="x1lliihq x1n2onr6" fill="rgb(0, 149, 246)" height="15" role="img" viewBox="0 0 40 40" width="15"><title>Verificado</title><path fill='#000' d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z" fill-rule="evenodd"></path></svg>
                                ) : (null)}
                            </div>
                            <p style={{fontSize: '10pt', color: '#2c2c2c'}}>{parceiro?.categoria_nome}</p>
                        </>
                    )}
                    <div style={{display: 'flex', marginTop: '5px', marginBottom: '5px', alignItems: 'center'}}>
                        {nota != 0 && loading ? (<ion-icon name="star" style={{color: 'orange', fontSize: '8pt'}}></ion-icon>) : (null)}
                        {loading ? (<p style={{fontSize: '8pt', background: nota == 0 ? '#000' : '', color: nota == 0 ? '#fff' : '', borderRadius: nota == 0 ? '4px' : '', paddingLeft: nota == 0 ? '4px' : '', paddingRight: nota == 0 ? '4px' : ''}}>{parceiro?.id_parceiro != null ? nota != 0 ? nota : "novo" : '...'}</p>) : (null)}
                        &nbsp;•&nbsp;
                        <p style={{fontSize: '8pt'}}>
                            {distance ? (distance?.toFixed(1)+"km") : (`...`)}
                        </p>
                        {parceiro?.flg_movel == 1 ? (
                            <>
                                &nbsp;•&nbsp;
                                <ion-icon name="walk-outline" style={{color: 'black', fontSize: '9pt'}}></ion-icon>
                                <p style={{fontSize: '8pt'}}>Móvel</p> 
                            </>
                        ) : (null)}
                        {assinaturas?.length > 0 ? (
                            <>
                                &nbsp;•&nbsp;
                                <p style={{fontSize: '8pt'}}>Assinatura {Number(assinaturas[0]?.preco).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                            </>
                        ) : (null)}
                    </div>
                </div>
            </div>
            {desfavoritedButton ? (
                <div style={{padding: '8px'}}>
                    <ion-icon name="heart-dislike-outline" onClick={handleDesfavorite} style={{fontSize: '1.7rem'}}></ion-icon>
                </div>
            ) : (null)}
        </div>
    )
}
