import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Button, Checkbox, Input } from '../../components';
import { useNavigate } from 'react-router-dom';
import Utils from '../../Utils';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';

export default () => {

    const navigate = useNavigate();

    const {user, setUser} = useContext(MainContext);

    const [code, setCode] = useState("");

    const [startTimer, setStartTimer] = useState(false);
    const [seconds, setSeconds] = useState(0 || localStorage.getItem("tk_beauty_verify_code_seconds"));

    useEffect(() => {
        if(user?.verificado){
            navigate("/");
        }
    }, [user]);

    useEffect(() => {
        const storedSeconds = localStorage.getItem("tk_beauty_verify_code_seconds");
        if (storedSeconds) {
            setSeconds(Number(storedSeconds));
            setStartTimer(true);
        }
    }, []);

    useEffect(() => {
        let intervalId;
        if (startTimer && seconds > 0) {
            intervalId = setInterval(() => {
                const newSeconds = seconds - 1;
                localStorage.setItem("tk_beauty_verify_code_seconds", newSeconds);
                setSeconds(newSeconds);
            }, 1000);
        }

        if(seconds <= 0){
            setStartTimer(false);
            setSeconds(0);
        }

        return () => clearInterval(intervalId); // Limpa o intervalo quando o componente for desmontado
    
    }, [startTimer, seconds]);

    const handlerResend = async () => {
        if(startTimer) return;

        Utils.notify("success", "Enviamos seu código de confirmação no seu whatsapp.");

        setStartTimer(true);
        setSeconds(30);

        await Api.cliente.resendCode({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
        })
    }

    const handleVerify = async () => {

        if(code?.trim()?.length <= 0){
            Utils.notify("error", "Digite o código de verificação");
            return;
        }

        let request = await Api.cliente.verifyCode({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            code: code
        })

        Utils.processRequest(request, (data) => {
            Utils.notify("success", "Código válido!");
            window.location.reload();
        }, (error) => {})

    }

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <div style={{width: '90%'}}>
                    <div style={{ background: '#fff', padding: '20px', borderRadius: '5px', width: '100%' }}>
                        <div style={{display: 'flex', alignItems:'center', alignItems: 'center', justifyContent: 'center', marginTop: '-58px'}}>
                            <img src='../logo.png' className='shadow' style={{width: '80px', borderRadius: '8px', border: '3px solid #fff'}}/>
                        </div>
                        <div className='mt-2 mb-2'>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                                <div>
                                    <b style={{fontSize: '1.2rem'}}>Verificação de conta</b>
                                    <p style={{fontSize: '12pt', color: 'gray'}}>*Digite o código que enviamos no seu whatsapp.</p>
                                </div>
                            </div>
                        </div>
                        <Input type={"code-number"} value={code} setValue={setCode} label={"Degite o código"} />
                        <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center'}} onClick={handlerResend}>
                            <b style={{color: startTimer ? '#ddd' : 'black', textDecoration: 'underline'}}>Reenviar código</b>
                            {startTimer ? (
                                <b>&nbsp; ({seconds}s)</b>
                            ) : (null)}
                        </div>
                        <Button style={{width: '100%'}} onClick={handleVerify}>
                            <b>Verificar Código</b>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
