import React, { useEffect, useState } from 'react';
import './style.css';
import { Card, FragmentView, Hr, SpaceBox } from '../../components';
import Api from '../../Api';
import Utils from '../../Utils';

export default () => {

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        requests();
    }, [])

    const requests = async () => {
        setLoading(false);
        let notificationsResponse = await Api.cliente.notifications({
            forceToken: localStorage.getItem("tk_beauty_cliente_token")
        });

        let _notifications = notificationsResponse?.data?.data;

        setNotifications(_notifications);
        setLoading(true);
    }

    return (
        <FragmentView title={"Notificações"}>
            <Card style={{padding: '10px'}}>
                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                    <ion-icon name="notifications-outline"></ion-icon>&nbsp;
                    <b>Suas notificações</b>
                </div>
                <div className='mt-1 mb-1'>
                    {notifications?.length > 0 ? (
                        notifications?.map((h, index) => (
                            <>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', alignItems: 'start'}}>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: 'black', background: '#ddd'}}>
                                            <ion-icon name="notifications-circle-outline"></ion-icon>
                                        </div>&nbsp;&nbsp;&nbsp;
                                        <div>
                                            <b style={{}}>{h?.titulo}</b>
                                            <p style={{color: 'gray'}}>{h?.mensagem}</p>
                                            <p className='mt-1' style={{color: 'black', fontSize: '10pt', textAlign: 'left'}}>
                                                {Utils.converterDate(h?.ts_registro)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {index < (notifications?.length - 1) ? (
                                    <Hr elevation={0.5}/>
                                ) : (null)}
                            </>
                        ))
                    ) : (
                        loading == true ? (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                <b style={{color: 'gray'}}>Nenhuma notificação!</b>
                            </div>
                        ) : (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                <b style={{color: 'gray'}}>Carregando...</b>
                            </div>
                        )
                    )}
                </div>
            </Card>
            <SpaceBox space={80}/>
        </FragmentView>
    )
}
