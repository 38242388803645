import React, { useEffect } from 'react';
import './style.css';
import { useNavigate, useLocation } from 'react-router-dom';

export default ({pageIndex, setPageIndex}) => {

    const navigator = useNavigate();
    const location = useLocation();

    const hide_in_pages = ["/forgot", "/terms-of-use", "/privacity-policity", "/payment-link"];

    useEffect(() => {
        const list = document.querySelectorAll(".list");

        function activeLink() {
            list.forEach((item) => {
                item.classList.remove('active');
                this.classList.add('active');
            });
        }

        list.forEach((item) => {
            item.addEventListener('click', activeLink);
        });

        const updatePageIndex = () => {
            const path = location.pathname;
            switch (path) {
                case '/':
                    setPageIndex(1);
                    break;
                case '/favorites':
                    setPageIndex(2);
                    break;
                case '/reservations':
                    setPageIndex(3);
                    break;
                case '/market':
                    setPageIndex(4);
                    break;
                case '/profile':
                    setPageIndex(5);
                    break;
                default:
                    break;
            }
        };

        // Adiciona o ouvinte para a mudança na localização
        updatePageIndex();

        // Remove o ouvinte quando o componente é desmontado
        return () => {
            // Remove o ouvinte ao desmontar o componente
            list.forEach((item) => {
                item.removeEventListener('click', activeLink);
            });
        };
    }, [location, setPageIndex]);

    const clickSaveButtonIndex = (index, route) => {
        localStorage.setItem("tk_bauty_bottomtab_index", index);
        setPageIndex(index);
        navigator(route);
    };

    return (
        hide_in_pages.includes(window.location.pathname) ? (null) : (
        <div className='navigation-container'>
            <div className='navigation'>
                <ul>
                    <li onClick={() => {clickSaveButtonIndex(1, "/")}} className={`list ${pageIndex == 1 ? 'active' : ''}`}>
                        <a href='javascript:void(0)'>
                            <span className='icon'>
                                {pageIndex == 1 ? (
                                    <ion-icon name={`home`}></ion-icon>
                                ) : (
                                    <ion-icon name={`home-outline`}></ion-icon>
                                )}
                            </span>
                            <span className='text'>Início</span>
                        </a>
                    </li> 
                    <li onClick={() => {clickSaveButtonIndex(2, "/favorites")}} className={`list ${pageIndex == 2 ? 'active' : ''}`}>
                        <a href='javascript:void(0)'>
                            <span className='icon'>
                                {pageIndex == 2 ? (
                                    <ion-icon name={`heart`}></ion-icon>
                                ) : (
                                    <ion-icon name={`heart-outline`}></ion-icon>
                                )}
                            </span>
                            <span className='text'>Favoritos</span>
                        </a>
                    </li> 
                    <li onClick={() => {clickSaveButtonIndex(3, "/reservations")}} className={`list ${pageIndex == 3 ? 'active' : ''}`}>
                        <a href='javascript:void(0)'>
                            <span className='icon'>
                                {pageIndex == 3 ? (
                                    <ion-icon name={`calendar`}></ion-icon>
                                ) : (
                                    <ion-icon name={`calendar-outline`}></ion-icon>
                                )}
                            </span>
                            <span className='text'>Reservas</span>
                        </a>
                    </li> 
                    <li onClick={() => {clickSaveButtonIndex(4, "/market")}} className={`list ${pageIndex == 4 ? 'active' : ''}`}>
                        <a href='javascript:void(0)'>
                            <span className='icon'>
                                {pageIndex == 4 ? (
                                    <ion-icon name="bag"></ion-icon>
                                ) : (
                                    <ion-icon name="bag-outline"></ion-icon>
                                )}
                            </span>
                            <span className='text'>Market</span>
                        </a>
                    </li> 
                    <li onClick={() => {clickSaveButtonIndex(5, "/profile")}} className={`list ${pageIndex == 5 ? 'active' : ''}`}>
                        <a href='javascript:void(0)'>
                            <span className='icon'>
                                {pageIndex == 5 ? (
                                    <ion-icon name={`person`}></ion-icon>
                                ) : (
                                    <ion-icon name={`person-outline`}></ion-icon>
                                )}
                            </span>
                            <span className='text'>Perfil</span>
                        </a>
                    </li> 
                    <div className='indicator'></div>
                </ul>
            </div>
        </div>)
    )
}
