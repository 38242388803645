import React, { useEffect } from 'react';
import {Card, Container, HeaderWithBack, SpaceBox} from '../../components';
import './style.css';

export default () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Container>
            <HeaderWithBack title={"Termos de Uso"}/>
            <SpaceBox space={20}/>
            <Card className={"body-terms"}>
                <h1>Termos de Uso</h1>
                <p>Bem-vindo ao IGend! Estes termos de uso descrevem as regras e regulamentos para a utilização do nosso site e serviços. Ao acessar ou usar nossos serviços, você concorda em cumprir e ficar vinculado a estes termos. Se você não concordar com qualquer parte destes termos, não utilize nossos serviços.</p>

                <div class="section-terms">
                    <h2>1. Definições</h2>
                    <p>Nestes Termos de Uso, as seguintes definições se aplicam:</p>
                    <ul>
                        <li><strong>IGend:</strong> Plataforma de gestão de agendamentos e serviços.</li>
                        <li><strong>Usuário:</strong> Qualquer pessoa que acessa e utiliza os serviços do IGend.</li>
                        <li><strong>Parceiro:</strong> Profissional ou estabelecimento que oferece serviços através da plataforma IGend.</li>
                    </ul>
                </div>

                <div class="section-terms">
                    <h2>2. Elegibilidade</h2>
                    <p>Para utilizar os serviços do IGend, você deve:</p>
                    <ul>
                        <li>Ter pelo menos 18 anos de idade ou ser emancipado.</li>
                        <li>Fornecer informações verdadeiras, precisas e completas durante o processo de registro.</li>
                        <li>Atualizar suas informações de registro para mantê-las precisas e completas.</li>
                    </ul>
                </div>

                <div class="section-terms">
                    <h2>3. Conta do Usuário</h2>
                    <h3>3.1. Registro</h3>
                    <p>Para acessar certas funcionalidades do IGend, você deve criar uma conta. Você concorda em fornecer informações precisas e completas durante o registro e a manter essas informações atualizadas.</p>

                    <h3>3.2. Responsabilidade da Conta</h3>
                    <p>Você é responsável por manter a confidencialidade das informações da sua conta, incluindo sua senha, e por todas as atividades que ocorram sob sua conta. Você concorda em notificar imediatamente o IGend de qualquer uso não autorizado da sua conta ou qualquer outra violação de segurança.</p>
                </div>

                <div class="section-terms">
                    <h2>4. Uso dos Serviços</h2>
                    <h3>4.1. Uso Permitido</h3>
                    <p>Você concorda em utilizar os serviços do IGend somente para fins legais e de acordo com estes Termos de Uso. Você não deve usar nossos serviços para:</p>
                    <ul>
                        <li>Violar quaisquer leis ou regulamentos aplicáveis.</li>
                        <li>Infringir ou violar nossos direitos ou os direitos de terceiros.</li>
                        <li>Enviar spam ou outras mensagens não solicitadas.</li>
                        <li>Distribuir vírus ou qualquer outro código de computador prejudicial.</li>
                    </ul>

                    <h3>4.2. Conteúdo Gerado pelo Usuário</h3>
                    <p>Você é responsável por todo o conteúdo que publicar, enviar ou de outra forma disponibilizar através dos nossos serviços. Você declara e garante que possui todos os direitos necessários para conceder ao IGend uma licença para usar tal conteúdo.</p>
                </div>

                <div class="section-terms">
                    <h2>5. Planos e Pagamentos</h2>
                    <h3>5.1. Assinaturas</h3>
                    <p>Alguns serviços do IGend são oferecidos através de planos de assinatura. Os preços e as condições das assinaturas são descritos no site e estão sujeitos a alterações. O usuário será notificado previamente sobre qualquer alteração de preço.</p>

                    <h3>5.2. Pagamentos</h3>
                    <p>Todos os pagamentos devem ser realizados de acordo com os métodos de pagamento aceitos pelo IGend. Você concorda em pagar todas as taxas aplicáveis e impostos associados ao uso dos serviços pagos.</p>

                    <h3>5.3. Cancelamentos e Reembolsos</h3>
                    <p>As políticas de cancelamento e reembolso estão descritas na nossa Política de Privacidade. Você deve revisar essas políticas antes de contratar um serviço pago.</p>
                </div>

                <div class="section-terms">
                    <h2>6. Propriedade Intelectual</h2>
                    <p>Todo o conteúdo e materiais disponíveis no IGend, incluindo, mas não se limitando a, textos, gráficos, logotipos, ícones, imagens, clipes de áudio, downloads digitais e software, são de propriedade do IGend ou de seus licenciadores e estão protegidos por leis de direitos autorais, marcas registradas e outras leis de propriedade intelectual.</p>
                </div>

                <div class="section-terms">
                    <h2>7. Armazenamento de Dados</h2>
                    <p>Armazenamos dados dos usuários, incluindo informações sensíveis como dados de cartão de crédito, de forma criptografada para garantir a segurança e a privacidade. Utilizamos protocolos de segurança avançados para proteger suas informações contra acessos não autorizados e violações.</p>
                </div>

                <div class="section-terms">
                    <h2>8. Limitação de Responsabilidade</h2>
                    <p>Na máxima extensão permitida por lei, o IGend não será responsável por quaisquer danos indiretos, incidentais, especiais, consequenciais ou punitivos, ou por qualquer perda de lucros ou receitas, incorridos direta ou indiretamente, ou por qualquer perda de dados, uso, goodwill ou outras perdas intangíveis, resultantes de:</p>
                    <ul>
                        <li>Seu uso ou incapacidade de usar nossos serviços.</li>
                        <li>Qualquer conduta ou conteúdo de terceiros nos serviços.</li>
                        <li>Acesso não autorizado, uso ou alteração do seu conteúdo.</li>
                    </ul>
                </div>

                <div class="section-terms">
                    <h2>9. Indenização</h2>
                    <p>Você concorda em indenizar, defender e isentar o IGend, seus diretores, funcionários, parceiros e agentes, de e contra todas as reivindicações, responsabilidades, danos, perdas e despesas, incluindo honorários advocatícios e custos relacionados, decorrentes de ou de alguma forma relacionados ao seu acesso ou uso dos serviços, à sua violação destes Termos de Uso, ou à sua violação de quaisquer direitos de terceiros.</p>
                </div>

                <div class="section-terms">
                    <h2>10. Rescisão</h2>
                    <p>O IGend pode rescindir ou suspender seu acesso aos serviços imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, sem limitação, se você violar estes Termos de Uso. Após a rescisão, seu direito de usar os serviços cessará imediatamente.</p>
                </div>

                <div class="section-terms">
                    <h2>11. Disposições Gerais</h2>
                    <h3>11.1. Alterações nos Termos</h3>
                    <p>Reservamos o direito de modificar estes Termos de Uso a qualquer momento. Quando fizermos alterações, atualizaremos a data no topo deste documento e notificaremos você através de um aviso em nosso site ou por outros meios apropriados.</p>

                    <h3>11.2. Lei Aplicável</h3>
                    <p>Estes Termos de Uso serão regidos e interpretados de acordo com as leis do Brasil, sem consideração aos seus conflitos de princípios legais.</p>

                    <h3>11.3. Resolução de Disputas</h3>
                    <p>Qualquer disputa decorrente ou relacionada a estes Termos de Uso ou ao uso dos serviços será resolvida exclusivamente nos tribunais localizados no Distrito Federal, Brasil.</p>

                    <h3>11.4. Acordo Integral</h3>
                    <p>Estes Termos de Uso constituem o acordo completo entre você e o IGend com relação ao uso dos serviços e substituem todos os acordos anteriores ou contemporâneos, comunicações e propostas, sejam elas orais ou escritas, entre você e o IGend.</p>
                </div>

                <div class="section-terms">
                    <h2>12. Contato</h2>
                    <p>Se você tiver quaisquer perguntas sobre estes Termos de Uso, entre em contato conosco:</p>
                    <ul>
                        <li>Telefone: +55 (61) 99193-0897</li>
                        <li>Email: suporte@igend.com.br</li>
                        <li>Local: Brasil - Distrito Federal</li>
                    </ul>
                </div>
            </Card>
        </Container>
    )
}
