import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal';
import Button from '../Button';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import { Scanner } from '@yudiel/react-qr-scanner';
import Utils from '../../Utils';
import Card from '../Card';
import Switch from '../Switch';

export default ({parceiro, handleEye, showEyes}) => {

    const navigate = useNavigate();

    const {user} = useContext(MainContext);

    const [openSettings, setOpenSettings] = useState(false);
    const [openScan, setOpenScan] = useState(false);

    const [blockAgendamentos, setBlockAgendamentos] = useState(false);

    const handleSettings = () => { 
        setOpenSettings(true);
    }

    const handleClick = () => {
        navigate('/search');
    }

    const handleScan = () => {
        setOpenScan(true);
    }

    const onHandleScannedQrCodeResult = async (e) => {
        try{
            let text = e[0]?.rawValue;
            let chave = Utils.verificarUrlParceiro(text);
            if(chave != null){
                let responseParceiro = await Api.geral.parceiro({
                    id_parceiro: chave,
                })

                Utils.processRequest(responseParceiro, (data) => {}, (err) => {
                    setOpenScan(false);
                }, false)

                let _parceiro = responseParceiro?.data?.data;
                if(_parceiro != null){
                    navigate('/parceiro/'+chave);
                }
            }
        }catch(err){
            Utils.notify("error", "Parceiro não encontrado!");
            setOpenScan(false);
        }
    }

    return (
        <>
            <Modal setShow={setOpenSettings} show={openSettings}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <b style={{fontSize: '1.2rem'}}>Configurações</b>
                </div>
                <Card className={"mb-1 mt-1"}>
                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="close-circle-outline"></ion-icon>&nbsp; 
                        <b>Bloqueios</b>
                    </div>
                    <div className='mb-1' style={{border: 'solid 1px #ddd', borderRadius: '8px', padding: '10px'}}>
                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                            <Switch
                                index={99} 
                                isOn={blockAgendamentos} 
                                onChange={() => {
                                    let _blockAgendamentos = !blockAgendamentos;
                                    setBlockAgendamentos(_blockAgendamentos);
                                }}
                                colorOne="#000"
                                colorTwo="#ddd"/>&nbsp;&nbsp;
                            <b>Bloquear agenda</b>
                        </div>
                        <p style={{fontSize: '10pt'}}>Bloquear agendamentos temporariamente até eu desbloquear.</p>
                    </div>
                </Card>
            </Modal>
            <Modal setShow={setOpenScan} show={openScan}>
                <div className='mb-1'>
                    <b style={{fontSize: '1.2rem'}}>Escanear estabelecimento</b><br/>
                    <span style={{color: 'rgba(0, 0, 0, 0.9)', fontSize: '10pt'}}>*Aponte a camera para o qrcode do estabelecimento</span>
                </div>
                <Scanner onScan={onHandleScannedQrCodeResult} />
            </Modal>
            {parceiro ? (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h3>Agendamentos</h3>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {user?.role == 'A' ? (
                            showEyes ? (
                                <>
                                    &nbsp;&nbsp;<ion-icon onClick={handleEye} name="eye-outline" style={{fontSize: '1.7rem', fontWeight: 'bold', cursor: 'ponter', cursor: 'pointer'}}></ion-icon>&nbsp;&nbsp;&nbsp;
                                </>
                            ) : (
                                <>
                                    &nbsp;&nbsp;<ion-icon onClick={handleEye} name="eye-off-outline" style={{fontSize: '1.7rem', fontWeight: 'bold', cursor: 'ponter', cursor: 'pointer'}}></ion-icon>&nbsp;&nbsp;&nbsp;
                                </>
                            )
                        ) : (null)}
                        <ion-icon onClick={handleSettings} name="settings-outline" style={{fontSize: '1.5rem', fontWeight: 'bold', cursor: 'ponter', cursor: 'pointer'}}></ion-icon>
                    </div>
                </div>
            ) : (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h3>Encontre seu <br/> profissional favorito</h3>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon onClick={handleScan} name="scan-outline" style={{fontSize: '1.5rem', fontWeight: 'bold'}}></ion-icon>&nbsp;&nbsp;&nbsp;&nbsp;
                        <ion-icon onClick={handleClick} name="search-outline" style={{fontSize: '1.5rem', fontWeight: 'bold'}}></ion-icon>
                    </div>
                </div>
            )}
        </>
    )
}
