import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, ColaboradorItem, FlatButton, FragmentView, Input, Modal, Parceiro, SpaceBox, Switch } from '../../components'
import './style.css';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import Utils from '../../Utils';
import Environment from '../../Environment';
import ImageCompressor from 'image-compressor';

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);
    const [colaboradores, setColaboradores] = useState([]);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [mode, setMode] = useState(null);
    const [colaboradorSelected, setColaboradorSelected] = useState(null);
    const [ativo, setAtivo] = useState(true);
    const [nome, setNome] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [email, setEmail] = useState("");
    const [celular, setCelular] = useState("");
    const [senha, setSenha] = useState("");
    const [senhaConfirmar, setSenhaConfirmar] = useState("");
    const [disponivel, setDisponivel] = useState(true);

    useEffect(() => {
        requestColaboradores();
    }, [])

    const requestColaboradores = async () => {
        let response = await Api.colaborador.colaboradoresByParceiro({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro
        });
        let _colaboradores = response?.data?.data;
        setColaboradores(_colaboradores);
        setLoading(true);
    }

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
    
        // Comprimir a imagem
        try {
            const compressedFile = await new ImageCompressor(file, {
                quality: 0.5, // Ajuste a qualidade conforme necessário
                maxWidth: 200, // Ajuste o tamanho máximo conforme necessário
                maxHeight: 200, // Ajuste o tamanho máximo conforme necessário
                convertSize: 5000000, // Limite de tamanho do arquivo em bytes (neste caso, 5MB)
            });
    
            setImageFile(compressedFile);
        } catch (error) {
            console.error(error);
            setImageFile(file); // Em caso de erro, use o arquivo original
        }
    };

    const equipeModal = () => {
        setMode("CREATE");
        setColaboradorSelected(null);
        setShow(true);
    }

    const onCloseCallback = () => {
        setAtivo(true);
        setNome("");
        setImageFile(null);
        setEmail("");
        setCelular("");
        setSenha("");
        setSenhaConfirmar("");
        setDisponivel(true);
        setColaboradorSelected(null)
    }

    const saveColaborador = async () => {

        if(nome?.trim()?.length <= 0){
            Utils.notify("error", `Digite o nome do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}.`);
            return;
        }

        if(email?.trim()?.length <= 0){
            Utils.notify("error", `Digite o email do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}.`);
            return;
        }

        if(celular?.trim()?.length <= 0){
            Utils.notify("error", `Digite o celular do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}.`);
            return;
        }

        if(mode == "CREATE"){
            if (imageFile && !Utils.isImageValid(imageFile)) {
                return;
            }
        }else{
            if(!imageFile){
                Utils.notify("error", "Selecione uma imagem.");
            }
        }

        let request = null;

        switch(mode){
            case "CREATE":

                if(senha?.trim()?.length <= 0){
                    Utils.notify("error", `Digite a senha do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}.`);
                    return;
                }
        
                if(senha != senhaConfirmar){
                    Utils.notify("error", "As senhas não se coincidem.");
                    return;
                }

                request = await Api.colaborador.addColaborador({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    id_parceiro: user?.id_parceiro,
                    ativo: ativo ? 1 : 0,
                    nome: nome,
                    imagem: imageFile,
                    email: email,
                    celular: celular?.replaceAll("(", "").replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", ""),
                    senha: senha,
                    disponivel: disponivel ? 1 : 0
                });
                break;
            case "UPDATE":
                request = await Api.colaborador.updateColaborador({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    id_parceiro: colaboradorSelected?.id_parceiro,
                    id_colaborador: colaboradorSelected?.id_colaborador,
                    ativo: ativo ? 1 : 0,
                    nome: nome,
                    imagem: imageFile,
                    email: email,
                    celular: celular?.replaceAll("(", "").replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", ""),
                    disponivel: disponivel ? 1 : 0
                });
                break;
        }

        Utils.processRequest(request, (data) => {
            if(mode == "CREATE"){
                Utils.notify("success", `Foi enviado um link de acesso para o ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}.`);
            }else{
                Utils.notify("success", `${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME} atualizado com sucesso.`);
            }
            requestColaboradores();
            onCloseCallback();
            setShow(false);
        }, (error) => {});

    }
   
    const openModalEdit = async (colaborador) => {
        setShow(true);
        setMode("UPDATE");
        setColaboradorSelected(colaborador);
        setAtivo(colaborador?.ativo == 1);
        setNome(colaborador?.nome);
        setImageFile(await Utils.fetchImage(Environment.API_IMAGE+'/imagem/'+colaborador.foto))
        setEmail(colaborador?.email);
        setCelular(Utils.formatarParaCelular(colaborador?.celular));
        setSenha(colaborador?.senha);
        setSenhaConfirmar(colaborador?.senha);
        setDisponivel(colaborador?.disponivel);
    }

    const handleDeleteColaborador = (colaborador) => {
        setShowDelete(true);
        setColaboradorSelected(colaborador);
    }

    const handleDeleteColaboradorOk = async () => {
        let request = await Api.colaborador.deleteColaborador({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro,
            id_colaborador: colaboradorSelected?.id_colaborador
        })
        Utils.processRequest(request, (data) => {
            Utils.notify("success", `${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()} excluído com sucesso!`);
            requestColaboradores();
            onCloseCallback();
            setShowDelete(false);
        }, (error) => {});
    }

    const shareAccessLink = () => {
        if (navigator.share) {
            // Objeto de dados para compartilhar
            const dadosCompartilhar = {
              title: `${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()} - ${user?.nome_parceiro}`,
              text: `Acesso ao painel do ${user?.nome_parceiro}`,
              url: window.location.origin+'/colaborador-login'
            };
          
            // Chama o método 'share' do objeto 'navigator'
            navigator.share(dadosCompartilhar)
              .then(() => console.log('Link compartilhado com sucesso!'))
              .catch((error) => console.error('Erro ao compartilhar link:', error));
        } else {
            console.log('A funcionalidade de compartilhamento não é suportada neste navegador.');
        }
    }

    return (
        <>
            <FlatButton text={"Adicionar"} onClick={equipeModal}>+</FlatButton>
            <Modal setShow={setShow} show={show} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>{mode == "CREATE" ? `Adicionar ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}` : `Atualizar ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}`}</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>{mode == "CREATE" ? "Adicione" : "Atualize"} o {Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()} ao negócio.</div>
                    </div>
                </div>
                <div>
                    <div className='mt-2 mb-1' style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <Input type='file' accept="image/*" id='imagem' onChange={handleImageChange} hidden/>
                        <label htmlFor='imagem'>
                            <div className='shadow' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray', border: 'solid 1px gray', borderRadius: '50%', padding: imageFile ? '1px' : '8px', width: '70px', height: '70px'}}>
                                {imageFile ? <img src={URL.createObjectURL(imageFile)} alt="Imagem selecionada" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}} /> : <ion-icon name="image-outline" size={"large"}></ion-icon>}  
                            </div>
                        </label>
                        <label htmlFor='imagem'>
                            <b style={{color: 'gray'}}>&nbsp;&nbsp;Foto do {Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}</b>
                        </label>
                    </div>
                    <Checkbox flexStart setChecked={setAtivo} checked={ativo}>{Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME} ativo?</Checkbox>
                    <Input type="text" label={`Nome do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}`} value={nome} setValue={setNome}/>
                    <Input type="email" label={`Email do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}`} value={email} setValue={setEmail}/>
                    <Input type="celular" label={`Celular do ${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}`} value={celular} setValue={setCelular}/>
                    {mode == "CREATE" ? (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Input type="password" style={{width: '100%'}} hideInputBoxMargin label="Senha" value={senha} setValue={setSenha}/>&nbsp;&nbsp;
                            <Input type="password" style={{width: '100%'}} hideInputBoxMargin label="Confirmar senha" value={senhaConfirmar} setValue={setSenhaConfirmar}/>
                        </div>
                    ) : (null)}
                    <Checkbox flexStart setChecked={setDisponivel} checked={disponivel}>{Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME} disponível?</Checkbox>
                </div>
                <div>
                    <Button onClick={saveColaborador} style={{width: '100%'}}>Salvar</Button>
                </div>
            </Modal>
            <Modal show={showDelete} setShow={setShowDelete} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <b>Confirmar exclusão do {Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()}?</b>
                </div>
                <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Button style={{color: '#ff2e36', background: '#fceaeb', height: '40px',}} onClick={() => {setShowDelete(false)}} >&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>
                    <Button style={{height: '40px'}} onClick={handleDeleteColaboradorOk}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <FragmentView title={`${Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_BOTTOM_MENUNAME_EQUIPE}`} onHanderAction={{action: shareAccessLink,  text: "Link de Acesso"}} actionIconComponent="share-social-outline">
                {loading ? (
                    colaboradores?.length > 0 ? (
                        colaboradores?.map((colaborador, index) => (
                            <ColaboradorItem className={`mb-1`} index={index} key={colaborador?.id_colaborador} openModalEdit={openModalEdit} handleDeleteColaborador={handleDeleteColaborador} colaborador={colaborador} />
                        ))
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                            <b style={{color: 'gray'}}>Nenhum {Utils.parceiroCategorryMapper(user?.categoria_parceiro).PARCEIRO_AGENDA_SELECT_LABEL_NAME?.toLowerCase()} cadastrado.</b>
                        </div>
                    )
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                        <b style={{color: 'gray'}}>Carregando...</b>
                    </div>
                )}
                <SpaceBox space={80}/>
            </FragmentView>
        </>
    )
}
