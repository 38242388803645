import React, { useContext, useEffect, useState } from 'react';
import {AccountModal, AvaliarModal, Button, Card, Container, DoLoginBanner, HeaderWithBack, Input, Modal, ReservationItem, Ship, SpaceBox, SwipperRefresh} from '../../components';
import './style.css';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';
import { format, isToday, isYesterday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Utils from '../../Utils';

export default () => {

    const {user, onRefreshHandler} = useContext(MainContext);
    
    const [accountModalShow, setAccountModalShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [reservations, setReservations] = useState([]);

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");

    const [showModalPayment, setShowModalPayment] = useState(false);
    const [modalPaymentStep, setModalPaymentStep] = useState(1);

    const [showModalConfirmation, setShowModalConfirmation] = useState(false);

    const [reservaSelected, setReservaSelected] = useState(null);

    const uniqueDates = {};

    useEffect(() => {
        if(user){
            setAccountModalShow(false);
            setup(localStorage.getItem("tk_beauty_cliente_token"));
        }else{
            setAccountModalShow(true);
            setLoading(true);
        }
    }, [])

    const setup = async (token) => {
        setLoading(false);

        setNome(user?.nome);
        setEmail(user?.email);
        setCpf(user?.cpf)

        let _token = localStorage.getItem("tk_beauty_cliente_token") || token;

        let responseAgendamentos = await Api.cliente.obterAgendamentos(_token);

        let _reservations = responseAgendamentos?.data?.data;

        if(_reservations[0]?.status_pagamento == 'A' && localStorage.getItem("tk-beauty-has-agendamento")){
            if(_reservations[0]?.local_pagamento == 'A'){
                setShowModalPayment(true);
            }else{
                setShowModalConfirmation(true);
            }
            setReservaSelected(_reservations[0]);
            localStorage.removeItem("tk-beauty-has-agendamento")
        }

        if(!checkIfUserPropsIsNull()){
            setModalPaymentStep(2);
        }

        setReservations(_reservations);
        setLoading(true);
    }  

    const onTokenCallback = async (token) => {
        setup(token);
    }

    function handleOpenModalPayment (reserva) {
        setShowModalPayment(true);
        setReservaSelected(reserva);
        if(!checkIfUserPropsIsNull()){
            setModalPaymentStep(2);
        }
    }

    function handleOpenModalConfirmation (reserva) {
        setShowModalPayment(true);
        setReservaSelected(reserva);
    }

    const checkIfUserPropsIsNull = () => {
        if(!user?.nome || !user?.cpf || !user?.email){
            return true;
        } else {
            return false;
        }
    }

    const handleCredentialsUpdate = async () => {

        if(!nome){
            Utils.notify("error", "Digite seu nome.");
            return false;
        }

        if(!Utils.validateEmail(email)){
            Utils.notify("error", "Digite um email válido.");
            return false;
        }

        if(!Utils.validateCpf(cpf)){
            Utils.notify("error", "Digite um CPF válido.");
            return false;
        }

        let clienteUpdateResponse = await Api.cliente.update({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            data: {
                nome: nome,
                email: email,
                cpf: cpf?.toString()?.replaceAll(".", "")?.replaceAll("-", ""),
            }
        })

        let data = clienteUpdateResponse?.data;

        if(data?.success){
            return true;
        }else{
            Utils.notify("error", data?.message);
            return false;
        }
    }

    const handleNextModalPaymentStep = async () => {
        if(checkIfUserPropsIsNull()){
            let credentialsUpdated = await handleCredentialsUpdate();
            if(credentialsUpdated){
                setModalPaymentStep(modalPaymentStep+1);
            }
        }else{
            setModalPaymentStep(modalPaymentStep+1);
        }
    }

    const handleBackModalPaymentStep = () => {
        setModalPaymentStep(modalPaymentStep-1);
    }

    const formatReservationDate = (timestamp) => {
        const date = new Date(timestamp);
        if (isToday(date)) {
            return 'Hoje';
        } else if (isYesterday(date)) {
            return 'Ontem';
        } else {
            return format(date, "d 'de' MMMM", { locale: ptBR });
        }
    };

    const modalPaymentCloseCallback = async () => {
        setup(localStorage.getItem("tk_beauty_cliente_token"));
    }

    return (
        <SwipperRefresh onRefreshHandler={onRefreshHandler}>
            <Container>
                <AccountModal show={accountModalShow} setShow={setAccountModalShow} onTokenCallback={onTokenCallback}/>
                <HeaderWithBack title={"Reservas"}/>
                {!user ? (
                    <DoLoginBanner onClickButton={() => {setAccountModalShow(true)}}/>
                ) : (
                    loading ? (
                        <>
                            <Modal show={showModalPayment} setShow={setShowModalPayment} onCloseCallback={modalPaymentCloseCallback}>
                                {modalPaymentStep == 1 ? (
                                    <>
                                        <div className='mb-2'>
                                            <b style={{fontSize: '1.2rem'}}><span style={{background: '#000', color: 'white', borderRadius: "50%", paddingLeft: '7px', paddingRight: '7px'}}>1</span> Identificação</b>
                                        </div>
                                        <Input type={"nome"} setValue={setNome} value={nome} label={"Qual seu nome?"}/>
                                        <Input type={"email"} setValue={setEmail} value={email} label={"Qual seu email?"}/>
                                        <Input type={"cpf"} setValue={setCpf} value={cpf} label={"Qual seu CPF?"}/>
                                        <Button onClick={handleNextModalPaymentStep} style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                                            <b>Avançar</b>&nbsp;
                                            <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </Button>
                                    </>
                                ) : modalPaymentStep == 2 ? (
                                    <>
                                        {reservaSelected?.local_pagamento == 'A' ? ( //pagar no app
                                            <>
                                                {reservaSelected?.metodo_pagamento == 'CARD' ? (
                                                    <>
                                                        <div className='mb-2'>
                                                            <b style={{fontSize: '1.2rem'}}><span style={{background: '#000', color: 'white', borderRadius: "50%", paddingLeft: '7px', paddingRight: '7px'}}>2</span> Pagamento</b>
                                                        </div>
                                                        <iframe style={{border: 'none', width: '100%', minHeight: '430px', maxHeight: '500px'}} src={`https://payment.igend.com.br?payment_type=card&id_cliente=${user?.id_cliente}&id_agendamento=${reservaSelected?.id_agendamento}&card=false&force_body_block=false`}/>
                                                    </>
                                                ) : reservaSelected?.metodo_pagamento == 'PIX' ? (
                                                    <>
                                                        <div className='mb-2'>
                                                            <b style={{fontSize: '1.2rem'}}><span style={{background: '#000', color: 'white', borderRadius: "50%", paddingLeft: '7px', paddingRight: '7px'}}>2</span> Pagamento</b>
                                                        </div>
                                                        <iframe style={{border: 'none', width: '100%', minHeight: '460px', maxHeight: '600px'}} src={`https://payment.igend.com.br?payment_type=pix&id_cliente=${user?.id_cliente}&id_agendamento=${reservaSelected?.id_agendamento}&card=false&force_body_block=false`}/>
                                                    </>
                                                ) :  (null)}
                                            </>
                                        ):(//pagar presencial
                                            <>

                                            </>
                                        )}
                                    </>
                                ) : (null)}
                            </Modal>
                            <Modal show={showModalConfirmation} setShow={setShowModalConfirmation}>
                                {/*Modal de confiemação se for presencial [DEPRECATED]*/}
                            </Modal>
                            <div className='mt-1 mb-1'></div>
                            
                            
                            {reservations?.length > 0 ? (
                                <>
                                    
                                    {reservations.map((reserva, index) => {
                                    
                                    if (!uniqueDates[formatReservationDate(reserva.ts_registro)]) {
                                    
                                        uniqueDates[formatReservationDate(reserva.ts_registro)] = true;
                                        
                                        return (
                                            <>
                                                <div style={{position: 'sticky', top: '0px', zIndex: 1, background: '#f5f5f5', padding: '8px', fontWeight: 'bold', marginBottom: '10px' }}>
                                                    {formatReservationDate(reserva.ts_registro)}
                                                </div>
                                                <ReservationItem
                                                    key={reserva.id}
                                                    expanded={index === 0 && reserva?.status_pagamento == 'A'}
                                                    style={{ opacity: reserva.status_agendamento === 'A' ? '1' : '0.6' }}
                                                    reserva={reserva}
                                                    handleOpenModalPayment={() => handleOpenModalPayment(reserva)}
                                                    handleOpenModalConfirmation={() => handleOpenModalConfirmation(reserva)}
                                                    onFetchReservations={setup}
                                                />
                                            </>
                                        );
                                    } else {
                                        return (
                                            <ReservationItem
                                                key={reserva.id}
                                                expanded={index === 0 && reserva?.status_pagamento == 'A'}
                                                style={{ opacity: reserva.status_agendamento === 'A' ? '1' : '0.6' }}
                                                reserva={reserva}
                                                handleOpenModalPayment={() => handleOpenModalPayment(reserva)}
                                                handleOpenModalConfirmation={() => handleOpenModalConfirmation(reserva)}
                                                onFetchReservations={setup}
                                            />
                                        );
                                    }
                                    })}
                                </>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mt-1 mb-1'>
                                    <b style={{ color: 'gray' }}>Nenhum agendamento</b>
                                </div>
                            )}


                        </>
                    ) : (
                        <>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                <b style={{color: 'gray'}}>Carregando...</b>
                            </div>
                        </>
                    )
                )}
                <SpaceBox space={70}/>
                {user ? (<AvaliarModal/>) : (null)}
            </Container>
        </SwipperRefresh>
    )
}
