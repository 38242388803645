import React, { useContext, useEffect, useState } from 'react';
import {AccountModal, Button, Card, Container, DoLoginBanner, HeaderWithBack, Hr, Modal, Ship, SpaceBox} from '../../components';
import './style.css';
import { MainContext } from '../../helpers/MainContext';
import { useNavigate } from 'react-router-dom';

export default () => {

    const navigate = useNavigate();

    const {user, logout, setUser, setAuthenticated} = useContext(MainContext);
    const [accountModalShow, setAccountModalShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    useEffect(() => {
        setLoading(false);
        if(user){
            setAccountModalShow(false);
        }else{
            setAccountModalShow(true);
        }
        setLoading(true);
    }, [])

    const handleLogout = async () => {
        setShow(true);
    }

    const handleLogoutConfirm = async () => {
        logout(() => {
            setUser(null);
            setAuthenticated(false);
            navigate("/");
        })
    }

    return (
        <Container>
            <AccountModal show={accountModalShow} setShow={setAccountModalShow}/>
            <Modal show={show} setShow={setShow}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <b>Deseja desconectar sua conta agora?</b>
                </div>
                <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                    <Button style={{color: '#ff2e36', background: '#fceaeb', height: '40px',}} onClick={() => {setShow(false)}} >&nbsp;&nbsp;&nbsp;Não&nbsp;&nbsp;&nbsp;</Button>
                    <Button style={{height: '40px'}} onClick={handleLogoutConfirm}>&nbsp;&nbsp;&nbsp;Sim&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Modal>
            <HeaderWithBack title={"Perfil"}/>
            {!user ? (
                <DoLoginBanner onClickButton={() => {setAccountModalShow(true)}}/>
            ) : (
                [loading ? (
                    <>
                        <div className='mt-1 mb-1'></div>
                        <Card className={"mt-2 mb-1"}>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="person-outline"></ion-icon>&nbsp;
                                <b>Conta</b>
                            </div>
                            {user?.role == 'A' ? (
                                <>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Meus dados</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div onClick={() => {navigate('/adm-saldo')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Saque & Extrato</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Contas Pix</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Metas & Prêmios</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Histórico de Atividades</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Gerenciar Assinaturas</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Gerenciar Negócio</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Galeria de Fotos</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div onClick={() => {navigate('/adm-avaliacoes')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Avaliações</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Notificações</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Meus dados</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Metas & Prêmios</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Histórico de Atividades</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Notificações</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                </>
                            )}
                        </Card>
                        <Card className={"mb-1"}>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="cube-outline"></ion-icon>&nbsp; 
                                <b>Marketplace</b>
                            </div>
                            {user?.role == 'A' ? (
                                <>
                                    <div onClick={() => {navigate('/adm-products')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Ver Produtos</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div onClick={() => {navigate('/adm-pedidos')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Ver Pedidos</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <Hr elevation={0.5}/>
                                    <div onClick={() => {navigate('/adm-shipping')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                        <b style={{color: 'gray'}}>Locais de Entrega</b>
                                        <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                    </div>
                                </>
                            ) : (
                                <div onClick={() => {navigate('/adm-pedidos')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                    <b style={{color: 'gray'}}>Ver Pedidos</b>
                                    <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                </div>
                            )}
                        </Card>
                        {user?.role == 'A' ? (
                            <Card className={"mb-1"}>
                                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="megaphone-outline"></ion-icon>&nbsp; 
                                    <b>Marketing</b>
                                </div>
                                <div onClick={() => {navigate('/adm-clientes')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                    <b style={{color: 'gray'}}>Meus Clientes</b>
                                    <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                </div>
                                <Hr elevation={0.5}/>
                                <div onClick={() => {navigate('/adm-cupons')}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                    <b style={{color: 'gray'}}>Gerenciar Cupons</b>
                                    <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                </div>
                                <Hr elevation={0.5}/>
                                <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                    <b style={{color: 'gray'}}>Inpulsionar Negócio</b>
                                    <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                                </div>
                            </Card>
                        ) : (null)}
                        <Card className={"mb-1"}>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="shield-checkmark-outline"></ion-icon>&nbsp; 
                                <b>Segurança</b>
                            </div>
                            <div className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                <b style={{color: 'gray'}}>Alterar senha</b>
                                <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                            </div>
                        </Card>
                        <Card className={"mb-1"}>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="bookmark-outline"></ion-icon>&nbsp;
                                <b>Políticas</b>
                            </div>
                            <div onClick={() => {navigate("/privacity-policity")}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                <b style={{color: 'gray'}}>Política de privacidade</b>
                                <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                            </div>
                            <Hr elevation={0.5}/>
                            <div onClick={() => {navigate("/terms-of-use")}} className='btn-hover' style={{paddingLeft: '8px', paddingRight: '8px', paddingTop: '8px', paddingBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                                <b style={{color: 'gray'}}>Política de uso</b>
                                <ion-icon style={{color: 'gray'}} name="chevron-forward-outline"></ion-icon>
                            </div>
                        </Card>
                        <Button onClick={handleLogout} style={{width: '100%'}}>
                            <b>Desconectar</b>
                        </Button>
                    </>
                ) : (<></>)]
            )}
            <SpaceBox space={80}/>
        </Container>
    )
}
