import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import Button from '../Button';
import SpaceBox from '../SpaceBox';
import Card from '../Card';
import Select from '../Select';
import Option from '../Option';
import Utils from '../../Utils';

export default ({loading, defaultPage, items, handleRowEdit, handleRowRemove}) => {

    const [currentPage, setCurrentPage] = useState(defaultPage);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [orders, setOrders] = useState(items);
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        setCurrentPage(1);
    }, [setItemsPerPage, itemsPerPage]);

    useEffect(() => {
        setOrders(items);
    }, [items]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const next = () => {
        const totalPages = Math.ceil(orders.length / itemsPerPage);
        let nextPage = currentPage + 1;
        if(nextPage >= totalPages){
            setCurrentPage(totalPages);
        }else{
            setCurrentPage(nextPage);
        }
    }

    const previous = () => {
        let previousPage = currentPage - 1;
        if(previousPage <= 1){
            setCurrentPage(1);
        }else{
            setCurrentPage(previousPage);
        }
    }

    const getProprietyName = (array) => {
        if (array.length > 0) {
            return Object.keys(array[0]);
        }
        return [];
    };

    const mapperSpecificWordsValues = (word) => {
        switch(word){
            case "PRODUTO":
                return "Produto"
            case "SERVICO":
                return "Serviço";
            default:
                return word;
        }
    }

    const renderTableRows = () => {
        if (currentOrders.length === 0) {
            return (
                <tr>
                    <td colSpan={getProprietyName(items).length + 1} style={{textAlign: 'center'}}>
                        Nenhum dado encontrado.
                    </td>
                </tr>
            );
        }

        return currentOrders.map((order, index) => (
            <tr key={index} className='table-tr'>
                {getProprietyName(items).map((prop, propIndex) => (
                    <td key={propIndex} data-label={mapperSpecificWords(capitalizeFirstLetter(prop))}>
                        {prop == 'ts_registro' ? Utils.converterDateBR(order[prop]) : mapperSpecificWordsValues(order[prop])}
                    </td>
                ))}
                <td data-label="Ação">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Button onClick={() => {handleRowEdit(order)}} style={{display: 'flex', width: '40px', justifyContent: 'center', alignItems: 'center', background: '#ffffff', border: 'solid 1px #000', color: '#000', height: '40px'}}>
                            <ion-icon name="create-outline"></ion-icon>
                        </Button>&nbsp;
                        <Button onClick={() => {handleRowRemove(order)}} style={{display: 'flex', width: '40px', justifyContent: 'center', alignItems: 'center', background: '#ffffff', border: 'solid 1px red', color: 'red', height: '40px'}}>
                            <ion-icon name="trash-outline"></ion-icon>
                        </Button>
                    </div>
                </td>
            </tr>
        ));
    };

    const paginationRef = useRef(null);

    useEffect(() => {
        if (paginationRef.current) {
            const activeButton = paginationRef.current.querySelector('.pagination-button.active');
            if (activeButton) {
                const containerWidth = paginationRef.current.offsetWidth;
                const buttonOffsetLeft = activeButton.offsetLeft;
                const buttonWidth = activeButton.offsetWidth;
                const scrollTo = buttonOffsetLeft - (containerWidth / 2) + (buttonWidth / 2);
                paginationRef.current.scrollTo({
                    left: scrollTo,
                    behavior: 'smooth'
                });
            }
        }
    }, [currentPage]);

    const renderPagination = () => {
        const totalPages = Math.ceil(orders.length / itemsPerPage);
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div>
                    <Select setValue={setItemsPerPage} value={itemsPerPage} hideInputBoxMargin label={"Linhas"}>
                        <Option value={10}>10 linhas</Option>
                        <Option value={20}>20 linhas</Option>
                        <Option value={30}>30 linhas</Option>
                        <Option value={50}>50 linhas</Option>
                    </Select>
                </div>&nbsp;
                <div className="pagination-container" style={{justifyContent: Utils.mobileCheck() ? 'flex-start' : 'center', width: Utils.mobileCheck() ? '150px' : '100%', marginTop: '0px'}}>
                    <Button
                        style={{height: '40px', display: 'flex', alignItems: 'center'}}
                        className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
                        onClick={() => previous()}
                    >
                        <b style={{display: 'flex'}}><ion-icon name="chevron-back-outline"></ion-icon></b>
                    </Button>&nbsp;
                    <div className="pagination-overflow">
                        {pageNumbers.map((number, index) => (
                            <>
                                <Button
                                    key={number}
                                    style={{ width: '40px', height: '40px', border: currentPage === number ? undefined : 'solid 1px gray', background: currentPage === number ? '#000' : '#fff', color: currentPage === number ? '#fff' : 'gray' }}
                                    className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                                    onClick={() => handlePageChange(number)}
                                >
                                    {number}
                                </Button>
                                {index == (pageNumbers?.length - 1) ? (null) : (<>&nbsp;</>)}
                            </>
                        ))}
                    </div>&nbsp;
                    <Button
                        style={{height: '40px', display: 'flex', alignItems: 'center'}}
                        className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
                        onClick={() => next()}
                    >
                        <b style={{display: 'flex'}}><ion-icon name="chevron-forward-outline"></ion-icon></b>
                    </Button>
                </div>
            </div>
        );
    };

    const mapperSpecificWords = (word) => {
        switch(word){
            case "Descricao":
                return "Descrição"
            case "Ts_registro":
                return "Data";
            default:
                return word;
        }
    }

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string' || string.length === 0) {
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    };    

    return (
        <>
            <Card>
                {loading ? (
                    orders?.length > 0 ? (
                        <>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            {getProprietyName(items).map(item => (
                                                <th key={item} data-label={mapperSpecificWords(capitalizeFirstLetter(item))}>{mapperSpecificWords(capitalizeFirstLetter(item))}</th>
                                            ))}
                                            <th data-label={"Ação"} style={{textAlign: 'right'}}>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderTableRows()}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {renderPagination()}
                            </div>
                        </>
                    ) : (
                        <div className='mt-1 mb-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <b style={{color: 'gray'}}>Nenhum dado encontrado.</b>
                        </div>
                    )
                ) : (
                    <div className='mt-1 mb-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <b style={{color: 'gray'}}>Carregando...</b>
                    </div>
                )}
            </Card>
            <SpaceBox space={80}/>
        </>
    )
}