import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { FragmentView, Card, Button, Switch, Select, Option, Modal, Input, Hr, SpaceBox } from '../../components';
import Utils from '../../Utils';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);

    const [trabalhaEntregas, setTrabalhaEntregas] = useState(false);
    const [tipoFreteCorreios, setTipoFreteCorreios] = useState(false);
    const [tipoFreteManual, setTipoFreteManual] = useState(false);

    const [locaisEntrega, setLocaisEntrega] = useState(0);
    const [tipoPrecificacao, setTipoPrecificacao] = useState(0);

    const [locaisModal, setLocaisModal] = useState(false);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [uf, setUf] = useState(null);
    const [municipio, setMunicipio] = useState(null);

    const [valorEspecifico, setValorEspecifico] = useState("R$ 0,00");
    const [valorKm, setValorKm] = useState("R$ 0,00");
    const [valorFixo, setValorFixo] = useState("R$ 0,00");

    const [locaisEspecificos, setLocaisEspecificos] = useState([]);

    useEffect(() => {
        setup();
    }, [])

    useEffect(() => {
        if(uf){
            requestMunicipios();
        }
    }, [setUf, uf])

    useEffect(() => {
        if(locaisEntrega == 0 && tipoPrecificacao == 0 && tipoFreteManual){
            setTipoPrecificacao(1);
            setLocaisEspecificos([]);
            Utils.notify("error", "Para entregas em todo Brasil, escolha tarifa fixa, por km ou Correios.");
        }
    }, [locaisEntrega, setLocaisEntrega, tipoPrecificacao, setTipoPrecificacao])

    useEffect(() => {
        if(locaisEntrega == 0){
            setTipoPrecificacao(1);
        }
    }, [tipoFreteManual, setTipoFreteManual, locaisEntrega, setLocaisEntrega])

    const setup = async () => {

        setLoading(false);

        let pontosEntregaResponse = await Api.parceiro.pontoEntregas({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro
        })

        let _flg_delivery = pontosEntregaResponse?.data?.data?.flg_delivery;
        setTrabalhaEntregas(_flg_delivery == 1);

        let _pontosEntrega = pontosEntregaResponse?.data?.data?.json;

        let _tipoFrete = _pontosEntrega?.tipoFrete;
        switch(_tipoFrete){
            case "SEDEX":
                setTipoFreteCorreios(true);
                setTipoFreteManual(false);
                break;
            case "MANUAL":
                setTipoFreteCorreios(false);
                setTipoFreteManual(true);
                break;
        }
        setLocaisEntrega(Number(_pontosEntrega?.locaisEntrega));
        setTipoPrecificacao(Number(_pontosEntrega?.precificacao?.tipoPrecificacao));
        switch(Number(_pontosEntrega?.precificacao?.tipoPrecificacao)){
            case 0:
                break;
            case 1:
                setValorKm(Number(_pontosEntrega?.precificacao?.valor)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
                break;
            case 2:
                setValorFixo(Number(_pontosEntrega?.precificacao?.valor)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
                break;
        }
        if(_pontosEntrega?.pontosEntrega == "Brasil"){
            setLocaisEspecificos([]);
        }else{
            setLocaisEspecificos(_pontosEntrega?.pontosEntrega);
        }

        let ufListResponse = await Api.geral.ufs();
        let _ufs = ufListResponse?.data?.data;
        setUfs(_ufs);

        setLoading(true);
    }

    const requestMunicipios = async () =>{
        let municipioResponse = await Api.geral.municipio({
            ufid: ufs.filter(_uf => {return _uf.sigla == uf})[0]?.id
        });
        let _municipios = municipioResponse?.data?.data;
        setMunicipios(_municipios);
    }
    
    const handleAddLocal = () => {
        setLocaisModal(true);
    }

    const handleAddLocalOk = () => {

        if(!uf){
            Utils.notify("error", "Selecione a UF.");
            return;
        }

        if(!municipio){
            Utils.notify("error", "Selecione o município.");
            return;
        }
        
        if (municipio === "all") {
            let _ufSelected = ufs.filter(_uf => _uf.sigla === uf)[0]?.id;
            let _municipiosByUf = municipios.filter(m => m.ufid === _ufSelected).map(m => ({
                id: m.id,
                uf: uf,
                municipio: m.nome,
                valor: valorEspecifico
            }));
            
            // Filtrar municípios já existentes
            let newLocais = _municipiosByUf.filter(m => 
                !locaisEspecificos.some(l => l.uf === m.uf && l.municipio === m.municipio)
            );

            setLocaisEspecificos([...locaisEspecificos, ...newLocais]);
        } else {

            let _obj = {
                id: municipio,
                uf: uf,
                municipio: municipios.filter(m => {return m.id == municipio})[0]?.nome,
                valor: valorEspecifico
            };

            // Verificar se o município já existe
            if (!locaisEspecificos.some(l => l.uf === _obj.uf && l.municipio === _obj.municipio)) {
                setLocaisEspecificos([...locaisEspecificos, _obj]);
            }
        }

        setUf(null);
        setMunicipio(null);
        setValorEspecifico("R$ 0,00");

        setLocaisModal(false);
    }
    
    const handleRemoveLocal = (index) => {
        const updatedLocais = locaisEspecificos.filter((_, i) => i !== index);
        setLocaisEspecificos(updatedLocais);
    };

    const removeAllLocal = () => {
        setLocaisEspecificos([]);
    }

    const handleChangeValor = (index, value) => {
        const updatedLocaisEspecificos = locaisEspecificos.map((l, i) => i === index ? { ...l, valor: value } : l);
        setLocaisEspecificos(updatedLocaisEspecificos);
    };

    const getPrecificacao = () => {
        if(tipoFreteCorreios){
            return null;
        }
        return {
            tipoPrecificacao: Number(tipoPrecificacao),
            valor: tipoPrecificacao == 1 ? Utils.converterMoedaParaNumero(valorKm) : Utils.converterMoedaParaNumero(valorFixo)
        }
    }

    const getPontosEntrega = () => {
        if(locaisEntrega == 0){
            return "Brasil"
        }
        return locaisEspecificos;
    }

    const handleSave = async () => {
        let json = {
            tipoFrete: tipoFreteCorreios ? "SEDEX" : "MANUAL",
            precificacao: getPrecificacao(),
            locaisEntrega: locaisEntrega,
            pontosEntrega: getPontosEntrega()
        }

        let request = await Api.parceiro.pontoEntregasUpdate({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro, 
            flg_delivery: trabalhaEntregas,
            json: json
        })

        Utils.processRequest(request, () => {
            setup();
        }, () => {}, true);
    }

    return (
        <>
            <Modal show={locaisModal} setShow={setLocaisModal} onCloseCallback={() => {
                setUf(null);
                setMunicipio(null);
                setValorEspecifico("R$ 0,00");
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>Adicionar locais</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>Adicione seus locais de entrega.</div>
                    </div>
                </div>
                <div className='mb-2 mt-2'>
                    <Select label={"UF"} width={"100%"} value={uf} setValue={setUf}>
                            <Option value={null}>Selecione...</Option>
                            {ufs?.map(uf => (
                                <Option value={uf?.sigla}>{uf?.sigla}</Option>
                            ))}
                    </Select>
                    {uf ? (
                        <Select label={"Cidade"} width={"100%"} value={municipio} setValue={setMunicipio}>
                            <Option value={null}>Selecione...</Option>
                            <Option value={"all"}>Todas</Option>
                            {municipios?.map(m => (
                                <Option value={m?.id}>{m?.nome}</Option>
                            ))}
                        </Select>
                    ) : (null)}
                    {municipio && tipoPrecificacao == 0 ? (
                        <Input type={"moeda"} label={"Valor do frete"} value={valorEspecifico} setValue={setValorEspecifico}/>
                    ) : (null)}
                </div>
                <Button style={{width: '100%'}} onClick={handleAddLocalOk}><b>Adicionar</b></Button>
            </Modal>
            <FragmentView title={"Locais de Entrega"}>
                {loading ? (
                    <>
                        <Card className={"mb-1"}>
                            <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                <ion-icon name="cube-outline"></ion-icon>&nbsp; 
                                <b>Serviço de entregas</b>
                            </div>
                            <div className='mb-1' style={{border: 'solid 1px #ddd', borderRadius: '8px', padding: '10px'}}>
                                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                    <Switch 
                                        index={99} 
                                        isOn={trabalhaEntregas} 
                                        onChange={() => {
                                            let _trabalhaEntregas = !trabalhaEntregas;
                                            setTrabalhaEntregas(_trabalhaEntregas);
                                        }}
                                        colorOne="#000"
                                        colorTwo="#ddd"/>&nbsp;&nbsp;
                                    <b>Trabalho com entregas</b>
                                </div>
                                <p style={{fontSize: '10pt'}}>Se você trabalha com entregas, ative e configure os locais.</p>
                            </div>
                        </Card>
                        {trabalhaEntregas ? (
                            <>
                            <Card className={"mb-1"}>
                                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="calculator-outline"></ion-icon>&nbsp; 
                                    <b>Cálculo de frete</b>
                                </div>
                                <div className='mb-1' style={{border: 'solid 1px #ddd', borderRadius: '8px', padding: '10px'}}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <Switch 
                                            index={0} 
                                            isOn={tipoFreteCorreios} 
                                            onChange={() => {
                                                let _tipoFreteCorreios = !tipoFreteCorreios;
                                                if(_tipoFreteCorreios == true){
                                                    setTipoFreteCorreios(true);
                                                    setTipoFreteManual(false);
                                                }else{
                                                    setTipoFreteCorreios(false);
                                                    setTipoFreteManual(true);
                                                }
                                            }}
                                            colorOne="#000"
                                            colorTwo="#ddd"/>&nbsp;&nbsp;
                                        <b>Calculadora dos correios</b>
                                    </div>
                                    <p style={{fontSize: '10pt'}}>O frete é calculado automaticamente pela integração dos correios.</p>
                                </div>
                                <div style={{border: 'solid 1px #ddd', borderRadius: '8px', padding: '10px'}}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <Switch 
                                            index={1} 
                                            isOn={tipoFreteManual} 
                                            onChange={() => {
                                                let _tipoFreteManual = !tipoFreteManual;
                                                if(_tipoFreteManual == true){
                                                    setTipoFreteManual(true);
                                                    setTipoFreteCorreios(false);
                                                }else{
                                                    setTipoFreteManual(false);
                                                    setTipoFreteCorreios(true);
                                                }
                                            }}
                                            colorOne="#000"
                                            colorTwo="#ddd"/>&nbsp;&nbsp;
                                        <b>Calcular manualmente</b>
                                    </div>
                                    <p style={{fontSize: '10pt'}}>Você decide como calcular o preço dos locais de entrega.</p>
                                </div>
                            </Card>
                            {!tipoFreteCorreios ? (
                                <Card className={"mb-1"}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="cash-outline"></ion-icon>&nbsp; 
                                        <b>Precificações</b>
                                    </div>
                                    <Select label={"Tipo de precificação"} width={"100%"} value={tipoPrecificacao} setValue={setTipoPrecificacao}>
                                        <Option value={0}>Preço específico</Option>
                                        <Option value={1}>Preço por KM</Option>
                                        <Option value={2}>Preço fixo</Option>
                                    </Select>
                                    {tipoPrecificacao == 1 ? (
                                        <>
                                            <Input type={"moeda"} label={"Preço por KM (ex: 500km * R$ 0,05 = R$ 25,00 )"} value={valorKm} setValue={setValorKm}/>
                                        </>
                                    ) : (null)}
                                    {tipoPrecificacao == 2 ? (
                                        <>
                                            <Input type={"moeda"} label={"Preço fixo"} value={valorFixo} setValue={setValorFixo}/>
                                        </>
                                    ) : (null)}
                                </Card>
                            ) : (null)}
                            <Card className={"mb-1"}>
                                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="location-outline"></ion-icon>&nbsp; 
                                    <b>Locais de entrega {locaisEntrega == 1 ? `(${locaisEspecificos?.length})` : `(${locaisEspecificos?.length})`}</b>
                                </div>
                                <Select label={"Locais de entrega"} width={"100%"} value={locaisEntrega} setValue={setLocaisEntrega}>
                                    <Option value={0}>Todos os Estados (Brasil)</Option>
                                    <Option value={1}>Estados específicos (Brasil)</Option>
                                </Select>
                                {locaisEntrega == 1 ? (
                                    <>
                                        {locaisEspecificos?.length > 0 ? (
                                            <div style={{width: '100%', maxHeight: '250px', overflow: 'auto', border: 'solid 1px #ddd', borderRadius: '8px', padding: '10px'}} className='mb-1'>
                                                {locaisEspecificos?.map((l, index) => (
                                                    <>
                                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                            <p>{l.municipio} - {l.uf}</p>
                                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                {tipoPrecificacao == 0 && tipoFreteManual == true ? (
                                                                    <Input 
                                                                        style={{width: '100px'}}
                                                                        type={"moeda"} 
                                                                        label={"Preço"} 
                                                                        value={l.valor} 
                                                                        hideInputBoxMargin
                                                                        setValue={(value) => handleChangeValor(index, value)} 
                                                                    />
                                                                ) : (null)}&nbsp;&nbsp;&nbsp;
                                                                <div onClick={() => {handleRemoveLocal(index)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                                                    <ion-icon style={{color: 'red'}} name="trash-outline" size="large"></ion-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {index <= (locaisEspecificos.length) ? (<Hr elevation={1}/>) : (null)}
                                                    </>
                                                ))}
                                            </div>
                                        ) : (null)}
                                        <div style={{display: 'flex', justifyContent: Utils.mobileCheck() ? 'space-between' : 'start'}}>
                                            <Button onClick={handleAddLocal} style={{width: Utils.mobileCheck() ? '100%' : undefined, border: 'solid 1px #000', color: 'black', background: '#fff'}}><b>+ Adicionar locais</b></Button>
                                            {locaisEspecificos?.length > 0 ? (
                                                <>
                                                    &nbsp;&nbsp;<Button onClick={removeAllLocal} style={{width: Utils.mobileCheck() ? '100%' : undefined, border: 'solid 1px red', color: 'red', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><ion-icon name="trash-outline"></ion-icon><b> Remover locais</b></Button>
                                                </>
                                            ) : (null)}
                                        </div>
                                    </>
                                ) : (null)}
                            </Card>
                            </>
                        ):(null)}
                        <Button style={{width: '100%'}} onClick={handleSave}><b>Salvar</b></Button>
                        <SpaceBox space={80}/>
                    </>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                        <b style={{color: 'gray'}}>Carregando...</b>
                    </div>
                )}
            </FragmentView>
        </>
    )
}
