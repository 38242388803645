import React from 'react';
import './style.css';
import Button from '../Button';

export default ({onClickButton}) => {
    return (
        <div className='mt-1 mb-1' style={{display: "flex", alignItems: 'center', justifyContent: 'center', width: 'auto'}}>
            <div>
                <img src={"./foto2.png"} style={{width: '100%'}}/>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <b style={{color: 'black', textAlign: 'center'}}>Faça login para continuar.</b>
                </div>
                <Button onClick={onClickButton} className={'mt-2'} style={{width: '100%'}}>
                    <b>Fazer login</b>
                </Button>
            </div>
        </div>
    )
}
