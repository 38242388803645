import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, ButtonGroup, Card, FlatButton, FragmentView, Input, Modal, Option, Select } from '../../components';
import Pagination from '../../components/Pagination';
import Utils from '../../Utils';

export default () => {

    const [mode, setMode] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);

    //filtros
    const [nome, setNome] = useState('');
    const [status, setStatus] = useState('');
    const [tipo, setTipo] = useState('');
    const [data, setData] = useState('');
    //

    const [nomeCupom, setNomeCupom] = useState(null);
    const [descricaoCupom, setDescricaoCupom] = useState(null);

    const [cupons, setCupons] = useState([
        {
            id: 0, 
            nome: "Cupom B",
            descricao: "Eae porra",
            tipo: "PRODUTO",
            status: "A",
            ts_registro: "2024-02-07 18:12:58",
        },
        {
            id: 0, 
            nome: "Cupom A",
            descricao: "Eae porra",
            tipo: "SERVICO",
            status: "A",
            ts_registro: "2024-02-07 18:12:58",
        }
    ]);

    const [filteredCupons, setFilteredCupons] = useState(cupons);

    const [cupomModal, setCupomModal] = useState(false);

    const [cupomMode, setCupomMode] = useState([
        {index: 0, label: "Produtos"}, 
        {index: 1, label: "Serviços"}, 
        {index: 2, label: "Todos"}
    ]);

    const [cupomSelected, setCupomSelected] = useState(null);

    useEffect(() => {
        let filtered = cupons;

        if (nome) {
            filtered = filtered.filter(cupom => cupom.nome.toLowerCase().includes(nome.toLowerCase()));
        }

        if (status?.trim()?.length > 0) {
            filtered = filtered.filter(cupom => cupom.status === status);
        }

        if (tipo?.trim()?.length > 0) {
            filtered = filtered.filter(cupom => cupom.tipo === tipo);
        }

        if (data?.trim()?.length > 0) {
            filtered = filtered.filter(cupom => cupom.ts_registro.includes(Utils.converterDateUS(data)));
        }

        setFilteredCupons(filtered);
    }, [nome, status, tipo, data, cupons]);

    const onChangeCupomMode = (mode) => {
        console.log(mode);
    }

    const handleCupomModal = () => {
        setMode("CREATE");
        setCupomSelected(null);
        setCupomModal(true);
    }

    const handleRowEdit = async (cupom) => {
        setMode("UPDATE");
        setCupomSelected(cupom);
        setCupomModal(true);
    }

    const handleRowRemove = async (cupom) => {
        
    }

    const handleClearFilter = () => {
        setNome('');
        setTipo('');
        setStatus('');
        setData('');
    }

    const onCloseCallback = () => {

    }

    return (
        <>
            <FlatButton text={"Adicionar"} onClick={handleCupomModal}>+</FlatButton>
            <Modal show={cupomModal} setShow={setCupomModal} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>{mode == "CREATE" ? `Adicionar cupom` : `Atualizar cupom`}</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>{mode == "CREATE" ? "Adicione" : "Atualize"} o cupom.</div>
                    </div>
                </div>
                <Card className='mb-1 mt-1'>
                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="information-circle-outline"></ion-icon>&nbsp; 
                        <b>Informações</b>
                    </div>
                    <span>*Cupom válido para:</span>
                    <ButtonGroup activeIndex={0} onChange={onChangeCupomMode} items={cupomMode}/>
                    <Input type="cupom" label="Nome do cupom" value={nomeCupom} setValue={setNomeCupom}/>
                    <Input type="textarea" label="Descrição do cupom" value={descricaoCupom} setValue={setDescricaoCupom}/>
                </Card>
                <Button style={{width: '100%'}}>
                    <b>Salvar</b>
                </Button>
            </Modal>
            <FragmentView title={"Cupons"}>
                <Card className={"mb-1"}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="filter-outline"></ion-icon>&nbsp;
                        <b>Filtros</b>
                    </div>
                    {Utils.mobileCheck() ? (
                        <div className='mt-1'>
                            <div style={{display: 'flex', alignItems: 'center'}} className='mb-05'>
                                <Input hideInputBoxMargin style={{width: '100%'}} label={"Pesquisar pedido (cliente, data, produto, etc...)"} type={"text"} value={nome} setValue={(e) => {
                                    setNome(e);
                                }} />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}} className='mb-05'>
                                <Select hideInputBoxMargin width={"100%"} label={"Status"} value={status} setValue={setStatus}>
                                    <Option value={""}>Selecione...</Option>
                                    <Option value={"A"}>Ativo</Option>
                                    <Option value={"I"}>Inativo</Option>
                                </Select>&nbsp;
                                <Select hideInputBoxMargin width={"100%"} label={"Tipo"} value={tipo} setValue={setTipo}>
                                    <Option value={""}>Selecione...</Option>
                                    <Option value={"PRODUTO"}>Produto</Option>
                                    <Option value={"SERVICO"}>Serviço</Option>
                                </Select>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input hideInputBoxMargin style={{width: '100%'}} label={"Data do pedido"} type={"data"} value={data} setValue={setData} />&nbsp;
                                <Button onClick={handleClearFilter} style={{flex: '0.3', display: 'flex', alignItems: 'center', height: '50px', width: '100%', justifyContent: 'center', background: '#fff', border: 'solid 1px red', color: 'red'}}>
                                    <ion-icon name="close-circle-outline"></ion-icon>
                                    <b>Limpar</b>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='mt-1'>
                            <div>
                                <Input style={{width: '100%'}} hideInputBoxMargin label={"Pesquisar pedido (cliente, data, produto, etc...)"} type={"text"} value={nome} setValue={(e) => {
                                    setNome(e);
                                }} />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex', width: '100%'}}>
                                    <Select hideInputBoxMargin width={"100%"} label={"Status"} value={status} setValue={setStatus}>
                                        <Option value={""}>Selecione...</Option>
                                        <Option value={"A"}>Ativo</Option>
                                        <Option value={"I"}>Inativo</Option>
                                    </Select>
                                </div>&nbsp;
                                <div style={{display: 'flex', width: '100%'}}>
                                    <Select hideInputBoxMargin width={"100%"} label={"Tipo"} value={tipo} setValue={setTipo}>
                                        <Option value={""}>Selecione...</Option>
                                        <Option value={"PRODUTO"}>Produto</Option>
                                        <Option value={"SERVICO"}>Serviço</Option>
                                    </Select>
                                </div>&nbsp;
                                <Input style={{width: '100%'}} label={"Data do pedido"} type={"data"} value={data} setValue={setData} />&nbsp;
                                <Button onClick={handleClearFilter} style={{display: 'flex', flex: '0.3', alignItems: 'center', height: '50px', width: '100%', justifyContent: 'center', background: '#fff', border: 'solid 1px red', color: 'red'}}>
                                    <ion-icon name="close-circle-outline"></ion-icon>&nbsp;
                                    <b>Limpar</b>
                                </Button>
                            </div>
                        </div>
                    )}
                </Card>
                <Pagination 
                    defaultPage={currentPage}
                    items={filteredCupons}
                    loading={true}
                    handleRowEdit={handleRowEdit}
                    handleRowRemove={handleRowRemove}
                />
            </FragmentView>
        </>
    )
}
