import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';

const MapMarker = ({onPositionChanged, locationIcon}) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [mapInstance, setMapInstance] = useState(null);

  useEffect(() => {
    CoordsProviderHelper((coords) => {
      setMarkerPosition(coords);
      setLatitude(coords.latitude.toString());
      setLongitude(coords.longitude.toString());
      onPositionChanged({ latitude: coords.latitude, longitude: coords.longitude })
    });
  }, []);

  const handleMarkerDragEnd = (event) => {
    const lat = event.target._latlng.lat;
    const lng = event.target._latlng.lng;

    setMarkerPosition({ latitude: lat, longitude: lng });

    setLatitude(lat.toString());
    setLongitude(lng.toString());

    if(onPositionChanged){
        onPositionChanged({ latitude: lat, longitude: lng })
    }

    if (mapInstance) {
      mapInstance.setView([lat, lng], mapInstance.getZoom());
    }
  };

  const handleMapInit = (map) => {
    setMapInstance(map);
  };

  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
    setMarkerPosition({ latitude: parseFloat(event.target.value), longitude: markerPosition.longitude });
  };

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
    setMarkerPosition({ latitude: markerPosition.latitude, longitude: parseFloat(event.target.value) });
  };

  const customIcon = new L.Icon({
    iconUrl: locationIcon ? locationIcon : '../location_move.png',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  return (
    markerPosition && (
      <div>
        <input type="text" value={latitude} onChange={handleLatitudeChange} hidden/>
        <input type="text" value={longitude} onChange={handleLongitudeChange} hidden/>
        <MapContainer
          center={[markerPosition.latitude, markerPosition.longitude]}
          zoom={15}
          style={{ height: '200px', width: '100%', borderRadius: '8px' }}
          whenCreated={handleMapInit}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            position={[markerPosition.latitude, markerPosition.longitude]}
            draggable={true}
            eventHandlers={{
              dragend: handleMarkerDragEnd,
            }}
            icon={customIcon}
          >
            <Popup>Sua localização</Popup>
          </Marker>
        </MapContainer>
      </div>
    )
  );
};

export default MapMarker;