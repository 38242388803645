import React, { useEffect, useState } from 'react';
import LocationProviderHelper from '../../helpers/LocationProviderHelper';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';

export default ({callback}) => {

    const [ready, setReady] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [addressName, setAddressName] = useState(getStoragedAddressName());

    useEffect(() => {
        const getLocation = async () => {
            try {
                requestLocation();
            } catch (error) {
                console.error(`Erro ao obter localização: ${error.message}`);
            } finally {
                if (mounted) {
                    setReady(true);
                }
            }
        };

        let mounted = true;
        getLocation();

        return () => {
            mounted = false;
        };
    }, []);
    
    function getStoragedAddressName () {
        const storedData = localStorage.getItem("tk_beauty_addressname");
        if (storedData) {
            const { value, timestamp } = JSON.parse(storedData);
            const now = new Date().getTime();
            const thirtyMinutesInMillis = 30 * 60 * 1000;

            if (now - timestamp < thirtyMinutesInMillis) {
                return value;
            } else {
                // Remove os dados expirados do localStorage
                localStorage.removeItem("tk_beauty_addressname");
            }
        }
        return null;
    }

    const saveToLocalStorage = (value) => {
        const data = {
            value,
            timestamp: new Date().getTime(),
        };
        localStorage.setItem("tk_beauty_addressname", JSON.stringify(data));
    }

    const requestLocation = async () => {
        await CoordsProviderHelper(async (coords) => {

            if(getStoragedAddressName() == null){
                let response = await LocationProviderHelper({lat: coords?.latitude, lon: coords?.longitude});
                if(response?.display_name){
                    setAddressName(`${response?.display_name}`);
                    saveToLocalStorage(`${response?.display_name}`);
                }
            }

            setLatitude(coords?.latitude);
            setLongitude(coords?.longitude);

            if(callback){
                callback(coords)
            }

        });
    }

    return (
        <div>
            {ready ? (
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {addressName!= null ? (
                        <>
                            <div onClick={requestLocation} style={{background: "#fff", border: 'solid 1px #ddd', borderRadius: '16px', padding: '8px', width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{overflow: 'hidden', flex: '0.99'}}> 
                                    <p style={{fontSize: '0.80rem', whiteSpace: 'nowrap'}}>{addressName}</p>
                                </div>
                                <ion-icon name="location-outline" style={{ fontSize: "1.5rem"}}></ion-icon>
                            </div>
                        </>
                    ) : (
                        <>
                            <div onClick={requestLocation} style={{background: "#fff", border: 'solid 1px #ddd', borderRadius: '16px', padding: '8px', width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{overflow: 'hidden', flex: '0.99'}}> 
                                    <p style={{fontSize: '0.80rem', whiteSpace: 'nowrap'}}>Onde você está?</p>
                                </div>
                                <ion-icon name="location-outline" style={{ fontSize: "1.5rem"}}></ion-icon>
                            </div>
                        </>
                    )}
                </div>
            ) : (null)}
        </div>
    );
};