import React, { useContext, useEffect, useState } from 'react';
import {Container, ParceiroList, LocationProvider, Header, AnnounceList, CategoryList, ParceiroProductList, ParceirosListAssigned, SpaceBox, AvaliarModal, SwipperRefresh} from '../../components';
import './style.css';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import DownloadApp from '../../components/DownloadApp';

const TitleComponent = () => (
    <div style={{display: 'flex', alignItems: 'center'}}>
        <ion-icon name="pricetags-outline" style={{color: "black"}}></ion-icon>
        <h3 style={{color: "black"}}>&nbsp;Produtos em destaque</h3> 
    </div>
)

export default () => {

    const {user, banners, categories, parceiros, produtos, onRefreshHandler} = useContext(MainContext);

    const [coords, setCoords] = useState(null);
    const [assinaturas, setAssianturas] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const setup = async () => {
            let response = await Api.cliente.assinaturasByCliente({
                forceToken: localStorage.getItem("tk_beauty_cliente_token"), 
            });
            let _assinaturas = response?.data?.data;
            setAssianturas(_assinaturas);
        }
        if(coords != null){
            setup();
        }
    }, [coords])

    const onHandleCallbackLocationProvider = async (coords) => {
        setCoords(coords);
    }

    return (
        <SwipperRefresh onRefreshHandler={onRefreshHandler}>
            <Container>
                <Header/>
                <LocationProvider callback={onHandleCallbackLocationProvider} />
                <AnnounceList announces={banners}/>
                <CategoryList categories={categories}/>
                <ParceirosListAssigned parceiros={assinaturas} hideFilter/>
                <ParceiroList parceiros={parceiros}/>
                <SpaceBox space={20}/>
                <ParceiroProductList parceiros={parceiros} produtos={produtos} titleComponent={<TitleComponent/>} />
                <DownloadApp/>
                <SpaceBox space={70}/>
                {user ? (<AvaliarModal/>) : (null)}
            </Container>
        </SwipperRefresh>
    )
}
