import React from 'react';
import "./style.css";
import { ModalSwitchApp } from '../../components';

export default ({callback}) => {
    return (
        <>
            <ModalSwitchApp callback={callback}/>
        </>
    )
}
