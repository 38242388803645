import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Card, FragmentView, SpaceBox } from '../../components';
import Utils from '../../Utils';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';

const startRatings = ({ reviews }) => {

  let rateBox = Array.from(document.querySelectorAll(".rate-box"));
  
  updateValues();

  function updateValues() {
    rateBox.forEach((box) => {
      let valueBox = rateBox[rateBox.indexOf(box)].querySelector(".value");
      let countBox = rateBox[rateBox.indexOf(box)].querySelector(".count");
      let progress = rateBox[rateBox.indexOf(box)].querySelector(".progress");

      countBox.innerHTML = nFormat(reviews[valueBox.innerHTML]);

      let progressValue = Math.round(
        (reviews[valueBox.innerHTML] / getTotal(reviews)) * 100
      );
      progress.style.width = `${progressValue}%`;
    });
  }

  function getTotal(reviews) {
    return Object.values(reviews).reduce((a, b) => a + b);
  }

  document.querySelectorAll(".value").forEach((element) => {
    element.addEventListener("click", () => {
      let target = element.innerHTML;
      reviews[target] += 1;
      updateValues();
    });
  });

  function nFormat(number) {
    if (number >= 1000 && number < 1000000) {
      return `${number.toString().slice(0, -3)}k`;
    } else if (number >= 1000000 && number < 1000000000) {
      return `${number.toString().slice(0, -6)}m`;
    } else if (number >= 1000000000) {
      return `${number.toString().slice(0, -9)}md`;
    } else if (number === "NaN") {
      return `0.0`;
    } else {
      return number;
    }
  }
}

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [avaliacoesContent, setAvaliacoesContent] = useState([]);
    const [nota, setNota] = useState(0);

    useEffect(() => {
        setup();
    }, [loading]);

    const setup = async () => {
        let _token = localStorage.getItem("tk_beauty_parceiro_token");

        let responseAvaliacao = await Api.geral.avaliacaoParceiro({
            forceToken: _token,
            id_parceiro: user?.id_parceiro
        })

        let responseAvaliacaoContent = await Api.geral.avaliacaoCompletaParceiro({
            forceToken: _token,
            id_parceiro: user?.id_parceiro
        })

        let _avaliacoesParceiro = responseAvaliacao?.data?.data;
        let _avaliacoesParceiroContent = responseAvaliacaoContent?.data?.data;

        const _nota = Utils.calcularAvaliacao(_avaliacoesParceiro);

        setAvaliacoes(_avaliacoesParceiro);
        setAvaliacoesContent(_avaliacoesParceiroContent);
        setNota(_nota);

        startRatings({
            reviews:{
                5: _avaliacoesParceiro?.filter(a => {return a == 5})?.length || 0,
                4: _avaliacoesParceiro?.filter(a => {return a == 4})?.length || 0,
                3: _avaliacoesParceiro?.filter(a => {return a == 3})?.length || 0,
                2: _avaliacoesParceiro?.filter(a => {return a == 2})?.length || 0,
                1: _avaliacoesParceiro?.filter(a => {return a == 1})?.length || 0
            }
        })

        setLoading(true);
    }

    const renderStarsContent = (nota) => {
        const renderStars = () => {
            const stars = [];
            for (let i = 1; i <= 5; i++) {
                stars.push(
                    <ion-icon 
                        style={{color: 'orange'}}
                        key={i} 
                        name={i <= Number(nota) ? 'star' : 'star-outline'} 
                    ></ion-icon>
                );
            }
            return stars;
        };
        return (renderStars());
    }

    const NotaComponent = () => {
        const renderStars = () => {
            const stars = [];
            for (let i = 1; i <= 5; i++) {
                stars.push(
                    <ion-icon 
                        key={i} 
                        name={i <= Number(nota?.replaceAll(",", ".")) ? 'star' : 'star-outline'} 
                    ></ion-icon>
                );
            }
            return stars;
        };
    
        return (
            <div className="global">
                <span className="global-value">{nota}</span>
                <div className="rating-icons">
                    {renderStars()}
                </div>
                <span className="total-reviews">({avaliacoes?.length}) avaliações</span>
            </div>
        );
    };

    return (
        <>
        <FragmentView title={"Avaliações"}>
            {loading ? (
                avaliacoesContent?.length > 0 ? (
                    <>
                        <Card className={"mb-1"}>
                            <div className="container-ratting" style={{display: Utils.mobileCheck() ? 'block' : 'flex', justifyContent: Utils.mobileCheck() ? 'space-between' : 'space-between', padding: '16px'}}>
                                <div className="chart">
                                    <div className="rate-box">
                                    <span className="value">5</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar">
                                        <span className="progress"></span>
                                    </div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">4</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">3</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">2</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span style={{background: '#ffc107'}} className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">1</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span style={{background: '#dc3545'}} className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                </div>
                                {loading ? (<NotaComponent/>) : (null)}
                            </div>
                        </Card>
                        {avaliacoesContent?.map(avaliacao => (
                            <Card className={"mb-1"}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', alignItems: 'center',}}>
                                        <div style={{width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', background: '#f5f5f5'}}>
                                            <b>{avaliacao?.nome_cliente?.split(" ")[0][0] || ""}{avaliacao?.nome_cliente?.split(" ")[1][0] || ""}</b>
                                        </div>&nbsp;&nbsp;
                                        <b>{avaliacao?.nome_cliente}</b>
                                    </div>
                                    <div>
                                        {renderStarsContent(Number(avaliacao?.estrelas))}
                                    </div>
                                </div>
                                {avaliacao?.comentario?.length > 0 ? (
                                    <p style={{padding: '8px'}}>- "{avaliacao?.comentario}"</p>
                                ) : (null)}
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end', fontSize: '10pt'}}>
                                    <small>{Utils.converterDate(avaliacao?.ts_registro)}</small>
                                </div>
                            </Card>
                        ))}
                        <SpaceBox space={80}/>
                    </>
                ) : (
                    <>
                        <Card>
                            <div className="container-ratting" style={{display: Utils.mobileCheck() ? 'block' : 'flex', justifyContent: Utils.mobileCheck() ? 'space-between' : 'space-between', padding: '16px'}}>
                                <div className="chart" style={{width: '100%'}}>
                                    <div className="rate-box">
                                    <span className="value">5</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar">
                                        <span className="progress"></span>
                                    </div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">4</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">3</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">2</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span style={{background: '#ffc107'}} className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                    <div className="rate-box">
                                    <span className="value">1</span><ion-icon name="star-outline"></ion-icon>
                                    <div className="progress-bar"><span style={{background: '#dc3545'}} className="progress"></span></div>
                                    <span className="count">0</span>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <b style={{color: 'gray'}}>Nenhuma avaliação</b>
                        </div>
                    </>
                )
            ) : (
                <div className='mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <b style={{color: 'gray'}}>Carregando...</b>
                </div>
            )}
        </FragmentView>
        </>
    )
}