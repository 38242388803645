import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox, HeaderWithBack, Hr, MapMarker, Modal, Option, Select, SpaceBox, Switch } from '../../components';
import Api from '../../Api';
import Utils from '../../Utils';
import { MainContext } from '../../helpers/MainContext';

export default () => {

    const navigate = useNavigate();

    const {setUser, setAuthenticated} = useContext(MainContext);

    const [loaded, setLoaded] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [bancos, setBancos] = useState([]);

    const [step, setStep] = useState(0);

    //
    const [nomeParceiro, setNomeParceiro] = useState("");
    const [categoria, setCategoria] = useState("");
    const [subcategoria, setSubcategoria] = useState("");
    const [descricao, setDescricao] = useState("");
    //
    const [cep, setCep] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [latitude, setLatitude] = useState(null);
    const [logintude, setLogintude] = useState(null);
    //
    const [frenteDocumento, setFrenteDocumento] = useState(null);
    const [trasDocumento, setTrasDocumento] = useState(null);
    const [selfieDocumento, setSelfieDocumento] = useState(null);
    const [certDocumento, setCertDocumento] = useState(null);
    //
    const [tipoConta, setTipoConta] = useState("PF");
    const [cpf, setCpf] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [nomeFantasia, setNomeFantasia] = useState("");
    const [nome, setNome] = useState("");
    //
    const horas = [
        "06:00", "06:30", 
        "07:00", "07:30", 
        "08:00", "08:30", 
        "09:00", "09:30", 
        "10:00", "10:30", 
        "11:00", "11:30", 
        "12:00", "12:30",
        "13:00", "13:30", 
        "14:00", "14:30", 
        "15:00", "15:30", 
        "16:00", "16:30", 
        "17:00", "17:30", 
        "18:00", "18:30",
        "19:00", "19:30", 
        "20:00", "20:30",
        "21:00", "21:30",
        "22:00", "22:30",
        "23:00", "23:30",
        "00:00", "00:30", 
        "01:00", "01:30",
        "02:00", "02:30", 
        "03:00", "03:30", 
        "04:00", "04:30",
        "05:00", "05:30",
    ];

    const days = [
        { key: 'domingo', name: 'Dom', state: useState(false), startState: useState(""), endState: useState("") },
        { key: 'segunda', name: 'Seg', state: useState(true), startState: useState("08:00"), endState: useState("18:00") },
        { key: 'terca', name: 'Ter', state: useState(true), startState: useState("08:00"), endState: useState("18:00") },
        { key: 'quarta', name: 'Qua', state: useState(true), startState: useState("08:00"), endState: useState("18:00") },
        { key: 'quinta', name: 'Qui', state: useState(true), startState: useState("08:00"), endState: useState("18:00") },
        { key: 'sexta', name: 'Sex', state: useState(true), startState: useState("08:00"), endState: useState("18:00") },
        { key: 'sabado', name: 'Sáb', state: useState(true), startState: useState("08:00"), endState: useState("14:00") },
    ];
    //
    const [tiposChavePix, setTiposChavePix] = useState(["CPF", "CNPJ", "Email", "Celular"]);
    const [tipoChavePix, setTipoChavePix] = useState("");
    const [chavePix, setChavePix] = useState("");
    const [proprietario, setProprietario] = useState("");
    //
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordC, setPasswordC] = useState("");

    const [acceptTerms, setAcceptTerms] = useState(false);

    const [clickFinished, setClickFinished] = useState(false);

    const [modalTermsShow, setModalTermsShow] = useState(false);
    const [viewMode, setViewMode] = useState(null);

    useState(() => {
        const load = async () => {
            let responseCategorias = await Api.geral.categorias(); 

            let _categorias = responseCategorias?.data?.data;

            setCategorias(_categorias);
            
            setLoaded(true);
        }
        load();
    }, [])

    useEffect(() => {
        const request = async () =>{
            if(cep?.length >= 9){
                let viaCepResponse = await Api.geral.viaCep({cep});
                if(viaCepResponse){
                    setLogradouro(viaCepResponse?.bairro +", "+ viaCepResponse?.logradouro)
                }else{
                    setLogradouro("");
                }
            }
        }
        request();
    }, [cep])

    useEffect(() => {
        const request = async () => {
            setCnpj(cnpj);
            let _cnpj = cnpj?.replaceAll(".", "").replaceAll("-", "").replaceAll("/","");
            if(_cnpj?.length >= 14){
                let response = await Api.geral.receitaAwsCnpj({cnpj: _cnpj});
                setNomeFantasia(response?.fantasia || "");
                setRazaoSocial(response?.nome || "");
                console.log(response);
            }
        }
        request();
    }, [cnpj])

    useEffect(() => {
        setChavePix("");
    }, [tipoChavePix])

    const handleShowTerms = (_viewMode) => {
        setModalTermsShow(true);
        setViewMode(_viewMode);
    }

    const handleLogin = () => {
        navigate('/'); 
    }

    const onPositionChanged = ({latitude, longitude}) => {
        setLatitude(latitude);
        setLogintude(longitude);
    }

    const getText = (index) => {
        switch(index){
            case 0: 
                return "Iremos precisar de algumas informações, ok?";
            case 1:
                return "Onde seus clientes podem te encontrar?";
            case 2:
                return "Qual seu o tipo de cadastro?";
            case 3:
                return "Quase lá, só precisamos confirmar sua identidade para seguirmos, ok?";
            case 4:
                return "Como funciona o expediente do seu negócio?";
            case 5:
                return "Agora precisamos dos seus dados de pix para recebimentos, beleza?";
            case 6:
                return "Por último precisamos informar dados de acesso, ok?";
    }
    }

    const changeTipoConta = (tipo) =>{
        setTipoConta(tipo);
    }

    const handleNext = () => {
        let validate = validatePage(step);
        if(!validate?.success){
            Utils.notify("error", validate?.message);
            return;
        }
        let newStep = step + 1;
        setStep(newStep);
    }

    const handleBack = () => {
        let newStep = step - 1;
        setStep(newStep);
    }

    const isValidTime = (time) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(time);
    };

    const validateDays = (days) => {
        let success = true;
        let message = "";

        for(let i = 0; i < days.length; i++){
            let day = days[i];
            const [state] = day.state;
            const [startState] = day.startState;
            const [endState] = day.endState;

            if (state) {
                if (!isValidTime(startState)) {
                    return {
                        success: false,
                        message: `Hora de abertura inválida para ${day.key}.`
                    }
                }
                if (!isValidTime(endState)) {
                    return {
                        success: false,
                        message: `Hora de fechamento inválida para ${day.key}.`
                    }
                }
            }
        }

        return {
            success,
            message
        }
    };

    const validatePage = (step) => {
        let success = false;
        let message = "";

        switch (step) {
            case 0:
                if (Utils.isEmpty(nomeParceiro)) {
                    return {
                        success: false,
                        message: "Digite o nome do negócio."
                    };
                }
                if (Utils.isEmpty(categoria)) {
                    return {
                        success: false,
                        message: "Escolha a categoria principal do negócio."
                    };
                }
                if (Utils.isEmpty(descricao)) {
                    return {
                        success: false,
                        message: "Digite uma descrição breve do negócio."
                    };
                }
                return {
                    success: true,
                    message: ""
                };
            case 1:
                if (Utils.isEmpty(cep)) {
                    return {
                        success: false,
                        message: "Digite o CEP."
                    };
                }
                if(!Utils.validateCep(cep)){
                    return {
                        success: false,
                        message: "CEP inválido."
                    };
                }
                if (Utils.isEmpty(logradouro)) {
                    return {
                        success: false,
                        message: "Logradouro não encontrado. Verifique o CEP."
                    };
                }
                if (Utils.isEmpty(numero)) {
                    return {
                        success: false,
                        message: "Digite o número."
                    };
                }
                return {
                    success: true,
                    message: ""
                };
            case 2:
                if (tipoConta === "PF") {
                    if (Utils.isEmpty(cpf)) {
                        return {
                            success: false,
                            message: "Digite o CPF."
                        };
                    }
                    if(!Utils.validateCpf(cpf)){
                        return {
                            success: false,
                            message: "CPF inválido."
                        };
                    }
                    if (Utils.isEmpty(nome)) {
                        return {
                            success: false,
                            message: "Digite o nome completo."
                        };
                    }
                } else if (tipoConta === "PJ") {
                    if (Utils.isEmpty(cnpj)) {
                        return {
                            success: false,
                            message: "Digite o CNPJ."
                        };
                    }
                    if(!Utils.validateCNPJ(cnpj)){
                        return {
                            success: false,
                            message: "CNPJ inválido."
                        };
                    }
                    if (Utils.isEmpty(razaoSocial)) {
                        return {
                            success: false,
                            message: "Digite a razão social."
                        };
                    }
                    if (Utils.isEmpty(nomeFantasia)) {
                        return {
                            success: false,
                            message: "Digite o nome fantasia."
                        };
                    }
                    if (Utils.isEmpty(nome)) {
                        return {
                            success: false,
                            message: "Digite o nome completo."
                        };
                    }
                    if (Utils.isEmpty(cpf)) {
                        return {
                            success: false,
                            message: "Digite o CPF do responsável."
                        };
                    }
                    if(!Utils.validateCpf(cpf)){
                        return {
                            success: false,
                            message: "CPF inválido."
                        };
                    }
                }
                return {
                    success: true,
                    message: ""
                };
            case 3:
                if(frenteDocumento == null){
                    return {
                        success: false,
                        message: "Envie a frente do seu documento de identificação."
                    };
                }
                if(trasDocumento == null){
                    return {
                        success: false,
                        message: "Envie o verso do seu documento de identificação."
                    };
                }
                if(selfieDocumento == null){
                    return {
                        success: false,
                        message: "Envie uma selfie segurando seu documento de identificação."
                    };
                }
                return {
                    success: true,
                    message: ""
                };
            case 4:
                let validate = validateDays(days);
                return {
                    success: validate?.success,
                    message: validate?.message
                };
            case 5:
                if (Utils.isEmpty(tipoChavePix)) {
                    return {
                        success: false,
                        message: "Escolha o tipo de chave."
                    };
                }
                if (Utils.isEmpty(chavePix)) {
                    return {
                        success: false,
                        message: "Digite a chave pix."
                    };
                }
                switch(tipoChavePix){
                    case "CPF":
                        if(!Utils.validateCpf(chavePix)){
                            return {
                                success: false,
                                message: "Chave pix 'cpf' inválida."
                            };
                        }
                        break;
                    case "CNPJ":
                        if(!Utils.validateCNPJ(chavePix)){
                            return {
                                success: false,
                                message: "Chave pix 'cnpj' inválida."
                            };
                        }
                        break;
                    case "Email":
                        if(!Utils.validateEmail(chavePix)){
                            return {
                                success: false,
                                message: "Chave pix 'email' inválida."
                            };
                        }
                        break;
                    case "Celular":
                        if(!Utils.validatePhoneNumber(chavePix)){
                            return {
                                success: false,
                                message: "Chave pix 'celular' inválida."
                            };
                        }
                        break;
                }
                if (Utils.isEmpty(proprietario)) {
                    return {
                        success: false,
                        message: "Digite o nome do proprietário."
                    };
                }
                return {
                    success: true,
                    message: ""
                };
            default:
                return {
                    success,
                    message
                };
        }
    };

    const validateFinishPage = () => {
        if (Utils.isEmpty(phone)) {
            return {
                success: false,
                message: "Digite o número de celular."
            };
        }
        if(!Utils.validatePhoneNumber(phone)){
            return {
                success: false,
                message: "Celular inválido."
            };
        }
        if (Utils.isEmpty(email)) {
            return {
                success: false,
                message: "Digite o email."
            };
        }
        if(!Utils.validateEmail(email)){
            return {
                success: false,
                message: "Email inválido."
            };
        }
        if (Utils.isEmpty(password)) {
            return {
                success: false,
                message: "Digite a senha."
            };
        }
        if (password !== passwordC) {
            return {
                success: false,
                message: "As senhas não coincidem."
            };
        }
        if(!Utils.checkPasswordStrength(password)){
            return {
                success: false,
                message: "A senha precisa ter letras e números"
            };
        }
        return {
            success: true,
            message: ""
        };
    }

    const convertHoursToInt = (hora) => {
        if(hora?.trim()?.length <= 0)return;
        const partes = hora.split(':');
        const horas = parseInt(partes[0]);
        return horas;
    }

    const getExpediente = (id_parceiro) => {
        const expediente = {};
        expediente["id_parceiro"] = id_parceiro;
        ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'].forEach(dia => {
          const dayData = days.find(d => d.key === dia);
          expediente[dia] = dayData.state[0] ? 1 : 0;
          expediente[`${dia}_hora_inicio`] = dayData ? convertHoursToInt(dayData.startState[0]) : "";
          expediente[`${dia}_hora_fim`] = dayData ? convertHoursToInt(dayData.endState[0]) : "";
        });
        return expediente;
    };

    const getChaveTipo = (tipo) => {
        switch(tipo){
            case "CPF":
                return "CPF";
            case "CNPJ":
                return "CNPJ";
            case "Email":
                return "EMAIL";
            case "Celular":
                return "PHONE";
        }
    }

    const handleFinish = async () => {

        let validate = validateFinishPage();
        if(!validate?.success){
            Utils.notify("error", validate?.message);
            return;
        }

        if(!acceptTerms){
            Utils.notify("error", "Você precisa concordar com os termos e políticas.");
            return;
        }

        let id_parceiro = Utils.makeid(50);

        let data = {
            parceiro: {
                id_parceiro: id_parceiro,
                bind_parceiro: Utils.stringToSlug(nomeParceiro),
                nome_parceiro: nomeParceiro,
                categoria_parceiro: categoria,
                categoria_parceiro_secundaria: subcategoria,
                descricao_parceiro: descricao,
                endereco_parceiro: logradouro,
                latitude: latitude,
                logintude: logintude
            },
            detalhes: {
                id_parceiro: id_parceiro,
                tipo: tipoConta,
                numero_documento_identificacao: cpf.replaceAll(".", "").replaceAll(" ","").replaceAll("-", ""),
                foto_frente_documento_identificacao: frenteDocumento,
                foto_verso_documento_identificacao: trasDocumento,
                selfie_documento_identificacao: selfieDocumento,
                cnpj: cnpj.replaceAll(".", "").replaceAll(" ","").replaceAll("-", "").replaceAll("/", ""),
                razao_social: razaoSocial,
                nome_fantasia: nomeFantasia,
                foto_certificacao_servico: certDocumento,
            },
            expediente: getExpediente(id_parceiro),
            conta_pix:{
                id_parceiro: id_parceiro,
                nome: `${tipoChavePix} - ${chavePix}`,
                tipo: getChaveTipo(tipoChavePix),
                chave: chavePix ,
                proprietario: proprietario
            },
            colaborador:{
                id_parceiro: id_parceiro,
                nome: nome,
                email: email,
                celular: phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("-", ""),
                senha: password,
                device_token: Utils.getDeviceToken()
            }
        }

        setClickFinished(true);

        let registerResponse = await Api.parceiro.register({
            data: data,
        })
        
        Utils.processRequest(registerResponse, (data) => {}, (erro) => {});

        let token = registerResponse?.data?.token;
        await localStorage.setItem("tk_beauty_parceiro_token", token);
        let authResponse = await Api.colaborador.get(token);
        if(authResponse?.data?.success){
            window.location.reload();
        }else{
            setClickFinished(false);
        }

    }

    return (
        loaded ? (
            <>  
                <Modal show={modalTermsShow} setShow={setModalTermsShow}>
                    {viewMode == "policity" ? (
                        <iframe style={{width: '100%'}} height={`300px`} src={"/privacity-policity"}></iframe>
                    ) : (
                        <iframe style={{width: '100%'}} height={`300px`}  src={"/terms-of-use"}></iframe>
                    )}
                </Modal>
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ background: '#fff', padding: '20px', borderRadius: '5px', width: Utils.mobileCheck() ? '90%' : '40%' }}>
                        <div style={{display: 'flex', alignItems:'center', alignItems: 'center', justifyContent: 'center', marginTop: '-58px'}}>
                            <img src='../logo.png' className='shadow' style={{width: '80px', borderRadius: '8px', border: '3px solid #fff'}}/>
                        </div>
                        <div className='mt-2 mb-2'>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                                <div>
                                    <b style={{fontSize: '1.1rem'}}>Registro - IGend</b>
                                    <p>{getText(step)}</p>
                                </div>
                            </div>
                        </div>
                        {step == 0 ? (
                            <>
                                <Input type={"text"} label={"Nome do seu negócio"} value={nomeParceiro} setValue={setNomeParceiro} maxLegth={50}/>
                                <Select label={"Categoria principal"} setValue={setCategoria} value={categoria}>
                                    <Option value={""}>Selecione...</Option>
                                    {categorias?.map(c => (
                                        <Option key={c.id_categoria} value={c.id_categoria}>
                                            {c.display_text}
                                        </Option>
                                    ))}
                                </Select>
                                <Select label={"Categoria secundária (opcional)"} setValue={setSubcategoria} value={subcategoria}>
                                    <Option value={""}>Selecione...</Option>
                                    {categorias?.map(c => (
                                        <Option key={c.id_categoria} value={c.id_categoria}>
                                            {c.display_text}
                                        </Option>
                                    ))}
                                </Select>
                                <Input type={"textarea"} label={"Descreva brevemente seu negócio..."} setValue={setDescricao} value={descricao}/>
                            </>
                        ) : step == 1 ? (
                            <>
                                <MapMarker onPositionChanged={onPositionChanged}/>
                                <SpaceBox space={10}/>
                                <Input type={"cep"} label={"CEP"} setValue={setCep} value={cep}/>
                                {logradouro.trim()?.length > 0 ? (
                                    <>
                                        <Input hideInputBoxMargin type={"text"} label={"Logradouro"} setValue={setLogradouro} value={logradouro}/>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Input type={"code-number"} style={{width: '100%'}} label={"Numero"} setValue={setNumero} value={numero}/>&nbsp;&nbsp;
                                            <Input type={"text"} style={{width: '100%'}} maxLegth={20} label={"Complemento (opcional)"} setValue={setComplemento} value={complemento}/>
                                        </div>
                                    </>
                                ) : (<></>)}
                            </>
                        ) : step == 2 ? (
                            <>
                                <div className='mb-1 mt-1'>
                                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '0px'}}>
                                        <div className='period-button' style={{cursor: 'pointer', borderBottomRightRadius: 0, borderTopRightRadius: 0, border: tipoConta == "PF" ? 'solid 2px #000' : 'solid 2px #ddd', color: tipoConta == "PF" ? 'black' : 'black'}} onClick={() => {changeTipoConta("PF")}}>Física</div>
                                        <div className='period-button' style={{cursor: 'pointer', borderBottomLeftRadius: 0, borderTopLeftRadius: 0, border: tipoConta == "PJ" ? 'solid 2px #000' : 'solid 2px #ddd', color: tipoConta == "PJ" == 1 ? 'black' : 'black'}} onClick={() => {changeTipoConta("PJ")}}>Jurídica</div>
                                    </div>
                                </div>
                                {tipoConta == "PF" ? (
                                    <>
                                        <Input type={"cpf"} label={"CPF"} setValue={setCpf} value={cpf}/>
                                        <Input type={"text"} maxLegth={50} label={"Nome Completo"} setValue={setNome} value={nome}/>
                                    </>
                                ) : (
                                    <>
                                        <Input type={"cnpj"} label={"CNPJ"} setValue={setCnpj} value={cnpj}/>
                                        <Input type={"text"} maxLegth={50} label={"Razão Social"} setValue={setRazaoSocial} value={razaoSocial}/>
                                        <Input type={"text"} maxLegth={50} label={"Nome Fantasia"} setValue={setNomeFantasia} value={nomeFantasia}/>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Input type={"text"} style={{width: '100%'}} hideInputBoxMargin maxLegth={50} label={"Nome Completo"} setValue={setNome} value={nome}/>&nbsp;&nbsp;
                                            <Input type={"cpf"} style={{width: '100%'}} hideInputBoxMargin label={"CPF"} setValue={setCpf} value={cpf}/>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : step == 3 ? (
                            <>
                                <code>*Documentos aceitos (CPF, RG, CNH)</code>
                                <SpaceBox space={5}/>
                                <div className='mb-1' style={{cursor: 'pointer', height: '56px', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '8px', color: frenteDocumento ? 'white' : 'black', background: frenteDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                    <label htmlFor='frente_documento'>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ion-icon name="image-outline"></ion-icon>&nbsp;&nbsp;
                                            <b>Foto da frente&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                        </div>
                                    </label>
                                    <input id='frente_documento' onChange={(e) => {setFrenteDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                                </div>
                                <div className='mb-1' style={{cursor: 'pointer', height: '56px', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '8px', color: trasDocumento ? 'white' : 'black', background: trasDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                    <label htmlFor='tras_documento'>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ion-icon name="image-outline"></ion-icon>&nbsp;&nbsp;
                                            <b>Foto do verso&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                        </div>
                                    </label>
                                    <input id='tras_documento' onChange={(e) => {setTrasDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                                </div>
                                <div className='' style={{cursor: 'pointer', height: '56px', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '8px', color: selfieDocumento ? 'white' : 'black', background: selfieDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                    <label htmlFor='selfie_documento'>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ion-icon name="person-outline"></ion-icon>&nbsp;&nbsp;
                                            <b>Selfie segurando o documento</b>
                                        </div>
                                    </label>
                                    <input id='selfie_documento' onChange={(e) => {setSelfieDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                                </div>
                                <SpaceBox space={5}/>
                                <Hr elevation={1}/>
                                <SpaceBox space={5}/>
                                <div className='mb-1' style={{cursor: 'pointer', padding: '1rem', borderRadius: '8px', color: certDocumento ? 'white' : 'black', background: certDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                    <label htmlFor='cert_documento'>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ion-icon style={{fontSize: '16pt'}} name="shield-checkmark-outline"></ion-icon>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <p>(opcional)</p>
                                                <b>Certificação de atividade</b>
                                                <p style={{fontSize: '10pt'}}>*Com isso você passa mais credibilidade ao público.</p>
                                            </div>
                                        </div>
                                    </label>
                                    <input id='cert_documento' onChange={(e) => {setCertDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                                </div>
                            </>
                        ) : step == 4 ? (
                            <>
                                {days.map((day, index) => {
                                    const [isOn, setIsOn] = day.state;
                                    const [startTime, setStartTime] = day.startState;
                                    const [endTime, setEndTime] = day.endState;

                                    return (
                                        <React.Fragment key={index}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Switch
                                                        index={index}
                                                        isOn={isOn}
                                                        onChange={() => { 
                                                            setIsOn(!isOn) 
                                                            setStartTime("");
                                                            setEndTime("");
                                                        }}
                                                        colorOne="#000"
                                                        colorTwo="#ddd"
                                                    />
                                                    <b>&nbsp;{day.name}&nbsp;&nbsp;</b>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Select label={"Início"} hideInputBoxMargin setValue={setStartTime} value={startTime} disabled={!isOn} width={"95px"}>
                                                        <Option value={""}>...</Option>
                                                        {horas?.map(h => (
                                                            <Option value={h}>{h}</Option>
                                                        ))}
                                                    </Select>
                                                    <p>&nbsp;às&nbsp;</p>
                                                    <Select label={"Fim"} hideInputBoxMargin setValue={setEndTime} value={endTime} disabled={!isOn} width={"95px"}>
                                                        <Option value={""}>...</Option>
                                                        {horas?.map(h => (
                                                            <Option value={h}>{h}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                            <SpaceBox space={5} />
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        ) : step == 5 ? (
                            <>
                                <Select label={"Qual tipo de chave pix?"} setValue={setTipoChavePix} value={tipoChavePix}>
                                    <Option value={""}>Selecione...</Option>
                                    {tiposChavePix?.map(c => (
                                        <Option value={c}>{c}</Option>
                                    ))}
                                </Select>
                                {tipoChavePix?.trim()?.length > 0 ? (
                                    <>
                                        {tipoChavePix == "CPF" ? (
                                            <Input type={"cpf"} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                                        ) : tipoChavePix == "CNPJ" ? (
                                            <Input type={"cnpj"} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                                        ) : tipoChavePix == "Email" ? (
                                            <Input type={"text"} maxLegth={100} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                                        ) : (
                                            <Input type={"celular"} label={"Qual a chave?"} setValue={setChavePix} value={chavePix}/>
                                        )}
                                        <Input type={"text"} maxLegth={50} label={"Nome (completo)"} setValue={setProprietario} value={proprietario}/>
                                    </>
                                ) : (null)}
                            </>
                        ) : step == 6 ? (
                            <>
                                <Input type={"celular"}  label={"Qual seu celular?"} setValue={setPhone} value={phone}/>
                                <Input type={"email"}  label={"Qual seu email?"} setValue={setEmail} value={email}/>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Input type={"password"} style={{width: '100%'}} maxLegth={50} hideInputBoxMargin label={"Sua senha"} setValue={setPassword} value={password}/>&nbsp;&nbsp;
                                    <Input type={"password"} style={{width: '100%'}} maxLegth={50} hideInputBoxMargin label={"Confirmar senha"} setValue={setPasswordC} value={passwordC}/>
                                </div>
                                <div>
                                    <Checkbox flexStart key={0} checked={acceptTerms} setChecked={setAcceptTerms}>
                                        Eu concordo com os &nbsp;<b style={{cursor: 'pointer'}} onClick={() => {handleShowTerms("term")}}>termos</b>&nbsp; e &nbsp;<b style={{cursor: 'pointer'}} onClick={() => {handleShowTerms("policity")}}>políticas</b>.
                                    </Checkbox>
                                </div>
                            </>
                        ) : (null)}
                        <div className='mt-2 mb-2'></div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {step > 0 ? (
                                <>
                                    <Button onClick={handleBack} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <ion-icon name="chevron-back"></ion-icon>
                                        <b>Voltar</b>
                                    </Button>&nbsp;&nbsp;
                                </>
                            ) : (null)}
                            {step < 6 ? (
                                <>
                                    <Button onClick={handleNext} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <b>Proximo</b>
                                        <ion-icon name="chevron-forward"></ion-icon>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {!clickFinished ? (
                                        <Button onClick={handleFinish} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <b>Finalizar</b>
                                            <ion-icon name="checkmark"></ion-icon>
                                        </Button>
                                    ) : (
                                        <Button style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <b>Carregando...</b>
                                        </Button>
                                    )}
                                </>
                            )}
                            
                        </div>
                        {step == 0 ? (
                            <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'center', cursor: 'pointer'}} onClick={handleLogin}>
                                Já tem conta? <b>login</b>
                            </div>
                        ) : (null)}
                    </div>
                </div>
            </>
        ):(null)
    )
}
