import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainContext } from '../../helpers/MainContext';

export default () => {
    const navigate = useNavigate();
    const {target} = useParams();
    const {setSwitched, logout} = useContext(MainContext);
    useEffect(() => {
        switch(target){
            case "parceiro-register":
                if(!localStorage.getItem('tk_beauty_parceiro_token')){
                    localStorage.setItem("tk_beauty_switched", "parceiro");
                    setSwitched("parceiro");
                    navigate('/adm-register');
                }else{
                    navigate("/");
                }
                break;
            case "cliente":
                if(!localStorage.getItem('tk_beauty_cliente_token')){
                    localStorage.setItem("tk_beauty_switched", "cliente");
                    setSwitched("cliente");
                    navigate("/");
                }else{
                    navigate("/");
                }
                break;
            default:
                navigate('/');
                break;
        }
    }, [])
    return (<></>)
}
