import React from 'react';
import './style.css';

export default ({children}) => {
  return (
    <div className='container'>
        {children}
    </div>
  )
}
