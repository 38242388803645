import React from 'react';
import Parceiro from '../Parceiro';
import Utils from '../../Utils';

export default ({parceiros, hideText, hideFilter}) => {
  return (
    <div className={(hideText && hideFilter) ? 'mt-1 mb-1' : 'mt-3 mb-1'}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>     
      {hideText ? (null) : (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <ion-icon name="location-outline" style={{color: "black"}}></ion-icon>
            <h3 style={{color: "black"}}>&nbsp;Perto de você</h3> 
          </div>  
      )}
      {hideFilter ? (null) : (
        <div>
          <ion-icon name="filter-outline" style={{fontSize: '15pt'}}></ion-icon>
        </div>
      )}
      </div>
      <div className='mt-1' style={{display: 'grid', gridTemplateColumns: Utils.mobileCheck() ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)', justifyContent: 'center', gap: '10px'}}>
          {parceiros?.map((parceiro, index) => (
              <Parceiro className={`mt-1`} key={index} parceiro={parceiro}/>
          ))}
      </div>    
    </div>
  )
}
