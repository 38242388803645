import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import {AvaliarModal, BottomTabNavigation, BottomTabNavigationParceiro, Modal, Sidebar, SwipperRefresh} from './components';
import {Home, PageNotFound, Favorites, Reservations, Market, Profile, TermsUse, PrivacityProlicity, OptionOut, Parceiro, ModalSwitchApp, AdmParceiro, Search, ForgotPassword, AdmParceiroLogin, MeusDados, AlterarSenha, SaldoBonus, MinhasAssinaturas, AdmServicos, AdmProdutos, AdmEquipe, AdmPerfil, VerificacaoCliente, ColaboradorLogin, AdmParceiroRegister, TargetLink, AdmSaldo, PaymentLink, Notificacoes, Enderecos, AdmForgotPassword, AdmLocaisEntrega, AdmAvaliacoes, AdmClientes, AdmCupons} from './pages';
import {MainContext} from './helpers/MainContext';
import CoordsProviderHelper from './helpers/CoordsProviderHelper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Api from './Api';
import Utils from './Utils';
import AdmPedidos from './pages/AdmPedidos';

function App() {

  const [switched, setSwitched] = useState(localStorage.getItem("tk_beauty_switched") || null);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(Number(localStorage.getItem("tk_bauty_bottomtab_index") ? localStorage.getItem("tk_bauty_bottomtab_index") : 1));
  const [pageIndexDesktop, setPageIndexDesktop] = useState(Number(localStorage.getItem("tk_bauty_bottomtab_index_desktop")) || 1);

  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const [deviceToken, setDeviceToken] = useState(localStorage.getItem("tk_beauty_device_token") ? localStorage.getItem("tk_beauty_device_token") : null);

  const [coords, setCoords] = useState(null);

  const [onRefresh, setOnRefresh] = useState(false);

  //PAGE STATES
  const [banners, setBanners] = useState([
    {
      id_banner: null,
        imagem: "placeholder-image.png",
    },
    {
        id_banner: null,
        imagem: "placeholder-image.png",
    },
    {
        id_banner: null,
        imagem: "placeholder-image.png",
    },
    {
        id_banner: null,
        imagem: "placeholder-image.png",
    },
  ]);
  const [categories, setCategories] = useState([
    {
        id: null,
        imagem: 'placeholder-image.png',
        nome: ""
    },
    {
        id: null,
        imagem: 'placeholder-image.png',
        nome: ""
    },
    {
        id: null,
        imagem: 'placeholder-image.png',
        nome: ""
    },
    {
        id: null,
        imagem: 'placeholder-image.png',
        nome: ""
    },
    {
        id: null,
        imagem: 'placeholder-image.png',
        nome: ""
    },
  ]);
  const [parceiros, setParceiros] = useState([
    {
        id_parceiro: null,
        logo_parceiro: "placeholder-image.png",
        nome_parceiro: "",
        descricao_parceiro: "",
    },
    {
        id_parceiro: null,
        logo_parceiro: "placeholder-image.png",
        nome_parceiro: "",
        descricao_parceiro: "",
    },
    {
        id_parceiro: null,
        logo_parceiro: "placeholder-image.png",
        nome_parceiro: "",
        descricao_parceiro: "",
    },
  ])
  const [produtos, setProdutos] = useState([]);
  const [assinaturas, setAssianturas] = useState([]);

  const logout = (callback) => {
    localStorage.removeItem("tk_beauty_switched");
    localStorage.removeItem("tk_beauty_cliente_token");
    localStorage.removeItem("tk_beauty_parceiro_token");
    localStorage.removeItem("tk_beauty_id");
    localStorage.removeItem("tk_bauty_bottomtab_index");
    localStorage.removeItem("tk_bauty_bottomtab_index_desktop");
    if(callback){
      callback();
      window.location.reload();
    }
    setSwitched(null);
  }

  useEffect(() => {
    setLoading(false);
    setOnRefresh(false);

    if(localStorage.getItem("tk_beauty_device_token")){
      setDeviceToken(localStorage.getItem("tk_beauty_device_token"));
    }

    const setupCliente = async () => {
      CoordsProviderHelper(async (coords) => {
        setCoords(coords);

        //Setup page cliente on coords callback=======================
        if(switched == "cliente") {
          let responseBanners = await Api.geral.banners({
              lat: coords?.latitude,
              lon: coords?.longitude
          });
          let _banners = responseBanners?.data?.data;
          setBanners(_banners);

          let responseCategorias = await Api.geral.categorias();
          let _categories = responseCategorias?.data?.data
          setCategories(_categories);

          let responseParceiros = await Api.geral.parceiros({
              lat: coords?.latitude,
              lon: coords?.longitude
          });
          setParceiros(responseParceiros?.data?.data);

          let ids_parceiros = [];
          let _parceiros = responseParceiros?.data?.data;

          for(let i = 0; i < _parceiros?.length; i++){
              ids_parceiros.push(_parceiros[i]?.id_parceiro);
          }

          if(ids_parceiros?.length > 0) {
              let responseProdutos = await Api.geral.produtos({
                  ids_parceiros: ids_parceiros
              });
              setProdutos(responseProdutos?.data?.data);
          }

          let response = await Api.cliente.assinaturasByCliente({
              forceToken: localStorage.getItem("tk_beauty_cliente_token"), 
          });
          let _assinaturas = response?.data?.data;
          setAssianturas(_assinaturas);
        }
        //====================================================

      });
      const responseAuth = await Api.cliente.auth();
      if (responseAuth?.data?.data?.success) {
        let responseUser = await Api.cliente.get();
        setUser(responseUser?.data?.data);
      }else{
        setAuthenticated(false);
        setUser(null);
      }
      if (switched != "parceiro" || switched != "colaborador"){
        setLoading(true);
      }
    }
    const setupParceiro = async () => {
      CoordsProviderHelper(async (coords) => {
        setCoords(coords);
      });
      const responseAuth = await Api.colaborador.auth();
      if (responseAuth?.data?.data?.success) {
        let responseUser = await Api.colaborador.get();
        setUser(responseUser?.data?.data);
        setAuthenticated(true);
      }else{
        setAuthenticated(false);
        setUser(null);
      }
      setLoading(true);
    }
    setupCliente();
    if (switched == "parceiro" || switched == "colaborador"){
      setupParceiro();
    }

  }, [switched, onRefresh])

  const onRefreshHandler = async () => {
    setOnRefresh(true);
  }

  const onHandleSwitchedChanged = () => {
    setSwitched(localStorage.getItem("tk_beauty_switched"));
  }

  const getInitialScreen = () => (
    switched == "parceiro" ?
      authenticated ? <AdmParceiro/> : <AdmParceiroLogin loading={loading}/> 
    : switched == "colaborador" ? 
      authenticated ? <AdmParceiro/> : <ColaboradorLogin loading={loading}/> 
    : switched == "cliente" ? 
    <Home/>
    :
    <ModalSwitchApp callback={onHandleSwitchedChanged}/>
  );

  const nextPageIfAuthenticatedAndVerified = (user, targetPage, recusePage) => {
    if(user != null){
      if(user?.verificado == 0){
        return <VerificacaoCliente/>
      }else{
        return targetPage;
      }
    }else{
      return recusePage;
    }
  }

  return (
    loading ? (
      <>
        <MainContext.Provider value={{ loading, setSwitched, authenticated, user, coords, setAuthenticated, setUser, logout, setOnRefresh, onRefreshHandler, banners, categories, parceiros, produtos, assinaturas, deviceToken }}>
          <Router>
            {switched == "parceiro" && !Utils.mobileCheck() && authenticated ? (<Sidebar setPageIndex={setPageIndexDesktop} pageIndex={pageIndexDesktop}/>) : (null)}
            <div style={{display: Utils.mobileCheck() ? undefined : 'flex', justifyContent: Utils.mobileCheck() ? undefined : 'center' }}>
              <div style={{width: Utils.mobileCheck() ? '100%' : '50%', marginLeft: Utils.mobileCheck() ? undefined : authenticated ? '250px' : '0px'}}>
                <Routes>
                  <Route path="/" exact element={nextPageIfAuthenticatedAndVerified(user, getInitialScreen(), getInitialScreen())} />
                  <Route path="/favorites" element={nextPageIfAuthenticatedAndVerified(user, <Favorites />, <Favorites />)}/>
                  <Route path="/reservations" element={nextPageIfAuthenticatedAndVerified(user, <Reservations />, <Reservations />)} />
                  <Route path="/profile" element={nextPageIfAuthenticatedAndVerified(user, <Profile />, <Profile />)} />
                  <Route path="/profile/info" element={nextPageIfAuthenticatedAndVerified(user, <MeusDados/>, <Profile />)} />
                  <Route path="/profile/editpassword" element={nextPageIfAuthenticatedAndVerified(user, <AlterarSenha/>, <Profile />)} />
                  <Route path="/profile/bonus" element={nextPageIfAuthenticatedAndVerified(user, <SaldoBonus/>, <Profile />)} />
                  <Route path="/profile/subscriptions" element={nextPageIfAuthenticatedAndVerified(user, <MinhasAssinaturas/>, <Profile />)} />
                  <Route path="/profile/notifications" element={nextPageIfAuthenticatedAndVerified(user, <Notificacoes/>, <Profile />)} />
                  <Route path="/profile/address" element={nextPageIfAuthenticatedAndVerified(user, <Enderecos/>, <Profile />)} />
                  <Route path="/parceiro/:id_parceiro" element={nextPageIfAuthenticatedAndVerified(user, <Parceiro/>, <Parceiro />)} />
                  <Route path="/verify-code" element={user ? <VerificacaoCliente /> : getInitialScreen()} />
                  <Route path="/target-link/:target" element={<TargetLink/>} />
                  <Route path='/payment-link/:type/:phone/:id_transaction/:collect_address/:id_ref' element={<PaymentLink/>}/>
                  {/*========ADM=======*/}
                  <Route path="/adm-services" element={user ? <AdmServicos /> : <AdmParceiroLogin loading={loading}/>} />
                  <Route path="/adm-products" element={user ? <AdmProdutos /> : <AdmParceiroLogin loading={loading}/>} />
                  <Route path="/adm-team" element={user ? <AdmEquipe /> : <AdmParceiroLogin loading={loading}/>} />
                  <Route path="/adm-profile" element={user ? <AdmPerfil /> : <AdmParceiroLogin loading={loading}/>} />
                  <Route path="/adm-register" element={user ? <AdmParceiro /> : <AdmParceiroRegister/>} />
                  <Route path="/adm-saldo" element={user ? <AdmSaldo /> : <AdmParceiroLogin/>} />
                  <Route path="/adm-shipping" element={user ? <AdmLocaisEntrega /> : <AdmParceiroLogin/>} />
                  <Route path="/adm-pedidos" element={user ? <AdmPedidos /> : <AdmParceiroLogin/>} />
                  <Route path='/adm-avaliacoes' element={user ? <AdmAvaliacoes/> : <AdmParceiroLogin/>} />
                  <Route path='/adm-clientes' element={user ? <AdmClientes/> : <AdmParceiroLogin/>} />
                  <Route path='/adm-cupons' element={user ? <AdmCupons/> : <AdmParceiroLogin/>} />
                  {/*=======COLABORADOR========*/}
                  <Route path="/colaborador-login" element={user ? <AdmParceiro/> :  <ColaboradorLogin loading={loading}/>} />
                  {/*=======PUBLIC======*/}
                  <Route path="/market" element={nextPageIfAuthenticatedAndVerified(user, <Market />, <Market /> )} />
                  <Route path='/search' element={<Search/>} />
                  <Route path="/terms-of-use" element={<TermsUse />} />
                  <Route path="/privacity-policity" element={<PrivacityProlicity />} />
                  <Route path="/option-out" element={<OptionOut />} />
                  <Route path='/forgot' element={<ForgotPassword/>} />
                  <Route path='/adm-forgot' element={<AdmForgotPassword/>} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </div>
            </div>
            {(
              switched == "cliente" ?
                <BottomTabNavigation pageIndex={pageIndex} setPageIndex={setPageIndex} />
              : 
                authenticated && Utils.mobileCheck() ? <BottomTabNavigationParceiro pageIndex={pageIndex} setPageIndex={setPageIndex} /> : null
            )}
            <ToastContainer style={{zIndex: 999999}} />
          </Router>
        </MainContext.Provider>
      </>
    ) : (null)
  );
}

export default App;
