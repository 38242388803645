const CoordsProviderHelper = async (callback) => {
    if (navigator.geolocation) {
        try{
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;

            if(callback){
                callback({
                    latitude,
                    longitude
                })
            }

        }catch(err){
            console.log(err)
            if(callback){
                callback(null)
            }
        }
    } else {
        console.error('Geolocalização não suportada pelo navegador');
    }
}

export default CoordsProviderHelper;