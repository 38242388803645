import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Button, Card, Container, FlatButton, Header, Hr, Input, Modal, Option, ParceiroAgenda, ParceiroAgendaAdm, ReservationItemAdm, Select, SpaceBox, SwipperRefresh } from '../../components';
import Api from '../../Api';
import { format, isToday, isTomorrow, isYesterday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MainContext } from '../../helpers/MainContext';
import Utils from '../../Utils';
import DownloadApp from '../../components/DownloadApp';
import { useNavigate } from 'react-router-dom';

export default () => {

    const {user, onRefreshHandler} = useContext(MainContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [agendamentos, setAgendamentos] = useState([]);
    const [modalAgendamento, setModalAgendamento] = useState(false);
    const [showBalances, setShowBalances] = useState(localStorage.getItem("tk_beauty_parceiro_show_balances") == 'true');
    const [receitaDiaria, setReceitaDiaria] = useState(0);
    const [ticketDiario, setTicketDiario] = useState(0);
    const [assinantes, setAssinantes] = useState([]);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [nota, setNota] = useState(0);

    const [documentsModal, setDocumentsModal] = useState(false);
    const [frenteDocumento, setFrenteDocumento] = useState(null);
    const [trasDocumento, setTrasDocumento] = useState(null);
    const [selfieDocumento, setSelfieDocumento] = useState(null);
    const [clickedDocumentSending, setClickedDocumentSending] = useState(false);

    const [parceiro, setParceiro] = useState(null);
    const [expediente, setExpediente] = useState(null);
    const [servicos, setServicos] = useState(null);
    const [servicoSelected, setServicoSelected] = useState(null);
    const [servicoSelectedOjb, setServicoSelectedObj] = useState(null);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const [modeAgendamentoSelected, setModeAgendamentoSelected] = useState(0);
    const [clientes, setClientes] = useState([]);
    const [clienteSelected, setClienteSelected] = useState(null);

    const uniqueDates = {};

    useEffect(() => {
        setup();
        const intervalId = setInterval(() => {
            setup();
        }, 10000);
        return () => {
          clearInterval(intervalId);
        };
    }, []); 

    useEffect(() => {
        setServicoSelectedObj(null);
        let _s  = servicos?.filter(s => {return s?.id_servico == servicoSelected})[0];
        setServicoSelectedObj(_s);
    }, [servicoSelected, setServicoSelected])

    useEffect(() => {
        let valid = Utils.validatePhoneNumber(phone);
        setPhoneIsValid(valid);
        if(!valid){
            handleResetComponent();
        }
    }, [phone, setPhone])

    useEffect(() => {
        const requests = async () => {
            if(name?.trim()?.length > 0){
                let clientesResponse = await Api.parceiro.clientes({
                    forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
                    id_parceiro: user?.id_parceiro,
                    nome: name,
                })
                let _clientes = clientesResponse?.data?.data;
                if(name?.trim()?.length <= 0){
                    setClientes([]);
                }else{
                    setClientes(_clientes);
                }
            }else{
                setClientes([]);
            }
        }
        requests();
    }, [setName, name])

    const setup = async () => {
        let _token = localStorage.getItem("tk_beauty_parceiro_token");

        let parceiroResponse = await Api.geral.parceiro({
            id_parceiro: user?.id_parceiro
        });

        let expedienteResponse = await Api.geral.parceiroExpedientes({
            id_parceiro: user?.id_parceiro
        });

        let servicosResponse = await Api.geral.servicosByParceiro({
            id_parceiro: user?.id_parceiro
        });

        let responseAgendamentos = await Api.colaborador.getAgendamentos({
            forceToken: _token,
        });
        let responseReceitaDiaria = await Api.colaborador.receitaDiaria({
            forceToken: _token,
        });
        let responseTicketDiario = await Api.colaborador.ticketDiario({
            forceToken: _token,
        });
        let responseAssinantes = await Api.colaborador.assinantes({
            forceToken: _token,
            id_parceiro: user?.id_parceiro
        })
        let responseAvaliacao = await Api.geral.avaliacaoParceiro({
            forceToken: _token,
            id_parceiro: user?.id_parceiro
        })

        let _parceiro = parceiroResponse?.data?.data;
        let _expediente = expedienteResponse?.data?.data;
        let _servicos = servicosResponse?.data?.data;
        let _agendamentos = responseAgendamentos?.data?.data;
        let _receitaDiaria = responseReceitaDiaria?.data?.data;
        let _ticketDiario = responseTicketDiario?.data?.data;
        let _assinantes = responseAssinantes?.data?.data;
        let _avaliacoesParceiro = responseAvaliacao?.data?.data;

        const _nota = Utils.calcularAvaliacao(_avaliacoesParceiro);

        setParceiro(_parceiro);
        setExpediente(_expediente);
        setServicos(_servicos);
        setAgendamentos(_agendamentos);
        setReceitaDiaria(_receitaDiaria);
        setTicketDiario(_ticketDiario);
        setAssinantes(_assinantes);
        setAvaliacoes(_avaliacoesParceiro);
        setNota(_nota);
        setLoading(true);
    } 

    const formatReservationDate = (_timestamp) => {

        let _date = _timestamp?.split(" ")[0];
        let _hour = _timestamp?.split(" ")[1];

        let _dia = _date?.split("/")[0];
        let _mes = _date?.split("/")[1];
        let _ano = _date?.split("/")[2];

        let timestamp = _ano + "-" + _mes + "-" + _dia + " " + _hour;

        const date = new Date(timestamp);
        if (isToday(date)) {
            return 'Hoje';
        } else if (isTomorrow(date)) {
            return 'Amanhã';
        } else {
            return format(date, "d 'de' MMMM", { locale: ptBR });
        }
    };

    const onFetchReservations = async () => {
        setup();
    }

    const handleEye = () => {
        setShowBalances(!showBalances);
        localStorage.setItem("tk_beauty_parceiro_show_balances", !showBalances);
    }

    const getReceitaDiaria = () => receitaDiaria?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
     
    const getTicketMedioDiario = () => ticketDiario?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

    const handleDocumentsModal = () => {
        setDocumentsModal(true);
    }

    const resendDocumentation = async () => {
        setClickedDocumentSending(true);

        if(frenteDocumento == null){
            Utils.notify("error", "Envie a frente do seu documento de identificação.");
            setClickedDocumentSending(false);
            return;
        }
        if(trasDocumento == null){
            Utils.notify("error", "Envie o verso do seu documento de identificação.");
            setClickedDocumentSending(false);
            return;
        }
        if(selfieDocumento == null){
            Utils.notify("error", "Envie uma selfie segurando seu documento de identificação.");
            setClickedDocumentSending(false);
            return;
        }

        let response = await Api.colaborador.resendDocumentation({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            data: {
                foto_frente_documento_identificacao: frenteDocumento,
                foto_verso_documento_identificacao: trasDocumento,
                selfie_documento_identificacao: selfieDocumento,
            }
        })
        Utils.processRequest(response, (data) => {
            setTimeout(function () {
                window.location.reload();
            }, 1000)
        }, () => {
            setClickedDocumentSending(false);
        }, true);
    }
    
    const handleResetComponent = () => {
        setPhone(null);
        setServicoSelected(null);
        setServicoSelectedObj(null);
        if(modeAgendamentoSelected == 0){
            setModeAgendamentoSelected(0);
        }
    }

    const onScrollBottomAnimate = () => {
        const divElement = document.getElementById('child-modal-agendamento'); 
        divElement.scrollTop = divElement.scrollHeight;
    }

    const handleModeAgendamentoClick = (mode) => {
        setModeAgendamentoSelected(mode);
        setName(null);
        setPhone(null);
        setClientes([]);
        setClienteSelected(null);
    }

    const unlinkCliente = () => {
        setClienteSelected(null);
        setName("");
        setPhone(null);
    }

    const handleSelectCliente = (cliente) => {
        setClienteSelected(cliente);
        setName(cliente?.nome);
        setPhone(Utils.formatarParaCelular(cliente?.celular));
    }

    return (
        <>
            {loading == true ? (
                <>
                    <Modal setShow={setModalAgendamento} show={modalAgendamento} onCloseCallback={handleResetComponent}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <b style={{fontSize: '1.2rem'}}>Novo agendamento</b>
                                <div style={{color: 'gray', fontSize: '0.85rem'}}>Adicionar novo agendamento para o cliente.</div>
                            </div>
                        </div>
                        <div id='child-modal-agendamento' style={{width: '100%', maxHeight: 'calc(100vh - 200px)', overflow: 'auto'}}>
                            
                            <div className='mb-1 mt-1'>
                                <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', gap: '0px'}}>
                                    <div className='period-button' style={{cursor: 'pointer', border: modeAgendamentoSelected == 0 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeAgendamentoSelected == 0 ? 'black' : 'black'}} onClick={() => {handleModeAgendamentoClick(0)}}>Novo cliente</div>
                                    <div className='period-button' style={{cursor: 'pointer', border: modeAgendamentoSelected == 1 ? 'solid 2px #000' : 'solid 2px #ddd', color: modeAgendamentoSelected == 1 ? 'black' : 'black'}} onClick={() => {handleModeAgendamentoClick(1)}}>Meus clientes</div>
                                </div>
                            </div>

                            {modeAgendamentoSelected == 0 ? (
                                <>
                                    <Input type={"text"} value={name} setValue={setName} label={"Nome do cliente (opcional)"} />
                                    <Input type={"celular"} value={phone} setValue={setPhone} label={"Número do cliente"} />
                                </>
                            ) : (
                                <>
                                    {clienteSelected ? (
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Input type={"text"} disabled={true} value={`${clienteSelected?.nome} - ${clienteSelected?.celular}`} label={"Nome do cliente"} style={{width: '100%'}} />&nbsp;
                                            <ion-icon size={"large"} style={{cursor: 'pointer', color: 'gray'}} onClick={unlinkCliente} name="close-circle-outline"></ion-icon>
                                        </div>
                                    ) : (
                                        <>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <Input type={"text"} value={name} setValue={setName} label={"Nome do cliente"} style={{width: '100%'}} />&nbsp;
                                                <ion-icon size={"large"} name="search-outline"></ion-icon>
                                            </div>
                                            {clientes?.length > 0 ? (
                                                <div style={{display: 'flex', alignItems: 'center', position: 'absolute', zIndex: 999}}>
                                                    <div className={"shadow"} style={{background: "#fff", maxHeight: '200px', overflow: 'auto', marginTop: '-10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', padding: '8px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'}}>
                                                        {clientes?.map((c) => (
                                                            <li onClick={() => {handleSelectCliente(c)}} className='li-colaborador' style={{height: '40px', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center'}}>
                                                                <ion-icon name="search-outline"></ion-icon>&nbsp;&nbsp;
                                                                <b>{c?.nome} - {c?.celular}</b>
                                                            </li>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (null)}
                                        </>
                                    )}
                                </>
                            )}
                            
                            {phoneIsValid == true ? (
                                <>
                                    <Select label={"Qual serviço?"} setValue={setServicoSelected} value={servicoSelected}>
                                        <Option value={""}>Selecione...</Option>
                                        {servicos?.map(c => (
                                            <Option key={c.id_servico} value={c.id_servico}>
                                                {c.nome_servico} - {Utils.converterHoraPorExtenso(c.tempo_servico)}
                                            </Option>
                                        ))}
                                    </Select>
                                    
                                    {servicoSelected ? (
                                        <ParceiroAgendaAdm phone={phone} name={name} onScrollBottomAnimate={onScrollBottomAnimate} key={servicoSelectedOjb?.id_servico} expediente={expediente} parceiro={parceiro} servico={servicoSelectedOjb} handleResetComponent={handleResetComponent} assinante={false} comeToMe={parceiro?.flg_movel == 1} bonus={0}/>
                                    ) : (null)}

                                </>
                            ) : (null)}
                        </div>
                    </Modal>
                    <Modal setShow={setDocumentsModal} show={documentsModal}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <b style={{fontSize: '1.2rem'}}>Reenvio de documentação</b>
                                <div style={{color: 'gray', fontSize: '0.85rem'}}>Tente enviar fotos legíveis sem rasuras ou reflexos.</div>
                            </div>
                        </div>
                        <div className='mt-2 mb-2'>
                            <code>*Documentos aceitos (CPF, RG, CNH)</code>
                            <SpaceBox space={5}/>
                            <div className='mb-1' style={{height: '56px', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '8px', color: frenteDocumento ? 'white' : 'black', background: frenteDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                <label htmlFor='frente_documento'>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="image-outline"></ion-icon>&nbsp;&nbsp;
                                        <b>Foto da frente&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                    </div>
                                </label>
                                <input id='frente_documento' onChange={(e) => {setFrenteDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                            </div>
                            <div className='mb-1' style={{height: '56px', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '8px', color: trasDocumento ? 'white' : 'black', background: trasDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                <label htmlFor='tras_documento'>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="image-outline"></ion-icon>&nbsp;&nbsp;
                                        <b>Foto do verso&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                    </div>
                                </label>
                                <input id='tras_documento' onChange={(e) => {setTrasDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                            </div>
                            <div className='' style={{height: '56px', paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '8px', color: selfieDocumento ? 'white' : 'black', background: selfieDocumento ? '#07bc0c' : '#f5f5f5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: 'solid 1px #ddd'}}>
                                <label htmlFor='selfie_documento'>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="person-outline"></ion-icon>&nbsp;&nbsp;
                                        <b>Selfie segurando o documento</b>
                                    </div>
                                </label>
                                <input id='selfie_documento' onChange={(e) => {setSelfieDocumento(e.target.files[0])}} type='file' capture='user' accept='image/*' hidden/>
                            </div>
                        </div>
                        {clickedDocumentSending ? (
                            <Button style={{width: '100%'}}>
                                <b>Enviando...</b>
                            </Button>
                        ) : (
                            <Button style={{width: '100%'}} onClick={resendDocumentation}>
                                <b>Reenviar</b>
                            </Button>
                        )}
                    </Modal>
                </>
            ): (null)}
            <Container>
                <SwipperRefresh heightForce={Utils.mobileCheck() ? '90vh' : undefined} onRefreshHandler={onRefreshHandler}>
                    {loading == true ? (
                        user?.cadastro_aprovado == 'S' && user?.reprovado_parceiro == 'N' ? (
                            <FlatButton text="Agendar" onClick={() => {setModalAgendamento(true)}} fontSize={"2rem"}>
                                <ion-icon name="calendar-outline" style={{marginTop: '10px'}}></ion-icon>
                            </FlatButton>
                        ) : (null)
                    ):(null)}
                    <SpaceBox space={7}/>
                    <Header parceiro handleEye={handleEye} showEyes={showBalances}/>
                    <div className='mt-1 mb-2'></div>
                    {loading ? (
                        <>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                {user?.role  == 'A' ? (
                                    <>
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #24a028'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Receita diária</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? getReceitaDiaria() : 'R$ ****'}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #4a90e2'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Ticket diário</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? getTicketMedioDiario() : 'R$ ****'}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%', borderTop: 'solid 3px #d49730'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Agendamentos</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{showBalances ? agendamentos?.length : '****'}</b>
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <>
                                        <Card className={'shadow'} style={{width: '100%'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Agendamentos</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{agendamentos?.length}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Avaliações</span>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}} onClick={() => {navigate('/adm-avaliacoes')}}>
                                                <ion-icon style={{color: 'orange'}} name="star"></ion-icon>&nbsp;
                                                <b style={{fontSize: '110%'}}>{nota}</b>
                                                <p style={{fontSize: '10pt'}}>&nbsp;&nbsp;(<ion-icon style={{fontSize: '8pt'}} name="person-outline"></ion-icon>&nbsp;{avaliacoes?.length})</p>
                                            </div>
                                        </Card>
                                    </>
                                )}
                            </div>
                            {user?.role  == 'A' ? (
                                <>
                                    <SpaceBox space={10}/>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Card className={'shadow'} style={{width: '100%'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Assinantes</span>
                                            </div>
                                            <div>
                                                <b style={{fontSize: '110%'}}>{assinantes?.length}</b>
                                            </div>
                                        </Card>&nbsp;&nbsp;
                                        <Card className={'shadow'} style={{width: '100%'}}>
                                            <div>
                                                <span style={{fontSize: '10pt'}}>Avaliações</span>
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center'}} onClick={() => {navigate('/adm-avaliacoes')}}>
                                                <ion-icon style={{color: 'orange'}} name="star"></ion-icon>&nbsp;
                                                <b style={{fontSize: '110%'}}>{nota}</b>
                                                <p style={{fontSize: '10pt'}}>&nbsp;&nbsp;(<ion-icon style={{fontSize: '8pt'}} name="person-outline"></ion-icon>&nbsp;{avaliacoes?.length})</p>
                                            </div>
                                        </Card>
                                    </div>
                                </>
                            ):(null)}
                        </>
                    ):(null)}
                    {user?.cadastro_aprovado == 'N' && user?.reprovado_parceiro == 'N' ? (
                        <>
                            <div className='shadow mt-1 mb-1' style={{display: 'flex', alignItems: 'center', background: '#f7eede', color: '#89744d', border: 'solid 0.5px orange', borderRadius: '8px', padding: '8px'}}>
                                <ion-icon name="time-outline" size="large"></ion-icon>&nbsp;&nbsp;
                                <b>Seu cadastro está em análise, dentro de alguns minutos te retornaremos.</b>
                            </div>
                        </>
                    ) : (null)}
                    {user?.cadastro_aprovado == 'N' && user?.reprovado_parceiro == 'S' ? (
                        <>
                            <div className='shadow mt-1 mb-1' style={{background: '#ffefef', color: '#5e2d2d', border: 'solid 0.5px red', borderRadius: '8px', padding: '8px'}}>
                                <div style={{display: 'flex', alignItems: 'center',}}>
                                    <ion-icon name="close" size="large"></ion-icon>&nbsp;&nbsp;
                                    <b>Seu cadastro foi reprovado: {user?.reprovado_motivo}</b>
                                </div>
                                {user?.role == 'A' ? (
                                    <div className='mt-1' style={{display: 'flex', justifyContent: 'end'}}>
                                        <Button onClick={handleDocumentsModal} className={'shadow'} style={{height: '45px', background: 'white', color: 'black', display: 'flex', alignItems: 'center'}}>
                                            &nbsp;&nbsp;<ion-icon name="cloud-upload-outline"></ion-icon>
                                            <b>&nbsp;Reenviar documentação&nbsp;&nbsp;</b>
                                        </Button>
                                    </div>
                                ) : (null)}
                            </div>
                        </>
                    ) : (null)}
                    {loading && user?.cadastro_aprovado == 'S' && user?.reprovado_parceiro == 'N' ? (
                        <>
                            {agendamentos?.length > 0 ? (
                                agendamentos.map((reserva, index) => {
                                
                                if (!uniqueDates[formatReservationDate(reserva.data_agendamento+" "+reserva?.hora_agendamento)]) {
                                
                                    uniqueDates[formatReservationDate(reserva.data_agendamento+" "+reserva?.hora_agendamento)] = true;
                                    
                                    return (
                                        <>
                                            <div style={{position: 'sticky', top: '0px', zIndex: 1, background: '#f5f5f5', padding: '8px', fontWeight: 'bold', marginBottom: '10px' }}>
                                                {formatReservationDate(reserva.data_agendamento+" "+reserva?.hora_agendamento)}
                                            </div>
                                            <ReservationItemAdm onFetchReservations={onFetchReservations} reserva={reserva} expanded={index == 0 && reserva?.executado == 0} />
                                        </>
                                    );
                                } else {
                                    return (
                                        <ReservationItemAdm onFetchReservations={onFetchReservations} reserva={reserva} expanded={index == 0 && reserva?.executado == 0} />
                                    );
                                }
                                })
                            ) : (
                                <>
                                    <SpaceBox space={10}/>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mt-1 mb-1'>
                                        <b style={{ color: 'gray' }}>Nenhum agendamento.</b>
                                    </div>
                                </>
                            )}
                            {Utils.isWebView() ? (<DownloadApp/>) : (null)}
                        </>
                    ):(
                        user?.cadastro_aprovado == 'S' && user?.reprovado_parceiro == 'N' ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mt-1 mb-1'>
                                <b style={{ color: 'gray' }}>Carregando...</b>
                            </div>
                        ):(null)
                    )}
                    <SpaceBox space={70}/>
                </SwipperRefresh>
            </Container>
        </>
    )
}
