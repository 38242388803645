import axios from 'axios';

const LocationProviderHelper = async ({lat, lon}) => {
  const apiUrl = 'https://geocode.maps.co/reverse';
  const apiKey = '65ac5c730c65c170330920dxbdc60b5';
  try {
    const response = await axios.get(apiUrl, {
      params: {
        lat,
        lon,
        api_key: apiKey,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (error) {
    return null;
  }
};

export default LocationProviderHelper;