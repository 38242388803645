import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = ({ latitude, longitude, bind }) => {

    useEffect(() => {
        const mapContainer = L.map('map').setView([latitude, longitude], 13);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(mapContainer);

        const icon = L.icon({
            iconUrl: '../loc.webp',
            iconSize: [32, 32],
            iconAnchor: [16, 32]
        });

        L.marker([latitude, longitude], { icon: icon })
            .addTo(mapContainer)
            .bindPopup(bind)
            .openPopup();

        // Clean up function
        return () => {
            mapContainer.remove();
        };
    }, [latitude, longitude, bind]);

    return <div id="map" style={{ width: '100%', height: '200px' }}></div>;
};

export default MapComponent;