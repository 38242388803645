import React, { useEffect, useState, useRef, useContext  } from 'react';
import './style.css';
import { Button, Card, Checkbox, FragmentView, Input, Modal, Option, Select, Ship, SpaceBox } from '../../components';
import Utils from '../../Utils';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';
import Environment from '../../Environment';

const styles = {
    overlay: {},
    modal: {
        maxHeight: '65vh',
        overflow: 'auto',
        marginBottom: '10px'
    },
    content: {},
    card: {},
    productInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    image: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: '8px',
    },
};

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [filter, setFilter] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [data, setData] = useState('');
    const [status, setStatus] = useState('');
    const [tipo, setTipo] = useState('');

    const [orders, setOrders] = useState([]);

    const [showModalStatus, setShowModalStatus] = useState(false);
    const [modalStatusPedido, setModalStatusPedido] = useState('');
    const [modalNotificaCliente, setModalNotificaCliente] = useState(true);
    const [pedidoSelected, setPedidoSelected] = useState(null);

    const [showModalPedido, setShowModalPedido] = useState(false);
    const [selectNavigationParceiroModal, setSelectNavigationParceiroModal] = useState(false);

    useEffect(() => {
        setup();
    }, [])

    useEffect(() => {
        if (filter.trim().length > 0 || status !== null || tipo !== null || data.trim().length > 0) {
            let filtered = orders.filter(order => {
                const matchesFilter = order?.nome_cliente?.toLowerCase().includes(filter.toLowerCase()) || order?.nome_produto?.toLowerCase().includes(filter.toLowerCase());
                const matchesData = data.trim().length > 0 ? order?.ts_registro.includes(Utils.converterDateUS(data)) : true;
                const matchesStatus = status === null || status === "" ? true : order?.status === status;
                const matchesTipo = tipo === null || tipo === "" ? true : order?.tipo === tipo;

                return matchesFilter && matchesStatus && matchesTipo && matchesData;
            });
            setFilteredOrders(filtered);
            setCurrentPage(1);
        } else {
            setFilteredOrders(orders);
        }
    }, [filter, status, tipo, data, orders]);

    const setup = async () => {
        setLoading(false);
        let ordersResponse = await Api.parceiro.pedidos({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: user?.id_parceiro
        })
    
        let _orders = ordersResponse?.data?.data;

        setOrders(_orders);
        setLoading(true);
    }

    const handleFilterChange = (e) => {
        setFilter(e);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const next = () => {
        const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);
        let nextPage = currentPage + 1;
        if(nextPage >= totalPages){
            setCurrentPage(totalPages);
        }else{
            setCurrentPage(nextPage);
        }
    }

    const previous = () => {
        let previousPage = currentPage - 1;
        if(previousPage <= 1){
            setCurrentPage(1);
        }else{
            setCurrentPage(previousPage);
        }
    }

    const handleClearFilter = () => {
        setFilter('');
        setData('');
        setStatus('')
        setTipo('')
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

    const getType = (_type) => {
        switch(_type){
            case "R":
                return( 
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="storefront-outline"></ion-icon>&nbsp;
                        <b>Retirada</b>
                    </div>);
            case "E":
                return( 
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="cube-outline"></ion-icon>&nbsp;
                        <b>Entrega</b>
                    </div>);
        }
    }

    const getEntregue = (value) => {
        const valueMap = {
            "S": "Sim",
            "N": "Não"
        }
        return valueMap[value];
    }

    const getStatus = (status) => {
        const statusMap = {
            "P": (
                <Ship cursorPointer noBorder dotStyle="green">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Pago</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            ),
            "A": (
                <Ship cursorPointer noBorder dotStyle="orange">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Aguardando&nbsp;pagamento</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            ),
            "C": (
                <Ship cursorPointer noBorder dotStyle="red">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Cancelado</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            ),
            "S": (
                <Ship cursorPointer noBorder dotStyle="purple">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Separado</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            ),
            "D": (
                <Ship cursorPointer noBorder dotStyle="brown">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Despachado</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            ),
            "T": (
                <Ship cursorPointer noBorder dotStyle="redlight">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Em transporte</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            ),
            "E": (
                <Ship cursorPointer noBorder dotStyle="blue">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <b>Entregue</b>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                </Ship>
            )
        };
        return statusMap[status];
    }

    const renderTableRows = () => {
        return currentOrders.map((order, index) => (
            <tr key={index} className='table-tr'>
                <td data-label="Produto">
                    <div style={{display: "flex", alignItems: 'center'}}>
                        <img src={Environment.API_IMAGE + "/imagem/" + order?.imagem_produto || "../placeholder-image.png"} className='shadow' style={{borderRadius: '8px', objectFit: 'cover', height: '50px', width: '50px', marginRight: '10px'}}/>
                        <b>{order.nome_produto}</b>
                    </div>
                </td>
                <td data-label="Total" style={{color: 'green', fontWeight: 'bold'}}>{Number(order.total)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                <td data-label="Tipo">{getType(order.tipo)}</td>
                <td data-label="Status" onClick={() => {handleChangePedingStatus(order)}}>{getStatus(order.status)}</td>
                <td data-label="Entregue?">{getEntregue(order.entregue)}</td>
                <td data-label="Data" style={{fontSize: '10pt'}}>{Utils.converterDate(order.ts_registro)}</td>
                <td data-label="Ação">
                    <Button onClick={() => {handlePedidoSelected(order)}} style={{display: 'flex', alignItems: 'center', background: '#ffffff', border: 'solid 1px #000', color: '#000', height: '40px', width: '100%'}}>
                        <ion-icon name="create-outline"></ion-icon>&nbsp;
                        <b>detalhes</b>
                    </Button>
                </td>
            </tr>
        ));
    };

    const paginationRef = useRef(null);

    useEffect(() => {
        if (paginationRef.current) {
            const activeButton = paginationRef.current.querySelector('.pagination-button.active');
            if (activeButton) {
                const containerWidth = paginationRef.current.offsetWidth;
                const buttonOffsetLeft = activeButton.offsetLeft;
                const buttonWidth = activeButton.offsetWidth;
                const scrollTo = buttonOffsetLeft - (containerWidth / 2) + (buttonWidth / 2);
                paginationRef.current.scrollTo({
                    left: scrollTo,
                    behavior: 'smooth'
                });
            }
        }
    }, [currentPage]);

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    
        return (
            <div className="pagination-container">
                <Button
                    style={{height: '40px', display: 'flex', alignItems: 'center'}}
                    className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
                    onClick={() => previous()}
                >
                    <b style={{display: 'flex'}}><ion-icon name="chevron-back-outline"></ion-icon></b>
                </Button>&nbsp;
                <div className="pagination-overflow">
                    {pageNumbers.map((number, index) => (
                        <>
                            <Button
                                key={number}
                                style={{ width: '40px', height: '40px', border: currentPage === number ? undefined : 'solid 1px gray', background: currentPage === number ? '#000' : '#fff', color: currentPage === number ? '#fff' : 'gray' }}
                                className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                                onClick={() => handlePageChange(number)}
                            >
                                {number}
                            </Button>
                            {index == (pageNumbers?.length - 1) ? (null) : (<>&nbsp;</>)}
                        </>
                    ))}
                </div>&nbsp;
                <Button
                    style={{height: '40px', display: 'flex', alignItems: 'center'}}
                    className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
                    onClick={() => next()}
                >
                    <b style={{display: 'flex'}}><ion-icon name="chevron-forward-outline"></ion-icon></b>
                </Button>
            </div>
        );
    };

    const handleChangePedingStatus = (pedido) => {
        setShowModalStatus(true);
        setModalStatusPedido(pedido?.status);
        setPedidoSelected(pedido);
    }

    const handlePedidoSelected = (pedido) => {
        setShowModalPedido(true);
        setPedidoSelected(pedido);
    }

    const handleUpdateStatus = async () => {
        
        if(modalStatusPedido?.trim()?.length <= 0){
            Utils.notify("error", "Selecione o novo status do pedido.")
            return;
        }

        let request = await Api.parceiro.updatePedidoStaus({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_pedido: pedidoSelected?.id_pedido,
            status: modalStatusPedido,
            notify: modalNotificaCliente
        })

        Utils.processRequest(request, (data) => {
            setShowModalStatus(false);
            setShowModalPedido(false);
            setup();
        }, (err) => {}, true)

    }

    const onCloseCallback = () => {
        setPedidoSelected(null);
    }

    const handleNavigatorToLocation = () => {
        setSelectNavigationParceiroModal(true);
    }

    const handleNavigateGoogleMaps = () => {
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${pedidoSelected?.latitude},${pedidoSelected?.logintude}`;
        window.open(googleMapsUrl, '_blank');
        setSelectNavigationParceiroModal(false);
    }

    const handleNavigateUber = () => {
        if(pedidoSelected == null) return;
        const uberUrl = `uber://?action=setPickup&pickup=my_location&dropoff[latitude]=${pedidoSelected?.latitude}&dropoff[longitude]=${pedidoSelected?.logintude}`;
        window.open(uberUrl, '_blank');
        setSelectNavigationParceiroModal(false);
    }

    return (
        <>
            <Modal show={showModalPedido} setShow={setShowModalPedido} onCloseCallback={onCloseCallback}>
                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>Detalhes do pedido</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>Detalhes do pedido do cliente.</div>
                    </div>
                </div>
                <div style={styles.overlay}>
                    {pedidoSelected ? (
                        <>
                            <div style={styles.modal}>
                                <Card className={"mb-1"}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="cube-outline"></ion-icon>&nbsp; 
                                        <b>Produto</b>
                                    </div>
                                    <div style={styles.productInfo}>
                                        <img src={Environment.API_IMAGE + "/imagem/" + pedidoSelected?.imagem_produto || "../placeholder-image.png"} alt={pedidoSelected.nome_produto} style={styles.image} />
                                        <div>
                                            <p><strong>Nome:</strong> {pedidoSelected.nome_produto}</p>
                                            <p><strong>Parceiro:</strong> {pedidoSelected.nome_parceiro}</p>
                                            <p><strong>Quantidade:</strong> {1}</p>
                                        </div>
                                    </div>
                                </Card>
                                <Card className={"mb-1"}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="person-outline"></ion-icon>&nbsp; 
                                        <b>Cliente</b>
                                    </div>
                                    <p><strong>Nome:</strong> {pedidoSelected.nome_cliente}</p>
                                </Card>
                                {pedidoSelected?.tipo == "E" ? (
                                    <Card className={"mb-1"}>
                                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                            <ion-icon name="location-outline"></ion-icon>&nbsp; 
                                            <b>Tipo</b>
                                        </div>
                                        <p><strong>Tipo:</strong> Entrega</p>
                                        <p><strong>Local:</strong> {pedidoSelected.cep} - {pedidoSelected?.logradouro}, {pedidoSelected?.numero}{pedidoSelected?.complemento}, {pedidoSelected?.nome_municipio}-{pedidoSelected?.uf}</p>
                                        <div onClick={handleNavigatorToLocation} className='mt-1' style={{color: '#000', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                            <ion-icon name="navigate-circle-outline"></ion-icon>&nbsp;
                                            <b style={{textDecoration: 'underline'}}>como chegar?</b>
                                        </div>
                                    </Card>
                                ) : (
                                    <Card className={"mb-1"}>
                                        <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                            <ion-icon name="storefront-outline"></ion-icon>&nbsp; 
                                            <b>Tipo</b>
                                        </div>
                                        <p><strong>Tipo:</strong> Retirada</p>
                                        <p><strong>Local: </strong> {pedidoSelected?.nome_parceiro}</p>
                                    </Card>
                                )}
                                <Card className={"mb-1"}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="information-circle-outline"></ion-icon>&nbsp; 
                                        <b>Informaçoes</b>
                                    </div>
                                    <p><strong>Nº do Pedido:</strong> {pedidoSelected.id_pedido}</p>
                                    <p><strong>Cupom:</strong> {pedidoSelected.nome_cupom || '(nenhum)'}</p>
                                    <p><strong>Entregue:</strong> {pedidoSelected.entregue == 'S' ? 'Sim' : 'Não'}</p>
                                    <p><strong>Data:</strong> {new Date(pedidoSelected.ts_registro).toLocaleString()}</p>
                                    <div className='mt-05' style={{display: 'flex', alignItems: 'center'}}>
                                        <strong>Status:</strong>&nbsp;
                                        <div style={{maxWidth: '200px'}} onClick={() => {handleChangePedingStatus(pedidoSelected)}}>
                                            {getStatus(pedidoSelected?.status)}
                                        </div>
                                    </div>
                                </Card>
                                <Card className={"mb-1"}>
                                    <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="receipt-outline"></ion-icon>&nbsp; 
                                        <b>Totais</b>
                                    </div>
                                    <p><strong>Subtotal:</strong> {Number(pedidoSelected.subtotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                    <p><strong>Desconto:</strong> {Number(pedidoSelected.desconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                    <p><strong>Frete:</strong> {Number(pedidoSelected.frete).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                    <p><strong>Total:</strong> {Number(pedidoSelected.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                </Card>
                            </div>
                            <Button onClick={() => {setShowModalPedido(false)}} style={{width: '100%'}}>
                                <b>Fechar</b>
                            </Button>
                        </>
                    ) : (null)}
                </div>
            </Modal>
            <Modal show={showModalStatus} setShow={setShowModalStatus} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '1.2rem'}}>Atualizar status</b>
                        <div style={{color: 'gray', fontSize: '0.85rem'}}>Atualize o status do pedido manualmente.</div>
                    </div>
                </div>
                <div className='mt-2 mb-1'>
                    <Select width={"100%"} label={"Status"} value={modalStatusPedido} setValue={setModalStatusPedido}>
                        <Option value={""}>Selecione...</Option>
                        {pedidoSelected?.tipo == "E" ? (
                            <>
                                <Option value={"P"}>Pago</Option>
                                <Option value={"A"}>Aguardando pagameto</Option>
                                <Option value={"C"}>Cancelado</Option>
                                <Option value={"S"}>Separado</Option>
                                <Option value={"D"}>Despachado</Option>
                                <Option value={"T"}>Em transporte</Option>
                                <Option value={"E"}>Entregue</Option>
                            </>
                        ) : (
                            <>
                                <Option value={"P"}>Pago</Option>
                                <Option value={"A"}>Aguardando pagameto</Option>
                                <Option value={"C"}>Cancelado</Option>
                                <Option value={"S"}>Separado</Option>
                                <Option value={"E"}>Entregue</Option>
                            </>
                        )}
                    </Select>
                    <Checkbox flexStart checked={modalNotificaCliente} setChecked={setModalNotificaCliente}>
                        Notificar status para o cliente&nbsp;
                        <ion-icon name="logo-whatsapp"></ion-icon>
                    </Checkbox>
                </div>
                <Button style={{width: '100%'}} onClick={handleUpdateStatus}><b>Atualizar</b></Button>
            </Modal>
            <Modal setShow={setSelectNavigationParceiroModal} show={selectNavigationParceiroModal}>
                <div className='mb-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                    <b style={{fontSize: '1.2rem'}}>Abrir com</b>
                </div>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div onClick={handleNavigateGoogleMaps} className='shadow' style={{background: '#fff', borderRadius: '8px', padding: '16px', cursor: 'pointer', textAlign: 'center'}}>
                        <img src='../google_maps.png' style={{width: '100px'}}/><br/>
                        <b>Maps</b>
                    </div>&nbsp;&nbsp;
                    <div onClick={handleNavigateUber} className='shadow' style={{background: '#fff', borderRadius: '8px', padding: '16px', cursor: 'pointer', textAlign: 'center'}}>
                        <img src='../uber.png' style={{width: '100px'}}/><br/>
                        <b>Uber</b>
                    </div>
                </div>
            </Modal>
            <FragmentView title={"Pedidos"}>
                <Card className={"mb-1"}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="filter-outline"></ion-icon>&nbsp;
                        <b>Filtros</b>
                    </div>
                    {Utils.mobileCheck() ? (
                        <div className='mt-1'>
                            <div style={{display: 'flex', alignItems: 'center'}} className='mb-05'>
                                <Input hideInputBoxMargin style={{width: '100%'}} label={"Pesquisar pedido (cliente, data, produto, etc...)"} type={"text"} value={filter} setValue={(e) => {
                                    handleFilterChange(e);
                                }} />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}} className='mb-05'>
                                <Select hideInputBoxMargin width={"100%"} label={"Status"} value={status} setValue={setStatus}>
                                    <Option value={""}>Selecione...</Option>
                                    <Option value={"P"}>Pago</Option>
                                    <Option value={"A"}>Aguardando pagameto</Option>
                                    <Option value={"C"}>Cancelado</Option>
                                    <Option value={"S"}>Separado</Option>
                                    <Option value={"D"}>Despachado</Option>
                                    <Option value={"T"}>Em transporte</Option>
                                    <Option value={"E"}>Entregue</Option>
                                </Select>&nbsp;
                                <Select hideInputBoxMargin width={"100%"} label={"Tipo"} value={tipo} setValue={setTipo}>
                                    <Option value={""}>Selecione...</Option>
                                    <Option value={"R"}>Retirada</Option>
                                    <Option value={"E"}>Entrega</Option>
                                </Select>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input hideInputBoxMargin style={{width: '100%'}} label={"Data do pedido"} type={"data"} value={data} setValue={setData} />&nbsp;
                                <Button onClick={handleClearFilter} style={{flex: '0.3', display: 'flex', alignItems: 'center', height: '50px', width: '100%', justifyContent: 'center', background: '#fff', border: 'solid 1px red', color: 'red'}}>
                                    <ion-icon name="close-circle-outline"></ion-icon>
                                    <b>Limpar</b>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='mt-1'>
                            <div>
                                <Input style={{width: '100%'}} hideInputBoxMargin label={"Pesquisar pedido (cliente, data, produto, etc...)"} type={"text"} value={filter} setValue={(e) => {
                                    handleFilterChange(e);
                                }} />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex', width: '100%'}}>
                                    <Select width={"100%"} label={"Status"} value={status} setValue={setStatus}>
                                        <Option value={""}>Selecione...</Option>
                                        <Option value={"P"}>Pago</Option>
                                        <Option value={"A"}>Aguardando pagameto</Option>
                                        <Option value={"C"}>Cancelado</Option>
                                        <Option value={"S"}>Separado</Option>
                                        <Option value={"D"}>Despachado</Option>
                                        <Option value={"T"}>Em transporte</Option>
                                        <Option value={"E"}>Entregue</Option>
                                    </Select>
                                </div>&nbsp;
                                <div style={{display: 'flex', width: '100%'}}>
                                    <Select width={"100%"} label={"Tipo"} value={tipo} setValue={setTipo}>
                                        <Option value={""}>Selecione...</Option>
                                        <Option value={"R"}>Retirada</Option>
                                        <Option value={"E"}>Entrega</Option>
                                    </Select>
                                </div>&nbsp;
                                <Input style={{width: '100%'}} label={"Data do pedido"} type={"data"} value={data} setValue={setData} />&nbsp;
                                <Button onClick={handleClearFilter} style={{display: 'flex', flex: '0.3', alignItems: 'center', height: '50px', width: '100%', justifyContent: 'center', background: '#fff', border: 'solid 1px red', color: 'red'}}>
                                    <ion-icon name="close-circle-outline"></ion-icon>&nbsp;
                                    <b>Limpar</b>
                                </Button>
                            </div>
                        </div>
                    )}
                </Card>
                <Card>
                    {loading ? (
                        filteredOrders?.length > 0 ? (
                            <>
                                <div className="table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Total</th>
                                                <th>Tipo</th>
                                                <th>Status</th>
                                                <th>Entregue</th>
                                                <th>Data</th>
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderTableRows()}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    {renderPagination()}
                                </div>
                            </>
                        ) : (
                            <div className='mt-1 mb-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <b style={{color: 'gray'}}>Nenhum pedido encontrado.</b>
                            </div>
                        )
                    ) : (
                        <div className='mt-1 mb-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <b style={{color: 'gray'}}>Carregando...</b>
                        </div>
                    )}
                </Card>
                <SpaceBox space={80}/>
            </FragmentView>
        </>
    );
}
