import React, { useContext, useEffect, useState } from 'react';
import {AccountModal, Container, DoLoginBanner, HeaderWithBack, ParceirosListFavorited} from '../../components';
import './style.css';
import { MainContext } from '../../helpers/MainContext';
import Api from '../../Api';

export default () => {

    const {user} = useContext(MainContext);
    const [accountModalShow, setAccountModalShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        if(user){
            setAccountModalShow(false);
        }else{
            setAccountModalShow(true);
        }
    }, [])

    useEffect(()=>{
        setup();
    }, [])

    const setup = async () => {
        setLoading(false);
        let favoritesResponse = await Api.cliente.favoritosByCliente({
            forceToken: localStorage.getItem("tk_beauty_cliente_token")
        });
        let _favorites = favoritesResponse?.data?.data;
        setFavorites(_favorites);
        setLoading(true);
    }

    const onDesfavoriteCallback = async () => {
        setup();
    }

    return (
        <Container>
            <AccountModal show={accountModalShow} setShow={setAccountModalShow} onTokenCallback={setup}/>
            <HeaderWithBack title={"Favoritos"}/>
            {!user ? (
                <DoLoginBanner onClickButton={() => {setAccountModalShow(true)}}/>
            ) : (
                <>
                    {loading ? (
                        <>
                            {favorites?.length > 0 ? (
                                <ParceirosListFavorited parceiros={favorites} onDesfavoriteCallback={onDesfavoriteCallback} hideFilter hideText/>
                            ) : (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                                    <b style={{color: 'gray'}}>Nenhum parceiro favoritado.</b>
                                </div>
                            )}
                        </>
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                            <b style={{color: 'gray'}}>Carregando...</b>
                        </div>
                    )} 
                </>
            )}
        </Container>
    )
}
