import React, { useEffect, useState } from 'react';
import './style.css';
import Utils from '../../Utils';
import Environment from '../../Environment';
import { useNavigate } from 'react-router-dom';

export default ({post, title}) => {

    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    useEffect(() => {
        startGallery();
    }, []);

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash !== '#modal') {
                setShow(false);
                window.removeEventListener('hashchange', handleHashChange);
                const modal = document.querySelector('.parceiro-gallery-modal');
                modal.classList.remove('active');
                window.location.hash = '';
            }
        };

        if (show) {
            window.location.hash = 'modal';
            window.addEventListener('hashchange', handleHashChange);
            document.body.style.overflow = 'hidden';  // Trava o scroll
        }else{
            document.body.style.overflow = 'auto';    // Ativa o scroll novamente
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
            document.body.style.overflow = 'auto';    // Ativa o scroll quando o componente desmonta
        };
    }, [show, setShow]);

    const startGallery = () => {

        const images = Array.from(document.querySelectorAll('.parceiro-gallery-grid img'));
        const modal = document.querySelector('.parceiro-gallery-modal');
        const closeBtn = document.querySelector('.parceiro-gallery-btn');
    
        images.forEach(image => {
            image.addEventListener('click', () => {
                setShow(true);
                modal.classList.add('active');
                const img = document.createElement('img');
                img.src = image.src.replace("/imagem/", "/imagem/hd/");
                img.style.width = '100%';
                if(modal.children[1]){
                    modal.removeChild(modal.children[1]);
                }
                modal.appendChild(img);
            })
        })
    
        window.addEventListener('click', (e) => {
            if(e.target === modal){
                setShow(false);
                modal.classList.remove('active');
                window.location.hash = '';
                navigate(-1);
            }
        });
    
        closeBtn.addEventListener('click', () => {
            setShow(false);
            modal.classList.remove('active');
            window.location.hash = '';
            navigate(-1);
        })
    
    }

    return (
        <>
            {post?.length > 0 ? (
                <div className='mb-2 mt-2' style={{display: 'flex', alignItems: 'center'}}>
                    <ion-icon name="images-outline"></ion-icon>&nbsp;
                    <b>{title}</b>
                </div>
            ) : (null)}
            <div className='parceiro-gallery'>
                <div class="parceiro-gallery-modal">
                    <button class="parceiro-gallery-btn">
                        <ion-icon size={"large"} name="close"></ion-icon>
                    </button>
                </div>
                <div className="parceiro-gallery-main">
                    <div className="parceiro-gallery-grid">
                        {post?.map((p, index) => (
                            <img style={{objectFit: 'cover'}} src={Environment.API_IMAGE +"/imagem/"+ p?.imagem || "placeholder-image.png "}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
