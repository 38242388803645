import React, { useEffect, useRef } from 'react';
import './style.css';
import AnnounceItem from '../AnnounceItem';

const startSwipperLogic = (sliderRef) => {
    let startX;
    let scrollLeft;
    let animationFrame;

    const start = (e, s) => {
        startX = e.pageX || e.touches[0].pageX - s.offsetLeft;
        scrollLeft = s.scrollLeft;
    }

    const animateScroll = (s, targetScrollLeft, duration) => {
        const startTime = performance.now();
        const endTime = startTime + duration;
        const startLeft = s.scrollLeft;

        const scroll = () => {
            const now = performance.now();
            const timeFraction = Math.min(1, (now - startTime) / duration);
            const easedTimeFraction = ease(timeFraction);
            s.scrollLeft = startLeft + (targetScrollLeft - startLeft) * easedTimeFraction;

            if (timeFraction < 1) {
                animationFrame = requestAnimationFrame(scroll);
            }
        };

        scroll();
    };

    const end = (s) => {
        const x = s.scrollLeft - scrollLeft;
        const time = Math.abs(x) * 2;

        animateScroll(s, Math.round(s.scrollLeft / 100) * 100, time);
        cancelAnimationFrame(animationFrame);
    };

    const move = (e, s) => {
        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - s.offsetLeft;
        const dist = (x - startX);
        s.scrollLeft = scrollLeft - dist;
    };

    const ease = (t) => t * t * t * (t * (t * 6 - 15) + 10); // cubic bezier ease function

    sliderRef.current.addEventListener('mousedown', (e) => { start(e, sliderRef.current) });
    sliderRef.current.addEventListener('touchstart', (e) => { start(e, sliderRef.current) });
    sliderRef.current.addEventListener('mousemove', (e) => { move(e, sliderRef.current) });
    sliderRef.current.addEventListener('touchmove', (e) => { move(e, sliderRef.current) });
    sliderRef.current.addEventListener('mouseleave', () => { end(sliderRef.current) });
    sliderRef.current.addEventListener('mouseup', () => { end(sliderRef.current) });
    sliderRef.current.addEventListener('touchend', () => { end(sliderRef.current) });

    // Automatic smooth scrolling
    const autoScroll = () => {
        if(sliderRef?.current?.offsetWidth == null) return;

        const itemWidth = sliderRef?.current?.offsetWidth / 1.33; // Assuming 3 items visible at a time
        const currentScroll = sliderRef.current.scrollLeft;
        const maxScroll = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;

        let targetScroll;
        if (currentScroll >= maxScroll) {
            targetScroll = 0;
        } else {
            targetScroll = Math.round(currentScroll / itemWidth) * itemWidth + itemWidth;
        }

        animateScroll(sliderRef.current, targetScroll, 1000); // Adjust duration as needed
    };

    const interval = setInterval(autoScroll, 5000); // Adjust interval as needed

    // Cleanup on unmount
    return () => {
        clearInterval(interval);
        cancelAnimationFrame(animationFrame);
    };
};

const AnnounceList = ({ announces }) => {
    const sliderRef = useRef(null);

    useEffect(() => {
        startSwipperLogic(sliderRef);
    }, []);

    return (
        <div className="wrapper mb-2">
            <ul className="items" ref={sliderRef}>
                {announces?.map((item, index) => (
                    <AnnounceItem index={index} item={item} />
                ))}
            </ul>
        </div>
    );
};

export default AnnounceList;
