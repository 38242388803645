import Utils from "../Utils";

const AgendaProviderHelper = {

  getDaysPeriods: () => {

    const diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const hoje = new Date();
    const proximos30DiasComSubperiodos = [];
  
    for (let i = 0; i < 30; i++) {
      const data = new Date();
      data.setDate(hoje.getDate() + i);
      const ano = data.getFullYear();
      const mes = (data.getMonth()+1);
      const diaSemana = diasDaSemana[data.getDay()];
      const dia = data.getDate();
      proximos30DiasComSubperiodos.push({ id: Utils.makeid(9), ano, mes, diaSemana, dia });
    }

    return proximos30DiasComSubperiodos;
  },
  getHoursPeriods: (horaInicio, horaFim, dataRef) => {
    const agora = new Date();

    const ano = agora.getFullYear();
    const mes = String(agora.getMonth() + 1).padStart(2, '0'); // Os meses são baseados em zero, então adicione 1
    const dia = String(agora.getDate()).padStart(2, '0');

    const hoje = `${ano}-${mes}-${dia}`;

    if (dataRef === hoje) {
      // Se a data de referência for igual à data de hoje, remova os horários passados
      const horaAtual = agora.getHours();
      const minutoAtual = agora.getMinutes();
      
      // Transforma as horas em minutos para facilitar a comparação
      const minutosAtuais = horaAtual * 60 + minutoAtual;
      const horarios = [];

      // Loop para gerar os horários de 30 em 30 minutos
      for (let hora = horaInicio; hora <= horaFim; hora++) {
        for (let minuto = 0; minuto < 60; minuto += 30) {
          const minutosHorario = hora * 60 + minuto;

          if (minutosHorario >= minutosAtuais && minutosHorario < horaFim * 60) {
            // Adiciona o horário ao array apenas se for após o horário atual e antes da horaFim
            horarios.push({
              id: Math.random().toString(36).substring(7),
              hora: `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`,
              livre: true,
              dataRef: dataRef
            });
          }
        }
      }
      
      // Adiciona o horário exato de horaFim ao array
      horarios.push({
        id: Math.random().toString(36).substring(7),
        hora: `${horaFim.toString().padStart(2, '0')}:00`,
        livre: true
      });
      
      return horarios;
    } else {
      // Se a data de referência não for igual à data de hoje, retorna todos os horários
      const horarios = [];
      
      // Loop para gerar os horários de 30 em 30 minutos
      for (let hora = horaInicio; hora <= horaFim; hora++) {
        for (let minuto = 0; minuto < 60; minuto += 30) {
          horarios.push({
            id: Math.random().toString(36).substring(7),
            hora: `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`,
            livre: true,
            dataRef: dataRef
          });
        }
      }

      horarios.pop();
      
      return horarios;
    }
  },
  getAgendaPeriods: (abertura, fechamento) => {

    const diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  
    const hoje = new Date();
    const proximos30DiasComSubperiodos = [];
  
    for (let i = 0; i < 30; i++) {
      const data = new Date();
      data.setDate(hoje.getDate() + i);
      
      const ano = data.getFullYear();
      const mes = (data.getMonth()+1);
      const diaSemana = diasDaSemana[data.getDay()];
      const dia = data.getDate();
  
      const horasDisponiveis = {
        Madrugada: [],
        Manha: [],
        Tarde: [],
        Noite: []
      };
  
      for (let hora = abertura; hora <= fechamento; hora += 0.5) {
        const minutos = (hora % 1) * 60;
        const horaFormatada = Math.floor(hora);
        const minutosFormatados = minutos === 0 ? '00' : '30';
  
        const horaObj = {
          id: Utils.makeid(9),
          hora: `${horaFormatada}:${minutosFormatados}`,
          livre: true
        };

        if(dia != new Date().getDate()){
          if ((hora >= 0 && hora < 6)) {
            horasDisponiveis.Madrugada.push(horaObj);
          } else if (hora >= 6 && hora < 12) {
            horasDisponiveis.Manha.push(horaObj);
          } else if (hora >= 12 && hora < 18) {
            horasDisponiveis.Tarde.push(horaObj);
          } else {
            horasDisponiveis.Noite.push(horaObj);
          }
        }else{
          if ((hora >= 0 && hora < 6)) {
            if(hora > new Date().getHours() + 0.5){
              horasDisponiveis.Madrugada.push(horaObj);
            }
          } else if (hora >= 6 && hora < 12) {
            if(hora > new Date().getHours() + 0.5){
              horasDisponiveis.Manha.push(horaObj);
            }
          } else if (hora >= 12 && hora < 18) {
            if(hora > new Date().getHours() + 0.5){
              horasDisponiveis.Tarde.push(horaObj);
            }
          } else {
            if(hora > new Date().getHours() + 0.5){
              horasDisponiveis.Noite.push(horaObj);
            }
          }
        }

      }
      proximos30DiasComSubperiodos.push({ id: Utils.makeid(9), ano, mes, diaSemana, dia, horasDisponiveis });
    }
    return proximos30DiasComSubperiodos;
  }

}

export default AgendaProviderHelper;