import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import Input from '../Input';
import Button from '../Button';
import Hr from '../Hr';
import { GoogleOAuthProvider, GoogleLogin  } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../Checkbox';
import Utils from '../../Utils';
import Api from '../../Api';
import { MainContext } from '../../helpers/MainContext';

const Modal = ({ show, setShow, onTokenCallback }) => {
    
    const { setAuthenticated, setUser } = useContext(MainContext);

    const navigate = useNavigate();

    const [mode, setMode] = useState('login');
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordC, setPasswordC] = useState("");
    const [termsChecked, setTermsChecked] = useState(false);

    const handleBackdropClick = () => {
        setShow(false);
        setMode('login');
        navigate(-1); //se der merda tire essa linha
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash !== '#modal') {
                setShow(false);
                window.removeEventListener('hashchange', handleHashChange);
            }
        };

        if (show) {
            window.location.hash = 'modal';
            window.addEventListener('hashchange', handleHashChange);
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [show, setShow]);

    const handleForgot = async () => {
        navigate("/forgot");
    }

    const handleChangeMode = async () => {
        setPhone("");
        setPassword("");
        setPasswordC("");
        setTermsChecked(false);
        switch(mode){
            case "login":
                setMode("register");
                break;
            case "register":
                setMode("login");
                break;
        }
    }

    const handleAccount = async () => {

        if(phone?.trim()?.length <= 0){
            Utils.notify("error", "Digite seu celular.");
            return;
        }

        if(!Utils.validatePhoneNumber(phone)){
            Utils.notify("error", "Celular inválido.");
            return;
        }

        if(password?.trim()?.length <= 0){
            Utils.notify("error", "Digite sua senha.");
            return;
        }

        switch(mode){
            case "login":
                
                let loginResponse = await Api.cliente.login({
                    device_token: Utils.getDeviceToken(),
                    phone: phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("-", ""),
                    password: password
                })

                Utils.processRequest(loginResponse, async (data) => {
                    let token = await data?.token;
                    await localStorage.setItem("tk_beauty_cliente_token", token);
                    let authResponse = await Api.cliente.get(token);
                    if(onTokenCallback){
                        await onTokenCallback(token);
                    }
                    Utils.processRequest(authResponse, async (data) => {
                        Utils.notify("success", "Logado com sucesso!");
                        setAuthenticated(true);
                        setUser(data);
                        setShow(false);
                    })
                }, null, false)

                break;
            case "register":
                
                if(passwordC != password){
                    Utils.notify("error", "As senhas não se coincidem.");
                    return;
                }

                if(!Utils.checkPasswordStrength(password)){
                    Utils.notify("error", "A senha deve conter letras, números, símbolos e ter mais de 5 caracteres.");
                    return;
                }

                if(!termsChecked){
                    Utils.notify("error", "Você precisa ler e concordar com os termos.");
                    return;
                }

                let registerResponse = await Api.cliente.register({
                    nome: "", 
                    email: "", 
                    cpf: "",
                    device_token: Utils.getDeviceToken(),
                    phone: phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("-", ""),
                    password: password,
                    precadastro: 'N',
                })

                Utils.processRequest(registerResponse, async (data) => {
                    let token = await data?.token;
                    await localStorage.setItem("tk_beauty_cliente_token", token);
                    let authResponse = await Api.cliente.get(token);
                    if(onTokenCallback){
                        await onTokenCallback(token);
                    }
                    Utils.processRequest(authResponse, async (data) => {
                        Utils.notify("success", "Registrado com sucesso!");
                        setAuthenticated(true);
                        setUser(data);
                        setShow(false);
                    })
                }, null, false)

                break;
        }
    }

    return (
        show ? (
            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999999, background: 'rgba(0, 0, 0, 0.6)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleBackdropClick}>
                <div style={{ background: '#fff', padding: '20px', borderRadius: '5px', width: '90%' }} onClick={handleContentClick}>
                    <div style={{display: 'flex', alignItems:'center', alignItems: 'center', justifyContent: 'center', marginTop: '-58px'}}>
                        <img src='../logo.png' className='shadow' style={{width: '80px', borderRadius: '8px', border: '3px solid #fff'}}/>
                    </div>
                    <div className='mt-2 mb-2'>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                            <b style={{fontSize: '1.1rem'}}>Faça login ou registre-se no app.</b>
                        </div>
                    </div>
                    <Input type={"celular"} value={phone} setValue={setPhone} label={"DDD + Celular"} />
                    <Input type={"password"} value={password} setValue={setPassword} label={"Sua senha"} />
                    {mode == 'login' ? (null) : (
                        <>
                            <Input type={"password"} value={passwordC} setValue={setPasswordC} label={"Confirmar senha"} />
                            <Checkbox size={25} checked={termsChecked} setChecked={setTermsChecked}>
                                <label style={{flex: '0.9'}}>Eu li e concordo com os <b style={{textDecoration: 'underline'}} onClick={() => {navigate('/terms-of-use')}}>termos de uso</b> e <b style={{textDecoration: 'underline'}} onClick={() => {navigate('/privacity-policity')}}>política de privacidade</b>.</label>
                            </Checkbox>
                        </>
                    )}
                    {mode == 'login' ? (
                        <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'right'}} onClick={handleForgot}>
                            Esqueceu sua <b>senha?</b>
                        </div>
                    ) : (null)}
                    <Button style={{width: '100%'}} onClick={handleAccount}>
                        <b>{mode == 'login' ? 'Entrar' : 'Registrar'}</b>
                    </Button>
                    <div className='mt-2 mb-2' style={{width: '100%', textAlign: 'center'}} onClick={handleChangeMode}>
                        {mode == 'login' ? (
                            <>
                                Não tem conta? <b>registre-se</b>
                            </>
                        ):(
                            <>
                                Já tem conta? <b>acesse</b>
                            </>
                        )}
                    </div>
                    {/*<Hr elevation={1.5}/>
                    <GoogleOAuthProvider clientId="794159652945-6g05svmk5vlnf3843quq7589m5m2clpa.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log(credentialResponse);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                        </GoogleOAuthProvider>*/}
                </div>
            </div>
        ) : (null)
    )
}

export default Modal;