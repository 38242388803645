import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { Button, Container, ParceiroAgenda, ProductItem, ParceiroGallery, ParceiroProduto, AccountModal, SpaceBox, Card, Modal, MapComponent, Hr, Checkbox, Input, SwipperRefresh } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../Api';
import Utils from '../../Utils';
import CoordsProviderHelper from '../../helpers/CoordsProviderHelper';
import { MainContext } from '../../helpers/MainContext';
import { Helmet } from 'react-helmet';
import Environment from '../../Environment';
import DownloadApp from '../../components/DownloadApp';

export default () => {

    const {user, setUser, setSwitched, onRefreshHandler} = useContext(MainContext);

    const {id_parceiro} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [productIdSaved, setProductIdSaved] = useState(localStorage.getItem("tk_beauty_product_clicked") || null);
    
    const [favorited, setFavorited] = useState(false);
    const [parceiro, setParceiro] = useState(null);
    const [distance, setDistance] = useState(null);

    const [assinaturas, setAssinaturas] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);

    const [expediente, setExpediente] = useState(null);

    const [post, setPosts] = useState([]);

    const [produtoSelected, setProdutoSelected] = useState(null);   
    const [servicoSelected, setServicoSelected] = useState(null);

    const [accountModalShow, setAccountModalShow] = useState(false);
    const [infoModalShow, setInfoModalShow] = useState(false);
    const [showMoreAssiantura, setShowMoreAssinatura] = useState(false);

    const [assinaturaCycleIndex, setAssinaturaCycleIndex] = useState("M");

    const [assianturaChecker, setAssinaturaChecker] = useState(false);

    const [modalPaymentStep, setModalPaymentStep] = useState(0);
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");

    const [avaliacoesParceiro, setAvaliacoesParceiro] = useState([]);
    const [nota, setNota] = useState(0);  

    const [bonus, setBonus] = useState(0);

    const [selectNavigationParceiroModal, setSelectNavigationParceiroModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let referOrigin = window?.document?.referrer;
        if(referOrigin == 'https://parceiro.igend.com.br/'){ //veio de um link SEO parceiro
            localStorage.setItem("tk_beauty_switched", "cliente");
            setSwitched("cliente");
        }
    }, [])

    useEffect(() => {
        const setup = async () => {
            setLoading(false);
            
            let parceiroResponse = await Api.geral.parceiro({
                id_parceiro: id_parceiro
            })
            let assinaturasResponse = await Api.geral.assinaturasByParceiro({
                id_parceiro: id_parceiro
            })
            let servicosResponse = await Api.geral.servicosByParceiro({
                id_parceiro: id_parceiro
            })
            let produtosResponse = await Api.geral.produtosByParceiro({
                id_parceiro: id_parceiro
            })

            let favoritoResponse = await Api.cliente.favoritoByParceiro({
                forceToken: localStorage.getItem("tk_beauty_cliente_token"),
                id_parceiro: id_parceiro
            })

            let expedienteResponse = await Api.geral.parceiroExpedientes({
                id_parceiro: id_parceiro
            })

            let colaboradoresResponse = await Api.geral.colaboradoresByParceiro({
                id_parceiro: id_parceiro
            })

            let postsResponse = await Api.geral.postsByParceiro({
                id_parceiro: id_parceiro
            })

            let _parceiro = parceiroResponse?.data?.data;
            let _assinaturas = assinaturasResponse?.data?.data;
            let _servicos = servicosResponse?.data?.data;
            let _produtos = produtosResponse?.data?.data;
            let _favorito = favoritoResponse?.data?.data;
            let _expediente = expedienteResponse?.data?.data;
            let _colaboradores = colaboradoresResponse?.data?.data;
            let _posts = postsResponse?.data?.data;

            if(user){
                if(_favorito?.length <= 0){
                    setFavorited(false);
                }else{
                    setFavorited(true);
                }
            }

            if(_parceiro?.id_parceiro != null){
                CoordsProviderHelper(async (coords) => {
                    let distance = Utils.calculateDistance(
                        Number(_parceiro?.latitude),
                        Number(_parceiro?.logintude),
                        coords?.latitude,
                        coords?.longitude,
                    )
                    setDistance(distance);
                })
            }

            getAvaliacoesParceiro(id_parceiro);
            
            setAssinaturas(_assinaturas);
            setServicos(_servicos);
            setProdutos(_produtos);
            setParceiro(_parceiro);
            setExpediente(_expediente);
            setColaboradores(_colaboradores);
            setPosts(_posts);

            setLoading(true);
        }
        setup();
    }, [])

    useEffect(() => {
        loadUserAnCheckSubscription();
    }, [user]);

    useEffect(() => {
        loadUserBonus();
    }, [user])

    const loadUserBonus = async () => {
        if(user != null){
            let response = await Api.cliente.obterSaldoBonus({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),  
            })
            let _bonus = response?.data?.data?.valor;
            setBonus(Number(_bonus));
        }
    }

    const loadUserAnCheckSubscription = async () => {
        if(user != null){
            let assianturaCheckerResponse = await Api.cliente.assinaturaByClienteCheck({
                forceToken: localStorage.getItem("tk_beauty_cliente_token"),
                id_parceiro: id_parceiro
            })
            let _assinaturaChecker = assianturaCheckerResponse?.data?.data;
            setAssinaturaChecker(_assinaturaChecker);
        }else{
            setAssinaturaChecker(false);
        }
    }

    const getAvaliacoesParceiro = async (id_parceiro) => {
        const response = await Api.geral.avaliacaoParceiro({
            forceToken: localStorage.getItem("tk_beauty_parceiro_token"),
            id_parceiro: id_parceiro
        })
        let _avaliacoesParceiro = response?.data?.data;
        setAvaliacoesParceiro(_avaliacoesParceiro);
        const _nota = Utils.calcularAvaliacao(_avaliacoesParceiro);
        setNota(_nota);
    }

    useEffect(() => {
        if(productIdSaved != null && loading){
            if(produtos?.length > 0){
                let _produto = produtos?.filter(p => {return p?.id_produto == productIdSaved})[0]
                setProdutoSelected(_produto);
            }
            localStorage.removeItem("tk_beauty_product_clicked");
        }
    }, [loading])

    const handleServiceSelected = (s) => {
        setServicoSelected(s);
    }

    const handleProdutoSelected = (p) => {
        setProdutoSelected(p);
    }

    const handleResetComponent = () => {
        setServicoSelected(null);
        setProdutoSelected(null);
    }

    const handleFavorite = async () => {
        if(loading){
            if(user){    
                if(!favorited){
                    setFavorited(true);
                    await Api.cliente.favoritar({
                        forceToken: localStorage.getItem("tk_beauty_cliente_token"),
                        id_parceiro: id_parceiro
                    })
                }else{
                    setFavorited(false);
                    await Api.cliente.desfavoritar({
                        forceToken: localStorage.getItem("tk_beauty_cliente_token"),
                        id_parceiro: id_parceiro
                    })
                }
            }else{
                setAccountModalShow(true);
            }
        }
    }

    const handleClick = async () => {
        setInfoModalShow(true);
    }

    const getExpedienteLabel = (semana) => {
        switch(semana){
            case "segunda":
                return expediente?.segunda == 1 ? `${expediente?.segunda_hora_inicio}h - ${expediente?.segunda_hora_fim}h` : `fechado`;
            case "terca":
                return expediente?.terca == 1 ? `${expediente?.terca_hora_inicio}h - ${expediente?.terca_hora_fim}h` : `fechado`;
            case "quarta":
                return expediente?.quarta == 1 ? `${expediente?.quarta_hora_inicio}h - ${expediente?.quarta_hora_fim}h` : `fechado`;
            case "quinta":
                return expediente?.quinta == 1 ? `${expediente?.quinta_hora_inicio}h - ${expediente?.quinta_hora_fim}h` : `fechado`;
            case "sexta":
                return expediente?.sexta == 1 ? `${expediente?.sexta_hora_inicio}h - ${expediente?.sexta_hora_fim}h` : `fechado`;
            case "sabado":
                return expediente?.sabado == 1 ? `${expediente?.sabado_hora_inicio}h - ${expediente?.sabado_hora_fim}h` : `fechado`;
            case "domingo":
                return expediente?.domingo == 1 ? `${expediente?.domingo_hora_inicio}h - ${expediente?.domingo_hora_fim}h` : `fechado`;
        }
    }

    const handleShowMoreAssinatura = () => {
        setShowMoreAssinatura(true);
    }

    const handleCycleAssinaturaChange = (cycle) => {
        setAssinaturaCycleIndex(cycle);
    }

    const getCycleString = () => {
        switch(assinaturaCycleIndex){
            case "M":
                return "mês";
            case "S":
                return "sem";
            case "A":
                return "ano";
        }
    }

    const checkIfUserPropsIsNull = () => {
        if(!user?.nome || !user?.cpf || !user?.email){
            return true;
        } else {
            return false;
        }
    }

    const handleAssinar = async () => {
        if(user == null){
            setAccountModalShow(true);
        }else{
            if(checkIfUserPropsIsNull()){
                setModalPaymentStep(1);
            }else{
                setModalPaymentStep(2);
            }
        }
    }

    const onCloseCallback = () => {
        setModalPaymentStep(0);
        loadUserAnCheckSubscription();
    }
    
    const handleCredentialsUpdate = async () => {

        if(!nome){
            Utils.notify("error", "Digite seu nome.");
            return false;
        }

        if(!Utils.validateEmail(email)){
            Utils.notify("error", "Digite um email válido.");
            return false;
        }

        if(!Utils.validateCpf(cpf)){
            Utils.notify("error", "Digite um CPF válido.");
            return false;
        }

        let clienteUpdateResponse = await Api.cliente.update({
            forceToken: localStorage.getItem("tk_beauty_cliente_token"),
            data: {
                nome: nome,
                email: email,
                cpf: cpf?.toString()?.replaceAll(".", "")?.replaceAll("-", ""),
            }
        })

        let data = clienteUpdateResponse?.data;

        if(data?.success){
            setUser({ ...user, nome: nome, email: email, cpf: cpf });
            return true;
        }else{
            Utils.notify("error", data?.message);
            return false;
        }
    }

    const handleNextModalPaymentStep = async () => {
        if(checkIfUserPropsIsNull()){
            let credentialsUpdated = await handleCredentialsUpdate();
            if(credentialsUpdated){
                setModalPaymentStep(2);
            }
        }else{
            setModalPaymentStep(2);
        }
    }

    const handleShare = () => {
        if (navigator.share) {
            // Objeto de dados para compartilhar
            const dadosCompartilhar = {
              title: parceiro?.nome_parceiro,
              text: parceiro?.descricao_parceiro,
              url: `https://parceiro.igend.com.br/?parceiro=${parceiro?.bind_parceiro}`
            };
          
            // Chama o método 'share' do objeto 'navigator'
            navigator.share(dadosCompartilhar)
              .then(() => console.log('Link compartilhado com sucesso!'))
              .catch((error) => console.error('Erro ao compartilhar link:', error));
        } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({
              action: 'share',
              title: `https://parceiro.igend.com.br/?parceiro=${parceiro?.bind_parceiro}`,
              text: `https://parceiro.igend.com.br/?parceiro=${parceiro?.bind_parceiro}`,
              url: `https://parceiro.igend.com.br/?parceiro=${parceiro?.bind_parceiro}`
            }));
          }
    }

    const handleNavigatorToLocation = () => {
        setSelectNavigationParceiroModal(true);
    }

    const handleNavigateGoogleMaps = () => {
        const latitude = Number(parceiro?.latitude);
        const longitude = Number(parceiro?.logintude);
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        window.open(googleMapsUrl, '_blank');
        setSelectNavigationParceiroModal(false);
    }

    const handleNavigateUber = () => {
        const latitude = Number(parceiro?.latitude);
        const longitude = Number(parceiro?.logintude);
        const uberUrl = `uber://?action=setPickup&pickup=my_location&dropoff[latitude]=${latitude}&dropoff[longitude]=${longitude}`;
        window.open(uberUrl, '_blank');
        setSelectNavigationParceiroModal(false);
    }

    return (
        <>
            <Helmet>
                <meta name="description" content={parceiro?.descricao_parceiro} />
                <meta property="og:title" content={parceiro?.nome_parceiro} />
                <meta property="og:description" content={parceiro?.descricao_parceiro} />
                <meta property="og:image" content={parceiro?.logo_parceiro} />
            </Helmet>
            <Modal setShow={setSelectNavigationParceiroModal} show={selectNavigationParceiroModal}>
                <div className='mb-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                    <b style={{fontSize: '1.2rem'}}>Abrir com</b>
                </div>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div onClick={handleNavigateGoogleMaps} className='shadow' style={{background: '#fff', borderRadius: '8px', padding: '16px', cursor: 'pointer', textAlign: 'center'}}>
                        <img src='../google_maps.png' style={{width: '100px'}}/>
                        <b>Maps</b>
                    </div>&nbsp;&nbsp;
                    <div onClick={handleNavigateUber} className='shadow' style={{background: '#fff', borderRadius: '8px', padding: '16px', cursor: 'pointer', textAlign: 'center'}}>
                        <img src='../uber.png' style={{width: '100px'}}/>
                        <b>Uber</b>
                    </div>
                </div>
            </Modal>
            <Modal setShow={setInfoModalShow} show={infoModalShow}>
                <div style={{overflow: 'auto', height: 'calc(95vh - 100px)'}}>
                    <div className='mb-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <b style={{fontSize: '16pt'}}>Informações</b>
                    </div>
                    <div className='mb-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <ion-icon style={{fontSize: '20pt'}} name="location"></ion-icon>&nbsp;&nbsp;
                        <span className='location-text'>{parceiro?.endereco_parceiro}</span>
                    </div>
                    <MapComponent bind={parceiro?.nome_parceiro} latitude={parceiro?.latitude} longitude={parceiro?.logintude}/>
                    <div className='mb-2 mt-2'>
                        <b>Horarios de Atendimento</b>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Segunda-feira</span>
                            <span>{getExpedienteLabel("segunda")}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Terça-feira</span>
                            <span>{getExpedienteLabel("terca")}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Quarta-feira</span>
                            <span>{getExpedienteLabel("quarta")}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Quinta-feira</span>
                            <span>{getExpedienteLabel("quinta")}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Sexta-feira</span>
                            <span>{getExpedienteLabel("sexta")}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Sábado</span>
                            <span>{getExpedienteLabel("sabado")}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <span>Domingo</span>
                            <span>{getExpedienteLabel("domingo")}</span>
                        </div>
                    </div>
                    {parceiro?.categoria_parceiro == 16 ? ( //se categoria de stúdio musical
                        <>
                            <div className='mt-2 mb-2'>
                                <b>Polos</b>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', overflow: 'auto'}}>
                                {colaboradores?.map((c, index)=>(
                                    <div style={{marginRight: '5px', marginLeft: '5px'}}>
                                        <center>
                                            <img style={{width: '70px', height: '70px', objectFit: 'cover', borderRadius: '50%'}} src={Environment.API_IMAGE +"/imagem/"+ c?.foto || "placeholder-image.png"} />
                                        </center>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <b style={{fontSize: '10pt'}}>{c?.nome}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='mt-2 mb-2'>
                                <b>Profissionais</b>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', overflow: 'auto'}}>
                                {colaboradores?.map((c, index)=>(
                                    <div style={{marginRight: '5px', marginLeft: '5px'}}>
                                        <center>
                                            <img style={{width: '70px', height: '70px', objectFit: 'cover', borderRadius: '50%'}} src={Environment.API_IMAGE +"/imagem/"+ c?.foto || "placeholder-image.png"} />
                                        </center>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <b style={{fontSize: '10pt'}}>{c?.nome?.split(" ")[0]}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </Modal>
            <Modal setShow={setShowMoreAssinatura} show={showMoreAssiantura} onCloseCallback={onCloseCallback}>
                {modalPaymentStep == 0 ? (
                    <>
                    <div className='mb-2'>
                        <b style={{fontSize: '1.2rem'}}>Detalhes da assinatura</b>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div onClick={() => {handleCycleAssinaturaChange("M")}} className={`button-group-default-outline ${assinaturaCycleIndex == "M" ? 'button-group-default-outline-active' : ''}`}>Mensal</div>
                        <div onClick={() => {handleCycleAssinaturaChange("S")}} className={`button-group-default-outline ${assinaturaCycleIndex == "S" ? 'button-group-default-outline-active' : ''}`}>Semestral</div>
                        <div onClick={() => {handleCycleAssinaturaChange("A")}} className={`button-group-default-outline ${assinaturaCycleIndex == "A" ? 'button-group-default-outline-active' : ''}`}>Anual</div>
                    </div>
                    <SpaceBox space={20}/>
                    {assinaturas?.filter(item => {return item.ciclo == assinaturaCycleIndex})[0] != undefined ? (
                        <>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <div>
                                    <b>R$</b>
                                    <b style={{fontSize: '34pt'}}>{assinaturas?.filter(item => {return item.ciclo == assinaturaCycleIndex})[0]?.preco?.split('.')[0]}</b>
                                    <b>
                                        {assinaturas?.filter(item => {return item.ciclo == assinaturaCycleIndex})[0]?.preco?.split('.')[1] > 0 ? (
                                            ","+assinaturas.filter(item => {return item.ciclo == assinaturaCycleIndex})[0]?.preco?.split('.')[1]
                                        ) : (null)}/{getCycleString()}
                                    </b>
                                </div>
                            </div>
                            <SpaceBox space={20}/>
                            <div>
                                <div className='pricing-row'>
                                    <ion-icon name="checkmark-outline"></ion-icon>&nbsp;
                                    <span><b>{assinaturas?.filter(item => {return item.ciclo == assinaturaCycleIndex})[0]?.limite_agendamento}</b> Agendamentos/mês</span>
                                </div>
                                <Hr elevation={0.5}/>
                                <div className='pricing-row'>
                                    <ion-icon name="checkmark-outline"></ion-icon>&nbsp;
                                    <span>Serviços exclusivos</span>
                                </div>
                                <Hr elevation={0.5}/>
                                <div className='pricing-row'>
                                    <ion-icon name="checkmark-outline"></ion-icon>&nbsp;
                                    <span>Cupons exclusivos</span>
                                </div>
                            </div>
                            <SpaceBox space={30}/> 
                            <div>
                                <Button onClick={handleAssinar} style={{width: '100%'}}>
                                    <b>Assinar Agora</b>
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='mt-1 mb-1'>
                            <b style={{color: 'gray'}}>Esté ciclo está indisponível.</b>
                        </div>
                    )}
                </>
                ) : modalPaymentStep == 1 ? (
                    <>
                        <div className='mb-2'>
                            <b style={{fontSize: '1.2rem'}}><span style={{background: '#000', color: 'white', borderRadius: "50%", paddingLeft: '7px', paddingRight: '7px'}}>1</span> Identificação</b>
                        </div>
                        <Input type={"nome"} setValue={setNome} value={nome} label={"Qual seu nome?"}/>
                        <Input type={"email"} setValue={setEmail} value={email} label={"Qual seu email?"}/>
                        <Input type={"cpf"} setValue={setCpf} value={cpf} label={"Qual seu CPF?"}/>
                        <Button onClick={handleNextModalPaymentStep} style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                            <b>Avançar</b>&nbsp;
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                        </Button> 
                    </>
                ) : (
                    <>
                        <div className='mb-2'>
                            <b style={{fontSize: '1.2rem'}}><span style={{background: '#000', color: 'white', borderRadius: "50%", paddingLeft: '7px', paddingRight: '7px'}}>2</span> Pagamento</b>
                        </div>
                        <iframe style={{border: 'none', width: '100%', minHeight: '430px', maxHeight: '500px'}} src={`https://payment.igend.com.br?payment_type=card&id_cliente=${user?.id_cliente}&id_assinatura=${assinaturas?.filter(item => {return item.ciclo == assinaturaCycleIndex})[0]?.id_assinatura}&id_parceiro=${parceiro?.id_parceiro}&ciclo=${assinaturaCycleIndex}&card=false&force_body_block=false`}/>
                    </>
                )}
            </Modal>
            <AccountModal show={accountModalShow} setShow={setAccountModalShow}/>
            {servicoSelected ? (
                <ParceiroAgenda expediente={expediente} parceiro={parceiro} servico={servicoSelected} handleResetComponent={handleResetComponent} assinante={assianturaChecker && servicoSelected?.incluso_na_assinatura == 1} comeToMe={parceiro?.flg_movel == 1} bonus={bonus}/>
            ) : (null)}
            {produtoSelected ? (
                <ParceiroProduto parceiro={parceiro} produto={produtoSelected} handleResetComponent={handleResetComponent} />
            ) : (null)}
            <SwipperRefresh onRefreshHandler={onRefreshHandler}>
                <div style={{position: 'absolute', top: '0', width: '100%'}}>
                    <div className='header' style={{backgroundImage: `url(${Environment.API_IMAGE +"/imagem/hd/"+ parceiro?.wallpaper_parceiro || "placeholder-image.png "})`}}>
                        <div className='header-actions'>
                            <div className='header-button' onClick={() => {navigate(-1)}}>
                                <ion-icon name="chevron-back-outline"></ion-icon>
                            </div>
                            <div className='header-actions' style={{padding: 0}}>
                                <div className='header-button' onClick={handleShare}>
                                    <ion-icon name={"share-social-outline"}></ion-icon>
                                </div>&nbsp;
                                <div className='header-button' onClick={handleFavorite}>
                                    <ion-icon name={favorited == true ? "heart" : "heart-outline"} style={{color: favorited == true ? 'red' : 'black'}}></ion-icon>
                                </div>
                            </div>
                        </div>
                        <div className='header-bottom-box'></div>
                    </div>
                </div>
                <div style={{position: 'absolute', top: 'calc(25vh - 15px)', width: '100%'}}>
                    <Container>
                        {parceiro != null ? (
                            <>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <img style={{objectFit: 'cover', width: '50px', height: '50px', borderRadius: '8px'}} src={Environment.API_IMAGE + "/imagem/" + parceiro?.logo_parceiro || "../placeholder-image.png"}/>&nbsp;
                                        <div>
                                            <b style={{fontSize: '1.3rem'}}>{parceiro?.nome_parceiro}</b>&nbsp;
                                            {parceiro?.cadastro_verificado == 'S' ? (
                                                <svg aria-label="Verificado" class="x1lliihq x1n2onr6" fill="rgb(0, 149, 246)" height="15" role="img" viewBox="0 0 40 40" width="15"><title>Verificado</title><path fill='#000' d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z" fill-rule="evenodd"></path></svg>
                                            ) : (null)}
                                            <br/>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                {nota != 0 && loading ? (<ion-icon name="star" style={{color: 'orange', fontSize: '8pt'}}></ion-icon>) : (null)}
                                                {loading ? (<p style={{fontSize: '8pt', background: nota == 0 ? '#000' : '', color: nota == 0 ? '#fff' : '', borderRadius: nota == 0 ? '4px' : '', paddingLeft: nota == 0 ? '4px' : '', paddingRight: nota == 0 ? '4px' : ''}}>{parceiro?.id_parceiro != null ? nota != 0 ? nota : "novo" : '...'}</p>) : (null)}
                                                &nbsp;•&nbsp;
                                                <p style={{fontSize: '8pt'}}>
                                                    {distance ? (distance?.toFixed(1)+"km") : (`...`)}
                                                </p>
                                                {parceiro?.flg_movel == 1 ? (
                                                    <>
                                                        &nbsp;•&nbsp;
                                                        <ion-icon name="walk-outline" style={{color: 'black', fontSize: '9pt'}}></ion-icon>
                                                        <p style={{fontSize: '8pt'}}>Móvel</p> 
                                                    </> 
                                                ) : (null) }
                                                {assinaturas?.length > 0 ? (
                                                    <>
                                                        &nbsp;•&nbsp;
                                                        <p style={{fontSize: '8pt'}}>Assinatura {Number(assinaturas[0]?.preco)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                    </>
                                                ) : (null)}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div onClick={handleNavigatorToLocation} style={{border: 'solid 1px #000', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <div style={{textAlign: 'center'}}>
                                                <div style={{marginTop: '4px'}}>
                                                    <ion-icon name="location-outline"></ion-icon>
                                                </div>
                                                <div style={{fontSize: '10pt', fontWeight: 'bold', marginTop: '-9px', paddingTop: '3px'}}>IR</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-2 mb-2'>
                                    <div className='mb-05' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="storefront-outline"></ion-icon>&nbsp;
                                        <b style={{fontSize: '0.90rem', color: parceiro?.status_parceiro == "A" ? `black`:`red`}}>{parceiro?.status_parceiro == "A" ? `Aberto` : `Fechado`}</b>
                                    </div>
                                    <div className='mb-05' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="time-outline"></ion-icon>&nbsp;
                                        <label>Atendimento por</label><b style={{fontSize: '0.90rem'}}>&nbsp;hora marcada</b>
                                    </div>
                                    <div className='mb-05' style={{display: 'flex', alignItems: 'start'}}>
                                        <ion-icon name="location-outline" style={{fontSize: '1.2rem'}}></ion-icon>&nbsp;
                                        <label style={{fontSize: '0.90rem'}}>{parceiro?.endereco_parceiro}</label>
                                    </div>
                                    <div className='mb-05' style={{display: 'flex', alignItems: 'start', fontWeight: 'bold'}} onClick={handleClick}>
                                        <ion-icon name="eye-outline" style={{fontSize: '1.2rem'}}></ion-icon>&nbsp;
                                        <label style={{fontSize: '0.90rem', textDecoration: 'underline'}}>Mais informações</label>
                                    </div>
                                </div>
                                {assianturaChecker == true ? (
                                    user ? (
                                        <div className='mb-2'>
                                            <Card className={"mt-05 banner-assinatura shadow"}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <ion-icon name="diamond-outline" style={{color: "black"}}></ion-icon>&nbsp;
                                                    <b>Você já é assinante no {parceiro?.nome_parceiro}</b>
                                                </div>
                                            </Card>
                                        </div>
                                    ):(null)
                                ) : (
                                    assinaturas?.length > 0 ? (
                                        <div className='mb-2'>
                                            <Card className={"mt-05 banner-assinatura shadow"}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <ion-icon name="diamond-outline" style={{color: "black"}}></ion-icon>&nbsp;
                                                    <b>Seja assinante no {parceiro?.nome_parceiro}</b>
                                                </div>
                                                <div className='mt-1'>
                                                    <p>{assinaturas[0]?.descricao}</p>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                                                    <b style={{fontSize: '14pt'}}>{Number(assinaturas[0]?.preco).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}/mês</b>&nbsp;&nbsp;
                                                    <Button style={{height: '40px'}} onClick={handleShowMoreAssinatura}>&nbsp;&nbsp;Ver mais&nbsp;&nbsp;</Button>
                                                </div>
                                            </Card>
                                        </div>
                                    ) : (null)
                                )}

                                <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="cut-outline"></ion-icon>&nbsp;
                                    <b>Lista de serviços</b>
                                </div>
                                <div className='service-list mb-2'>
                                    {servicos.length > 0 ? servicos?.map((service, index) => (
                                        <div className='service-item' onClick={() => {handleServiceSelected(service)}}>
                                            <div>
                                                <label style={{fontWeight: 'bold'}}>{service?.nome_servico}</label><br/>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <ion-icon name="time-outline" style={{fontSize: '9pt'}}></ion-icon>
                                                    <p style={{fontSize: '8pt'}}>{Utils.converterHoraPorExtenso(service?.tempo_servico)}</p>&nbsp;
                                                    <p style={{fontSize: '8pt'}}>{service?.incluso_na_assinatura == 1 ? '• Incluso na assinatura' : ''}</p>&nbsp;
                                                    <p style={{fontSize: '8pt'}}>{service?.atende_domicilio == 1 ? '• Atende á domicílio' : ''}</p>
                                                </div>
                                                <div style={{display: 'flex', alignItems: 'center', fontSize: '0.90rem'}}>
                                                    <ion-icon name="cash-outline"></ion-icon>&nbsp;
                                                    <b style={{color: 'green'}}> {Number(service?.preco_servico)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                                </div>
                                            </div>
                                            <div>
                                                <Button onClick={() => {handleServiceSelected(service)}}>Agendar</Button>
                                            </div>
                                        </div>
                                    )):(
                                        <>
                                            <b style={{color: 'gray'}}>Nenhum serviço disponível.</b>
                                        </>
                                    )}
                                </div>
                                
                                {produtos?.length > 0 ? (
                                    <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
                                        <ion-icon name="cube-outline"></ion-icon>&nbsp;
                                        <b>Nossos produtos</b>
                                    </div>
                                ) : (null)}
                                <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', justifyContent: 'center', gap: '5px', rowGap: '5px'}}>
                                    {produtos?.map((produto, index) => (
                                        <ProductItem key={index} onClick={() => {handleProdutoSelected(produto)}} produto={produto} style={{marginRight: '0px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.05)'}} />
                                    ))}
                                </div>
                                
                                <ParceiroGallery post={post} title={"Galeria de fotos"}/>
                            
                                <DownloadApp/>
                                    
                            </>
                        ) : (null)} 

                        <SpaceBox space={80} />

                    </Container>
                </div>
            </SwipperRefresh>
        </>
    )
}
