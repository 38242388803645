const DEVELOPMENT_MODE = false;

const Environment = {
    API_BASE: (DEVELOPMENT_MODE) ? "http://localhost:3001" : "https://api.igend.com.br",
    API_IMAGE: (DEVELOPMENT_MODE) ? "http://localhost:3002" : "https://images.igend.com.br",
    INTERVALO_MINUTOS: 30,
    HEADERS_CLIENTE: { 
        headers: { 
            TKBEAUTY_CLIENTE_ACCESS_TOKEN : localStorage.getItem("tk_beauty_cliente_token")
        } 
    },
    HEADERS_PARCEIRO: { 
        headers: { 
            TKBEAUTY_PARCEIRO_ACCESS_TOKEN : localStorage.getItem("tk_beauty_parceiro_token")
        } 
    }
}

export default Environment;