import React, { useContext, useEffect, useState } from 'react'
import { Card, FragmentView, Hr, Input, SpaceBox } from '../../components'
import { MainContext } from '../../helpers/MainContext'
import Api from '../../Api';
import Utils from '../../Utils';

export default () => {

    const {user} = useContext(MainContext);

    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        setup();
    }, [])

    const setup = async () => {
        let responseHistory = await Api.cliente.historico({
            forceToken: localStorage.getItem("tk_beauty_cliente_token")
        })

        let _history = responseHistory?.data?.data;

        setHistory(_history);

        setLoading(true);
    }

    const getPaymentName = (name) => {
        switch(name){
            case "PIX":
                return "Pix";
            case "CARD":
                return "Cartão";
            case "SIGNATURE":
                return "Assinatura";
            case "BONUS":
                return "Bônus";
        }
    }

    return (
        <FragmentView title={"Meus Dados"}>
            <Card>
                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                    <ion-icon name="person-outline"></ion-icon>&nbsp;
                    <b>Suas informações</b>
                </div>
                <Input type="name" label={"Seu nome"} value={user?.nome || "Não informado"} disabled={true}/>
                <Input type="email" label={"Seu email"} value={user?.email || "Não informado"} disabled={true}/>
                <div style={{display: 'flex'}}>
                    <Input type="celular" label={"Seu celular"} value={user?.celular || "Não informado"} disabled={true}/>&nbsp;&nbsp;&nbsp;
                    <Input type="cpf" label={"Seu CPF"} value={user?.cpf || "Não informado"} disabled={true}/>
                </div>
            </Card>
            <SpaceBox space={20}/>
            <Card>
                <div className='mb-1' style={{display: 'flex', alignItems: 'center'}}>
                    <ion-icon name="time-outline"></ion-icon>&nbsp;
                    <b>Suas atividades</b>
                </div>
                <div className='mt-1 mb-1'>
                    {loading ? (
                        history?.length > 0 ? (
                            history?.map((h, index) => (
                                <>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px', width: '25px', height: '25px', borderRadius: '50%', color: h?.status_pagamento == 'P' ? 'green' : h?.status_pagamento == 'A' ? 'orange' : 'red', background: h?.status_pagamento == "P" ? 'rgb(242, 255, 245)' : h?.status_pagamento == 'A' ? '#fff3dc' : 'rgb(244 183 183 / 30%)'}}>
                                                {h?.status_pagamento == "P" ? (
                                                    <ion-icon name="checkmark-done-outline"></ion-icon>
                                                ) : h?.status_pagamento == "A" ? (
                                                    <ion-icon name="time-outline"></ion-icon>
                                                ) : (
                                                    <ion-icon name="close-circle-outline"></ion-icon>
                                                )}
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <b>{h?.nome_parceiro}</b>
                                                <p style={{color: 'gray'}}>{h?.nome_servico}</p>
                                                <b><p style={{color: 'gray'}}>{Number(h?.total)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} - {getPaymentName(h?.metodo_pagamento)}</p></b>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{color: 'gray', fontSize: '10pt', textAlign: 'right', width: '90px'}}>
                                                {Utils.converterDate(h?.ts_registro)}
                                            </p>
                                        </div>
                                    </div>
                                    {index < (history?.length - 1) ? (
                                        <Hr elevation={1}/>
                                    ):(null)}
                                </>
                            ))
                        ) : (
                            <b style={{color: 'gray'}}>Nenhuma atividade.</b>
                        )
                    ) : (
                        <b style={{color: 'gray'}}>Carregando...</b>
                    )}
                </div>
            </Card>
            <SpaceBox space={80}/>
        </FragmentView>
    )
}
