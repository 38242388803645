import React, { useEffect, useRef, useState } from 'react'
import './style.css';
import Card from '../Card'
import Ship from '../Ship';
import SpaceBox from '../SpaceBox';
import Button from '../Button';
import Modal from '../Modal';
import Input from '../Input';
import Utils from '../../Utils';
import Api from '../../Api';
import { Scanner } from '@yudiel/react-qr-scanner';

export default ({reserva, expanded, onFetchReservations}) => {

    const inputRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [modalFinish, setModalFinish] = useState(false);
    const [mode, setMode] = useState("code");
    const [code, setCode] = useState("");
    const [cpf, setCpf] = useState("");
    const [startScan, setStartScan] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        setOpen(expanded);
    }, [])

    const handleClick = () => {
        setOpen(!open);
    }

    const handleFinish = () => {
        setModalFinish(true);
        inputRef?.current?.focus();
    }

    const handleFinishConfirm = async () => {
        let _token = localStorage.getItem("tk_beauty_parceiro_token");
        switch(mode){
            case "code":
                if(code?.trim()?.length <= 0){
                    Utils.notify("error", "Digite o código de agendamento.");
                    return;
                }
                let responseCode = await Api.colaborador.finishAgendamentoByCode({
                    forceToken: _token,
                    id_agendamento: reserva?.id_agendamento,
                    code: code
                })
                if(responseCode?.data?.success){
                    Utils.notify("success", "Agendamento finalizado com sucesso!");
                    if(onFetchReservations){
                        onFetchReservations();
                        setModalFinish(false);
                    }
                }else{
                    Utils.notify("error", responseCode?.data?.message);
                }
                break;
            case "cpf":
                if(cpf?.trim()?.length <= 0){
                    Utils.notify("error", "Digite o CPF do cliente do agendamento.");
                    return;
                }
                let responseCpf = await Api.colaborador.finishAgendamentoByCpf({
                    forceToken: _token,
                    id_agendamento: reserva?.id_agendamento,
                    cpf: cpf?.replaceAll(".", "")?.replaceAll("-", "")
                })
                if(responseCpf?.data?.success){
                    Utils.notify("success", "Agendamento finalizado com sucesso!");
                    if(onFetchReservations){
                        onFetchReservations();
                        setModalFinish(false);
                    }
                }else{
                    Utils.notify("error", responseCpf?.data?.message);
                }
                break;
        }
    }

    const handleModeCPF = () => {
        setCpf("");
        setCode("");
        setMode("cpf");
        setStartScan(false);
    }

    const handleModeCode = () => {
        setCpf("");
        setCode("");
        setMode("code");
    }

    const onCloseCallback = () => {
        setCpf("");
        setCode("");
        setMode("code");
        setStartScan(false);
    }

    const handleScan = () => {
        setCode("");
        setStartScan(!startScan);
    }

    const handleSetCode = (text) => {
        setCode(text);
    }

    const onClickBackdrop = () => {
        setShowSettings(false);
    }

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    const handleModalCancel = () => {

    }

    const onHandleScannedQrCodeResult = (e) => {
        try{
            let code = e[0]?.rawValue;
            setCode(code);
            setStartScan(false);
        }catch(err){
            console.log(err);
        }
    }    

    return (
        <>
            <Modal setShow={setModalFinish} show={modalFinish} onCloseCallback={onCloseCallback}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <b style={{fontSize: '14pt'}}>Finalizar agendamento</b><br/>
                        <span style={{color: 'rgba(0, 0, 0, 0.9)', fontSize: '10pt'}}>Escaneie ou digite o código do agendamento.</span>
                    </div>
                </div>
                <div className='mt-1'>
                    <div>
                        {startScan ? (
                            <>
                                <Scanner onScan={onHandleScannedQrCodeResult} />
                            </>
                        ) : (null)}
                        {mode == "code" ? (
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Input ref={inputRef} type={"code"} value={code} setValue={setCode} label={"Código de agendamento"} style={{width: '100%'}} />&nbsp;
                                <Button onClick={handleScan} style={{width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <ion-icon name="scan-outline" style={{fontSize: '20pt'}}></ion-icon>
                                </Button>
                            </div>
                        ) : (
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Input ref={inputRef} type={"cpf"} value={cpf} setValue={setCpf} label={"CPF do cliente"} style={{width: '100%'}} />
                            </div>
                        )}
                        {mode == "code" ? (
                            <p style={{fontSize: '10pt'}}>*Solicite o <b>código</b> ao cliente <b>{reserva?.nome_cliente}</b>.</p>
                        ):(
                            <p style={{fontSize: '10pt'}}>*Solicite o <b>CPF</b> ao cliente <b>{reserva?.nome_cliente}</b>.</p>
                        )}
                    </div>
                </div>
                {mode == "code" ? (
                    <div className='mt-1 mb-1'>
                        <b onClick={handleModeCPF} style={{textDecoration: 'underline', fontSize: '9pt'}}>Sem código, tentar outro método</b>
                    </div>
                    ):(
                    <div className='mt-1 mb-1'>
                        <b onClick={handleModeCode} style={{textDecoration: 'underline', fontSize: '9pt'}}>Já tenho o código</b>
                    </div>
                )}
                <div className='mt-2 mb-1'>
                    <span style={{color: 'rgba(0, 0, 0, 0.9)', fontSize: '10pt'}}>*Adicione <b>fotos</b> do seu serviço para que futuros clientes possam ver (opcional).</span>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div className='shadow mt-05' style={{width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', cursor: 'pointer'}}>
                            <ion-icon name="images-outline" size={"large"}></ion-icon>
                        </div>&nbsp;
                        <div className='shadow mt-05' style={{background: '#f5f5f5', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', cursor: 'pointer'}}>
                            <ion-icon name="add-outline" size={"large"}></ion-icon>
                        </div>
                    </div>
                </div>
                <Button className={"mt-1"} style={{width: '100%'}} onClick={handleFinishConfirm}><b>{mode == 'code' ? `Confirmar código` : `Confirmar CPF`}</b></Button>
            </Modal>
            <Card className={'mb-1'}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} onClick={handleClick}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <ion-icon name="time-outline"></ion-icon>
                        <b style={{fontSize: '0.90rem'}}>&nbsp;{reserva?.hora_agendamento}</b>&nbsp;-
                        <b style={{fontSize: '0.90rem'}}>&nbsp;{reserva?.nome_colaborador?.split(" ")[0]}</b>
                    </div>
                    {reserva.executado == 1 ? (
                        <Ship dotStyle={'green'}>finalizado</Ship>
                    ) : (
                        <Ship dotStyle={'blue'}>não finalizado</Ship>
                    )}
                    &nbsp;<ion-icon style={{fontSize: '1rem'}} name={open ? "chevron-up" : "chevron-down"}></ion-icon>
                </div>
                {open ? (
                    <>
                        <div className='mt-2' style={{border: 'solid rgba(0,0,0, 0.05) 1px', borderRadius: '4px', padding: '8px'}}>
                            <div style={{position: 'absolute', marginTop: '-20px'}}>
                                <label style={{background: '#fff'}}>Dados do agendamento</label>
                            </div>
                            <div className='mb-05 mt-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="location-outline"></ion-icon>&nbsp;
                                    <b>Local</b>
                                </div>
                                <div>
                                    <span>{reserva?.tipo_agendamento == "P" ? 'No estabelecimento' : 'Localização do cliente'}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="cut-outline"></ion-icon>&nbsp;
                                    <b>Serviço</b>
                                </div>
                                <div style={{textAlign: 'right'}}>
                                    <span>{reserva?.nome_servico}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="person-outline"></ion-icon>&nbsp;
                                    <b>Profissional</b>
                                </div>
                                <div>
                                    <span>{reserva?.nome_colaborador}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="person-outline"></ion-icon>&nbsp;
                                    <b>Cliente</b>
                                </div>
                                <div>
                                    <span>{reserva?.nome_cliente}</span>
                                </div>
                            </div>
                            <div className='mb-05' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="calendar-outline"></ion-icon>&nbsp;
                                    <b>Data</b>
                                </div>
                                <div>
                                    <span>{reserva?.data_agendamento}</span>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <ion-icon name="time-outline"></ion-icon>&nbsp;
                                    <b>Hora</b>
                                </div>
                                <div>
                                    <span>{reserva?.hora_agendamento}</span>
                                </div>
                            </div>
                        </div>

                        <SpaceBox space={10}/>

                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}> 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <b>Método de Pagamento</b>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {reserva.metodo_pagamento == "PIX" ? (
                                    <img src={`../pix_icon.png`} style={{width: '15px'}}/>
                                ) : reserva.metodo_pagamento == "CARD" ? (
                                    <ion-icon name="card-outline"></ion-icon>
                                ) : reserva.metodo_pagamento == "MONEY" ? (
                                    <ion-icon name="cash-outline"></ion-icon>
                                ) : reserva.metodo_pagamento == "BONUS" ? (
                                    <ion-icon name="cash-outline"></ion-icon>
                                ) : (<ion-icon name="diamond-outline"></ion-icon>)}
                                &nbsp;<b>{reserva.metodo_pagamento == "PIX" ? 'Pix' : reserva.metodo_pagamento == "CARD" ? 'Cartão' : reserva.metodo_pagamento == "MONEY" ? 'Dinheiro' : reserva.metodo_pagamento == "BONUS" ? 'Bônus' : 'Assinatura'}</b>
                            </div>
                        </div>
                        
                        {reserva?.metodo_pagamento == "SIGNATURE" ? (
                            <div className='totals'>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Status Pagamento</b>
                                    <b>{reserva?.status_pagamento == 'P' ? `Pago` : `Não pago`}</b>
                                </div>
                            </div>
                        ):(
                            <div className='totals'>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Status Pagamento</b>
                                    <b>{reserva?.status_pagamento == 'P' ? `Pago` : `Não pago`}</b>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <b>Total</b>
                                    <b style={{color: 'green'}}>{Number(reserva?.total)?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>
                                </div>
                            </div>
                        )}
                        
                        {reserva.executado == 0 ? (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Button onClick={() => {setShowSettings(true)}} className={'mt-1 shadow'} style={{display: "flex", alignItems: 'center', background: '#f5f5f5', color: 'black'}}>&nbsp;&nbsp;<ion-icon name="ellipsis-horizontal-outline"></ion-icon>&nbsp;&nbsp;</Button>&nbsp;
                                <Button className={'mt-1'} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={handleFinish}>
                                    <ion-icon name="cash" style={{transform: 'rotateZ(-45deg)'}}></ion-icon>&nbsp;
                                    <b>Finalizar</b>
                                </Button>          
                            </div>
                        ):(null)}

                        {showSettings ? (
                            <>
                                <div onClick={onClickBackdrop} style={{position: 'fixed', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0)', top: '0', left: '0', zIndex: 999}}></div>
                                <div onClick={handleContentClick}>
                                    <Card style={{zIndex: 9999, position: 'absolute', width: '200px', marginTop: '-25px', border: 'solid 1px #ddd', marginLeft: '14px'}}>
                                        {/*<Button onClick={handleModalCancel} style={{marginBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#000', background: '#ddd', height: '40px', width: '100%'}}>
                                            <ion-icon name="calendar"></ion-icon>&nbsp;
                                            <b>Remarcar</b>
                                        </Button>*/}
                                        <Button onClick={handleModalCancel} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#ff2e36', background: '#fceaeb', height: '40px', width: '100%'}}>
                                            <ion-icon name="close-circle"></ion-icon>&nbsp;
                                            <b>Cancelar</b>
                                        </Button>
                                    </Card>
                                </div>
                            </>
                        ) : (null)}

                    </>
                ) : (null)}
            </Card>
        </>
    )
}
